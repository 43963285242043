import React from "react";
import { createDndContext, DndProvider as BaseDndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const DndContext = createDndContext(HTML5Backend);

export const DndProvider = (props: { children: React.ReactNode }) => {
  return (
    <BaseDndProvider manager={DndContext.dragDropManager!}>
      {props.children}
    </BaseDndProvider>
  );
};
