import React from "react";
import { Redirect, Route } from "react-router-dom";

import { isMobile } from "react-device-detect";

import { AppMode, mode } from "config/initializers";

interface PublicRouteProps {
  path: string;
  children: React.ReactChild;
  isLoggedIn: boolean;
}

export const PublicRoute = (props: PublicRouteProps) => {
  return (
    <Route
      path={props.path}
      render={({ location }) => {
        return !props.isLoggedIn ? props.children : (
          <Redirect
            to={{
              pathname: (mode === AppMode.onboarding && "/app/me") || (isMobile && "/app/stage") || "/app/lobby",
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};
