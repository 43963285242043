import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { app } from "config";

import { notification } from "antd";
import logo from "assets/images/adw/logo/logo-dark.svg";

import { Loading } from "components/Loading/Loading";
import { parse } from "qs";
import { useOnMount, useAsync } from "hooks";
import { AuthService } from "services/AuthService";

export const Session = () => {
  const location = useLocation();
  const queryParams = parse(location.search, { ignoreQueryPrefix: true });

  const { execute, error } = useAsync(AuthService().passwordless.sessions.create);
  useOnMount(() => execute(queryParams.token as string));

  useEffect(() => {
    if (error) {
      notification.error({
        message: "Your passwordless login link is invalid. Request a new one",
      });
    }
  }, [error]);

  return (
    <div className="auth-wrapper">
      <div className="auth-wrapper-form-container">
        <div className="logo">
          <img src={logo} alt={`${app.name} Logo`} />
          <h1 className="title">March 2023</h1>
        </div>

        <div style={{ textAlign: "center" }}>
          {error ? (
            <>
              <h3 style={{ color: "#e52730" }}>Your passwordless login link is invalid!</h3>
              <p>
                It might have expired. Request a new one {" "}
                <Link to="/auth/login" style={{ textDecoration: "underline" }}>here</Link>.
              </p>
            </>
          ) : (
            <>
              <h3>Verifying you passwordless login link.</h3>

              <div>
                <Loading size={48} />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
