import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { deleteResource, InitialState, updateResources } from "app/common";
import { StageEntity } from "./stage.entity";

const initialState = {
  keyIds: {},
  byId: {},
} as StageInitialState;

interface StageInitialState extends InitialState<StageEntity<string>> {}

const talks = {
  create: createAction<{talkId: string; stageId: string}>("talks/create"),
};

const store = createSlice({
  name: "stageStore",
  initialState,
  reducers: {
    reset: () => initialState,
    deleteResource,
    updateResources,
  },
  extraReducers: (builder) => {
    builder.addCase(talks.create, (state, action) => {
      const stage = state.byId[action.payload.stageId];
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.stageId]: {
            ...stage,
            talks: [
              ...stage.talks,
              action.payload.talkId,
            ],
          },
        },
      };
    });
  },
});

export const stageStore = { ...store, actions: { ...store.actions, talks } };
