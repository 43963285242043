import React, { useContext, useMemo, useState } from "react";
import _ from "lodash";

import { connect, ConnectedProps } from "react-redux";
import { RootState } from "services";

import { DiContext, EPubSubTable, Tracking } from "app/common";
import { useAsync, useModal, useOnError, useOnMount } from "hooks";

import { RaffleEntity, raffleService } from "app/infra/raffle";

import { notification } from "antd";

import { Loading } from "components/Loading/Loading";
import Raffle from "./Raffle";
import { Consent } from "./Modal/Consent";

const RaffleList = (props: PropsRedux) => {
  const { apiService, dispatch } = useContext(DiContext);
  const raffleSrv = raffleService({ apiService, dispatch });

  const [selectedRaffle, setSelectedRaffle] = useState<RaffleEntity | null>(null);

  const { error, isPending, execute } = useAsync(() => {
    return raffleSrv.getAll();
  });

  useOnMount(execute);
  useOnError(error);

  const modal = useModal();

  const onAgreeShareInformation = (raffle: RaffleEntity) => {
    return raffleSrv.claim(raffle).then((response) => {
      if (response) {
        notification.info({ message: "May the odds be with you!" });
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call, no-underscore-dangle
        window._AWTrackProxy.push({
          type: "reducer.raffle_entry",
          payload: { selectedRaffle: raffle },
          context: {},
        });
      }

      Tracking.PubSub([{
        table: EPubSubTable.Raffle,
        data: {
          datetime: Tracking.Date,
          user_id: props.user.old_id || props.user.id,
          raffle: raffle.name,
        },
      }]);

      setSelectedRaffle(null);
      return response;
    });
  };

  const onCloseAgreeShareInformation = () => {
    modal.shut();
    setSelectedRaffle(null);
  };

  const onClickRaffle = (raffle: RaffleEntity) => {
    if (!raffle.isClaimed) {
      setSelectedRaffle(raffle);
      modal.open();
    }
  };

  const raffles: RaffleEntity[] = useMemo(() => {
    return _.orderBy(props.raffles, "date", ["desc"]);
  }, [props.raffles]);

  return (
    <div className="lottery">
      {selectedRaffle && (
        <Consent
          onOk={() => onAgreeShareInformation(selectedRaffle)}
          onCancel={onCloseAgreeShareInformation}
          isVisible={modal.isVisible}
        />
      )}

      {isPending ? (
        <Loading style={{ textAlign: "center" }} />
      ) : (
        raffles.slice(0, 1).map((raffle) => <Raffle raffle={raffle} onClickRaffle={onClickRaffle} />)
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  raffles: state.raffleStore.keyIds["lobby-raffles"].map((id) => state.raffleStore.byId[id]),
  user: state.userStore.byId["me"],
});

const connector = connect(mapStateToProps);

type PropsRedux = ConnectedProps<typeof connector>;

export default connector(RaffleList);
