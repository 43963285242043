import React, { useContext } from "react";

import { DiContext } from "app/common";
import { useAsync, useOnError } from "hooks";

import { ReplayTrack, ReplayTrackEntity, replayTrackService } from "app/infra/replayTrack";
import { SponsorshipEntity } from "app/infra/sponsorships";

import { Form, Modal, notification } from "antd";
import * as Replay from "components/@admin/Replay";

interface UpdateModalProps {
  isVisible: boolean;
  onShut: () => void;
  track: ReplayTrackEntity;
  sponsorships: SponsorshipEntity[];
}

export const Update = (props: UpdateModalProps) => {
  const [form] = Form.useForm();

  const { apiService, dispatch } = useContext(DiContext);
  const trackSrv = replayTrackService({ apiService, dispatch });

  const { execute, isPending, error } = useAsync((data: ReplayTrack) => {
    return trackSrv.update(data, props.track.id).then((response) => {
      props.onShut();
      notification.success({ message: "Replay Track was successfully updated" });

      return response;
    });
  });

  useOnError(error);

  return (
    <Modal
      title="Update Replay Track"
      visible={props.isVisible}
      destroyOnClose={true}
      onOk={form.submit}
      onCancel={props.onShut}
    >
      <Replay.TrackForm
        form={form}
        isPending={isPending}
        onFinish={execute}
        track={props.track}
        sponsorships={props.sponsorships}
      />
    </Modal>
  );
};
