import React, { useEffect, useRef, useState, useContext, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { app } from "config";

import { connect } from "react-redux";
import { RootState } from "services";

import { DiContext } from "app/common/utils";
import { useAsync, useOnError, useOnMount } from "hooks";

import { ChatService, chatStore } from "app/infra/chat";
import { UserEntity } from "app/infra/user/user.entity";

interface ChatPixelProps {
  me?: UserEntity;
  hasConsented: boolean;
  currentRoomId: string;
  fcmToken: string;
}

const mapStateToProps = (state: RootState) => {
  return {
    me: state.userStore.byId["me"],
    hasConsented: state.chatStore.hasConsented,
    currentRoomId: state.chatStore.currentRoomId,
    fcmToken: state.chatStore.fcmToken,
  };
};

export const ChatPixel = connect(mapStateToProps)((props: ChatPixelProps) => {
  const { apiService, dispatch } = useContext(DiContext);

  const chatSrv = ChatService({ apiService, dispatch });

  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [isDisplayed, setIsDisplayed] = useState(false);

  const { execute, error } = useAsync(chatSrv.updateChatCredentials);
  useOnError(error);

  const changeRoom = (path: string | null) => {
    iframeRef.current?.contentWindow?.postMessage({ externalCommand: "go", path }, "*");
  };

  useEffect(() => {
    changeRoom(props.currentRoomId);
  }, [props.currentRoomId]);

  const location = useLocation();

  useEffect(() => {
    if (location.pathname.search("/app/chats") === 0) {
      if (!props.me?.chatPassword || !props.me?.chatUid) {
        execute();
      }
      setIsDisplayed(true);
    } else {
      dispatch(chatStore.actions.update({ currentRoomId: props.currentRoomId }));
      setIsDisplayed(false);
    }
  }, [location, props.me]);

  const iframeSrc = useMemo(() => {
    if (props.me && props.me.chatPassword && props.me.email) {
      return `${app.chatUrl}/${props.currentRoomId || ""}?email=${encodeURIComponent(props.me.email)}&password=${encodeURIComponent(props.me.chatPassword)}&fcmToken=${encodeURIComponent(props.fcmToken)}`;
    }

    return "";
  }, [props.me, props.currentRoomId, props.fcmToken]);

  useOnMount(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data.event === "unreadMessages") {
        const unreadMessages = parseInt(event.data.unreadMessages, 10) || 0;
        dispatch(chatStore.actions.update({ unreadMessages }));
      }

      if (event.data.error === "auth/wrong-password") {
        execute();
      }
    };

    window.addEventListener("message", handleMessage);

    return () => window.removeEventListener("message", handleMessage);
  });

  if (!props.hasConsented) return <></>;

  return (
    <iframe
      title="chatpixel"
      frameBorder={0}
      className="rocketchatpixel"
      ref={iframeRef}
      src={iframeSrc}
      style={(isDisplayed
        ? {
          width: "100%",
          height: "100%",
          flex: 1,
          zIndex: 600,
          position: "absolute",
          top: 0,
          left: 0,
        }
        : {
          height: 0,
          width: 0,
          display: "none",
        }
      )}
    />
  );
});
