import React, { useContext, useMemo } from "react";

import { DiContext } from "app/common";
import { useAsync } from "hooks/useAsync";
import { useOnError } from "hooks/useOnError";

import { PostEntity, postService } from "app/infra/posts";
import { ReactTypes } from "app/infra/talk";

import { Heart } from "react-feather";

export const PostReaction = (props: { post: PostEntity }) => {
  const { apiService, dispatch } = useContext(DiContext);
  const postSrv = postService({ apiService, dispatch });

  const { execute, error } = useAsync((type: ReactTypes) => {
    return postSrv.toggleReaction(props.post.id, type);
  });

  useOnError(error);

  const { execute: executeRemove } = useAsync(() => {
    return postSrv.removeReaction(props.post.id);
  });

  const isReactedByCurrentUser = useMemo(() => {
    return Object.values(ReactTypes).includes(props.post.reaction_type as ReactTypes);
  }, [props.post]);

  const toggleReaction = (type: ReactTypes) => {
    if (isReactedByCurrentUser) {
      executeRemove();
    } else {
      execute(type);
    }
  };

  return (
    <div
      role="button"
      tabIndex={0}
      className="reaction-container react noselect"
      onClick={() => { toggleReaction(ReactTypes.love); }}
      onKeyPress={() => { toggleReaction(ReactTypes.love); }}
    >
      <span
        role="img"
        aria-label="heart"
        className={`reaction-icon ${isReactedByCurrentUser ? "liked" : ""}`}
      >
        <Heart />
      </span>

      <span className="reaction-counter">{props.post.reactions["love"]}</span>
    </div>
  );
};
