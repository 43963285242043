import React, {
  useState,
  useCallback,
  useRef,
  useMemo,
  Fragment,
  useContext,
} from "react";
import { format } from "date-fns";
import { Button } from "antd";
import { Edit, Trash2 } from "react-feather";
import { useAsync, DiContext } from "app/common/utils";
import { useOnError } from "hooks/useOnError";
import { UserEntity } from "app/infra/user";
import { ReplayTalkEntity, replayTalkService } from "app/infra/replayTalk";
import { ReplayStageEntity, replayStageService } from "app/infra/replayStage";
import { ReplayTrackEntity } from "app/infra/replayTrack";
import { DragableBodyRow } from "app/common/views";

import { useModal } from "hooks/useModal";

import { DndProvider } from "components/DndProvider/DndProvider";
import { Table } from "components/Table";

import { CreateReplayStageModal } from "./createReplayStage.modal";
import { EditReplayStageModal } from "./editReplayStage.modal";
import { CreateReplayTalkModal } from "./createReplayTalk.modal";
import { EditReplayTalkModal } from "./editReplayTalk.modal";

const editTableColumns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
  },
  {
    title: "",
    dataIndex: "edit",
    key: "edit",
    width: 50,
  },
  {
    title: "",
    dataIndex: "delete",
    key: "delete",
    width: 50,
  },
];

const editTalksColumns = [
  { title: "Title", dataIndex: "title", key: "title" },
  { title: "Description", dataIndex: "description", key: "description" },
  {
    title: "Starting At",
    key: "start_time",
    dataIndex: "start_time",
  },
  { title: "Length", dataIndex: "length", key: "length" },
  {
    title: "Presenters",
    dataIndex: "presenters",
    key: "presenters",
  },
  {
    title: "",
    dataIndex: "edit",
    key: "edit",
    width: 50,
  },
  {
    title: "",
    dataIndex: "delete",
    key: "delete",
    width: 50,
  },
];

interface TalksById {
  [key: string]: ReplayTalkEntity;
}

interface EditReplayStagesProps {
  stages: ReplayStageEntity<String>[];
  talksById: TalksById;
  speakers: UserEntity[];
  tracks: ReplayTrackEntity[];
}

interface ExpandedRowRenderProps {
  talks: ReplayTalkEntity[];
  id: string;
}

export const EditReplayStagesTable = ({
  stages,
  talksById,
  speakers,
  tracks,
}: EditReplayStagesProps) => {
  const { apiService, dispatch } = useContext(DiContext);
  const stageSrv = replayStageService({ apiService, dispatch });
  const talkSrv = replayTalkService({ apiService, dispatch });

  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editTalkModalVisible, setEditTalkModalVisible] = useState(false);
  const [stageToBeEdited, setStageToBeEdited] = useState<ReplayStageEntity<
    String
  > | null>(null);
  const [talkToBeEdited, setTalkToBeEdited] = useState<ReplayTalkEntity | null>(null);
  const [stageIdToBeAssigned, setStageIdToBeAssigned] = useState<string>("");

  const { execute, pending, error } = useAsync(
    (id: string) => stageSrv.delete(id),
    false
  );

  const {
    execute: executeOrder,
    pending: pendingOrder,
    error: errorOrder,
  } = useAsync(
    (id: string, order: number) => stageSrv.update({ order }, id),
    false
  );

  const { execute: executeDeleteTalk, error: errorTalk } = useAsync(
    (id: string) => talkSrv.delete(id),
    false
  );

  useOnError(error);
  useOnError(errorTalk);
  useOnError(errorOrder);

  const { shut: createTalkCloseModal, open: createTalkOpenModal, isVisible: createTalkVisible } = useModal();

  const closeCreateModalVisible = () => setCreateModalVisible(false);
  const openCreateModalVisible = () => setCreateModalVisible(true);
  const closeEditModalVisible = () => {
    setStageToBeEdited(null);
    setEditModalVisible(false);
  };
  const openEditModalVisible = (stage: ReplayStageEntity<String>) => {
    setEditModalVisible(true);
    setStageToBeEdited(stage);
  };

  const openEditTalkModalVisible = (talk: ReplayTalkEntity) => {
    setEditTalkModalVisible(true);
    setTalkToBeEdited(talk);
  };

  const closeEditTalkModalVisible = () => {
    setTalkToBeEdited(null);
    setEditTalkModalVisible(false);
  };

  const transformedData = useMemo(() => {
    return stages
      .sort((a, b) => a.order - b.order)
      .map((item) => {
        return {
          id: item.id,
          key: item.name,
          name: item.name,
          description: item.description,
          // @ts-ignore
          talks: item.talks && item.talks.map((talkId) => talksById[talkId]),
          edit: (
            <Button onClick={() => openEditModalVisible(item)}>
              <Edit size={20} />
            </Button>
          ),
          delete: (
            <Button onClick={() => execute(item.id)}>
              <Trash2 size={20} />
            </Button>
          ),
        };
      });
  }, [stages]);

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const stage = stages[dragIndex];
      executeOrder(stage.id, hoverIndex);
    },
    [stages]
  );

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: 24,
        }}
      >
        <Button type="primary" onClick={openCreateModalVisible}>
          Create stage
        </Button>
      </div>
      <CreateReplayStageModal
        visible={createModalVisible}
        close={closeCreateModalVisible}
      />
      <CreateReplayTalkModal
        speakers={speakers}
        tracks={tracks}
        visible={createTalkVisible}
        close={createTalkCloseModal}
        stageId={stageIdToBeAssigned}
      />
      {stageToBeEdited && (
        <EditReplayStageModal
          visible={editModalVisible}
          close={closeEditModalVisible}
          stage={stageToBeEdited}
        />
      )}
      {talkToBeEdited && (
        <EditReplayTalkModal
          talk={talkToBeEdited}
          visible={editTalkModalVisible}
          close={closeEditTalkModalVisible}
          speakers={speakers}
          tracks={tracks}
        />
      )}
      <DndProvider>
        <Table
          size="middle"
          loading={pending || pendingOrder}
          bordered={true}
          className="editStagesTable"
          onMoveRow={moveRow}
          isRowDraggable={true}
          rowKey="id"
          columns={editTableColumns}
          dataSource={transformedData}
          pagination={false}
          expandable={{
            expandedRowRender: ({ talks, id }: ExpandedRowRenderProps) => (
              <>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    type="primary"
                    className="create-talk-button"
                    stage-id={id}
                    onClick={() => {
                      setStageIdToBeAssigned(id);
                      createTalkOpenModal();
                    }}
                  >
                    Create talk
                  </Button>
                </div>
                <Table
                  size="middle"
                  bordered={true}
                  rowKey={(record) => (`${record.id}-${record.title}`)}
                  columns={editTalksColumns}
                  dataSource={[...(talks || [])]
                    .filter((talk) => talk)
                    .map((talk) => ({
                      ...talk,
                      presenters: talk?.speakers?.reduce(
                        (acc: string, value: UserEntity) =>
                          `${(acc && `${acc}, `) || ""}${value.first_name} ${
                            value.last_name
                          }`,
                        ""
                      ),
                      delete: (
                        <Button onClick={() => executeDeleteTalk(talk.id)}>
                          <Trash2 size={20} />
                        </Button>
                      ),
                      edit: (
                        <Button onClick={() => openEditTalkModalVisible(talk)}>
                          <Edit size={20} />
                        </Button>
                      ),
                    }))}
                  pagination={false}
                />
              </>
            ),
          }}
        />
      </DndProvider>
    </>
  );
};
