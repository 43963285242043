import React, { useContext, useEffect } from "react";
import { Modal, Form, notification } from "antd";
import { DiContext, useAsync } from "app/common/utils";
import { useOnError } from "hooks/useOnError";
import { UserForm } from "./user.form";
import { AdminEditUserDTO, UserEntity } from "app/infra/user";
import { adminUserService } from "app/infra/user";

interface EditProfileModalProps {
  visible: boolean;
  close: () => void;
  user: UserEntity | null;
  onSuccess: () => void;
}

interface FileFormFunctionProps {
  file: any;
}

interface FormProps extends AdminEditUserDTO {
  id: string;
}

export const EditProfileModal = ({
  visible,
  close,
  user,
  onSuccess,
}: EditProfileModalProps) => {
  const [form] = Form.useForm();
  const { apiService, dispatch } = useContext(DiContext);
  // const companySrv = companyService({ apiService, dispatch });
  const adminUserSrv = adminUserService({ apiService, dispatch });

  const onFinish = async (data: FormProps) => {
    console.log({ data });
    let newData = { ...data };

    // @ts-ignore
    const deactivate_sync = newData["deactivate_sync"] as boolean;

    if (user?.ticket) {
      await adminUserSrv.updateTicket(user?.ticket?.id, deactivate_sync);
    }

    // @ts-ignore
    if (newData["attendee_company"] === undefined || newData["attendee_company"] === "") {
      // @ts-ignore
      newData["attendee_company"] = null;
    }

    // @ts-ignore
    if (newData["attendee_company_type"] === undefined || newData["attendee_company_type"] === "") {
      // @ts-ignore
      newData["attendee_company_type"] = null;
    }

    // @ts-ignore
    delete newData["deactivate_sync"];
    // @ts-ignore
    delete newData["id"];

    return adminUserSrv.update({ ...newData }, data.id);
  };

  const customUpload = (props: FileFormFunctionProps) =>
    adminUserSrv.updatePhoto(props.file, user!.id);

  const deletePhoto = () => adminUserSrv.deletePhoto(user!.id);

  const { error, value, pending, execute } = useAsync(onFinish, false);

  const {
    execute: executePhoto,
    pending: pendingPhoto,
    error: errorPhoto,
  } = useAsync<UserEntity>(customUpload, false);

  const {
    execute: executeDeletePhoto,
    pending: pendingDeletePhoto,
    error: errorDeletePhoto,
  } = useAsync<UserEntity>(deletePhoto, false);

  useOnError(error);
  useOnError(errorDeletePhoto);
  useOnError(errorPhoto);

  useEffect(() => {
    if (value) {
      notification.success({
        message: "User was edited!",
      });
      onSuccess();
      close();
    }
  }, [value]);

  return (
    <Modal
      className="edit-profile-modal"
      visible={visible}
      destroyOnClose={true}
      onCancel={close}
      confirmLoading={pending}
      title="Edit user"
      okText="Update"
      cancelText="Close"
      onOk={() => {
        form.submit();
      }}
    >
      <Form
        form={form}
        onFinish={execute}
        scrollToFirstError
        onFinishFailed={() =>
          notification.error({
            message: "Invalid Form",
            description:
              "Please fix all invalid fields to be able to save your changes.",
          })
        }
        name="edit-user"
        layout="vertical"
      >
        {user && (
          <UserForm
            create={false}
            pending={pending}
            user={user}
            photo={{
              deletePhoto: executeDeletePhoto,
              upload: executePhoto,
              pendingDelete: pendingDeletePhoto,
              pendingUpload: pendingPhoto,
            }}
          />
        )}
      </Form>
    </Modal>
  );
};
