import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";

import { DiContext, usePaginationAsync } from "app/common";
import { useAsync, useOnError, useOnMount } from "hooks";

import { PerkEntity, perkService } from "app/infra/perk";

import { ColumnsType } from "antd/lib/table";
import { Button, Card, Col, Input, notification, Popconfirm, Row, Table } from "antd";
import { Edit, Search, Trash2 } from "react-feather";

import { parseQueryPath } from "app/common/utils/Path";
import { parse } from "qs";

interface PerkListParams {
  page?: string;
  search?: string;
}

export const PerkList = () => {
  const { apiService, dispatch } = useContext(DiContext);
  const perkSrv = perkService({ apiService, dispatch });

  const history = useHistory();
  const queryParams = parse(history.location.search, { ignoreQueryPrefix: true }) as PerkListParams;

  const pagination = usePaginationAsync<PerkEntity>(
    (page, pageSize, search, sortBy, sortDirection) => {
      return perkSrv.getAllPaginated({
        params: {
          count: pageSize,
          page,
          search: search || undefined,
          sortBy: sortBy || undefined,
          sortDirection: sortDirection || undefined,
        },
      });
    },
    {
      initialFilter: { search: queryParams.search || "" },
      initialPage: Number(queryParams.page),
    },
  );

  useOnMount(pagination.async.execute);

  const { execute: onDelete, error: onDeleteError } = useAsync((id: string) => {
    return perkSrv.delete(id).then((response) => {
      notification.success({ message: "Perk was successfully deleted" });
      pagination.async.execute();

      return response;
    });
  });
  useOnError(onDeleteError);

  const updateSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    history.push(parseQueryPath(
      "/admin/perks",
      "page",
      "1",
      "search",
      event.currentTarget.value,
    ));
    pagination.onSetFilter({ search: event.currentTarget.value });
  };

  const columns: ColumnsType<PerkEntity> = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      sorter: true,
      onHeaderCell: () => ({
        onClick: () => pagination.onToggleOrderBy("title"),
      }),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      ellipsis: true,
      sorter: true,
      onHeaderCell: () => ({
        onClick: () => pagination.onToggleOrderBy("description"),
      }),
    },
    {
      title: "Link",
      dataIndex: "link",
      key: "link",
      sorter: true,
      onHeaderCell: () => ({
        onClick: () => pagination.onToggleOrderBy("link"),
      }),
    },
    {
      key: "actions",
      width: 84,
      render: (_value, record) => (
        <Button.Group>
          <Link
            to={`/admin/perks/${record.id}/edit`}
            title="Edit"
            className="ant-btn ant-btn-link ant-btn-sm"
          >
            <Edit size={20} />
          </Link>

          <Popconfirm
            title="Are you certain that you want to delete this Perk?"
            onConfirm={() => onDelete(record.id)}
            okText="Confirm"
            cancelText="Cancel"
            placement="topRight"
          >
            <Button type="link" size="small" title="Delete" danger={true}>
              <Trash2 size={20} />
            </Button>
          </Popconfirm>
        </Button.Group>
      ),
    },
  ];

  return (
    <Card>
      <Row className="admin-page-header">
        <Col className="left-column">
          <h1 className="title">Perks</h1>
          <div className="elements">
            <Input
              placeholder="search"
              prefix={<Search size={20} color="#A3A7B2" />}
              value={pagination.filter.search}
              onChange={updateSearch}
            />
          </div>
        </Col>

        <Col>
          <Button type="primary" onClick={() => history.push("/admin/perks/new")}>
            Create Perk
          </Button>
        </Col>
      </Row>

      <Table
        size="middle"
        loading={pagination.async.isPending}
        bordered={true}
        rowKey="id"
        columns={columns}
        dataSource={pagination.async.value?.data}
        pagination={{
          size: "default",
          current: pagination.page,
          total: pagination.totalItems,
          pageSize: pagination.pageSize,
          onChange: (page, pageSize) => {
            pagination.onSetPage(page);
            if (pageSize) {
              pagination.onSetPageSize(pageSize);
            }

            history.push(parseQueryPath(
              "/admin/perks",
              "page",
              page.toString(),
              "search",
              pagination.filter.search,
            ));
            window.scrollTo({ top: 0 });
          },
        }}
      />
    </Card>
  );
};
