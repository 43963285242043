import React, { useContext } from "react";
import { useHistory } from "react-router-dom";

import { useOnError } from "hooks";
import { DiContext, useAsync } from "app/common";

import { Sponsorship, sponsorshipService } from "app/infra/sponsorships";

import { useForm } from "antd/lib/form/Form";
import { Button, Card, Col, notification, Row } from "antd";

import { SponsorshipForm } from "./SponsorshipForm";

export const SponsorshipCreate = () => {
  const { apiService, dispatch } = useContext(DiContext);
  const sponsorshipSrv = sponsorshipService({ apiService, dispatch });

  const [form] = useForm();
  const history = useHistory();

  const { execute, error } = useAsync((data: Sponsorship) => {
    return sponsorshipSrv.create(data).then((response) => {
      history.push("/admin/sponsorships");

      notification.success({ message: "Sponsorship was successfully created" });
      return response;
    });
  });
  useOnError(error);

  return (
    <Row>
      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
        <Card>
          <Row style={{ marginBottom: "20px" }}>
            <Col>
              <h1>Create Sponsorship</h1>
            </Col>
          </Row>

          <SponsorshipForm form={form} onFinish={execute} />

          <Row style={{ marginTop: "20px" }}>
            <Col style={{ marginLeft: "auto" }}>
              <Button type="primary" onClick={form.submit}>
                Create
              </Button>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};
