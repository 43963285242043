import { DiContext } from "app/common";
import { useContext } from "react";

export const UserService = () => {
  const { apiService, dispatch } = useContext(DiContext);

  return {
    passwordRecoveries: {
      create: (id: string) =>
        apiService.post<{ message: string }>(`admin/users/${id}/password/recoveries`),

      createLink: (id: string) =>
        apiService.post<{ link: string }>(`admin/users/${id}/password/recoveries/link`),
    },

    invitations: {
      create: (id: string) => {
        return apiService
          .post<{ message: string }>(`admin/users/${id}/invitations`);
      },
    },
  };
};
