import React from "react";

import { TrackEntity } from "app/infra/track";
import CheckboxFilter, { StringBooleanMap } from "components/CheckboxFilter/CheckboxFilter";

interface Props {
  tracks: TrackEntity[];
  selectedTracks: StringBooleanMap;
  setSelectedTracks: (obj: StringBooleanMap) => void;
  formats: { id: string; name: string; }[];
  selectedFormats: StringBooleanMap;
  setSelectedFormats: (obj: StringBooleanMap) => void;
}

const ScheduleFilter = (props: Props) => {
  return (
    <>
      <CheckboxFilter
        items={props.tracks.map((element) => {
          return {
            id: element.id,
            name: element.name,
          };
        })}
        placeholder="Tracks"
        selectedItems={props.selectedTracks}
        setSelectedItems={props.setSelectedTracks}
      />

      <CheckboxFilter
        items={props.formats.map((element) => {
          return {
            id: element.id,
            name: element.name,
          };
        })}
        placeholder="Format"
        selectedItems={props.selectedFormats}
        setSelectedItems={props.setSelectedFormats}
      />
    </>
  );
};

export default ScheduleFilter;
