import React, { useContext, useEffect, useMemo, useState } from "react";

import moment from "moment-timezone";

import { DiContext, PaginationRequestDTO } from "app/common";
import { useAsync } from "hooks/useAsync";
import { useOnError } from "hooks/useOnError";
import { useOnMount } from "hooks/useOnMount";

import { PushNotificationEntity, pushNotificationService } from "app/infra/pushNotification";
import { NotificationBareData, PushNotificationStore } from "app/infra/pushNotification/pushNotification.store";

import { Loading } from "components/Loading/Loading";
import { Badge } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";

export const NotificationDropdown = (props: { unreadNotifications: NotificationBareData[] }) => {
  const { apiService, dispatch } = useContext(DiContext);
  const pushNotificationSrv = pushNotificationService({ apiService, dispatch });

  const [page, setPage] = useState(0);

  const { execute, isPending, value, error } = useAsync((data: PaginationRequestDTO) => {
    return pushNotificationSrv.getAll(data);
  });

  useOnError(error);

  const [pushNotifications, setPushNotifications] = useState<PushNotificationEntity[]>([]);

  useEffect(() => {
    return setPushNotifications([...pushNotifications, ...(value?.data || [])]);
  }, [value]);

  const hasMore = useMemo(() => {
    return !value || pushNotifications.length < value?.pagination.total;
  }, [value]);

  const getData = () => {
    if (!isPending && hasMore) {
      execute({ page, count: 10 });
      setPage((prev) => prev + 1);
    }
  };

  useOnMount(getData);

  const isUnreadNotification = (id: string) => {
    return props.unreadNotifications.find((obj) => Object.keys(obj).includes(`${id}`));
  };

  const removeUnreadNotification = (id: string) => {
    if (isUnreadNotification(id)) {
      dispatch(PushNotificationStore.actions.unread.delete(id));
    }
  };

  const sendAt = (date: string) => {
    return (
      moment(date)
        .calendar({
          sameDay: "[Today]",
          nextDay: "[Tomorrow]",
          nextWeek: "dddd",
          lastDay: "[Yesterday]",
          lastWeek: "[Last] dddd",
          sameElse: "HH:MM DD MMM",
        })
        .toLowerCase()
    );
  };

  return (
    <div className="notification-drop-down" id="notification-wrapper">
      <InfiniteScroll
        next={getData}
        loader={(
          <div className="loading-mask" style={{ textAlign: "center", padding: "5px" }}>
            <Loading size={24} />
          </div>
        )}
        hasMore={hasMore}
        dataLength={pushNotifications.length}
        scrollableTarget="notification-wrapper"
      >
        {pushNotifications.length === 0 && (
          !isPending ? (
            <span className="notification-item notification-item-clickable push-notification">
              <div className="text">
                <div className="title">
                  There are no notifications <span role="presentation" aria-hidden="true">🔔</span>
                </div>
              </div>
            </span>
          ) : (
            <div className="loading-mask" style={{ textAlign: "center", padding: "5px" }}>
              <Loading size={24} />
            </div>
          )
        )}

        {pushNotifications.map((pushNotification) => (
          <a
            key={`${pushNotification.id}-${pushNotification.title}`}
            href={pushNotification.link}
            target="_blank"
            rel="noreferrer"
            className="notification-item notification-item-clickable push-notification"
            style={{ color: "#fff" }}
            onClick={() => removeUnreadNotification(pushNotification.id)}
            onMouseEnter={() => removeUnreadNotification(pushNotification.id)}
          >
            <div className="text">
              {isUnreadNotification(pushNotification.id) && (
                <Badge dot={true} color="#EA6CA0" style={{ float: "right" }} />
              )}
              <div className="date">{sendAt(pushNotification.send_at)}</div>
              <div className="title">{pushNotification.title}</div>
              <div className="body">{pushNotification.body}</div>
            </div>
          </a>
        ))}
      </InfiniteScroll>
    </div>
  );
};
