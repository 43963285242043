import React from "react";
import { Leads } from "./leads";

export const StatsSection = ({
  calledFromProfile = false,
}: {
  calledFromProfile: boolean;
}) => (
  <div className="admin-page stats-page">
    <Leads
      calledFromProfile={calledFromProfile}
    />
  </div>
);
