import React, { useContext } from "react";

import { DiContext } from "app/common";
import { useAsync, useOnError } from "hooks";

import { ReplayTrackEntity, replayTrackService } from "app/infra/replayTrack";

import { Modal, notification } from "antd";

interface DeleteModalProps {
  isVisible: boolean;
  onShut: () => void;
  track: ReplayTrackEntity;
}

export const Delete = (props: DeleteModalProps) => {
  const { apiService, dispatch } = useContext(DiContext);
  const trackSrv = replayTrackService({ apiService, dispatch });

  const { execute, error } = useAsync(() => {
    return trackSrv.delete(props.track.id).then((response) => {
      props.onShut();
      notification.success({ message: "Replay Track was successfully deleted" });

      return response;
    });
  });

  useOnError(error);

  return (
    <Modal
      visible={props.isVisible}
      destroyOnClose={true}
      onOk={execute}
      onCancel={props.onShut}
    >
      <div>
        Are you certain that you want to delete this replay track?
      </div>
    </Modal>
  );
};
