import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import { RootState } from "services";
import { DiContext, useAsync } from "app/common";
import { useOnError } from "hooks/useOnError";
import { useOnMount } from "hooks/useOnMount";

import { PerkEntity, perkService } from "app/infra/perk";
import { UserEntity } from "app/infra/user";

import { Skeleton } from "antd";
import { UserAvatar } from "components/User/UserAvatar";

const IDENTIFIER = "lobby-claimed-perks";

interface ClaimedPerkEntity extends PerkEntity {
  users: UserEntity[];
}

interface ClaimedPerksProps {
  perks: ClaimedPerkEntity[];
}

const mapStateToProps = (state: RootState): ClaimedPerksProps => {
  return {
    perks: state.perkStore.keyIds[IDENTIFIER].map((key) => {
      return {
        ...state.perkStore.byId[key],
        users: state.perkStore.claimsByPerkId[key].userIds.map((id) => state.userStore.byId[id]).filter(Boolean),
      };
    }),
  };
};

export const ClaimedPerks = connect(mapStateToProps)((props: ClaimedPerksProps) => {
  const { apiService, dispatch } = useContext(DiContext);
  const perkSrv = perkService({ apiService, dispatch });

  const { execute, error, pending } = useAsync(() => {
    return perkSrv.getClaimed({ identifier: IDENTIFIER });
  });

  useOnMount(execute);
  useOnError(error);

  return (
    <div className="claimed-perks">
      <div className="title-wrapper">
        <h4>Perks Claimed</h4>
      </div>

      <div className="perk-item-wrapper">
        {props.perks.length === 0 && pending ? (
          <>
            <Skeleton avatar active title paragraph={{ rows: 2 }} />
            <Skeleton avatar active title paragraph={{ rows: 2 }} />
            <Skeleton avatar active title paragraph={{ rows: 2 }} />
          </>
        ) : props.perks.map((perk) => (
          <Link to={`/app/perks/${perk.id}`} key={perk.id} className="perk-item">
            <aside>
              <Link to={`/app/user-profile/${perk.users[0].id}`}>
                <UserAvatar user={perk.users[0]} size={60} />
              </Link>

              <div className="text">
                <Link to={`/app/user-profile/${perk.users[0].id}`}>
                  <span className="title">
                    {perk.users[0].first_name} {perk.users[0].last_name}
                  </span>
                </Link>

                <div className="subtitle">
                  <span>RECENTLY CLAIMED A PERK: </span>
                  <span>{perk.title}</span>
                </div>
              </div>
            </aside>
          </Link>
        ))}
      </div>
    </div>
  );
});
