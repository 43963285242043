import React, { useContext, useEffect, useState } from "react";

import { DiContext } from "app/common";
import { useAsync, useDebounce, useOnError } from "hooks";

import { companyService } from "app/infra/company";
import { Sponsorship, SponsorshipEntity, sponsorshipService } from "app/infra/sponsorships";

import { FileFormFunctionProps, UploadPhoto } from "app/presentation/common";

import { FormInstance } from "antd/lib/form";
import { Form, Input, Select, Spin } from "antd";

interface Props {
  form: FormInstance,
  onFinish: (data: Sponsorship) => void,
  sponsorship?: SponsorshipEntity,
}

export const SponsorshipForm = (props: Props) => {
  const { apiService, dispatch } = useContext(DiContext);
  const companySrv = companyService({ apiService, dispatch });
  const sponsorshipSrv = sponsorshipService({ apiService, dispatch });

  const [search, setSearch] = useState("");

  const debouncedSearch = useDebounce(search, 500);

  const { execute, error, isPending, value: companies } = useAsync((searchString: string) => {
    return companySrv.getCompanies({
      search: searchString || undefined,
      component: "user-company-search",
      limit: 10,
      page: 0,
    });
  });
  useOnError(error);

  useEffect(() => {
    execute(debouncedSearch);
  }, [debouncedSearch]);

  const uploadPhoto = (fileForm: FileFormFunctionProps) => {
    return sponsorshipSrv.updatePhoto({
      id: props.sponsorship?.id || "0",
      photo: fileForm.file,
    });
  };

  return (
    <Form
      form={props.form}
      onFinish={props.onFinish}
      layout="vertical"
      initialValues={props.sponsorship}
    >
      <Form.Item
        label="Title"
        name="title"
        rules={[
          {
            required: true,
            message: "Title is required",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Description"
        name="description"
      >
        <Input.TextArea />
      </Form.Item>

      <Form.Item
        label="Link"
        name="link"
        rules={[
          {
            required: true,
            message: "Link is required",
          },
          {
            type: "url",
            message: "Not a valid URL",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Select Company"
        name="sponsorId"
        initialValue={props.sponsorship?.sponsor?.id}
      >
        <Select
          size="large"
          showSearch={true}
          filterOption={false}
          notFoundContent={isPending ? <Spin size="small" /> : null}
          onSearch={(val) => setSearch(val)}
        >
          {companies?.data.map((company) => {
            return <Select.Option value={company.id}>{company.name}</Select.Option>;
          })}
        </Select>
      </Form.Item>

      {props.sponsorship ? (
        <UploadPhoto uploadPhoto={uploadPhoto} image_url={props.sponsorship.imageURL} />
      ) : (
        "Image for sponsor can be uploaded only on edit, not on create!"
      )}
    </Form>
  );
};

SponsorshipForm.defaultProps = {
  sponsorship: undefined,
};
