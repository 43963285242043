/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useContext, Fragment } from "react";
import { ResourceEntity } from "app/infra/resource";
import { DiContext } from "app/common";
import { resourceService } from "app/infra/resource";
import { AgreeShareInformationResource } from "./agreeShareInformationResource.modal";
import { Download } from "react-feather";
import { useModal } from "hooks/useModal";
import { RootState } from "services";
import { connect } from "react-redux";

enum FileTypes {
  pdf = "pdf",
  xls = "xls",
  xlsx = "xlsx",
  docx = "docx",
}
interface ResourceProps {
  isAdmin: boolean;
  type: FileTypes;
  title: string;
  size: string;
  link: string;
  id: string;
}

const mapStateToProps = (state: RootState) => ({
  isAdmin: state.authStore.isAdmin,
});

const Resource = connect(mapStateToProps)(({ isAdmin, type, title, size, link, id }: ResourceProps) => {
  const modal = useModal();
  const { apiService, dispatch } = useContext(DiContext);
  const resourceSrv = resourceService({ apiService, dispatch });

  return (
    <>
      <a
        download={link}
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        className="resource"
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <Download />
        <div
          onClick={() => {
            if (isAdmin) {
              const a = document.createElement("a");
              a.target = "_blank";
              a.href = link;
              a.download = title;
              a.click();
            } else {
              modal.open();
            }
          }}
        >
          <h4>{title}</h4>
          <span>{size}</span>
        </div>
      </a>
      <AgreeShareInformationResource
        visible={modal.isVisible}
        onOk={() =>
          resourceSrv.download(id).then((response) => {
            modal.shut();
            return response;
          })
        }
        close={modal.shut}
        url={link}
        title={title}
      />
    </>
  );
});

interface ResourcesProps {
  resources: ResourceEntity[];
}

export const Resources = ({ resources }: ResourcesProps) => {
  return (
    <div className="resources-container">
      {resources.map((resource, key) => (
        <Resource
          key={key}
          title={resource.file_name}
          size={resource.size}
          type={resource.file_type as FileTypes}
          link={resource.path}
          id={resource.id}
        />
      ))}
    </div>
  );
};
