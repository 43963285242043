import React from "react";
import _ from "lodash";

import { UserEntity } from "app/infra/user";

import { Avatar } from "antd";
import { User } from "react-feather";

interface UserAvatarProps {
  user: UserEntity | undefined;
  size?: number;
  style?: React.CSSProperties;
}

export const UserAvatar = (props: UserAvatarProps) => {
  const backgrounds = [
    "#F082D8",
    "#CD82F0",
    "#8482F0",
    "#82CFF0",
    "#56DDBD",
    "#56DD63",
    "#F2C85D",
    "#F2815D",
    "#F25D5D",
  ];

  const alphabet = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];

  const colorFromLetter = (letter: string | undefined) => {
    const fallbackColor = "#000000";
    if (letter === undefined) return fallbackColor;

    const index = _
      .chunk(alphabet, backgrounds.length)
      .findIndex((chunk) => chunk.includes(letter));

    return index !== -1 ? backgrounds[index] : fallbackColor;
  };

  const initials = [props.user?.first_name, props.user?.last_name]
    .filter(Boolean)
    .map((word) => word?.slice(0, 1))
    .join("");

  return (
    <Avatar
      src={props.user?.profile_picture_url}
      alt={initials}
      size={props.size}
      style={{
        backgroundColor: colorFromLetter(initials[0]),
        color: "#FFFFFF",
        fontSize: Math.round(props.size! * 0.3),
        fontWeight: 700,
        ...props.style,
      }}
      data-alt={initials}
    >
      {initials || (
        <User
          size={Math.round(props.size! * 0.6)}
          color="#FFFFFF"
          style={{ verticalAlign: "middle" }}
        />
      )}
    </Avatar>
  );
};

UserAvatar.defaultProps = { size: 70, style: {} };
