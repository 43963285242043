import React, { useState } from "react";

import _ from "lodash";
import VimeoPlayer from "@vimeo/player";

import { useOnMount } from "hooks";
import { Radio, Tabs } from "antd";

interface PlayerProps {
  cover?: string;
  s3URL?: string;
  jwURL?: string;
  dacastID?: string;
  vimeoURL?: string;
}

interface VideoPlayer {
  play: () => void;
  pause: () => void;
}

const VimeoVideoPlayer = (vimeoURL: string, domID: string): VideoPlayer => {
  const player = new VimeoPlayer(domID, {
    title: false,
    url: vimeoURL,
    controls: true,
    height: 500,
    autoplay: false,

  });

  player.on("error", console.error);

  return {
    play: () => {
      player?.play().catch(console.error);
    },
    pause: () => {
      player?.pause().catch(console.error);
    },
  };
};

const JwVideoPlayer = (jwURL: string, domID: string): VideoPlayer => {
  // @ts-ignore
  const player = (window.jwplayer as typeof jwplayer)(domID).setup({
    file: jwURL,
    height: 500,
    controls: true,
  });

  return {
    play: () => {
      player?.play();
    },
    pause: () => {
      player?.stop();
    },
  };
};

const DacastVideoPlayer = (dacastID: string, domID: string): VideoPlayer => {
  // @ts-ignore
  const player = window.dacast(dacastID, domID) as { play: () => void; pause: () => void };

  return {
    play: () => {
      player.play();
    },
    pause: () => {
      player.pause();
    },
  };
};

export const Player = (props: PlayerProps) => {
  const [providers, setProviders] = useState<string[]>([]);
  const [selectedProvider, setSelectedProvider] = useState<string>();

  const [availablePlayers, setAvailablePlayers] = useState<{ [key: string]: VideoPlayer }>({});
  const [player, setPlayer] = useState<VideoPlayer>();

  const createPlayerFromProvider = (provider: string) => {
    player?.pause();
    setSelectedProvider(provider);

    if (provider === "s3") {
      if (availablePlayers["s3"]) {
        setPlayer(availablePlayers["s3"]);
      } else {
        const providerPlayer = JwVideoPlayer(props.s3URL as string, "s3-video-player");
        setAvailablePlayers((prev) => ({ ...prev, dacast: providerPlayer }));
        setPlayer(providerPlayer);
      }
      return;
    }

    if (provider === "jw") {
      if (availablePlayers["jw"]) {
        setPlayer(availablePlayers["jw"]);
      } else {
        const providerPlayer = JwVideoPlayer(props.jwURL as string, "jw-video-player");
        setAvailablePlayers((prev) => ({ ...prev, dacast: providerPlayer }));
        setPlayer(providerPlayer);
      }
      return;
    }

    if (provider === "vimeo") {
      if (availablePlayers["vimeo"]) {
        setPlayer(availablePlayers["vimeo"]);
      } else {
        const providerPlayer = VimeoVideoPlayer(props.vimeoURL as string, "vimeo-video-player");
        setAvailablePlayers((prev) => ({ ...prev, dacast: providerPlayer }));
        setPlayer(providerPlayer);
      }
      return;
    }

    if (provider === "dacast") {
      if (availablePlayers["dacast"]) {
        setPlayer(availablePlayers["dacast"]);
      } else {
        const providerPlayer = DacastVideoPlayer(props.dacastID as string, "dacast-video-player");
        setAvailablePlayers((prev) => ({ ...prev, dacast: providerPlayer }));
        setPlayer(providerPlayer);
      }
    }
  };

  useOnMount(() => {
    const availableProviders = _.entries(props).reduce<string[]>((acc, [key, value]) => {
      return value && key !== "cover" ? [...acc, key.replace("URL", "").replace("ID", "")] : acc;
    }, []);

    setProviders(availableProviders);
  });

  return (
    <>
      <Tabs activeKey={selectedProvider || "cover"} tabBarStyle={{ display: "none" }}>
        {props.cover && (
          <Tabs.TabPane key="cover" forceRender={true}>
            <div className="connectplexus-video-player" style={{ height: 500 }}>
              <div className="connectplexus-video-player__overlay" style={{ background: `url(${props.cover})`, height: 500, zIndex: 1 }} />
            </div>
          </Tabs.TabPane>
        )}

        {providers.map((provider, i) => (
          <Tabs.TabPane key={provider} forceRender={true}>
            <div className="connectplexus-video-player" style={{ height: 500 }}>
              <div
                id={`${provider}-video-player`}
              />
            </div>
          </Tabs.TabPane>
        ))}
      </Tabs>

      <Radio.Group
        defaultValue="main"
        onChange={(e) => createPlayerFromProvider(e.target.value as string)}
        style={{ marginTop: 20 }}
      >
        {providers.map((provider) => (
          <Radio.Button value={provider}>{_.upperCase(provider)}</Radio.Button>
        ))}
      </Radio.Group>
    </>
  );
};

Player.defaultProps = {
  cover: undefined,
  s3URL: undefined,
  jwURL: undefined,
  dacastID: undefined,
  vimeoURL: undefined,
};
