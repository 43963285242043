import React, { useContext } from "react";
import { Carousel } from "antd";
import { ChevronLeft, ChevronRight } from "react-feather";
import { connect } from "react-redux";
import { RootState } from "services";
import { DiContext, useAsync } from "app/common/utils";
import { useOnError } from "hooks/useOnError";
import { useOnMount } from "hooks/useOnMount";
import { highlightService, HighlightEntity } from "app/infra/highlights";

interface CurrentlyStreamingProps {
  highlights: HighlightEntity[];
}

export const CurrentlyStreaming = connect((state: RootState) => {
  const highlightIds = state.highlightStore.keyedIds["lobby-highlights"] || [];
  const highlights = highlightIds.map(
    (id) => state.highlightStore.highlightsById[id],
  );

  return { highlights };
})(({ highlights }: CurrentlyStreamingProps) => {
  const { apiService, dispatch } = useContext(DiContext);
  const highlightSrv = highlightService({ apiService, dispatch });
  const { execute, error } = useAsync(() => highlightSrv.getAll(), false);

  useOnMount(() => {
    execute();
  });

  useOnError(error);

  const settings = {
    dots: false,
    arrows: true,
    accessibility: true,
    // autoplay: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    swipeToSlide: true,
    draggable: true,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div className={`${highlights.length ? "currently-streaming" : ""}`}>
      <Carousel
        {...settings}
        infinite={false}
        centerMode={false}
        arrows={true}
        prevArrow={(
          <div className="slick-prev">
            <ChevronLeft
              size={32}
              color="#4B4C53"
              style={{ position: "absolute", paddingRight: "10%" }}
            />
          </div>
        )}
        nextArrow={(
          <div className="slick-next">
            <ChevronRight
              size={32}
              color="#4B4C53"
              style={{ position: "absolute", paddingLeft: "10%" }}
            />
          </div>
        )}
      >
        {highlights.map((highlight, index) => (
          <a className="streaming-item" href={highlight.video_url} key={index}>
            <img className="preview" src={highlight.thumbnail_url} alt="img" />
            <h5>{highlight.title}</h5>
          </a>
        ))}
      </Carousel>
    </div>
  );
});
