import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { TalkEntityExtended } from "app/infra/talk";
import { AppMode, mode } from "config/initializers";
import { isDarkColor } from "app/common/utils/isDarkColor";
import { Tag } from "antd";
import { PlayCircle } from "react-feather";

interface TalkItemOwnProps {
  talk: TalkEntityExtended;
}

interface TalkItemProps extends TalkItemOwnProps {}

export const TalkItem = ({ talk }: TalkItemProps) => {
  // const hasStayTuned: boolean = talk.title.toLowerCase().includes("stay tuned");
  // const hasStageBreak: boolean = talk.title
  //   .toLowerCase()
  //   .includes("stage break");
  // const isConferenceFinished: boolean = mode === AppMode.finished;

  const coverUrl = useMemo(() => {
    return talk.cover_url;
  }, [talk.cover_url]);

  // if ((hasStayTuned || hasStageBreak) && isConferenceFinished) return null;

  return (
    <Link
      className="talk-item"
      to={`/app/talk/${talk.id}`}
    >
      <div
        className="talk-content"
        style={{
          backgroundImage: `url(${coverUrl})`,
          borderRadius: 4,
          backgroundSize: "cover",
          backgroundPosition: "center center",
        }}
      >
        <div className="icon-play">
          <PlayCircle size={32} color="white" />
        </div>
      </div>

      <h3>
        {talk.title}
      </h3>

      {talk.track && (
        <Tag
          style={{
            background: talk?.track?.color,
            color: isDarkColor(talk?.track?.color || "#fff") ? "white" : "black",
          }}
          className="ant-tag-small"
        >
          {talk.track.name}
        </Tag>
      )}
    </Link>
  );
};
