import React, { useContext } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";

import { RootState } from "services";
import { DiContext } from "app/common";
import { Track, trackService } from "app/infra/track";
import { useAsync, useOnError, useOnMount } from "hooks";

import { Button, Card, Col, notification, Row } from "antd";
import { useForm } from "antd/lib/form/Form";

import { TrackForm } from "./TrackForm";

const mapStateToProps = (state: RootState) => ({
  tracks: state.trackStore.byId,
});

const connector = connect(mapStateToProps);
type PropsRedux = ConnectedProps<typeof connector>;

export const TrackUpdate = connector((props: PropsRedux) => {
  const { apiService, dispatch } = useContext(DiContext);
  const trackSrv = trackService({ apiService, dispatch });

  const [form] = useForm();
  const history = useHistory();
  const params = useRouteMatch().params as { id: string };

  const { execute, error } = useAsync(() => {
    return trackSrv.getOne(params.id);
  });
  useOnError(error);

  useOnMount(execute);

  const { execute: onUpdate, error: onUpdateError } = useAsync((data: Track) => {
    return trackSrv.update(data, params.id).then((response) => {
      history.push("/admin/schedule/tracks");

      notification.success({ message: "Track was successfully updated" });

      return response;
    });
  });
  useOnError(onUpdateError);

  return (
    <Row>
      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
        <Card>
          <Row align="middle" style={{ marginBottom: "20px" }}>
            <Col>
              <h1>Update Track</h1>
            </Col>
          </Row>

          {props.tracks[params.id] && (
            <TrackForm form={form} track={props.tracks[params.id]} onFinish={(data) => onUpdate(data)} />
          )}

          <Row>
            <Col style={{ marginLeft: "auto" }}>
              <Button type="primary" onClick={form.submit}>Update</Button>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
});
