import LinkedIn from "./LinkedinIcon";
import Facebook from "./FacebookIcon";
import FacebookSimple from "./FacebookSimpleIcon";
import Twitter from "./TwitterIcon";
import Pin from "./PinIcon";

export {
  LinkedIn,
  Facebook,
  FacebookSimple,
  Twitter,
  Pin,
};

const Icons = Object.freeze({
  LinkedIn,
  Facebook,
  FacebookSimple,
  Twitter,
  Pin,
});

export default Icons;
