import React from "react";
import { app } from "config";

import { Button, Modal } from "antd";

interface WelcomeModalProps {
  visible: boolean;
  onClose: () => void;
}

export const Welcome = (props: WelcomeModalProps) => {
  return (
    <Modal
      footer={null}
      visible={props.visible}
      centered={true}
      closable={false}
    >
      <h4>
        <span role="presentation" aria-hidden="true">🚨</span> What's next?
      </h4>

      <p>
        Fill out your Company Profile and get it listed on the upcoming
        "Featured Companies" webpage on the {app.name} website.

        <br />
        <br />

        The platform will be ready for you to access soon!

        <br />
        <br />

        <Button onClick={props.onClose} type="primary">Complete Now!</Button>
      </p>
    </Modal>
  );
};
