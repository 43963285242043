/* eslint-disable */
import axios, {AxiosResponse, AxiosInstance} from "axios";
import { app } from "config";
import crypto from "crypto";

const request: AxiosInstance = axios.create({ baseURL: "https://us-central1-istackmanila-playground.cloudfunctions.net" });

export enum EPubSubTable {
    LogIn =  "logins",
    Chat =  "chat",
    Video =  "video_events",
    PageView =  "page_views",
    Download =  "downloads",
    Perk =  "perks",
    Raffle =  "raffles",
    ExternalLinks =  "external_links",
    BusinessCards =  "business_cards",
}

type TPubSubDataBase = {
    user_id: string;
    datetime: string;
};

type TPubSubDataLogin = TPubSubDataBase & {
    ip: string;
    user_agent: string;
};

type TPubSubDataChat = TPubSubDataBase & {
    channel_id: string;
};

type TPubSubDataVideoEvent = TPubSubDataBase & {
    type: "live" | "replay" | "post-live";
    timestamp: number;
    platform: string;
    video_id: string;
};

type TPubSubDataPageView = TPubSubDataBase & {
    path: string;
    referral_path: string | null;
};

type TPubSubDataDownload = TPubSubDataBase & {
    path: string;
    file_name: string;
};

type TPubSubDataPerk = TPubSubDataBase & {
    company: string;
    perk: string;
};

type TPubSubDataRaffle = TPubSubDataBase & {
    raffle: string;
};

type TPubSubDataExternalLink = TPubSubDataBase & {
    path: string;
    link_url: string;
};

type TPubSubDataBusinessCard = TPubSubDataBase & {
    company: string;
    company_id: string;
};

type TPubSubMessageData = TPubSubDataLogin | TPubSubDataChat | TPubSubDataVideoEvent | TPubSubDataPageView
    | TPubSubDataDownload | TPubSubDataPerk | TPubSubDataRaffle | TPubSubDataExternalLink | TPubSubDataBusinessCard;

type TPubSubMessage<T extends EPubSubTable, D extends TPubSubMessageData> = { table: T; data: D };
type TPubSubMessageLogin = TPubSubMessage<EPubSubTable.LogIn, TPubSubDataLogin>;
type TPubSubMessageChat = TPubSubMessage<EPubSubTable.Chat, TPubSubDataChat>;
type TPubSubMessageVideo = TPubSubMessage<EPubSubTable.Video, TPubSubDataVideoEvent>;
type TPubSubMessagePageView = TPubSubMessage<EPubSubTable.PageView, TPubSubDataPageView>;
type TPubSubMessageDownload = TPubSubMessage<EPubSubTable.Download, TPubSubDataDownload>;
type TPubSubMessagePerk = TPubSubMessage<EPubSubTable.Perk, TPubSubDataPerk>;
type TPubSubMessageRaffle = TPubSubMessage<EPubSubTable.Raffle, TPubSubDataRaffle>;
type TPubSubMessageExternalLink = TPubSubMessage<EPubSubTable.ExternalLinks, TPubSubDataExternalLink>;
type TPubSubMessageBusinessCard = TPubSubMessage<EPubSubTable.BusinessCards, TPubSubDataBusinessCard>;

type TPubSubMessages = TPubSubMessageLogin | TPubSubMessageChat | TPubSubMessageVideo | TPubSubMessagePageView
    | TPubSubMessageDownload | TPubSubMessagePerk | TPubSubMessageRaffle | TPubSubMessageExternalLink
    | TPubSubMessageBusinessCard;

const userSymbol = Symbol("user");

type TUser = { id: string; first_name?: string; last_name?: string; name?: string; email?: string; };

export class Tracking  {
    public static get Date() {
        return new Date().toISOString().replace("T", " ").split(".")[0];
    }
    public static get User(): TUser | undefined {
        return (window as any)[userSymbol];
    }
    public static PubSub(messages: TPubSubMessages[]): void {
        try {
            if (!messages.length) return;

            // console.log("awts PubSub: ", messages);
            const nonce: number = Date.now();

            // it is important that the properties are sorted alphabetically
            const dataSignature: any = {
                key: app.gcKey,
                messages,
                nonce,
            };

            request.post("/portal-pubsub", {
                host: window.location.hostname,
                messages: dataSignature.messages,
                nonce: dataSignature.nonce,
                signature: crypto.createHmac("sha256", app.gcKey!)
                    .update(JSON.stringify(dataSignature))
                    .digest("hex"),
            }).then((response: AxiosResponse) => {
                // console.log("pubsub success: ", response.data);
            }).catch((error) => {
                console.error("pubsub req error: ", error);
            });
        }
        catch (error) {
            console.error("pubsub func error: ", error);
        }
    }
    public static SetUser(user: Omit<TUser, "id"> & { id: number }, resetCookie?: true) {
        const { id, first_name, last_name, name, email } = user;
        // need for GTM
        if (!window.user) window.user = {};

        if (id && !(window as any)[userSymbol]) {
            const usr: TUser = { id: id.toString(), first_name, last_name, name, email };
            (window as any)[userSymbol] = usr;
            if(!window.user.id) window.user = usr;

            // backup
            if (resetCookie) window.document.cookie = `userId=${ id }`;
        }
    }
}
