import React, { useContext } from "react";
import { useHistory } from "react-router-dom";

import { DiContext } from "app/common";
import { useAsync, useOnError } from "hooks";
import { Perk, perkService } from "app/infra/perk";

import { Button, Card, Col, notification, Row } from "antd";
import { useForm } from "antd/lib/form/Form";

import { PerkForm } from "./PerkForm";

export const PerkCreate = () => {
  const { apiService, dispatch } = useContext(DiContext);
  const perkSrv = perkService({ apiService, dispatch });

  const [form] = useForm();
  const history = useHistory();

  const { execute, error } = useAsync((data: Perk) => {
    return perkSrv.create(data).then((response) => {
      history.push("/admin/perks");

      notification.success({ message: "Perk was successfully created" });
      return response;
    });
  });
  useOnError(error);

  return (
    <Row>
      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
        <Card>
          <Row style={{ marginBottom: "20px" }}>
            <Col>
              <h1>Create Perk</h1>
            </Col>
          </Row>

          <PerkForm form={form} onFinish={execute} />

          <Row style={{ marginTop: "20px" }}>
            <Col style={{ marginLeft: "auto" }}>
              <Button type="primary" onClick={form.submit}>
                Create
              </Button>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};
