import React, { useState, useContext } from "react";
import { Button, Col, Input, Row } from "antd";
import { Edit, Trash2 } from "react-feather";

import { DiContext } from "app/common/utils";
import { useAsync, useOnMount, useOnError } from "hooks";

import { ReplayTrackEntity, replayTrackService } from "app/infra/replayTrack";
import { connect } from "react-redux";
import { SponsorshipEntity } from "app/infra/sponsorships";
import { RootState } from "services";

import { Table } from "components/Table";

import * as Modal from "./Modal";

interface ReplayTrackListProps {
  tracks: ReplayTrackEntity[];
  sponsorships: SponsorshipEntity[];
}

const mapStateToProps = (state: RootState) => {
  return {
    tracks: Object.values(state.replayTrackStore.byId),
    sponsorships: Object.values(state.sponsorshipStore.sponsorshipsById),
  };
};

export const TrackList = connect(mapStateToProps)((props: ReplayTrackListProps) => {
  const { apiService, dispatch } = useContext(DiContext);
  const trackSrv = replayTrackService({ apiService, dispatch });

  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [selectedTrack, setSelectedTrack] = useState<ReplayTrackEntity | null>(null);

  const { execute, isPending, error } = useAsync(() => {
    return trackSrv.getAll();
  });

  useOnMount(execute);
  useOnError(error);

  const openCreateModal = () => setIsCreateModalVisible(true);
  const shutCreateModal = () => setIsCreateModalVisible(false);

  const openUpdateModal = (track: ReplayTrackEntity) => {
    setIsUpdateModalVisible(true);
    setSelectedTrack(track);
  };

  const shutUpdateModal = () => {
    setIsUpdateModalVisible(false);
    setSelectedTrack(null);
  };

  const openDeleteModal = (track: ReplayTrackEntity) => {
    setIsDeleteModalVisible(true);
    setSelectedTrack(track);
  };

  const shutDeleteModal = () => {
    setIsDeleteModalVisible(false);
    setSelectedTrack(null);
  };

  const getColumns = () => {
    return [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Color",
        dataIndex: "color",
        key: "color",
      },
      {
        title: "Sponsor",
        dataIndex: "sponsor",
      },
      {
        key: "actions",
        dataIndex: "actions",
        width: 84,
      },
    ];
  };

  const getDataSource = () => {
    return props.tracks.map((track) => {
      const sponsorship = props.sponsorships.find((sponsor) => track?.sponsorship?.id === sponsor.id);

      return {
        ...track,
        key: track.id,
        sponsor: sponsorship?.title || "",
        actions: (
          <Button.Group>
            <Button
              type="link"
              size="small"
              title="Edit"
              onClick={() => openUpdateModal(track)}
            >
              <Edit size={20} />
            </Button>

            <Button
              type="link"
              size="small"
              title="Delete"
              danger={true}
              onClick={() => openDeleteModal(track)}
            >
              <Trash2 size={20} />
            </Button>
          </Button.Group>
        ),
      };
    });
  };

  return (
    <>
      <Row className="admin-page-header">
        <Col className="left-column">
          <h1 className="title">Replays</h1>
        </Col>

        <Col>
          <Button
            type="primary"
            onClick={openCreateModal}
            className="create-track"
          >
            Create track
          </Button>
        </Col>
      </Row>

      <Table
        size="middle"
        loading={isPending}
        bordered={true}
        rowKey="id"
        columns={getColumns()}
        dataSource={getDataSource()}
        pagination={false}
      />

      <Modal.Create isVisible={isCreateModalVisible} onShut={shutCreateModal} sponsorships={props.sponsorships} />

      {selectedTrack && (
        <>
          <Modal.Update
            isVisible={isUpdateModalVisible}
            onShut={shutUpdateModal}
            track={selectedTrack}
            sponsorships={props.sponsorships}
          />
          <Modal.Delete isVisible={isDeleteModalVisible} onShut={shutDeleteModal} track={selectedTrack} />
        </>
      )}
    </>
  );
});
