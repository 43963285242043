import React, { useContext } from "react";

import { DiContext } from "app/common";
import { useAsync, useOnError } from "hooks";

import { ReplayTrack, replayTrackService } from "app/infra/replayTrack";
import { SponsorshipEntity } from "app/infra/sponsorships";

import { Modal, Form, notification } from "antd";
import * as Replay from "components/@admin/Replay";

interface CreateModalProps {
  isVisible: boolean;
  onShut: () => void;
  sponsorships: SponsorshipEntity[];
}

export const Create = (props: CreateModalProps) => {
  const [form] = Form.useForm();

  const { apiService, dispatch } = useContext(DiContext);
  const trackSrv = replayTrackService({ apiService, dispatch });

  const shut = () => {
    form.resetFields();
    props.onShut();
  };

  const { execute, isPending, error } = useAsync((data: ReplayTrack) => {
    return trackSrv.create(data).then((response) => {
      shut();
      notification.success({ message: "Replay Track was successfully created" });

      return response;
    });
  });

  useOnError(error);

  return (
    <Modal
      title="Create Replay Track"
      visible={props.isVisible}
      onOk={form.submit}
      onCancel={shut}
    >
      <Replay.TrackForm form={form} isPending={isPending} onFinish={execute} sponsorships={props.sponsorships} />
    </Modal>
  );
};
