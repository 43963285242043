import { CommonServiceDeps } from "app/common";
import { UserEntity, userStore } from "../user";

export interface IChatService {
  updateChatCredentials: () => Promise<UserEntity>;
}

export const ChatService = ({ apiService, dispatch }: CommonServiceDeps) => ({
  updateChatCredentials: () =>
    apiService
      .post<UserEntity>("/users/chat/credentials")
      .then((response: UserEntity) => {
        dispatch(userStore.actions.updateMe({ user: response }));
        return response;
      }),
});
