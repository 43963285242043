import { useEffect } from "react";
import { useHistory } from "react-router-dom";

export const useIntercomLauncher = () => {
  const history = useHistory();

  useEffect(() => {
    if (history) {
      const intercomToggle = (pathname: string) => {
        if (pathname.search("/app/chats") !== -1) {
          window.Intercom("update", { hide_default_launcher: true });
        } else {
          window.Intercom("update", { hide_default_launcher: false });
        }
      };

      intercomToggle(history.location.pathname);

      history.listen((location) => {
        intercomToggle(location.pathname);
      });
    }
  }, [history]);
};
