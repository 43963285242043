import React, { useContext, useMemo, useState } from "react";
import { useHistory, Link } from "react-router-dom";

import { useAsync } from "hooks/useAsync";
import { printError, useOnError } from "hooks/useOnError";
import { useModal } from "hooks/useModal";

import { characterCountFormatter } from "helpers/characterCountFormatter";

import { DiContext } from "app/common";
import { isDarkColor } from "app/common/utils/isDarkColor";

import { CompanyEntity, companyService } from "app/infra/company";
import { UserEntity } from "app/infra/user";

import { country_list } from "app/common/data";
import { attendeeCompanyType, companyExpertises, companyType } from "app/core/user";
import { TicketTypes } from "app/infra/ticketType";

import {
  Avatar,
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Input,
  Layout,
  List,
  Modal,
  notification,
  PageHeader,
  Row,
  Select,
  Tabs,
  Tooltip,
  Upload,
} from "antd";
import { RcFile } from "antd/lib/upload";
import { Check, Delete, Droplet, Eye, Paperclip, RefreshCw, XCircle } from "react-feather";
import ImgCrop from "antd-img-crop";

import { ColorResult, SketchPicker } from "react-color";

import { Loading } from "components/Loading/Loading";

import companyHeaderBackground from "assets/images/booths/marketplace-company-header-icon.svg";

import { CompanyCard } from "./CompanyCard";

const { TabPane } = Tabs;

interface CompanyFormProps {
  company: CompanyEntity;
  user: UserEntity;
}

export const CompanyUpdateForm = (props: CompanyFormProps) => {
  const BULLET_POINT_PLACEHOLDERS = [
    "Reason 1 - How do you help your customers?",
    "Reason 2 - Advantage over you competitors?",
    "Reason 3 - Benefits in numbers?",
  ];

  const [form] = Form.useForm();
  const [isColorPickerShown, setIsColorPickerShown] = useState(false);
  const [background, setBackground] = useState(props.company.main_color || "#fff");

  const [preview, setPreview] = useState(props.company);
  const previewModal = useModal();

  const handleChange = (color: ColorResult) => {
    setBackground(color.hex);
    setPreview((obj) => ({ ...obj, main_color: color.hex }));
  };

  const handleChangeComplete = (color: ColorResult) => {
    setBackground(color.hex);
    setPreview((obj) => ({ ...obj, main_color: color.hex }));
  };

  const { apiService, dispatch } = useContext(DiContext);
  const companySrv = companyService({ apiService, dispatch });

  const {
    execute: upload,
    isPending: isUploadPending,
    error: uploadError,
  } = useAsync(({ file }: { file: string | Blob }) => {
    return companySrv.updateCompanyPhoto({ photo: file }).then((response) => {
      return response;
    });
  });

  useOnError(uploadError);

  const beforeUpload = (file: RcFile) => {
    if (file.size > 5000000) {
      notification.error({ message: "Photo must be less than 5mb" });
      return false;
    }

    return true;
  };

  const { execute, isPending, error } = useAsync((data: any) => {
    return companySrv.updateCompany({ ...data, main_color: background }, props.company.id).then((response) => {
      notification.success({ message: "Company was successfully updated" });
      return response;
    });
  });

  useOnError(error);

  const handleError = () => {
    notification.error({
      message: "Invalid Form",
      description: "Please fix all invalid fields to be able to save your changes",
    });
  };

  const history = useHistory();

  const handleTabClick = (key: string) => {
    if (key === "1") {
      history.push(`/app/user-profile/${props.user.old_id || props.user.id}/edit`);
    }
  };

  const accent = useMemo(() => {
    return isDarkColor(background) ? "white" : "black";
  }, [background]);

  const hasPromo = useMemo(() => {
    return props.company.ticket_type === TicketTypes.CompanySuperBooth;
  }, [props.company.ticket_type]);

  const hasTagging = useMemo(() => {
    return props.company.ticket_type !== TicketTypes.CompanyBasicBooth;
  }, [props.company.ticket_type]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={execute}
      onFinishFailed={handleError}
    >
      <PageHeader
        ghost={false}
        extra={[
          <Button
            key="update-company-form-reset-info"
            type="link"
            icon={(
              <RefreshCw size={18} style={{ verticalAlign: "middle", marginRight: 10 }} />
            )}
            onClick={() => { form.resetFields(); }}
          >
            Reset Info
          </Button>,
          <Button key="update-company-form-save-changes" type="primary" htmlType="submit">Save Changes</Button>,
        ]}
        style={{ position: "sticky", top: "57px", width: "100%", zIndex: 999 }}
      />

      <Layout className="wrapper" style={{ marginTop: "40px" }}>
        <Tabs
          defaultActiveKey="2"
          tabBarGutter={40}
          tabBarStyle={{ borderBottom: 0 }}
          onTabClick={handleTabClick}
        >
          <TabPane
            key="1"
            tab="My Profile"
          />

          <TabPane
            key="2"
            tab="Company Profile"
          />
        </Tabs>

        <Row gutter={{ md: 20, lg: 40, xxl: 60 }}>
          <Col xs={24} lg={14} xl={12}>
            <Card style={{ marginBottom: "20px" }}>
              <h2>Company Info</h2>

              <Form.Item
                name="name"
                label="Company Name"
                rules={[
                  {
                    required: true,
                    message: "Company name is required",
                  },
                ]}
                initialValue={props.company.name}
              >
                <Input placeholder="Company Name" disabled={isPending} />
              </Form.Item>

              <Form.Item
                name="email"
                label="Company Public Email"
                rules={[
                  {
                    type: "email",
                    message: "Not a valid email address",
                  },
                ]}
                initialValue={props.company.email}
              >
                <Input type="email" placeholder="Company Public Email" disabled={isPending} />
              </Form.Item>

              <Form.Item
                name="country"
                label="Company Headquarters Location"
                rules={[
                  {
                    required: true,
                    message: "Company Headquarters Location is required",
                  },
                ]}
                initialValue={props.company.country}
              >
                <Select placeholder="Country" showSearch={true} disabled={isPending}>
                  {country_list.map((country) => (
                    <Select.Option key={`country-${country}`} value={country}>
                      {country}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="url"
                label="Company URL"
                rules={[
                  {
                    type: "url",
                    message: "Not a valid URL",
                  },
                ]}
                initialValue={props.company.url}
              >
                <Input placeholder="Company Website URL" disabled={isPending} />
              </Form.Item>

              <Form.Item
                name="jobHiringURL"
                label="Company Careers Page"
                rules={[
                  {
                    type: "url",
                    message: "Not a valid URL",
                  },
                ]}
                initialValue={props.company.jobHiringURL}
              >
                <Input placeholder="https://example.com/careers" disabled={isPending} />
              </Form.Item>

              <Form.Item
                name="description"
                label="Company Bio"
                rules={[
                  {
                    max: 1000,
                    message: "Bio cannot be longer than 1000 characters",
                  },
                  {
                    required: true,
                    message: "Bio is required",
                  },
                ]}
                initialValue={props.company.description}
              >
                <Input.TextArea
                  rows={4}
                  maxLength={1000}
                  showCount={{ formatter: characterCountFormatter }}
                  placeholder="Write a short bio (max 1000 characters)"
                  disabled={isPending}
                />
              </Form.Item>

              {props.company.is_booth_cached && (
                <Form.Item
                  name="interested"
                  label={(
                    <span>
                      Looking for: <small>(Select up to 3)</small>
                    </span>
                  )}
                  rules={[
                    {
                      type: "array",
                      max: 3,
                      message: "You cannot select more than 3 interests",
                    },
                    {
                      required: true,
                      message: "Please select at least 1 field of interest",
                    },
                  ]}
                  initialValue={props.company.interested}
                >
                  <Select
                    mode="multiple"
                    options={attendeeCompanyType.map((type) => ({ value: type }))}
                    showArrow={true}
                    showSearch={true}
                    disabled={isPending}
                    placeholder="Company Interests"
                  />
                </Form.Item>
              )}

              <Form.Item
                name="company_type"
                label="Company Type"
                rules={[
                  {
                    required: true,
                    message: "Company Type is required",
                  },
                ]}
                initialValue={props.company.company_type}
              >
                <Select
                  placeholder="Company Type"
                  disabled={isPending}
                >
                  {companyType.map((type) => (
                    <Select.Option key={`company-type-${type}`} value={type}>
                      {type}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="expertise"
                label={(
                  <span>
                    Company Expertises <small>(Select up to 3)</small>
                  </span>
                )}
                rules={[
                  {
                    type: "array",
                    max: 3,
                    message: "You cannot select more than 3 expertises",
                  },
                  {
                    required: true,
                    message: "Please select at least 1 field of expertise",
                  },
                ]}
                initialValue={props.company.expertise}
              >
                <Select
                  mode="multiple"
                  options={companyExpertises.map((expertise) => ({ value: expertise }))}
                  showArrow={true}
                  showSearch={true}
                  disabled={isPending}
                  placeholder="Company Expertises"
                />
              </Form.Item>
            </Card>

            <Card style={{ marginBottom: "20px" }}>
              <h2>Company Representatives</h2>

              <List>
                {props.company.users.map((user) => (
                  <List.Item key={`company-representatives-${user.id}`}>
                    <List.Item.Meta
                      title={(
                        <Link to={`/app/user-profile/${user.old_id || user.id}`} target="_blank">
                          {user.first_name} {user.last_name}
                        </Link>
                      )}
                    />

                    <Button
                      type="link"
                      onClick={() => {
                        const modal = Modal.confirm({});
                        modal.update({
                          content: "Are you sure you want to remove the representative?",
                          okText: "Remove",
                          icon: "",
                          okButtonProps: { style: { background: "#F35F5C" } },
                          onOk: () => {
                            companySrv.users.delete(props.company.id, user.id)
                              .then(() => modal.destroy())
                              .catch((err) => printError(err));
                          },
                        });
                      }}
                    >
                      remove
                    </Button>
                  </List.Item>
                ))}
              </List>
            </Card>
          </Col>

          <Col xs={24} lg={10}>
            <Card style={{ marginBottom: "20px" }}>
              {props.company.is_booth_cached && (
                <>
                  <PageHeader
                    title={<h2>Create Booth</h2>}
                    extra={[
                      <Button
                        key="update-company-form-preview-booth"
                        icon={<Eye size={16} style={{ verticalAlign: "middle", marginRight: 10 }} />}
                        shape="round"
                        onClick={() => {
                          setPreview({ ...preview, ...form.getFieldsValue(), main_color: background });
                          previewModal.open();
                        }}
                      >
                        Preview Booth
                      </Button>,
                    ]}
                    style={{ padding: "0px", marginBottom: "20px" }}
                  />

                  <Modal
                    visible={previewModal.isVisible}
                    modalRender={() => (
                      <div>
                        <XCircle
                          size={26}
                          fill={accent === "white" ? "black" : "white"}
                          color={accent}
                          className="ant-modal-cancel-icon"
                          style={{
                            zIndex: 9999,
                            cursor: "pointer",
                            pointerEvents: "all",
                            position: "absolute",
                            top: "-13px",
                            right: "-13px",
                          }}
                          onClick={previewModal.shut}
                        />

                        <CompanyCard company={preview} />
                      </div>
                    )}
                    style={{ maxWidth: "420px" }}
                    onCancel={previewModal.shut}
                  />
                </>
              )}

              <Card style={{ backgroundImage: `linear-gradient(#FFFFFF -450%, ${background})`, backgroundColor: background, borderRadius: "10px" }}>
                {props.company.is_booth_cached && (
                  <div>
                    {props.company.ticket_type === TicketTypes.CompanySuperBooth && (
                      <img
                        src={companyHeaderBackground}
                        alt=""
                        style={{ position: "absolute", top: "0px", left: "0px" }}
                      />
                    )}

                    <Dropdown
                      arrow={true}
                      visible={isColorPickerShown}
                      trigger={[]}
                      placement="bottomCenter"
                      overlay={(
                        <Card style={{ padding: "0px" }}>
                          <Form.Item name="main_color" style={{ margin: "0px" }}>
                            <SketchPicker
                              color={background}
                              onChange={handleChange}
                              onChangeComplete={handleChangeComplete}
                              disableAlpha={true}
                              presetColors={[]}
                            />
                          </Form.Item>

                          <Button
                            type="primary"
                            block={true}
                            style={{ borderRadius: "0", marginTop: "-1px" }}
                            onClick={() => setIsColorPickerShown(false)}
                          >
                            Save
                          </Button>
                        </Card>
                      )}
                    >
                      <Button
                        icon={(
                          <Droplet
                            size={16}
                            color={accent}
                            style={{ verticalAlign: "middle", marginRight: 5 }}
                          />
                        )}
                        type="link"
                        shape="round"
                        onClick={() => { setIsColorPickerShown((prev) => !prev); }}
                        style={{ float: "right", marginBottom: "20px", background, color: accent, border: `1px solid ${accent}` }}
                        disabled={isPending}
                      >
                        Change Color
                      </Button>
                    </Dropdown>

                    <div style={{ clear: "both" }} />
                  </div>
                )}

                <List bordered={true} className="form-list" style={{ borderRadius: "10px", borderColor: accent }}>
                  <List.Item>
                    <ImgCrop>
                      <Upload
                        name="avatar"
                        accept=".png, .jpg, .jpeg"
                        listType="picture-card"
                        showUploadList={false}
                        beforeUpload={beforeUpload}
                        customRequest={upload}
                        className="ant-upload-picture-card-wrapper-circle"
                        disabled={isPending || isUploadPending}
                      >
                        <span>
                          <Avatar src={props.company.logo_url} style={{ zIndex: 0 }} />

                          {isUploadPending && (
                            <div
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                background: "#FFFFFF",
                                width: "40px",
                                height: "40px",
                                borderRadius: "50%",
                                zIndex: 4,
                              }}
                            >
                              <Loading
                                style={{ marginTop: "5px" }}
                                size={30}
                              />
                            </div>
                          )}

                          <Tooltip
                            title={(
                              <b>
                                Upload Image (.png/.jpg/.jpeg) <br /> recommended size is 150x150
                              </b>
                            )}
                            color={accent}
                            placement="top"
                            overlayInnerStyle={{ color: accent === "white" ? "black" : "white", textAlign: "center" }}
                          >
                            <Button
                              size="small"
                              shape="circle"
                              style={{
                                background: accent,
                                width: "35px",
                                height: "35px",
                                zIndex: 5,
                              }}
                              disabled={isPending || isUploadPending}
                            >
                              <Paperclip size={14} color={accent === "white" ? "black" : "white"} />
                            </Button>
                          </Tooltip>
                        </span>
                      </Upload>
                    </ImgCrop>
                  </List.Item>

                  {props.company.is_booth_cached && (
                    <>
                      {hasPromo && (
                        <List.Item>
                          <Form.Item
                            name="promo_video"
                            rules={[
                              {
                                type: "url",
                                message: "Not a valid URL",
                              },
                              {
                                validator: (_, value) => {
                                  if (!value || /https?:\/\/(www\.)?vimeo\.com\/\d+/.test(value)) {
                                    return Promise.resolve();
                                  }

                                  return Promise.reject();
                                },
                                message: "Not a valid Vimeo URL",
                              },
                            ]}
                            validateFirst={true}
                            initialValue={props.company.promo_video}
                            style={{ color: accent }}
                          >
                            <Input placeholder="Promo Video e.g: https://vimeo.com/xxxxxxx" disabled={isPending} />
                          </Form.Item>
                        </List.Item>
                      )}

                      {hasTagging && (
                        <Form.List
                          name="tags"
                          rules={[
                            {
                              validator: (_, fields) => {
                                if (fields && fields.length > 3) {
                                  return Promise.reject(new Error("Cannot add more than 3 Booth Bullet Points"));
                                }

                                if (!fields || fields.length === 0) {
                                  return Promise.reject(new Error("Must add at least 1 Booth Bullet Point"));
                                }

                                return Promise.resolve();
                              },
                            },
                          ]}
                          initialValue={props.company.tags}
                        >
                          {(fields, { add, remove }, { errors }) => (
                            <>
                              {fields.map((field, index) => (
                                <List.Item key={`${field.key}-${field.name}`}>
                                  <Form.Item
                                    key={field.key}
                                    name={field.name}
                                    rules={[
                                      {
                                        min: 3,
                                        max: 100,
                                        message: "Must be between 3 and 100 characters",
                                      },
                                      {
                                        required: true,
                                        message: "Bullet Point is required",
                                      },
                                    ]}
                                  >
                                    <Input
                                      placeholder={BULLET_POINT_PLACEHOLDERS[index]}
                                      prefix={(
                                        <Check
                                          size={34}
                                          color="white"
                                          strokeWidth={4}
                                          style={{
                                            background: "black",
                                            padding: 8,
                                            borderRadius: "50%",
                                            marginRight: 10,
                                            verticalAlign: "middle",
                                          }}
                                        />
                                      )}
                                      suffix={(
                                        <Button
                                          type="link"
                                          size="small"
                                          style={{ marginLeft: "10px" }}
                                          onClick={() => { remove(index); }}
                                        >
                                          <Delete style={{ verticalAlign: "middle", color: accent }} />
                                        </Button>
                                      )}
                                      disabled={isPending}
                                      style={{ color: accent }}
                                    />
                                  </Form.Item>
                                </List.Item>
                              ))}

                              <Form.ErrorList errors={errors} />

                              <List.Item>
                                <Form.Item>
                                  <Button
                                    onClick={() => { add(); }}
                                    disabled={fields.length >= 3 || isPending}
                                    style={{ color: accent }}
                                  >
                                    + Add Booth Bullet Point (max 3)
                                  </Button>
                                </Form.Item>
                              </List.Item>
                            </>
                          )}
                        </Form.List>
                      )}
                    </>
                  )}
                </List>
              </Card>
            </Card>
          </Col>
        </Row>
      </Layout>
    </Form>
  );
};
