import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";

import { DiContext, usePaginationAsync } from "app/common";
import { useAsync, useOnError, useOnMount } from "hooks";

import { RaffleEntity, raffleService } from "app/infra/raffle";

import { ColumnsType } from "antd/lib/table";
import { Button, Card, Col, Input, notification, Popconfirm, Row, Table } from "antd";
import { Edit, Search, Trash2 } from "react-feather";

import { format } from "date-fns";
import qs from "qs";
import { parseQueryPath } from "app/common/utils/Path";

interface RaffleListParams {
  page?: number;
  search?: string;
}

export const RaffleList = () => {
  const { apiService, dispatch } = useContext(DiContext);
  const raffleSrv = raffleService({ apiService, dispatch });

  const history = useHistory();
  const queryParams = qs.parse(history.location.search, { ignoreQueryPrefix: true }) as RaffleListParams;

  const pagination = usePaginationAsync<RaffleEntity>((page, pageSize, search, sortBy, sortDirection) => {
    return raffleSrv.getAllPaginated({ params: {
      count: pageSize,
      page,
      search: search || undefined,
      sortBy: sortBy || undefined,
      sortDirection: sortDirection || undefined,
    } });
  }, {
    initialFilter: { search: queryParams.search || "" },
    initialPage: queryParams.page,
  });

  useOnMount(pagination.async.execute);

  const { execute: onDelete, error: onDeleteError } = useAsync((id: string) => {
    return raffleSrv.delete(id).then((response) => {
      notification.success({ message: "Raffle was successfully deleted" });
      pagination.async.execute();

      return response;
    });
  });
  useOnError(onDeleteError);

  const updateSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    history.push(parseQueryPath("/admin/raffles", "page", "1", "search", event.currentTarget.value));
    pagination.onSetFilter({ search: event.currentTarget.value });
  };

  const columns: ColumnsType<RaffleEntity> = [
    {
      key: "name",
      dataIndex: "name",
      title: "Name",
      sorter: true,
      onHeaderCell: () => ({
        onClick: () => pagination.onToggleOrderBy("name"),
      }),
    },
    {
      key: "price",
      dataIndex: "price",
      title: "Price",
      sorter: true,
      onHeaderCell: () => ({
        onClick: () => pagination.onToggleOrderBy("price"),
      }),
    },
    {
      key: "date",
      dataIndex: "date",
      title: "Date",
      sorter: true,
      onHeaderCell: () => ({
        onClick: () => pagination.onToggleOrderBy("date"),
      }),
      render: (value: string) => {
        return format(new Date(value), "do LLL hh:mm");
      },
    },
    {
      key: "actions",
      width: 84,
      render: (_value, record) => (
        <Button.Group>
          <Link
            to={`/admin/raffles/${record.id}/edit`}
            title="Edit"
            className="ant-btn ant-btn-link ant-btn-sm"
          >
            <Edit size={20} />
          </Link>

          <Popconfirm
            title="Are you certain that you want to delete this Raffle?"
            onConfirm={() => onDelete(record.id)}
            okText="Confirm"
            cancelText="Cancel"
            placement="topRight"
          >
            <Button type="link" size="small" title="Delete" danger={true}>
              <Trash2 size={20} />
            </Button>
          </Popconfirm>
        </Button.Group>
      ),
    },
  ];

  return (
    <Card>
      <Row className="admin-page-header">
        <Col className="left-column">
          <h1 className="title">Raffles</h1>
          <div className="elements">
            <Input
              placeholder="search"
              prefix={<Search size={20} color="#A3A7B2" />}
              value={pagination.filter.search}
              onChange={updateSearch}
            />
          </div>
        </Col>

        <Col>
          <Button type="primary" onClick={() => history.push("/admin/raffles/new")}>
            Create Raffle
          </Button>
        </Col>
      </Row>

      <Table
        size="middle"
        loading={pagination.async.isPending}
        bordered={true}
        rowKey="id"
        columns={columns}
        dataSource={pagination.async.value?.data}
        pagination={{
          size: "default",
          current: pagination.page,
          total: pagination.totalItems,
          pageSize: pagination.pageSize,
          onChange: (page: number, pageSize?: number) => {
            pagination.onSetPage(page);
            if (pageSize) {
              pagination.onSetPageSize(pageSize);
            }

            history.push(parseQueryPath(
              "/admin/raffles",
              "page",
              page.toString(),
              "search",
              pagination.filter.search,
            ));
            window.scrollTo({ top: 0 });
          },
        }}
      />
    </Card>
  );
};
