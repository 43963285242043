import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";

import { DiContext, usePaginationAsync } from "app/common";
import { StageEntity, stageService } from "app/infra/stage";

import { move } from "helpers";
import { useAsync, useOnError, useOnMount } from "hooks";

import { Button, Card, Col, Input, notification, Popconfirm, Row } from "antd";
import { ColumnsType } from "antd/lib/table";
import { Edit, Search, Trash2 } from "react-feather";

import { DndProvider } from "components/DndProvider/DndProvider";
import { Table } from "components/Table";
import { parse } from "qs";
import { parseQueryPath } from "app/common/utils/Path";

interface StageListParameters {
  page?: number,
  search?: string,
}

export const StageList = () => {
  const { apiService, dispatch } = useContext(DiContext);
  const stageServ = stageService({ apiService, dispatch });

  const history = useHistory();
  const queryParams = parse(history.location.search, { ignoreQueryPrefix: true }) as StageListParameters;

  const pagination = usePaginationAsync<StageEntity<string>>((page, pageSize, search, sortBy, sortDirection) => {
    return stageServ.getAll({
      params: {
        count: pageSize,
        page,
        search: search || undefined,
        sortBy: sortBy || undefined,
        sortDirection: sortDirection || undefined,
      },
    });
  }, {
    initialFilter: { search: queryParams.search || "" },
    initialPage: queryParams.page,
  });

  const { execute: onDelete, error: onDeleteError } = useAsync((id: string) => {
    return stageServ.delete(id).then((response) => {
      notification.success({ message: "Stage was successfully deleted" });
      pagination.async.execute();

      return response;
    });
  });
  useOnError(onDeleteError);

  const { execute: onMoveRow, error: onMoveRowError } = useAsync((dragIndex: number, hoverIndex: number) => {
    const stages = move(pagination.async.value?.data || [], dragIndex, hoverIndex);
    const stage = stages[hoverIndex];
    return stageServ.update({ order: hoverIndex }, stage.id).then((response) => {
      return pagination.async.execute();
    });
  });
  useOnError(onMoveRowError);

  useOnMount(pagination.async.execute);

  const updateSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    history.push(parseQueryPath("/admin/schedule/stages", "page", "1", "search", event.currentTarget.value));
    pagination.onSetFilter({ search: event.currentTarget.value });
  };

  const columns: ColumnsType<StageEntity<string>> = [
    {
      title: "#",
      dataIndex: "order",
      sorter: true,
      onHeaderCell: () => ({
        onClick: () => pagination.onToggleOrderBy("order"),
      }),
    },
    {
      title: "Name",
      dataIndex: "name",
      sorter: true,
      onHeaderCell: () => ({
        onClick: () => pagination.onToggleOrderBy("name"),
      }),
    },
    {
      title: "Description",
      dataIndex: "description",
      sorter: true,
      onHeaderCell: () => ({
        onClick: () => pagination.onToggleOrderBy("description"),
      }),
    },
    {
      key: "actions",
      width: 84,
      render: (_value, record) => (
        <Button.Group>
          <Link
            to={`/admin/schedule/stages/${record.id}/edit`}
            title="Edit"
            className="ant-btn ant-btn-link ant-btn-sm"
          >
            <Edit size={20} />
          </Link>

          <Popconfirm
            title={record.talks && record.talks.length === 0
              ? "Are you certain that you want to delete this Stage?"
              : "There are talks present for this Stage, therefore it can not be deleted!"}
            onConfirm={() => onDelete(record.id)}
            okText="Confirm"
            okButtonProps={{
              disabled: record.talks && record.talks.length !== 0,
              title: "There are talks present for this Stage, therefore it can not be deleted!",
            }}
            cancelText="Cancel"
            placement="topRight"
          >
            <Button type="link" size="small" title="Delete" danger={true}>
              <Trash2 size={20} />
            </Button>
          </Popconfirm>
        </Button.Group>
      ),
    },
  ];

  return (
    <Card>
      <Row className="admin-page-header">
        <Col className="left-column">
          <h1 className="title">Stages</h1>
          <div className="elements">
            <Input
              placeholder="search"
              prefix={<Search size={20} color="#A3A7B2" />}
              value={pagination.filter.search}
              onChange={updateSearch}
            />
          </div>
        </Col>

        <Col>
          <Button type="primary" onClick={() => history.push("/admin/schedule/stages/new")}>Create Stage</Button>
        </Col>
      </Row>

      <DndProvider>
        <Table
          size="middle"
          loading={pagination.async.isPending}
          bordered={true}
          onMoveRow={onMoveRow}
          isRowDraggable={true}
          rowKey="id"
          columns={columns}
          dataSource={pagination.async.value?.data}
          pagination={{
            size: "default",
            total: pagination.totalItems,
            current: pagination.page,
            pageSize: pagination.pageSize,
            onChange: (page: number, pageSize?: number) => {
              pagination.onSetPage(page);
              if (pageSize) {
                pagination.onSetPageSize(pageSize);
              }
              history.push(parseQueryPath(
                "/admin/schedule/stages",
                "page",
                page.toString(),
                "search",
                pagination.filter.search,
              ));
              window.scrollTo({ top: 0 });
            },
          }}
        />
      </DndProvider>
    </Card>
  );
};
