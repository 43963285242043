/* eslint-disable @typescript-eslint/naming-convention */
import { createSlice } from "@reduxjs/toolkit";
import { EPubSubTable, Tracking } from "app/common/utils";
import jsonwebtoken from "jsonwebtoken";

interface Decoded {
  id: number;
  roles: string[];
}

interface AuthStoreState {
  token: string | null;
  isLoggedIn: boolean;
  isAdmin: boolean;
}

const initialState = {
  token: null,
  isLoggedIn: false,
  isAdmin: false,
} as AuthStoreState;

export const AuthStore = createSlice({
  name: "authStore",
  initialState,
  reducers: {
    reset: () => initialState,
    create: (_, action: { payload: string }) => {
      const token = action.payload;
      const jwt = jsonwebtoken.decode(token) as Decoded;

      const isAdmin = jwt && jwt?.roles.includes("ROLE_ADMIN");
      const isLoggedIn = true;

      Tracking.SetUser(jwt, true);
      Tracking.PubSub([{
        table: EPubSubTable.LogIn,
        data: {
          user_id: jwt.id.toString(),
          datetime: Tracking.Date,
          ip: "",
          user_agent: "",
        },
      }]);

      return { token, isLoggedIn, isAdmin };
    },
  },
});
