import React, { useContext, useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { connect, ConnectedProps } from "react-redux";

import { RootState } from "services";
import { DiContext } from "app/common";
import { useAsync, useOnError, useOnMount } from "hooks";
import { Talk, talkService } from "app/infra/talk";
import { scheduleService } from "app/infra/schedule";

import { useForm } from "antd/lib/form/Form";
import { Button, Card, Col, notification, Row } from "antd";

import { TalkForm } from "./TalkForm";
import { ResourceList } from "./Resource/ResourceList";

const mapStateToProps = (state: RootState) => ({
  talks: state.talkStore.byId,
});

const connector = connect(mapStateToProps);
type PropsRedux = ConnectedProps<typeof connector>;

export const TalkUpdate = connector((props: PropsRedux) => {
  const { dispatch, apiService } = useContext(DiContext);
  const talkSrv = talkService({ apiService, dispatch });
  const scheduleSrv = scheduleService({ apiService, dispatch });

  const [form] = useForm();

  const history = useHistory();
  const routerParams = useRouteMatch().params as { id: string };
  const talkSelected = props.talks[routerParams.id];

  const { execute: onMount, error: onMountError } = useAsync(() => {
    return scheduleSrv.getAgenda();
  });
  useOnError(onMountError);

  useOnMount(onMount);

  const { execute, isPending, error } = useAsync((data: Talk) => {
    return talkSrv.update(data, talkSelected.id).then((response) => {
      notification.success({ message: "Talk was successfully updated" });

      history.push("/admin/schedule/talks");

      return response;
    });
  });
  useOnError(error);

  return (
    <Row>
      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
        <Card>

          <Row>
            <Col>
              <h1>
                Update Talk
              </h1>
            </Col>
          </Row>

          <TalkForm form={form} loading={isPending} onFinish={execute} talk={talkSelected} />

          <ResourceList talk={talkSelected} />

          <Row>
            <Col style={{ marginLeft: "auto", marginTop: "20px" }}>
              <Button type="primary" onClick={form.submit}>
                Update
              </Button>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
});
