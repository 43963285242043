import React from "react";
import { useIubenda } from "hooks";

export const TermsPage = () => {
  useIubenda();

  return (
    <div style={{ background: "#fff", padding: "0 25px" }}>
      <a href="https://www.iubenda.com/terms-and-conditions/74865768" className="iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe iub-body-embed" title="Terms and Conditions">Terms and Conditions</a>;
    </div>
  );
};
