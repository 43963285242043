import { useContext } from "react";
import { DiContext } from "app/common";
import { AppDispatch } from "services";

import { scheduleStore } from "app/infra/schedule";
import { companyStore } from "app/infra/company";
import { highlightStore } from "app/infra/highlights";
import { perkStore } from "app/infra/perk";
import { postStore } from "app/infra/posts";
import { raffleStore } from "app/infra/raffle";
import { userStore } from "app/infra/user";
import { sponsorshipStore } from "app/infra/sponsorships";

import { AuthStore } from "../stores/AuthStore";

export const logout = ({ dispatch }: { dispatch: AppDispatch }) => {
  dispatch(scheduleStore.actions.reset());
  dispatch(AuthStore.actions.reset());
  dispatch(companyStore.actions.reset());
  dispatch(highlightStore.actions.reset());
  dispatch(perkStore.actions.reset());
  dispatch(postStore.actions.reset());
  dispatch(raffleStore.actions.reset());
  dispatch(sponsorshipStore.actions.reset());
  dispatch(userStore.actions.reset());
};

export const AuthService = () => {
  const { apiService, dispatch } = useContext(DiContext);

  return {
    passwordless: {
      requests: {
        create: (data: { email: string }) => {
          return apiService.post<{ ok: true }>("auth/passwordless/requests", data);
        },
      },

      sessions: {
        create: (token: string) => {
          return apiService
            .post<{ token: string }>("auth/passwordless/sessions", { token })
            .then((response) => {
              dispatch(AuthStore.actions.create(response.token));
            });
        },
      },
    },

    sessions: {
      create: (data: { email: string; password: string }) => {
        return apiService
          .post<{ token: string }>("auth/sessions", data)
          .then((response) => {
            dispatch(AuthStore.actions.create(response.token));
          });
      },
    },
  };
};
