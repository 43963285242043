import React from "react";

import { CreateStageDTO, StageEntity } from "app/infra/stage";

import { Form, Input } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import TextArea from "antd/lib/input/TextArea";

interface Props {
  form: FormInstance,
  stage?: StageEntity<string>,
  onFinish?: (data: CreateStageDTO) => void,
}

export const StageForm = (props: Props) => {
  return (
    <Form
      form={props.form}
      initialValues={props.stage}
      onFinish={props.onFinish}
      layout="vertical"
    >
      <Form.Item
        label="Name"
        name="name"
      >
        <Input type="text" />
      </Form.Item>

      <Form.Item
        label="Description"
        name="description"
      >
        <TextArea />
      </Form.Item>
    </Form>
  );
};

StageForm.defaultProps = {
  stage: undefined,
  onFinish: () => {},
};
