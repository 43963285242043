import React, { useContext } from "react";

import { DiContext } from "app/common";

import { CompanyEntity, companyService } from "app/infra/company";
import { attendeeCompanyType, companyExpertises } from "app/core/user";

import { country_list } from "app/common/data";
import { TicketTypes } from "app/infra/ticketType";

import Form, { FormInstance } from "antd/lib/form";
import { Button, Checkbox, Input, Select, Space, Switch } from "antd";
import { Delete } from "react-feather";

import { SketchPicker } from "react-color";

import { UploadPhoto } from "app/presentation/common";

export interface CompanyFormFields {
  name: string;
  ticket_type: string;
  description: string;
  country: string;
  url: string;
  expertise: string[];
  company_type: string[];
}

interface Props {
  form: FormInstance;
  onFinish: (data: CompanyFormFields) => void;
  company?: CompanyEntity;
}

export const CompanyForm = (props: Props) => {
  const { apiService, dispatch } = useContext(DiContext);
  const companySrv = companyService({ apiService, dispatch });

  const upload = ({ file }: { file: string | Blob }) => {
    return companySrv.adminUpdateCompanyPhoto({ id: props.company!.id, photo: file });
  };

  return (
    <Form
      form={props.form}
      onFinish={props.onFinish}
      layout="vertical"
      scrollToFirstError={true}
      initialValues={props.company}
    >
      {props.company && (
        <Form.Item
          name="deactivate_sync"
          label="Disable TITO synchronization"
        >
          <Switch />
        </Form.Item>
      )}

      <Form.Item
        name="rank"
        label="Rank"
      >
        <Input placeholder="e.g: 10" type="number" />
      </Form.Item>

      <Form.Item
        name="priority"
        label="Sponsor?"
        valuePropName="checked"
      >
        <Checkbox />
      </Form.Item>

      <Form.Item
        name="name"
        label="Name"
        rules={[
          {
            min: 2,
            max: 50,
            message: "Company name must be between 2 and 50 characters",
          },
          {
            required: true,
            message: "Company is required",
          },
        ]}
      >
        <Input placeholder="Name" />
      </Form.Item>

      <Form.Item
        name="description"
        label="Description"
        rules={[
          {
            required: true,
            message: "Description is required",
          },
          {
            max: 1000,
            message: "Description cannot be longer than 1000 characters",
          },
        ]}
      >
        <Input.TextArea placeholder="Description" />
      </Form.Item>

      <Form.Item
        name="ticket_type"
        label="Ticket Type"
        rules={[
          {
            required: true,
            message: "Ticket Type is required",
          },
        ]}
      >
        <Select placeholder="Ticket Type" showSearch={true}>
          {Object.values(TicketTypes).map((ticketType) => {
            return <Select.Option value={ticketType}>{ticketType}</Select.Option>;
          })}
        </Select>
      </Form.Item>

      <Form.Item
        name="url"
        label="Link"
        rules={[
          {
            type: "url",
            message: "Website link must be a valid URL and include https://",
          },
          {
            required: true,
            message: "Link is required",
          },
        ]}
      >
        <Input type="url" placeholder="Link" />
      </Form.Item>

      <Form.Item
        name="country"
        label="Country"
        rules={[
          {
            required: true,
            message: "Country is required",
          },
        ]}
      >
        <Select placeholder="Country" showSearch={true}>
          {country_list.map((country) => {
            return <Select.Option value={country}>{country}</Select.Option>;
          })}
        </Select>
      </Form.Item>

      <Form.Item
        name="expertise"
        label="Expertise"
        rules={[
          {
            required: true,
            message: "Please select at least one tag!",
          },
          {
            validator: (_rule, value, callback) => {
              if (value && value.length > 3) {
                callback("Expertise must contain less than or equal to 3 items");
              } else {
                callback();
              }
            },
          },
        ]}
      >
        <Select mode="multiple" placeholder="Expertise">
          {companyExpertises.map((expertise) => {
            return <Select.Option value={expertise}>{expertise}</Select.Option>;
          })}
        </Select>
      </Form.Item>

      {props.company?.is_booth_cached && (
        <>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                required: true,
                message: "Email is required",
              },
              {
                type: "email",
              },
            ]}
          >
            <Input type="email" placeholder="info@company.com" />
          </Form.Item>

          <Form.Item
            name="interested"
            label="Interested"
            rules={[
              {
                required: true,
                message: "Interested is required",
              },
              {
                validator: (_rule, value, callback) => {
                  if (value && value.length > 3) {
                    callback("Interested must contain less than or equal to 3 items");
                  } else {
                    callback();
                  }
                },
              },
            ]}
          >
            <Select mode="multiple" placeholder="Interests">
              {attendeeCompanyType.map((type) => {
                return <Select.Option value={type}>{type}</Select.Option>;
              })}
            </Select>
          </Form.Item>

          <Form.Item
            name="promo_video"
            label="Promo Video (Vimeo Link)"
            rules={[
              {
                type: "url",
                message: "Link must be a valid URL and include https://",
              },
              {
                validator: (_rule, value: string, callback) => {
                  if (value) {
                    if (/https?:\/\/(www\.)?vimeo\.com\/\d+/.test(value)) {
                      callback();
                    } else {
                      callback("Not a valid Vimeo link");
                    }
                  } else {
                    callback();
                  }
                },
              },
            ]}
          >
            <Input type="url" placeholder="https://vimeo.com/xxxxxxxxx" />
          </Form.Item>

          <Form.Item
            name="main_color"
            label="Booth Background Color"
            valuePropName="color"
          >
            <SketchPicker
              disableAlpha={true}
              presetColors={[]}
              onChange={(e) => props.form.setFieldsValue({ ...props.form.getFieldsValue(), main_color: e.hex })}
            />
          </Form.Item>

          <Form.Item label="Booth Bullet Points" required={true}>
            <Form.List
              name="tags"
              rules={[
                {
                  validator: (_rule, value, callback) => {
                    if (!value) {
                      callback("There should be at least one Booth Bullet point");
                    }
                    callback();
                  },
                },
              ]}
            >
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field, index) => {
                    return (
                      <Space>
                        <Form.Item
                          name={field.name}
                          fieldKey={field.fieldKey}
                          rules={[
                            {
                              min: 3,
                              message: "Text must be at least 3 characters",
                            },
                            {
                              max: 100,
                              message: "Text cannot be longer than 100 characters",
                            },
                            {
                              required: true,
                              message: "Booth bullet point text is required",
                            },
                          ]}
                        >
                          <Input placeholder="Description of your booth bullet point." />
                        </Form.Item>

                        {fields.length > 1 && (
                          <Button
                            icon={<Delete color="rgb(211, 16, 39)" />}
                            onClick={() => remove(field.name)}
                          />
                        )}
                      </Space>
                    );
                  })}

                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => { add(); }}
                    >
                      + Add Booth Bullet Point
                    </Button>

                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form.Item>
        </>
      )}

      {props.company ? (
        <UploadPhoto uploadPhoto={upload} image_url={props.company.logo_url} />
      ) : (
        "Company logo can be uploaded only on edit, not on create!"
      )}
    </Form>
  );
};

CompanyForm.defaultProps = {
  company: undefined,
};
