import { DiContext } from "app/common";
import React, { useContext } from "react";
import { raffleService } from "app/infra/raffle";
import { AxiosRequestConfig } from "axios";
import { DownloadStats, LeadStatsDTO, ByIdDTO } from "./leadComponent";

export const RaffleLeads = () => {
  const { dispatch, apiService } = useContext(DiContext);
  const raffleSrv = raffleService({ apiService, dispatch });

  return DownloadStats({
    statsCall: raffleSrv.getRaffleStats as
      (data?: LeadStatsDTO | ByIdDTO, config?: AxiosRequestConfig) =>
      Promise<string | ArrayBuffer | ArrayBufferView | Blob>,
      title: "raffle",
  });
};
