import React, { useMemo } from "react";
import { Link } from "react-router-dom";

import { useDebounce, useSessionState } from "hooks";
import CheckboxFilter, { StringBooleanMap } from "components/CheckboxFilter/CheckboxFilter";
import { Button, Input } from "antd";
import { Briefcase, Mic, Search, Users, XCircle } from "react-feather";
import { attendeeCompanyType, companyExpertises } from "app/core/user";
import { country_list } from "app/common/data";
import { CompanyCardList } from "./CompanyCardList";

export const CompanyCardListSection = () => {
  const [filter, setFilter] = useSessionState("directory:companies:search", "");
  const [selectedCountries, setSelectedCountries] = useSessionState("directory:companies:countries", {});
  const [selectedInterests, setSelectedInterests] = useSessionState("directory:companies:interest", {});
  const [selectedExpertises, setSelectedExpertises] = useSessionState("directory:companies:expertise", {});

  const debouncedFilter = useDebounce(filter, 300);

  const getSelectedOptions = (value: StringBooleanMap) => {
    return Object.entries(value).filter((element) => element[1] === true).map((element) => element[0]);
  };

  const showClearAllFilters = useMemo(() => {
    return (
      filter.length > 0
      || getSelectedOptions(selectedInterests).length > 0
      || getSelectedOptions(selectedExpertises).length > 0
      || getSelectedOptions(selectedCountries).length > 0
    );
  }, [filter, selectedInterests, selectedExpertises, selectedCountries]);

  const clearFilters = () => {
    setFilter("");
    setSelectedInterests({});
    setSelectedExpertises({});
    setSelectedCountries({});
  };

  const interests = useMemo(() => {
    return attendeeCompanyType.map((item) => ({ id: item, name: item }));
  }, [attendeeCompanyType]);

  const expertises = useMemo(() => {
    return companyExpertises.map((item) => ({ id: item, name: item }));
  }, [companyExpertises]);

  const countries = useMemo(() => {
    return country_list.map((item) => ({ id: item, name: item }));
  }, [country_list]);

  return (
    <div className="directory-page">
      <Button.Group className="networking-segmented-control">
        <Link className="selected" to="/app/directory/companies">
          <Button>
            <Briefcase size={22} style={{ verticalAlign: "middle" }} />
            <span style={{ verticalAlign: "middle" }}>MARKETPLACE</span>
          </Button>
        </Link>

        <Link to="/app/directory/attendees">
          <Button>
            <Users size={22} style={{ verticalAlign: "middle" }} />
            <span style={{ verticalAlign: "middle" }}>ATTENDEES</span>
          </Button>
        </Link>

        <Link to="/app/directory/speakers">
          <Button>
            <Mic size={22} style={{ verticalAlign: "middle" }} />
            <span style={{ verticalAlign: "middle" }}>SPEAKERS</span>
          </Button>
        </Link>
      </Button.Group>

      <div className="directory-page-main">
        <div className="checkbox-filters">
          <div className="search-input">
            <Input
              value={filter}
              prefix={<Search size={20} color="#A3A7B2" />}
              suffix={filter && <XCircle size={20} onClick={() => { setFilter(""); }} style={{ cursor: "pointer" }} />}
              placeholder="Search"
              className="adw-white-input"
              style={{ maxWidth: "100%" }}
              onChange={(e) => { setFilter(e.target.value); }}
            />
          </div>

          <div className="filter-right">
            <Button
              type="link"
              onClick={clearFilters}
              className="clear-filters-btn"
              style={!showClearAllFilters ? { visibility: "hidden" } : {}}
            >
              Clear Filters
            </Button>

            <CheckboxFilter
              placeholder="Filter by interests"
              selectedItems={selectedInterests}
              setSelectedItems={setSelectedInterests}
              items={interests}
              searchable={true}
            />

            <CheckboxFilter
              placeholder="Filter by expertises"
              selectedItems={selectedExpertises}
              setSelectedItems={setSelectedExpertises}
              items={expertises}
              searchable={true}
            />

            <CheckboxFilter
              placeholder="Filter by countries"
              selectedItems={selectedCountries}
              setSelectedItems={setSelectedCountries}
              items={countries}
              searchable={true}
            />
          </div>
        </div>

        <CompanyCardList
          filter={debouncedFilter}
          countries={getSelectedOptions(selectedCountries)}
          interests={getSelectedOptions(selectedInterests)}
          expertises={getSelectedOptions(selectedExpertises)}
        />
      </div>
    </div>
  );
};
