import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { ConnectionEntity, UserConnectionService, UserEntity } from "app/infra/user";
import { useAsync } from "app/common";
import { useOnError } from "hooks/useOnError";
import { Popover, Tag } from "antd";
import { MessageCircle } from "react-feather";
import { mode, AppMode } from "config/initializers";
import { connect } from "react-redux";
import { RootState } from "services";
import AttendeesFullName from "components/AttendeesFullName/AttendeesFullName";

import _ from "lodash";

import { Loading } from "components/Loading/Loading";
import { UserAvatar } from "components/User/UserAvatar";

interface PersonItemProps {
  user: UserEntity;
  me?: UserEntity;
  isAdmin: boolean;
}

export const PersonItem = connect((state: RootState) => ({
  isAdmin: state.authStore.isAdmin,
}))(({ me, user, isAdmin }: PersonItemProps) => {
  const history = useHistory();
  const foundMatches = _.intersection(me?.interested || [], user?.interested || []);

  const { execute, error, pending, value } = useAsync<ConnectionEntity>(
    UserConnectionService().create,
  );
  useOnError(error);

  useEffect(() => {
    if (value && value.isAccepted) {
      history.push(`/app/chats/${value.chatRoomId}`);
    }
  }, [value]);

  const { first_name, last_name } = user;

  const questions = [
    {
      question: "Currently working on:",
      answer: user.questionWorkingOn,
    },
    {
      question: "Looking to meet:",
      answer: user.questionLookingToMeet,
    },
    {
      question: "Biggest challenge:",
      answer: user.questionBiggestChallenge,
    },
  ];

  const userAnswers = questions.filter((q) => q.answer).sort(() => Math.random() - 0.5);

  const firstAnswer = userAnswers[0] || null;
  let questionsContent = null;

  if (userAnswers.length > 1) {
    questionsContent = userAnswers.slice(1).map((subject) => (
      <p key={`popover-question-${subject.question}`}>
        <span className="popover-question-text">{subject.question} </span>
        {subject.answer}
      </p>
    ));
  }

  return (
    (user && (
      <Link
        to={`/app/user-profile/${user.id}`}
        className="person-item"
        onClick={(e) => {
          if (mode === AppMode.onboarding && !isAdmin) {
            e.preventDefault();
          }
        }}
      >
        <Popover
          overlayClassName="questionPopover"
          placement="bottomRight"
          content={questionsContent}
          overlayStyle={questionsContent ? {} : { display: "none" }}
        >
          <div className="person-item-container">
            <div>
              <UserAvatar user={user} size={60} style={{ marginRight: "15px" }} />
            </div>

            <div className="person-item-info">
              <AttendeesFullName
                firstName={first_name}
                lastName={last_name}
              />
              <span className="position">
                {user.job_position} at{" "}
                {user.company?.name || user.attendee_company || ""}
              </span>
            </div>

            {(mode !== AppMode.onboarding || isAdmin) && me?.hasChatAccess && (
              <div
                role="none"
                className="person-item__actions"
                onClick={(e) => {
                  e.preventDefault();
                  execute(user.id);
                }}
                style={{
                  marginLeft: "auto",
                }}
              >
                {pending && <Loading />}
                {!pending && (
                  <div
                    style={{ width: 30, height: 30, display: "flex", justifyContent: "center", alignItems: "center" }}
                  >
                    <MessageCircle size={20} />
                  </div>
                )}
              </div>
            )}
          </div>

          <div style={{ marginTop: "10px" }}>
            {foundMatches && foundMatches.length > 0 && (
              <div className="subtitle">
                <span className="text">Common interests: </span>
                {foundMatches.map((match: string | false, index: number) => (
                  <Tag
                    key={index}
                    className="ant-tag-small"
                  >
                    {match}
                  </Tag>
                ))}
              </div>
            )}

            <div className="questionAnswer">
              {firstAnswer && (
                <>
                  <span>
                    <span className="question">{firstAnswer.question} </span>
                    {firstAnswer.answer}
                  </span>
                  {questionsContent ? <span className="answersIcon">i</span> : null}
                </>
              )}
            </div>
          </div>
        </Popover>
      </Link>
    )) || <div />
  );
});
