import React, { useEffect } from "react";
import { Modal, notification } from "antd";
import { useAsync, useOnError } from "hooks";
import { UserService } from "services/@admin/UserService";

interface InvitationProps {
  id: string;
  isVisible: boolean;
  onCancel: ()=> void;
}

export const Invitation = (props: InvitationProps) => {
  const { execute, isPending, value, error } = useAsync<{ message: string }, [string]>(
    UserService().invitations.create,
  );

  useOnError(error);

  useEffect(() => {
    if (value && value.message) {
      notification.success({ message: value.message });
      props.onCancel();
    }
  }, [value]);

  return (
    <Modal
      className="confirmation-link-modal"
      title="Resend Confirmation Link"
      okText="Send"
      cancelText="Close"
      visible={props.isVisible}
      onCancel={props.onCancel}
      confirmLoading={isPending}
      onOk={() => execute(props.id)}
    />
  );
};
