import React from "react";
import { Link } from "react-router-dom";
import { Avatar, Button, Tag } from "antd";
import { Clock, User, Video } from "react-feather";
import { TalkEntityExtended } from "app/infra/talk";
import moment from "moment-timezone";

import { UserEntity } from "app/infra/user";
import { UserAvatar } from "components/User/UserAvatar";

interface PostProps {
  text: string;
  speaker?: string;
  time?: string;
  length?: number;
  stage?: string | undefined;
  to?: string;
  trackName: string;
  user?: UserEntity;
}

const Post = ({
  text,
  speaker,
  time,
  stage,
  length,
  to,
  user,
  trackName,
}: PostProps) => {
  if (to)
    return (
      <Link to={to} className="post">
        {user && (
          <div style={{ marginRight: "15px" }}>
            <UserAvatar user={user} />
          </div>
        )}

        <div className="post-info">
          <div className="post-header">
            <h6 className="post-info-speaker">{speaker}</h6>
            <span>{stage}</span>
          </div>

          <p className="post-info-title" title={text}>
            {text}
          </p>

          {time && (
            <span className="post-info-footer">
              <span className="post-info-footer-time">
                <Clock size={18} style={{ verticalAlign: "middle", marginRight: 5 }} />
                {moment(time).tz(moment.tz.guess()).format("H:mm")} -{" "}
                {moment(time)
                  .add(length, "minutes")
                  .tz(moment.tz.guess())
                  .format("H:mm")}
              </span>
              <div>
                <Tag style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: 164,
                    display: "inline-block",
                    lineHeight: "20px",
                  }} className={`ant-tag-small ${trackName}`}>{trackName}</Tag>
              </div>
            </span>
          )}
        </div>
      </Link>
    );

  return (
    <div className="post">
      {user && (
        <div style={{ marginRight: "15px" }}>
          <UserAvatar user={user} />
        </div>
      )}

      <p>{text}</p>
    </div>
  );
};

interface ActivityProps {
  nextTalk?: TalkEntityExtended[];
  streamingNow?: TalkEntityExtended[];
  currentTalk?: TalkEntityExtended;
  contentContainerHeight?: number;
}

interface NoTalkProps {
  title: string;
}

const NoTalk = ({ title }: NoTalkProps) => (
  <div className="live-talk">
    <span
      style={{
        color: "#67696e",
        fontWeight: "bold",
        fontSize: 14,
      }}
    >
      —:—
    </span>
    <Avatar size={60} style={{ backgroundColor: "#000000", marginLeft: "10px" }}>
      <User size={40} color="#FFFFFF" style={{ verticalAlign: "middle" }} />
    </Avatar>

    <div className="content">
      <div>
        <h5>{title}</h5>
      </div>
    </div>
  </div>
);

export const Activity = ({
  nextTalk,
  streamingNow,
  currentTalk,
  contentContainerHeight,
}: ActivityProps) => {
  // console.log({ streamingNow });
  return (
    <div className="activity-container" style={{ height: contentContainerHeight || "100vh" }}>
      <div className="extra-activity-container">
        {/* <div className="now-streamings-container">
          <div className="title">
            <span>Other Stages</span>
          </div>
          {streamingNow && streamingNow.length === 0 && (
            <NoTalk title="No Current Talks" />
          )}
          {streamingNow &&
            streamingNow.length > 0 &&
            streamingNow
              .filter((talk) => talk.id !== currentTalk?.id)
              .map((now) => (
                <div className="streaming-now-post">
                  <Button
                    type="primary"
                    size="small"
                    shape="round"
                    className="streaming-now-button"
                  >
                    <Video size={18} style={{ verticalAlign: "middle" }} />
                    <span>STREAMING NOW </span>
                  </Button>
                  <Post
                    user={now.speakers![0]}
                    text={now.title}
                    speaker={`${
                      now?.speakers?.length
                        ? now.speakers[0].first_name +
                          " " +
                          now.speakers[0].last_name
                        : ""
                    }`}
                    to={`/app/talk/${now.id}`}
                    trackName={now.track.name}
                  />
                </div>
              ))}
        </div> */}

        <div className="upcoming-talks-container">
          <div className="title">
            <span>UPCOMING TALKS</span>
          </div>
          <div>
            {nextTalk &&
              nextTalk.map((nextTalk) => (
                <Post
                  user={nextTalk.speakers![0]}
                  text={nextTalk.title}
                  speaker={`${
                    nextTalk?.speakers?.length
                      ? nextTalk.speakers[0].first_name +
                        " " +
                        nextTalk.speakers[0].last_name
                      : ""
                  }`}
                  time={nextTalk.start_time}
                  length={nextTalk.length}
                  stage={nextTalk.stage?.name}
                  to={nextTalk.id.toString()}
                  trackName={nextTalk?.track?.name}
                />
              ))}
            {nextTalk && nextTalk.length === 0 && (
              <NoTalk title="No Upcoming Talks" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
