import { CommonServiceDeps } from "app/common";

import { ReplayTrackEntity } from "./replayTrack.entity";
import { replayTrackStore } from "./replayTrack.store";

export type ReplayTrack = Pick<ReplayTrackEntity, "name" | "color"> & { sponsor: string };

export const replayTrackService = (props: CommonServiceDeps) => {
  return {
    getAll: () => {
      return props.apiService
        .get<ReplayTrackEntity[]>("replay/tracks")
        .then((response: ReplayTrackEntity[]) => {
          props.dispatch(replayTrackStore.actions.addResources({ resources: response }));
          return response;
        });
    },
    create: (data: ReplayTrack) => {
      return props.apiService
        .post<ReplayTrackEntity>("replay/tracks", data)
        .then((response) => {
          props.dispatch(replayTrackStore.actions.addResources({ resources: [response] }));
          return response;
        });
    },
    delete: (id: string) => {
      return props.apiService
        .delete(`replay/tracks/${id}`)
        .then((response) => {
          props.dispatch(replayTrackStore.actions.deleteResource({ id }));
          return response;
        });
    },
    update: (data: ReplayTrack, id: string) => {
      return props.apiService
        .patch(`replay/tracks/${id}`, data)
        .then((response: ReplayTrackEntity) => {
          props.dispatch(replayTrackStore.actions.updateResources({ resources: [response] }));
          return response;
        });
    },
  };
};
