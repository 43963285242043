import React from "react";

import { Button, Col, Divider, Row } from "antd";
import { BookOpen, CheckCircle, Edit3, Key, MapPin, Users } from "react-feather";
import { Link, useHistory } from "react-router-dom";

import headerBg from "assets/images/pro-page/header-bg.png";
import contentBg from "assets/images/pro-page/content-bg.png";
import logo from "assets/images/adw/logo/logo.svg";
import ticket from "assets/images/pro-page/ticket.png";
import joinBg from "assets/images/pro-page/join-bg.png";

import facebook from "assets/images/pro-page/social/facebook.svg";
import instagram from "assets/images/pro-page/social/instagram.svg";
import google from "assets/images/pro-page/social/google.svg";
import youtube from "assets/images/pro-page/social/youtube.svg";
import tiktok from "assets/images/pro-page/social/tiktok.svg";
import snapchat from "assets/images/pro-page/social/snapchat.svg";
import amazon from "assets/images/pro-page/social/amazon.svg";
import linkedin from "assets/images/pro-page/social/linkedin.svg";
import pinterest from "assets/images/pro-page/social/pinterest.svg";
import taboola from "assets/images/pro-page/social/taboola.svg";
import icon from "assets/images/pro-page/social/icon.svg";

import master1 from "assets/images/pro-page/master/1.png";
import master2 from "assets/images/pro-page/master/2.png";
import master3 from "assets/images/pro-page/master/3.png";
import master4 from "assets/images/pro-page/master/4.png";
import master5 from "assets/images/pro-page/master/5.png";
import master6 from "assets/images/pro-page/master/6.png";

import masterLogo1 from "assets/images/pro-page/master-logo/1.svg";
import masterLogo2 from "assets/images/pro-page/master-logo/2.svg";
import masterLogo3 from "assets/images/pro-page/master-logo/3.svg";
import masterLogo4 from "assets/images/pro-page/master-logo/4.svg";
import masterLogo5 from "assets/images/pro-page/master-logo/5.svg";

export const AdwProPage = () => {
  const history = useHistory();

  return (
    <div className="adwpropage">
      <div style={{ padding: "20px 20px 0" }}>
        <div
          className="content"
        >
          <div
            style={{
              backgroundImage: `url(${contentBg})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              width: "100%",
              maxWidth: 1100,
              margin: "30px auto",
              padding: "50px 20px",
              borderRadius: 8,
              textAlign: "center",
              marginBottom: 45,
            }}
          >
            <span
              style={{
                color: "#fff",
                fontWeight: 400,
                fontSize: 24,
                marginBottom: 20,
                display: "block",
              }}
            >
              INTRODUCING
            </span>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 10,
              }}
            >
              <img src={logo} alt="adw-logo" width={325} />
              <span
                style={{
                  borderRadius: 20,
                  background: "linear-gradient(223.32deg, #FF9900 0%, #F75E2E 97.23%)",
                  color: "#fff",
                  fontWeight: 800,
                  fontSize: 22,
                  padding: "6px 15px",
                  marginTop: 5,
                }}
              >
                PRO
              </span>
            </div>
            <h1
              style={{
                background: "linear-gradient(144.71deg, #FFFFFF 41.38%, #EDEEF6 79.24%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                backgroundClip: "text",
                fontWeight: 700,
                fontSize: 44,
                margin: "20px 0 10px",
              }}
            >
              "THE NETFLIX FOR MARKETERS"
            </h1>
            <span
              style={{
                color: "#fff",
                fontWeight: 600,
                fontSize: 24,
              }}
            >
              Watch On-Demand Masterclasses, From Anywhere
            </span>
          </div>

          <Row
            style={{
              maxWidth: 1100,
              margin: "0 auto",
            }}
          >
            <Col md={24} lg={11}>
              <h1
                style={{
                  color: "#000",
                  fontWeight: 800,
                  fontSize: 45,
                  lineHeight: 1.5,
                }}
              >
                INSTANTLY ACCESS <br /> 200+ MASTERCLASS SESSIONS FROM:
              </h1>
              <ul
                style={{
                  listStyle: "none",
                  padding: 0,
                }}
              >
                <li
                  style={{
                    display: "flex",
                    textAlign: "center",
                    gap: 8,
                    fontSize: 18,
                    fontWeight: 400,
                    color: "#000",
                    marginBottom: 25,
                  }}
                >
                  <CheckCircle size={26} color="#f75e2e" style={{ marginTop: 1 }} />
                  Ad World March 2023
                </li>
                <li
                  style={{
                    display: "flex",
                    textAlign: "center",
                    gap: 8,
                    fontSize: 18,
                    fontWeight: 400,
                    color: "#000",
                    marginBottom: 25,
                  }}
                >
                  <CheckCircle size={26} color="#f75e2e" style={{ marginTop: 1 }} />
                  Ad World October 2022
                </li>
                <li
                  style={{
                    display: "flex",
                    textAlign: "center",
                    gap: 8,
                    fontSize: 18,
                    fontWeight: 400,
                    color: "#000",
                    marginBottom: 25,
                  }}
                >
                  <CheckCircle size={26} color="#f75e2e" style={{ marginTop: 1 }} />
                  Affiliate World Europe 2022
                </li>
                <li
                  style={{
                    display: "flex",
                    textAlign: "center",
                    gap: 8,
                    fontSize: 18,
                    fontWeight: 400,
                    color: "#000",
                    marginBottom: 25,
                  }}
                >
                  <CheckCircle size={26} color="#f75e2e" style={{ marginTop: 1 }} />
                  Ad World May 2022
                </li>
                <li
                  style={{
                    display: "flex",
                    textAlign: "center",
                    gap: 8,
                    fontSize: 18,
                    fontWeight: 400,
                    color: "#000",
                    marginBottom: 25,
                  }}
                >
                  <CheckCircle size={26} color="#f75e2e" style={{ marginTop: 1 }} />
                  Ecom World May 2022
                </li>
                <li
                  style={{
                    display: "flex",
                    textAlign: "center",
                    gap: 8,
                    fontSize: 18,
                    fontWeight: 400,
                    color: "#000",
                    marginBottom: 25,
                  }}
                >
                  <CheckCircle size={26} color="#f75e2e" style={{ marginTop: 1 }} />
                  Affiliate World Dubai 2022
                </li>
                <li
                  style={{
                    display: "flex",
                    textAlign: "center",
                    gap: 8,
                    fontSize: 18,
                    fontWeight: 400,
                    color: "#000",
                    marginBottom: 25,
                  }}
                >
                  <CheckCircle size={26} color="#f75e2e" style={{ marginTop: 1 }} />
                  Creator Conf 2021
                </li>
                <li
                  style={{
                    display: "flex",
                    textAlign: "center",
                    gap: 8,
                    fontSize: 18,
                    fontWeight: 400,
                    color: "#000",
                    marginBottom: 25,
                  }}
                >
                  <CheckCircle size={26} color="#f75e2e" style={{ marginTop: 1 }} />
                  ...and more!
                </li>
              </ul>
            </Col>
            <Col
              md={24}
              lg={13}
              style={{
                background: "linear-gradient(144.71deg, #F4F5F8 20.72%, #EDEEF6 79.28%)",
                padding: 30,
              }}
            >
              <p style={{ color: "#000", fontSize: 15, fontWeight: 500 }}>
                Since 2020, Ad World has been helping 100,000's of people across the
                globe to reinvent their advertising and produce results for their businesses.
              </p>
              <p style={{ color: "#000", fontSize: 15, fontWeight: 500, display: "block" }}>
                Now with Ad World Pro, you have the opportunity to learn on-demand from world-renowned
                marketers that have featured at Ad World, as well as <strong>Ecom World</strong> and our
                <strong> in-person event Affiliate World</strong>, with new sessions released weekly.
              </p>
              <Row gutter={[20, 20]}>
                <Col
                  span={10}
                >
                  <div
                    style={{
                      background: "#fff",
                      boxShadow: `0px 27px 11px rgba(201, 183, 215, 0.02), 0px 15px 9px rgba(201, 183, 215, 0.08),
                      0px 7px 7px rgba(201, 183, 215, 0.13), 0px 2px 4px rgba(201, 183, 215, 0.15), 0px 0px 0px rgba(201, 183, 215, 0.15)`,
                      borderRadius: 8,
                      padding: 15,
                    }}
                  >
                    <BookOpen size={32} color="#f75e2e" />
                    <h5>Hyper-focused masterclasses</h5>
                    <p style={{ color: "#000" }}>
                      20-30 minute hyper-focused masterclasses with 5+ key takeaways per session
                    </p>
                  </div>
                </Col>

                <Col
                  span={14}
                  style={{ padding: 10 }}
                >
                  <div
                    style={{
                      background: "#fff",
                      boxShadow: `0px 27px 11px rgba(201, 183, 215, 0.02), 0px 15px 9px rgba(201, 183, 215, 0.08),
                      0px 7px 7px rgba(201, 183, 215, 0.13), 0px 2px 4px rgba(201, 183, 215, 0.15), 0px 0px 0px rgba(201, 183, 215, 0.15)`,
                      borderRadius: 8,
                      padding: 15,

                    }}
                  >
                    <MapPin size={32} color="#f75e2e" />
                    <h5>Most popular sessions from your favorite events</h5>
                    <p style={{ color: "#000" }}>
                      Sessions curated from recent Ad World, Ecom World & Affiliate
                      World events so you stay ahead of the curve of what's working
                    </p>
                  </div>
                </Col>

                <Col
                  span={10}
                >
                  <div
                    style={{
                      background: "#fff",
                      boxShadow: `0px 27px 11px rgba(201, 183, 215, 0.02), 0px 15px 9px rgba(201, 183, 215, 0.08),
                      0px 7px 7px rgba(201, 183, 215, 0.13), 0px 2px 4px rgba(201, 183, 215, 0.15), 0px 0px 0px rgba(201, 183, 215, 0.15)`,
                      borderRadius: 8,
                      padding: 15,
                      display: "flex",
                      alignItems: "center",
                      gap: 10,
                    }}
                  >
                    <div><Key size={32} color="#f75e2e" /></div>
                    <h5>Unlimited access to 200+ instructors</h5>
                  </div>
                </Col>

                <Col
                  span={14}
                  style={{ padding: 10 }}
                >
                  <div
                    style={{
                      background: "#fff",
                      boxShadow: `0px 27px 11px rgba(201, 183, 215, 0.02), 0px 15px 9px rgba(201, 183, 215, 0.08),
                      0px 7px 7px rgba(201, 183, 215, 0.13), 0px 2px 4px rgba(201, 183, 215, 0.15), 0px 0px 0px rgba(201, 183, 215, 0.15)`,
                      borderRadius: 8,
                      padding: 15,
                      display: "flex",
                      alignItems: "center",
                      gap: 10,
                      height: "100%",
                    }}
                  >
                    <div><Users size={32} color="#f75e2e" /></div>
                    <h5>Join 10,000+ strong community</h5>
                  </div>
                </Col>

                <Col
                  span={14}
                  style={{ padding: 10 }}
                >
                  <div
                    style={{
                      background: "#fff",
                      boxShadow: `0px 27px 11px rgba(201, 183, 215, 0.02), 0px 15px 9px rgba(201, 183, 215, 0.08),
                      0px 7px 7px rgba(201, 183, 215, 0.13), 0px 2px 4px rgba(201, 183, 215, 0.15), 0px 0px 0px rgba(201, 183, 215, 0.15)`,
                      borderRadius: 8,
                      padding: 15,
                      display: "flex",
                      alignItems: "center",
                      gap: 10,
                      height: "100%",
                    }}
                  >
                    <div><Edit3 size={32} color="#f75e2e" /></div>
                    <h5>New masterclasses added every week</h5>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>

        <div className="master">
          <div style={{ maxWidth: 1100, margin: "0 auto", textAlign: "center" }}>
            <Divider style={{ fontSize: 30, fontWeight: 700, marginTop: 40 }}>MASTER</Divider>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 20,
                justifyContent: "center",
                marginBottom: 70,
                flexWrap: "wrap",
              }}
            >
              <div
                style={{
                  background: "linear-gradient(144.71deg, #FFFFFF 20.72%, #EDEEF6 79.28%)",
                  border: "1px solid #EDEEF6",
                  borderRadius: 45,
                  width: "fit-content",
                  padding: 15,
                }}
              >
                <img src={facebook} alt="facebook-logo" />
              </div>
              <div
                style={{
                  background: "linear-gradient(144.71deg, #FFFFFF 20.72%, #EDEEF6 79.28%)",
                  border: "1px solid #EDEEF6",
                  borderRadius: 45,
                  width: "fit-content",
                  padding: 15,
                }}
              >
                <img src={instagram} alt="instagram-logo" />
              </div>

              <div
                style={{
                  background: "linear-gradient(144.71deg, #FFFFFF 20.72%, #EDEEF6 79.28%)",
                  border: "1px solid #EDEEF6",
                  borderRadius: 45,
                  width: "fit-content",
                  padding: 15,
                }}
              >
                <img src={google} alt="google-logo" />
              </div>

              <div
                style={{
                  background: "linear-gradient(144.71deg, #FFFFFF 20.72%, #EDEEF6 79.28%)",
                  border: "1px solid #EDEEF6",
                  borderRadius: 45,
                  width: "fit-content",
                  padding: 15,
                }}
              >
                <img src={youtube} alt="youtube-logo" />
              </div>

              <div
                style={{
                  background: "linear-gradient(144.71deg, #FFFFFF 20.72%, #EDEEF6 79.28%)",
                  border: "1px solid #EDEEF6",
                  borderRadius: 45,
                  width: "fit-content",
                  padding: 15,
                }}
              >
                <img src={tiktok} alt="tiktok-logo" />
              </div>

              <div
                style={{
                  background: "linear-gradient(144.71deg, #FFFFFF 20.72%, #EDEEF6 79.28%)",
                  border: "1px solid #EDEEF6",
                  borderRadius: 45,
                  width: "fit-content",
                  padding: 15,
                }}
              >
                <img src={snapchat} alt="snaptchat-logo" />
              </div>

              <div
                style={{
                  background: "linear-gradient(144.71deg, #FFFFFF 20.72%, #EDEEF6 79.28%)",
                  border: "1px solid #EDEEF6",
                  borderRadius: 45,
                  width: "fit-content",
                  padding: 15,
                }}
              >
                <img src={amazon} alt="amazon-logo" />
              </div>

              <div
                style={{
                  background: "linear-gradient(144.71deg, #FFFFFF 20.72%, #EDEEF6 79.28%)",
                  border: "1px solid #EDEEF6",
                  borderRadius: 45,
                  width: "fit-content",
                  padding: 15,
                }}
              >
                <img src={linkedin} alt="linkedin-logo" />
              </div>

              <div
                style={{
                  background: "linear-gradient(144.71deg, #FFFFFF 20.72%, #EDEEF6 79.28%)",
                  border: "1px solid #EDEEF6",
                  borderRadius: 45,
                  width: "fit-content",
                  padding: 15,
                }}
              >
                <img src={pinterest} alt="pinterest-logo" />
              </div>

              <div
                style={{
                  background: "linear-gradient(144.71deg, #FFFFFF 20.72%, #EDEEF6 79.28%)",
                  border: "1px solid #EDEEF6",
                  borderRadius: 45,
                  width: "fit-content",
                  padding: 15,
                }}
              >
                <img src={taboola} alt="taboola-logo" />
              </div>

              <div
                style={{
                  background: "linear-gradient(144.71deg, #FFFFFF 20.72%, #EDEEF6 79.28%)",
                  border: "1px solid #EDEEF6",
                  borderRadius: 45,
                  width: "fit-content",
                  padding: 15,
                }}
              >
                <img src={icon} alt="icon-logo" />
              </div>
            </div>

            <h1 style={{ fontSize: 45, fontWeight: 800, marginBottom: 5 }}>HERE’S OUR TRENDING SESSIONS RIGHT NOW</h1>
            <p style={{ fontSize: 22, fontWeight: 600, color: "#000" }}>
              We have curated our top sessions from recent online and in-person events,
              plus we add new ones weekly so you can stay up to date and increase your ROI.
            </p>
          </div>

          <div
            style={{
              display: "flex",
              gap: 15,
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 40,
            }}
          >
            <div
              style={{
                background: `url(${master1})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                backgroundSize: "contain",
                height: 316,
                width: 235,
                borderRadius: 20,
                padding: "0 15px",
              }}
            >
              <div style={{ marginTop: "calc(100% - 25px)" }}>
                <h5 style={{ fontWeight: 700, fontSize: 16, color: "#fff", marginBottom: 5 }}>SCOTT GALLOWAY</h5>
                <div style={{ width: "100%", height: 1, background: "#535253" }} />
                <p style={{ fontSize: 12, fontWeight: 400, color: "#fff", marginTop: 5, marginBottom: 10 }}>
                  The Future Of The Metaverse, NFTs, & Big Tech
                </p>
                <img src={masterLogo1} alt="logo" />
              </div>
            </div>

            <div
              style={{
                background: `url(${master2})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                backgroundSize: "contain",
                height: 316,
                width: 235,
                borderRadius: 20,
                padding: "0 15px",
              }}
            >
              <div style={{ marginTop: "calc(100% - 25px)" }}>
                <h5 style={{ fontWeight: 700, fontSize: 16, color: "#fff", marginBottom: 5 }}>SETH GODIN</h5>
                <div style={{ width: "100%", height: 1, background: "#535253" }} />
                <p style={{ fontSize: 12, fontWeight: 400, color: "#fff", marginTop: 5, marginBottom: 10 }}>
                  The Future of Marketing & Bringing Ideas to Life
                </p>
                <img src={masterLogo2} alt="logo" />
              </div>
            </div>

            <div
              style={{
                background: `url(${master3})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                backgroundSize: "contain",
                height: 316,
                width: 235,
                borderRadius: 20,
                padding: "0 15px",
              }}
            >
              <div style={{ marginTop: "calc(100% - 25px)" }}>
                <h5 style={{ fontWeight: 700, fontSize: 16, color: "#fff", marginBottom: 5 }}>MIRELLA CRESPI</h5>
                <div style={{ width: "100%", height: 1, background: "#535253" }} />
                <p style={{ fontSize: 12, fontWeight: 400, color: "#fff", marginTop: 5, marginBottom: 10 }}>
                  How to Build a Winning Ad Creative Factory
                </p>
                <img src={masterLogo3} alt="logo" />
              </div>
            </div>

            <div
              style={{
                background: `url(${master4})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                backgroundSize: "contain",
                height: 316,
                width: 235,
                borderRadius: 20,
                padding: "0 15px",
              }}
            >
              <div style={{ marginTop: "calc(100% - 25px)" }}>
                <h5 style={{ fontWeight: 700, fontSize: 16, color: "#fff", marginBottom: 5 }}>NICK SHACKELFORD</h5>
                <div style={{ width: "100%", height: 1, background: "#535253" }} />
                <p style={{ fontSize: 12, fontWeight: 400, color: "#fff", marginTop: 5, marginBottom: 10 }}>
                  Setup & Scale: TikTok Launch Strategies For Media Buyers
                </p>
                <img src={masterLogo4} alt="logo" />
              </div>
            </div>

            <div
              style={{
                background: `url(${master5})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                backgroundSize: "contain",
                height: 316,
                width: 235,
                borderRadius: 20,
                padding: "0 15px",
              }}
            >
              <div style={{ marginTop: "calc(100% - 25px)" }}>
                <h5 style={{ fontWeight: 700, fontSize: 16, color: "#fff", marginBottom: 5 }}>RANDI ZUCKERBERG</h5>
                <div style={{ width: "100%", height: 1, background: "#535253" }} />
                <p style={{ fontSize: 12, fontWeight: 400, color: "#fff", marginTop: 5, marginBottom: 10 }}>
                  Our Disrupted World: The New Rules of Engagement
                </p>
                <img src={masterLogo5} alt="logo" />
              </div>
            </div>

            <div
              style={{
                background: `url(${master6})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                backgroundSize: "contain",
                height: 316,
                width: 235,
                borderRadius: 20,
                padding: 15,
              }}
            >
              <div style={{ margin: "auto 0", textAlign: "center" }}>
                <h1 style={{ fontSize: 70, fontWeight: 700, color: "#fff", marginTop: 45, marginBottom: 0 }}>+200</h1>
                <h3 style={{ fontSize: 24, fontWeight: 700, color: "#fff" }}>MORE <br /> SESSIONS!</h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="join" style={{ background: `url(${joinBg})`, marginTop: 60 }}>
        <div style={{ maxWidth: 1100, margin: "0 auto", padding: "35px 35px" }}>
          <div style={{ display: "flex", justifyContent: "center", gap: 25, marginBottom: 40, flexWrap: "wrap" }}>
            <img src={ticket} alt="ticket" width={500} />

            <div style={{ marginTop: 10, maxWidth: 450 }}>
              <span
                style={{
                  borderRadius: 8,
                  background: "linear-gradient(180deg, #FDFD5E -9.52%, #F7C82E 100%)",
                  color: "#000",
                  fontWeight: 800,
                  fontSize: 22,
                  padding: "6px 15px",
                }}
              >
                FOR 2023 ATTENDEES ONLY
              </span>
              <h3 style={{ color: "#fff", fontSize: 24, fontWeight: 700, marginTop: 10 }}>
                Get your Ad World Pro Membership at Super Early Bird price for a limited time only and SAVE $900+
              </h3>
              {/* <p style={{ color: "#fff", fontSize: 18 }}>
                Select your Ad World Pro upgrade at checkout
              </p> */}
              <a href="https://adworldconference.com/pro/vip" rel="noreferrer" target="_blank">
                <Button style={{
                  background: "linear-gradient(131.15deg, #5EFFD8 -13.07%, #60E2FF 94.86%)",
                  boxShadow: `0px 67.2616px 26.4636px rgba(5, 251, 255, 0.04), 0px 37.4901px 23.1556px rgba(5, 251, 255, 0.12),
                  0px 16.5397px 16.5397px rgba(5, 251, 255, 0.2), 0px 4.4106px 8.8212px rgba(5, 251, 255, 0.24), 0px 0px 0px rgba(5, 251, 255, 0.24)`,
                  color: "#000",
                  borderRadius: 8,
                  fontSize: 28,
                  fontWeight: 700,
                  padding: "10px 50px 55px",
                }}
                >
                  Join Now
                </Button>
              </a>
            </div>
          </div>

          <div style={{ width: "100%", height: 1, background: "#fff" }} />

          <p style={{ margin: 0, color: "#fff", textAlign: "center", marginTop: 20 }}>
            <strong>7 Day Money Back Guarantee: </strong>If Ad World Pro isn’t for you,
            we’ll refund in full after your first seven days!
          </p>
        </div>
      </div>
    </div>
  );
};
