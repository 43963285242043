import React from "react";
import { NavLink, useHistory } from "react-router-dom";

import { Card, Layout as AntLayout, Menu } from "antd";
import { Content } from "antd/lib/layout/layout";
import Sider from "antd/lib/layout/Sider";

import SubMenu from "antd/lib/menu/SubMenu";
import {
  Airplay,
  Archive,
  Award,
  BarChart2,
  Bell,
  Briefcase,
  Calendar,
  FileText,
  Gift,
  Layers,
  Package,
  Star,
  Tag,
  Users,
  Video,
} from "react-feather";

import { app } from "config";
import logo from "assets/images/adw/logo/logo.svg";
import { Footer } from "components/Footer/Footer";

interface SubMenuTitleProps {
  title: string;
  icon: React.ReactElement;
}

const SubMenuTitle = (props: SubMenuTitleProps) => {
  return (
    <div>
      {props.icon}
      <span>{props.title}</span>
    </div>
  );
};

interface Props {
  children: React.ReactNode;
}

export const Layout = (props: Props) => {
  const history = useHistory();

  return (
    <AntLayout hasSider={true} className="admin-layout">
      <Sider
        width={240}
        breakpoint="xl"
        collapsedWidth="0"
      >
        <Menu
          mode="inline"
          defaultSelectedKeys={[history.location.pathname]}
        >
          <Menu.Item className="logo">
            <NavLink to="/app/lobby" onClick={() => window.scroll({ top: 0, behavior: "smooth" })}>
              <img src={logo} alt={app.name} height={40} />
            </NavLink>
          </Menu.Item>

          <SubMenu title={<SubMenuTitle icon={<Calendar size={18} />} title="SCHEDULE" />}>
            <Menu.Item key="/admin/schedule/tracks">
              <NavLink to="/admin/schedule/tracks">
                <Tag size={18} />
                <span>Tracks</span>
              </NavLink>
            </Menu.Item>

            <Menu.Item key="/admin/schedule/stages">
              <NavLink to="/admin/schedule/stages">
                <Airplay size={18} />
                <span>Stages</span>
              </NavLink>
            </Menu.Item>

            <Menu.Item key="/admin/schedule/talks">
              <NavLink to="/admin/schedule/talks">
                <Video size={18} />
                <span>Talks</span>
              </NavLink>
            </Menu.Item>
          </SubMenu>

          <SubMenu title={<SubMenuTitle icon={<Archive size={18} />} title="REPLAYS" />}>
            <Menu.Item key="/admin/replay/tracks">
              <NavLink to="/admin/replay/tracks">
                <Tag size={18} />
                <span>Tracks</span>
              </NavLink>
            </Menu.Item>

            <Menu.Item key="/admin/replay/stages">
              <NavLink to="/admin/replay/stages">
                <Airplay size={18} />
                <span>Stages</span>
              </NavLink>
            </Menu.Item>

            <Menu.Item key="/admin/replay/talks">
              <NavLink to="/admin/replay/talks">
                <Video size={18} />
                <span>Talks</span>
              </NavLink>
            </Menu.Item>
          </SubMenu>

          <Menu.Item key="/admin/sponsorships">
            <NavLink to="/admin/sponsorships">
              <Package size={18} />
              <span>Sponsorships</span>
            </NavLink>
          </Menu.Item>

          <Menu.Item key="/admin/perks">
            <NavLink to="/admin/perks">
              <Gift size={18} />
              <span>Perks</span>
            </NavLink>
          </Menu.Item>

          <Menu.Item key="/admin/raffles">
            <NavLink to="/admin/raffles">
              <Award size={18} />
              <span>Raffles</span>
            </NavLink>
          </Menu.Item>

          <Menu.Item key="/admin/highlights">
            <NavLink to="/admin/highlights">
              <Star size={18} />
              <span>Highlights</span>
            </NavLink>
          </Menu.Item>

          <Menu.Item key="/admin/posts">
            <NavLink to="/admin/posts">
              <FileText size={18} />
              <span>Posts</span>
            </NavLink>
          </Menu.Item>

          <Menu.Item key="/admin/notifications">
            <NavLink to="/admin/notifications">
              <Bell size={18} />
              <span>Notifications</span>
            </NavLink>
          </Menu.Item>

          <Menu.Item key="/admin/companies">
            <NavLink to="/admin/companies">
              <Briefcase size={18} />
              <span>Companies</span>
            </NavLink>
          </Menu.Item>

          <Menu.Item key="/admin/users">
            <NavLink to="/admin/users">
              <Users size={18} />
              <span>Users</span>
            </NavLink>
          </Menu.Item>

          <Menu.Item key="/admin/tickets">
            <NavLink to="/admin/tickets">
              <Layers size={18} />
              <span>Tickets</span>
            </NavLink>
          </Menu.Item>

          <Menu.Item key="/admin/stats">
            <NavLink to="/admin/stats">
              <BarChart2 size={18} />
              <span>Stats</span>
            </NavLink>
          </Menu.Item>
        </Menu>
      </Sider>

      <AntLayout>
        <Content>
          {props.children}
        </Content>

        <Footer />
      </AntLayout>
    </AntLayout>
  );
};
