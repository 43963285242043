import * as PusherPushNotifications from "@pusher/push-notifications-web";
import jsonwebtoken from "jsonwebtoken";
import { pusher } from "config";

export class PusherBeam {
  beamsClient: any;

  tokenProviderUrl: string;

  constructor(instanceId: string, tokenProviderUrl: string) {
    this.tokenProviderUrl = tokenProviderUrl;
    try {
      this.beamsClient = new PusherPushNotifications.Client({
        instanceId: instanceId,
      });
    } catch (error) {
      console.log("Web notifications not supported.", error);
    }
  }

  subscribe(token: string | null, interest: string) {
    if (token != null && this.beamsClient) {
      const beamsTokenProvider = new PusherPushNotifications.TokenProvider({
        url: this.tokenProviderUrl,
        headers: {Authorization: `Bearer ${token}`},
      });

      const user: any = jsonwebtoken.decode(token);

      this.beamsClient
        .start()
        .then(() => {
          return Promise.all([
            this.beamsClient.addDeviceInterest(pusher.beam.interest),
            this.beamsClient.setUserId(String(user.id), beamsTokenProvider),
          ]);
        })
        .then(() => console.log("Beam Successfully registered and subscribed!"))
        .catch((ex: any) => {
          console.error("Beam error", ex);
        });

      return this.beamsClient;
    }
  }

  unSubscribe() {
    return this.beamsClient && this.beamsClient.stop();
  }
}
