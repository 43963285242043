import React from "react";

import { ReplayTrack, ReplayTrackEntity } from "app/infra/replayTrack";
import { SponsorshipEntity } from "app/infra/sponsorships";

import { FormInstance } from "antd/lib/form";
import { Form, Input, Select, notification } from "antd";

interface ReplayTrackFormProps {
  form: FormInstance;
  isPending: boolean;
  onFinish: (data: ReplayTrack) => void;
  track?: ReplayTrackEntity;
  sponsorships: SponsorshipEntity[];
}

export const TrackForm = (props: ReplayTrackFormProps) => {
  const handleError = () => {
    notification.error({
      message: "Invalid form",
      description: "Please fix all invalid fields to be able to save your changes",
    });
  };

  return (
    <Form
      form={props.form}
      layout="vertical"
      scrollToFirstError={true}
      onFinish={props.onFinish}
      onFinishFailed={handleError}
    >
      <Form.Item
        label="Name"
        name="name"
        initialValue={props.track?.name}
        rules={[
          {
            required: true,
            message: "Name is required",
          },
        ]}
      >
        <Input disabled={props.isPending} />
      </Form.Item>

      <Form.Item
        name="color"
        label="Background color"
        initialValue={props.track?.color}
        rules={[
          {
            required: true,
            message: "Background color is required",
          },
        ]}
      >
        <Input disabled={props.isPending} />
      </Form.Item>

      <Form.Item
        name="sponsorshipId"
        label="Sponsorship"
        initialValue={props.track?.sponsorship?.id}
        rules={[
          {
            required: true,
            message: "Sponsorship is required",
          },
        ]}
      >
        <Select
          showSearch={true}
          optionFilterProp="children"
          filterOption={(input, option) => {
            return (option?.children as string).toLowerCase().includes(input.toLowerCase());
          }}
          style={{ width: "100%" }}
        >
          {props.sponsorships.map((sponsorship) => (
            <Select.Option key={`${sponsorship.id}-${sponsorship.title}`} value={sponsorship.id}>
              {sponsorship.title}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Form>
  );
};

TrackForm.defaultProps = { track: undefined };
