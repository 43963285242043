import { DiContext } from "app/common";
import { ConnectionEntity } from "app/infra/user";
import { useContext } from "react";
import { connectionStore } from "./connection.store";

export interface IConnectionService {
  create: (id: string) => Promise<ConnectionEntity>;
}

export const UserConnectionService = () => {
  const { apiService, dispatch } = useContext(DiContext);

  return {
    create: (id: string) => {
      return apiService
        .post<ConnectionEntity>(`users/${id}/connections`)
        .then((response) => {
          dispatch(connectionStore.actions.addResources({ resources: [response] }));
          return response;
        });
    },
  };
};
