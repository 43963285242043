import React, { useState, useMemo } from "react";
import { Link } from "react-router-dom";

import _ from "lodash";

import { isDarkColor } from "app/common/utils/isDarkColor";
import { CompanyEntity } from "app/infra/company";

import { Tag, Avatar, Popover, Button } from "antd";
import { Briefcase, Check, PlayCircle } from "react-feather";

import { CompanyVideo } from "../CompanyVideo";

interface CompanyPremiumCardProps {
  company: CompanyEntity;
}

const interestsPopover = (company: CompanyEntity) => (
  <div className="super-card__inner__front__footer__popover__popup">
    {company.interested.map((interest) => (
      <Tag key={`super-interests-popup-${interest}`} className={`${interest}`}>{interest}</Tag>
    ))}
  </div>
);

export const Super = ({ company }: CompanyPremiumCardProps) => {
  const description = useMemo(() => {
    return _.truncate(company.description, { length: 100 });
  }, [company.description]);

  const className = useMemo(() => {
    return isDarkColor(company.main_color || "") ? "" : "inverted";
  }, [company.main_color]);

  const [flipped, setFlipped] = useState(false);

  return (
    <div className={`super-card ${className}`}>
      <div className={`super-card__inner ${flipped ? "flipped" : ""}`}>
        <div
          className="super-card__inner__front"
          style={{
            backgroundImage: `linear-gradient(#fff -600%, ${company.main_color})`,
            backgroundColor: company.main_color,
          }}
        >
          <div className="super-card__inner__front__header">
            <Link to={`/app/booth/${company.id}`}>
              <Avatar
                src={company?.logo_url}
                style={company?.logo_url ? {} : { backgroundColor: "#000000" }}
                size={100}
                className="super-card__inner__front__header__avatar"
              >
                <Briefcase size={50} color="#FFFFFF" style={{ verticalAlign: "middle" }} />
              </Avatar>
            </Link>

            <Link className="super-card__inner__front__header__title" to={`/app/booth/${company.id}`}>{company.name}</Link>

            <div className="super-card__inner__front__header__subtitle">
              {Boolean(company.priority) && (
                <span className="super-card__inner__front__header__subtitle__text">OFFICIAL PARTNER</span>
              )}
            </div>

            <div className="super-card__inner__front__header__subtitle">
              {company.company_type && (
                <span className="super-card__inner__front__header__subtitle__text">{company.company_type}</span>
              )}
            </div>

            <p className="super-card__inner__front__header__description">{description}</p>
          </div>

          <div className="super-card__inner__front__play">
            {company.promo_video && (
              <Button
                className="super-card__inner__front__play__btn"
                onClick={() => setFlipped(true)}
              >
                <PlayCircle style={{ color: company.main_color }} />
                <span
                  className="super-card__inner__front__play__btn__text"
                  style={{ color: company.main_color }}
                >
                  Play Intro Video
                </span>
              </Button>
            )}
          </div>

          <div className="super-card__inner__front__checks">
            {company.tags && (
              <ul className="super-card__inner__front__checks__ul">
                {company.tags.map((interest) => (
                  <li className="super-card__inner__front__checks__ul__li" key={`super-card-${company.id}-${interest}`}>
                    <Check
                      size={34}
                      color={company.main_color}
                      strokeWidth={4}
                      style={{
                        padding: 8,
                        borderRadius: "50%",
                        verticalAlign: "middle",
                      }}
                      className="super-card__inner__front__checks__ul__li__icon"
                    />

                    <span className="super-card__inner__front__checks__ul__li__text">{interest}</span>
                  </li>
                ))}
              </ul>
            )}
          </div>

          <div className="super-card__inner__front__footer">
            {company.interested && (
              <div className="super-card__inner__front__footer__popover">
                <Popover
                  className="super-card__inner__front__footer__popover__tags"
                  content={interestsPopover(company)}
                >
                  <span className="text-box">
                    We are looking for{" "}
                    <span className="super-card__inner__front__footer__popover__i-icon">i</span>
                  </span>
                </Popover>
              </div>
            )}

            {company?.jobHiringURL && (
              <Link
                className="super-card__inner__front__footer__hiring"
                to={`/app/booth/${company.id}`}
              >
                We are hiring
              </Link>
            )}

            <Link
              className="super-card__inner__front__footer__view-company"
              to={`/app/booth/${company.id}`}
            >
              View Company
            </Link>
          </div>
        </div>

        <div
          className="super-card__inner__back"
          style={{
            backgroundImage: `linear-gradient(#fff -600%, ${company.main_color})`,
            backgroundColor: company.main_color,
          }}
        >
          <div className="super-card__inner__back__header">
            <Link to={`/app/booth/${company.id}`}>
              <Avatar
                src={company?.logo_url}
                style={company?.logo_url ? {} : { backgroundColor: "#000000" }}
                size={100}
                className="super-card__inner__back__header__avatar"
              >
                <Briefcase size={50} color="#FFFFFF" style={{ verticalAlign: "middle" }} />
              </Avatar>
            </Link>

            <Link className="super-card__inner__back__header__title" to={`/app/booth/${company.id}`}>{company.name}</Link>

            <div className="super-card__inner__back__header__subtitle">
              {Boolean(company.priority) && (
                <span className="super-card__inner__back__header__subtitle__text">OFFICIAL PARTNER</span>
              )}
            </div>

            <div className="super-card__inner__back__header__subtitle">
              {company.company_type && (
                <span className="super-card__inner__back__header__subtitle__text">{company.company_type}</span>
              )}
            </div>
          </div>

          {company.promo_video && (
            <>
              {flipped && (
                <CompanyVideo vimeoURL={company.promo_video} isPlaying={flipped} />
              )}

              <Button
                className="super-card__inner__back__finished-btn"
                style={{ marginTop: "20px" }}
                onClick={() => setFlipped(false)}
              >
                <span
                  className="super-card__inner__back__finished-btn__text"
                  style={{ color: company.main_color }}
                >
                  Finished watching?
                </span>
              </Button>
            </>
          )}

          <div className="super-card__inner__back__footer">
            <Link
              className="super-card__inner__back__footer__view-company"
              to={`/app/booth/${company.id}`}
            >
              View Company
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
