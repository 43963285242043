import { DiContext } from "app/common";
import { useContext } from "react";
import { AuthStore } from "../stores/AuthStore";
import { UserEntity, userStore } from "../app/infra/user";

export interface RegisterUser {
  reference: string;
  email: string;
  password: string;
  consented?: boolean;
}

export interface UpdatePassword {
  currentPassword?: string;
  password: string;
  passwordConfirmation: string;
}

export const UserService = () => {
  const { apiService, dispatch } = useContext(DiContext);

  return {
    passwords: {
      update: (user: UserEntity, data: UpdatePassword): Promise<{ ok: true }> => {
        return apiService
          .patch<{ ok: true }>("users/passwords", data)
          .then((response) => {
            const resources = [{ ...user, hasPassword: true }];
            dispatch(userStore.actions.updateUsers({ resources }));

            return response;
          });
      },
    },

    passwordRecoveries: {
      create: (data: { email: string }) => {
        return apiService
          .post<{ ok?: boolean }>("users/password/recoveries", data);
      },

      update: (data: { id: string; token: string; password: string }): Promise<{ ok?: boolean }> => {
        return apiService
          .patch<{ ok?: boolean }>("users/password/recoveries", data);
      },
    },

    registrations: {
      create: (data: RegisterUser) => {
        return apiService
          .post<{ token: string }>("users/registrations", data)
          .then((response) => {
            dispatch(AuthStore.actions.create(response.token));
            return response;
          });
      },
    },
  };
};
