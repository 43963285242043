import React, { useContext } from "react";
import { useHistory } from "react-router-dom";

import { Col, Row, BackTop } from "antd";

import { connect } from "react-redux";
import { RootState } from "services";

import { DiContext, kvStore } from "app/common";
import { AppMode, mode } from "config/initializers";

import { PostList } from "components/Post/PostList";
import { PostModal } from "components/Post/PostModal";

import { CurrentlyStreaming } from "./currentlyStreaming";
import { ExtraInformation } from "./extraInformation";
import { WhatsOn } from "./whatsOn";
import IntroModal from "./IntroModal";

interface OwnProps {
  match: {
    params: {
      id: string;
    };
  };
}

interface LobbyPageProps {
  postId: string;
  completedTutorial: boolean;
}

const mapStateToProps = (
  state: RootState,
  ownProps: OwnProps,
): LobbyPageProps => {
  return {
    postId: ownProps.match.params.id,
    completedTutorial: state.kvStore.kv["completed-tutorial"] as boolean,
  };
};

export const LobbyPage = connect(mapStateToProps)((props: LobbyPageProps) => {
  const history = useHistory();
  const { dispatch } = useContext(DiContext);

  const handleCloseIntroModal = () => {
    dispatch(
      kvStore.actions.addKV({ identifier: "completed-tutorial", value: true })
    );
  };

  const redirectToLobby = () => {
    history.push("/app/lobby");
  };

  return (
    <div className="wrapper">
      <div className="lobby-page">
        {mode !== AppMode.onboarding && (
          <IntroModal
            handleClose={handleCloseIntroModal}
            sliderIsOpen={!props.completedTutorial}
          />
        )}

        {props.postId && (
          <PostModal
            postId={props.postId}
            visible={true}
            onClose={redirectToLobby}
          />
        )}

        <CurrentlyStreaming />

        <div className="cnt-wrapper">
          <Row gutter={36} style={{ width: "100%" }}>
            <Col className="whats-on-wrapper" xs={0} sm={0} md={9} lg={9} xl={7} xxl={7}>
              <WhatsOn />
            </Col>
            <Col className="main-content-wrapper" xs={24} sm={24} md={15} lg={15} xl={10} xxl={10}>
              <PostList />
            </Col>

            <Col className="extra-information-wrapper" xs={0} sm={0} md={0} lg={0} xl={7} xxl={7}>
              <ExtraInformation />
            </Col>
          </Row>
        </div>
      </div>
      <BackTop />
    </div>
  );
});
