import React from "react";
import { SponsorshipEntity } from "app/infra/sponsorships";
import { EPubSubTable, Tracking } from "app/common";

interface SponsorActionProps {
  sponsorship?: SponsorshipEntity;
}

export const SponsorAction = ({ sponsorship }: SponsorActionProps) =>
  (sponsorship && (
    <div
      className="forum-action"
      style={{
        marginTop: -5,
      }}
    >
      <span>POWERED BY</span>

      <a
        href={sponsorship.link}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => {
          if (Tracking.User) {
            Tracking.PubSub([{
              table: EPubSubTable.ExternalLinks,
              data: {
                user_id: Tracking.User.id,
                datetime: Tracking.Date,
                path: `${window.location.pathname}${window.location.search}`,
                link_url: sponsorship.link,
              },
            }]);
          }
        }}
      >
        <img src={sponsorship.imageURL} height={70} alt={sponsorship.title} />
      </a>
    </div>
  )) || <div />;
