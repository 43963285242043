import React, { useContext, useEffect, useMemo, useState } from "react";

import { useHistory, useRouteMatch } from "react-router-dom";

import { connect, ConnectedProps } from "react-redux";
import { RootState } from "services";

import { DiContext } from "app/common";

import { PerkEntity, perkService } from "app/infra/perk";
import { useAsync, useOnError, useOnMount, useSessionState } from "hooks";

import { Input } from "antd";
import { Search, XCircle } from "react-feather";

import { Perk } from "components/Perk/Perk";
import * as Modal from "./Modal/Consent";

const PerkList = (props: PropsRedux) => {
  const { apiService, dispatch } = useContext(DiContext);
  const perkServ = perkService({ apiService, dispatch });

  const { execute, isPending, error } = useAsync(perkServ.getAll);
  useOnMount(execute);
  useOnError(error);

  const onHashChange = () => {
    if (window.location.pathname) {
      const pathSplit = window.location.pathname.split("/");
      const hash = pathSplit[pathSplit.length - 1];

      setTimeout(() => {
        const element = document.getElementById(`perk-${hash}`);

        if (element) {
          const header = document.querySelector<HTMLElement>(".ant-layout-header");
          const top = element.offsetTop - header!.offsetHeight;

          window.scrollTo({ top, behavior: "smooth" });
        }
      }, 100);
    }
  };

  useEffect(() => {
    onHashChange();
  }, [window.location.pathname]);

  const history = useHistory();
  const routerParams = useRouteMatch().params as { id: string };

  const [selectedPerk, setSelectedPerk] = useState<null | PerkEntity>(null);

  const [textFilter, setTextFilter] = useSessionState("perk.page:search", "");

  const onClaim = (perk: PerkEntity) => {
    setSelectedPerk(perk);
  };

  const onCancel = () => {
    setSelectedPerk(null);
  };

  const onOk = () => {
    return perkServ.claimPerk(selectedPerk!.id).then((response) => {
      setSelectedPerk(null);

      if (routerParams.id) {
        history.push("/app/perks");
      }
      return response;
    });
  };

  const perks = useMemo(() => {
    if (textFilter !== "") {
      const strings = textFilter.toLowerCase().split(" ");
      return props.perks.filter((value) => {
        const title = value.title.toLowerCase();
        const description = value.description.toLowerCase();
        const companyName = value.company?.name.toLowerCase() || "";

        return strings.reduce((acc: boolean, val: string) => {
          if (acc) return acc;
          return val !== ""
                  && (title.search(val) !== -1
                    || description.search(val) !== -1
                    || companyName.search(val) !== -1);
        }, false);
      });
    }

    return props.perks;
  }, [props.perks, textFilter]);

  return (
    <div className="wrapper">
      <div className="perk-page">
        <Input
          className="con-white-input"
          size="large"
          placeholder="Search"
          prefix={<Search size={20} color="#A3A7B2" />}
          value={textFilter}
          onChange={(e) => setTextFilter(e.target.value)}
          suffix={textFilter && <XCircle size={20} onClick={() => setTextFilter("")} style={{ cursor: "pointer" }} />}
        />

        <div className="perk-card-list">
          {perks.map((perk) => {
            const isSelected = routerParams.id ? parseFloat(routerParams.id) === parseFloat(perk.id) : false;
            return (
              <div
                key={perk.id}
                id={`perk-${perk.id}`}
                className={`perk-card-wrapper ${isSelected ? "perk-card-selected" : ""}`}
              >
                <Perk perk={perk} onButtonClick={() => onClaim(perk)} />
              </div>
            );
          })}
        </div>

        {!isPending && perks.length === 0 && (
          <div className="directory-empty-state" style={{ textAlign: "center" }}>
            <div style={{ marginTop: 90 }}>
              No results found, make sure that all words are spelled correctly
            </div>
          </div>
        )}

        {selectedPerk && (
          <Modal.Consent
            user={props.me}
            perk={selectedPerk}
            onOk={onOk}
            onCancel={onCancel}
            isVisible={true}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    me: state.userStore.byId["me"],
    perks: Object.values(state.perkStore.byId)
      .sort((a, b) => (b.rank !== undefined ? b.rank : Infinity) - (a.rank !== undefined ? a.rank : Infinity)),
  };
};

const connector = connect(mapStateToProps);

type PropsRedux = ConnectedProps<typeof connector>;

export default connector(PerkList);
