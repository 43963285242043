import React, { useContext, useEffect, useState } from "react";

import Antd, { Checkbox, DatePicker, Input, notification } from "antd";

import moment from "moment";
import { DiContext } from "app/common";
import { printError, ServerError } from "hooks";
import { Post, PostEntity, postService } from "app/infra/posts";

import "react-quill/dist/quill.snow.css";
import ReactQuill, { Quill } from "react-quill";
// @ts-ignore
import LoadingImage from "quill-image-uploader/src/blots/image";
// @ts-ignore
import ImageUploader from "quill-image-uploader/src/quill.imageUploader";

import Form, { FormInstance } from "antd/lib/form";
import { pick } from "lodash";

interface PostFormProps {
  form: FormInstance;
  onFinish: (data: Post) => void;
  post?: PostEntity;
}

export const PostForm = (props: PostFormProps) => {
  const { apiService, dispatch } = useContext(DiContext);
  const postSrv = postService({ apiService, dispatch });

  Quill.register("formats/imageBlot", LoadingImage);
  Quill.register("modules/imageUploader", ImageUploader);

  const setPostBody = (val: string) => props.form.setFieldsValue({ body: val });

  const handleError = () => {
    notification.error({
      message: "Invalid form",
      description: "Please fix all invalid fields to be able to save your changes",
    });
  };

  const [quillRef, setQuillRef] = useState<ReactQuill | null>(null);

  useEffect(() => {
    const editor = quillRef?.getEditor();

    editor?.on("text-change", (_delta, oldContents, source) => {
      if (source !== "user") return;

      const { ops } = oldContents.diff(editor.getContents());
      if (ops && ops[1] && ops[1]["delete"] === 1) {
        const images = editor
          .getContents()
          .diff(oldContents)
          .ops?.filter((i) => i.insert && i.insert.image)
          .map((i) => i.insert.image);

        images?.forEach((image: string) => {
          postSrv.deletePhoto({ photo: image }).catch(console.error);
        });
      }
    });
  }, [quillRef]);

  const modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
        ["link", "image"],
        ["clean"],
      ],
    },

    imageUploader: {
      upload: (file: string | Blob) => {
        return new Promise((resolve, reject) => {
          postSrv
            .uploadPhoto({ photo: file })
            .then((response) => resolve(response.picture_url))
            .catch((error: ServerError) => {
              printError(error);
              reject(error.message);
            });
        });
      },
    },
  };

  const formats = [
    "header",
    "bold", "italic", "underline", "strike", "blockquote",
    "list", "bullet", "indent",
    "link", "image",
    "imageBlot",
  ];

  return (
    <Form
      form={props.form}
      onFinish={props.onFinish}
      onFinishFailed={handleError}
      scrollToFirstError={true}
      layout="vertical"
      initialValues={pick(props.post, ["pinned", "vimeo_id"])}
    >
      <Form.Item
        name="pinned"
        label="Pinned?"
        valuePropName="checked"
      >
        <Checkbox />
      </Form.Item>

      <Form.Item
        className="no-ant-form-item-control"
        name="body"
        label="Content"
        rules={[
          {
            required: true,
            message: "Content is required",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <div style={{ marginBottom: "20px" }}>
        <ReactQuill
          ref={setQuillRef}
          formats={formats}
          modules={modules}
          defaultValue={props.post?.body}
          onChange={setPostBody}
        />
      </div>

      <Form.Item
        name="schedule_at"
        label={(
          <>
            Scheduled At &nbsp;<b>(in your own timezone {moment.tz.guess()})</b>
          </>
        )}
        initialValue={props.post?.schedule_at && moment(props.post.schedule_at)}
      >
        <DatePicker showTime={true} />
      </Form.Item>

      <Form.Item
        name="vimeo_id"
        label="Vimeo Video ID"
      >
        <Input />
      </Form.Item>
    </Form>
  );
};
PostForm.defaultProps = { post: undefined };
