import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { connect } from "react-redux";
import { RootState } from "services";

import { DiContext } from "app/common/utils";
import { useModal, useOnMount } from "hooks";

import { UserEntity } from "app/infra/user";
import { chatStore } from "app/infra/chat";

import * as Modal from "./Modal";

interface OwnProps {
  match: {
    params: {
      id?: string;
    };
  };
}

interface ChatProps {
  me?: UserEntity;
  hasConsented: boolean;
  currentRoomId?: string;
}

const mapStateToProps = (state: RootState, own: OwnProps) => {
  return {
    me: state.userStore.byId["me"],
    hasConsented: state.chatStore.hasConsented,
    currentRoomId: own.match.params.id,
  };
};

export const Chat = connect(mapStateToProps)((props: ChatProps) => {
  const history = useHistory();

  const { dispatch } = useContext(DiContext);
  const modal = useModal();

  const onOk = () => {
    dispatch(chatStore.actions.update({ hasConsented: true }));
    modal.shut();
  };

  const onCancel = () => {
    history.push("/app/lobby");
  };

  useOnMount(() => {
    if (!props.hasConsented) {
      modal.open();
    }
  });

  useEffect(() => {
    if (props.currentRoomId) {
      dispatch(chatStore.actions.update({ currentRoomId: props.currentRoomId }));
    }
  }, [props.currentRoomId]);

  return (
    <div className="chat-page">
      <Modal.Consent
        isVisible={modal.isVisible}
        onOk={onOk}
        onCancel={onCancel}
      />
    </div>
  );
});
