import React, { useContext } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

import { DiContext } from "app/common";
import { useAsync, useOnError, useOnMount } from "hooks";

import { PushNotification, pushNotificationService } from "app/infra/pushNotification";

import { useForm } from "antd/lib/form/Form";
import Antd, { Button, Card, Col, notification, Row } from "antd";

import { NotificationForm } from "./NotificationForm";

export const NotificationUpdate = () => {
  const { apiService, dispatch } = useContext(DiContext);
  const pushNotificationSrv = pushNotificationService({ apiService, dispatch });

  const history = useHistory();
  const routerParams = useRouteMatch().params as { id: string };

  const { execute, error, value } = useAsync((id: string) => {
    return pushNotificationSrv.admin.getOne(id);
  });
  useOnError(error);

  useOnMount(() => execute(routerParams.id));

  const { execute: onUpdate, error: onUpdateError } = useAsync((data: PushNotification) => {
    return pushNotificationSrv.update(data, routerParams.id).then((response) => {
      notification.success({ message: "Push Notification was successfully updated" });
      history.push("/admin/notifications");

      return response;
    });
  });
  useOnError(onUpdateError);

  const [form] = useForm();

  if (!value) return null;

  return (
    <Row>
      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
        <Card>
          <Row justify="space-between" align="middle" style={{ marginBottom: "20px" }}>
            <Col>
              <h1>Create Push Notification</h1>
            </Col>
          </Row>

          <NotificationForm form={form} onFinish={onUpdate} pushNotification={value} />

          <Row justify="end" style={{ marginTop: "20px" }}>
            <Col>
              <Button type="primary" onClick={form.submit}>
                Update
              </Button>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};
