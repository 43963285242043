import React from "react";
import { Skeleton as AntdSkeleton } from "antd";

export const Skeleton = () => {
  return (
    <div className="company-card-skeleton">
      <div className="super-card">
        <div className="super-card__inner">
          <div
            className="super-card__inner__front"
          >
            <div
              className="super-card__inner__front__header"
            >
              <AntdSkeleton.Avatar size={100} />

              <AntdSkeleton
                title={true}
                paragraph={{ rows: 0 }}
                className="super-card__inner__front__header__skeleton-title"
              />

              <AntdSkeleton
                title={true}
                paragraph={{ rows: 3 }}
                className="super-card__inner__front__header__description"
              />
            </div>

            <div
              className="super-card__inner__front__checks"
            >
              <ul className="super-card__inner__front__checks__ul" style={{ margin: "0 auto" }}>
                <li className="super-card__inner__front__checks__ul__li">
                  <AntdSkeleton.Avatar
                    size={40}
                  />
                  <AntdSkeleton
                    title={true}
                    paragraph={{ rows: 1 }}
                    className="super-card__inner__front__checks__ul__li__text"
                  />
                </li>
                <li className="super-card__inner__front__checks__ul__li">
                  <AntdSkeleton.Avatar
                    size={40}
                  />
                  <AntdSkeleton
                    title={true}
                    paragraph={{ rows: 1 }}
                    className="super-card__inner__front__checks__ul__li__text"
                  />
                </li>
                <li className="super-card__inner__front__checks__ul__li">
                  <AntdSkeleton.Avatar
                    size={40}
                  />
                  <AntdSkeleton
                    title={true}
                    paragraph={{ rows: 1 }}
                    className="super-card__inner__front__checks__ul__li__text"
                  />
                </li>
              </ul>
            </div>

            <div
              className="super-card__inner__front__footer"
              style={{ marginTop: 30 }}
            >
              <AntdSkeleton.Button
                size="large"
                style={{ width: "100%", height: "60px", border: 0 }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
