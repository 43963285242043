import React, { useContext, useEffect } from "react";
import { Modal, Form } from "antd";
import { DiContext, useAsync } from "app/common/utils";
import { ReplayStageForm } from "./replayStage.form";
import { ReplayStageEntity, replayStageService } from "app/infra/replayStage";

interface EditReplayStageModalProps {
  stage: ReplayStageEntity<String>;
  visible: boolean;
  close: () => void;
}

interface FormProps {
  name: string;
  description: string;
  chaturi: string;
}

export const EditReplayStageModal = ({
  visible,
  close,
  stage,
}: EditReplayStageModalProps) => {
  const [form] = Form.useForm();
  const { apiService, dispatch } = useContext(DiContext);
  const stageSrv = replayStageService({ apiService, dispatch });

  const { execute, pending, value } = useAsync(
    (data: FormProps) => stageSrv.update(data, stage.id),
    false
  );

  useEffect(() => {
    if (value) {
      close();
    }
  }, [value]);

  return (
    <Modal
      title="Edit stage"
      visible={visible}
      onOk={() => {
        form.submit();
      }}
      onCancel={close}
    >
      <ReplayStageForm
        form={form}
        onFinish={execute}
        loading={pending}
        stage={stage}
      />
    </Modal>
  );
};
