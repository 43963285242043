import React from "react";
import { NavLink } from "react-router-dom";
import { app } from "config";

import { BookOpen, HelpCircle, Shield } from "react-feather";

export const Footer = () => {
  return (
    <footer>
      <a
        href={app.detailsURL}
        target="_blank"
        rel="noopener noreferrer"
      >
        <HelpCircle size={18} style={{ verticalAlign: "middle", marginRight: 5 }} />
        FAQ
      </a>

      <NavLink to="/privacy" target="_blank">
        <Shield size={18} style={{ verticalAlign: "middle", marginRight: 5 }} />
        Privacy Policy
      </NavLink>

      <NavLink to="/terms" target="_blank">
        <BookOpen size={18} style={{ verticalAlign: "middle", marginRight: 5 }} />
        Terms and Conditions
      </NavLink>
    </footer>
  );
};
