import React, { useContext } from "react";
import { useHistory } from "react-router-dom";

import { connect } from "react-redux";
import { RootState } from "services";

import { DiContext } from "app/common";
import { useAsync, useOnError, useOnMount } from "hooks";

import { TalkEntity } from "app/infra/talk";
import { scheduleService } from "app/infra/schedule";

import { Button, Card, Col, Row } from "antd";

import { Player } from "components/Video/Player";
import { ArrowLeft } from "react-feather";
import { Resources } from "app/presentation/dashboard/stage/resources";
import { SpeakerInformation } from "app/presentation/dashboard/stage/speakerInformation";

interface TalkViewProps {
  talk: TalkEntity;
}

const mapStateToProps = (state: RootState, ownProps: { id: string }) => ({
  talk: state.talkStore.byId[ownProps.id],
});

export const TalkView = connect(mapStateToProps)((props: TalkViewProps) => {
  const history = useHistory();

  const { dispatch, apiService } = useContext(DiContext);
  const scheduleSrv = scheduleService({ apiService, dispatch });

  const { execute, error } = useAsync(() => {
    return scheduleSrv.getAgenda();
  });

  useOnMount(execute);
  useOnError(error);

  if (!props.talk) return <div />;

  return (
    <Row>
      <Col xs={24} sm={24} md={24} lg={18} xl={18} xxl={18}>
        <Card>
          <Button type="link" size="small" onClick={() => { history.goBack(); }}>
            <ArrowLeft size={16} style={{ verticalAlign: "middle", marginRight: 5 }} />
            <span style={{ verticalAlign: "middle" }}>Back</span>
          </Button>

          <h1>{props.talk.title}</h1>

          <Player
            cover={props.talk.cover_url}
            dacastID={props.talk.video_url}
            vimeoURL={props.talk.video_url_2}
            s3URL={props.talk.video_url_3}
            jwURL={props.talk.video_url_4}
          />

          {props.talk.resources && props.talk.resources.length > 0 && (
            <>
              <br />
              <br />

              <Resources resources={props.talk.resources} />
            </>
          )}

          <p style={{ fontSize: 16, margin: "20px 0px" }}>
            {props.talk.description}
          </p>

          {props.talk.speakers?.map((user) => (
            <div key={user.id}>

              <SpeakerInformation
                user={user}
                fullname={[user.first_name, user.last_name].filter(Boolean).join(" ")}
                position={(
                  [user.job_position || "", user.company?.name || user.attendee_company || ""]
                    .filter(Boolean)
                    .join(", ")
                )}
                bio={user.bio || ""}
              />
            </div>
          ))}
        </Card>
      </Col>
    </Row>
  );
});
