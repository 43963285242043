import React, { useContext } from "react";
import { useHistory } from "react-router-dom";

import { DiContext } from "app/common";
import { useAsync, useOnError } from "hooks";

import { Track, trackService } from "app/infra/track";

import { Button, Card, Col, notification, Row } from "antd";
import { useForm } from "antd/lib/form/Form";

import { TrackForm } from "./TrackForm";

export const TrackCreate = () => {
  const { apiService, dispatch } = useContext(DiContext);
  const trackSrv = trackService({ apiService, dispatch });

  const [form] = useForm();

  const history = useHistory();

  const { execute, error } = useAsync((data: Track) => {
    return trackSrv.create(data).then((response) => {
      notification.success({ message: "Track was successfully created" });
      history.push("/admin/schedule/tracks");
      return response;
    });
  });
  useOnError(error);

  return (
    <Row>
      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
        <Card>
          <Row align="middle" style={{ marginBottom: "20px" }}>
            <Col>
              <h1>Create Track</h1>
            </Col>
          </Row>

          <TrackForm form={form} onFinish={execute} />

          <Row>
            <Col style={{ marginLeft: "auto" }}>
              <Button type="primary" onClick={form.submit}>Create</Button>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};
