import React, { useState } from "react";
import { app } from "config";

import { Checkbox, Modal } from "antd";

interface ConsentProps {
  isVisible: boolean;
  onOk: () => void;
  onCancel: () => void;
}

export const Consent = (props: ConsentProps) => {
  const [isDisabled, setIsDisabled] = useState(true);

  return (
    <Modal
      className="chat-agreement-modal ghost-modal"
      centered={true}
      visible={props.isVisible}
      onOk={props.onOk}
      onCancel={props.onCancel}
      okButtonProps={{ disabled: isDisabled }}
    >
      <p>
        Please help us create the best possible {app.name} community by staying
        positive and not resorting to hateful, discriminatory, obscene or
        disruptive messages. General Chat Rules:
      </p>

      <ol>
        <li>
          Don't Spam - No spamming, repetitive self promotion or solicitation.
        </li>

        <li>
          Don't be Offensive - No posts or links that would offend other
          attendees.
        </li>

        <li>
          Be Respectful - Be kind and respectful of other members at all times.
        </li>
      </ol>

      <p>
        If you do not adhere to the chat rules, it is in our sole discretion to
        remove the chat feature from your account.
      </p>

      <p>
        I agree I will abide by the simple rules above and if not I forfeit my
        privileges of the chat feature from my account.
      </p>

      <Checkbox onChange={(e) => { setIsDisabled(!e.target.checked); }}>
        I Agree
      </Checkbox>
    </Modal>
  );
};
