import { DiContext } from "app/common";
import React, { useContext } from "react";
import { userService } from "app/infra/user";
import { AxiosRequestConfig } from "axios";
import { ByIdDTO, DownloadStats, LeadStatsDTO } from "./leadComponent";

export const ProfileData = () => {
  const { dispatch, apiService } = useContext(DiContext);
  const userSrv = userService({ apiService, dispatch });

  return DownloadStats({
    statsCall: userSrv.getProfileData as
      (data?: LeadStatsDTO | ByIdDTO, config?: AxiosRequestConfig) =>
      Promise< string | ArrayBuffer | ArrayBufferView | Blob>,
      title: "profile",
  });
};
