import React, { useContext } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

import { DiContext } from "app/common";
import { useAsync, useOnError, useOnMount } from "hooks";

import { Post, postService } from "app/infra/posts";

import { useForm } from "antd/lib/form/Form";
import Antd, { Button, Card, Col, notification, Row } from "antd";

import { PostForm } from "./PostForm";

export const PostUpdate = () => {
  const { apiService, dispatch } = useContext(DiContext);
  const postSrv = postService({ apiService, dispatch });

  const [form] = useForm();

  const history = useHistory();
  const routerParams = useRouteMatch().params as { id: string };

  const { execute, error, value: post } = useAsync(() => {
    return postSrv.getOne(routerParams.id);
  });
  useOnError(error);

  useOnMount(execute);

  const { execute: onUpdate, error: onUpdateError } = useAsync((data: Post) => {
    return postSrv.update(data, routerParams.id).then((response) => {
      history.push("/admin/posts");
      notification.success({ message: "Post was successfully updated" });

      return response;
    });
  });
  useOnError(onUpdateError);

  if (!post) return null;

  return (
    <Row>
      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
        <Card>
          <Row style={{ marginBottom: "20px" }}>
            <Col>
              <h1>Update Post</h1>
            </Col>
          </Row>

          <PostForm form={form} onFinish={onUpdate} post={post} />

          <Row justify="end" style={{ marginTop: "20px" }}>
            <Col>
              <Button type="primary" onClick={form.submit}>
                Update
              </Button>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};
