import React, { useMemo, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { app } from "config";

import _ from "lodash";

import { connect } from "react-redux";
import { RootState } from "services";

import { DiContext, useAsync } from "app/common/utils";
import { useOnError, useSessionState } from "hooks";

import { ReplayStageEntity, replayStageService } from "app/infra/replayStage";
import { ReplayTalkEntityExtended } from "app/infra/replayTalk";
import { ReplayTrackEntity } from "app/infra/replayTrack";
import { UserEntity } from "app/infra/user";

import { Button, Col, Input, Row } from "antd";
import { Search, XCircle } from "react-feather";

import logo from "assets/images/adw/logo/logo.svg";

import CheckboxFilter from "components/CheckboxFilter/CheckboxFilter";
import { Loading } from "components/Loading/Loading";
import { ReplaysCountdown } from "components/Layout/Banner/ReplayCountdown";
import { TalkItem } from "./talk.item";

interface StringBooleanMap {
  [key: string]: boolean;
}

interface AllTalksPageProps {
  tracks: ReplayTrackEntity[];
  stages: ReplayStageEntity<string>[];
  talks: ReplayTalkEntityExtended[];
  user: UserEntity | null;
}
const replaysWithTicket = (
  <div className="replays-page-ticket">
    <div className="black-shadow" />
    <div className="replays-page-ticket__content">
      <h1 className="title">WELCOME TO THE</h1>
      <div className="replays-page-ticket__content__text-img">
        <img className="logo" src={logo} alt={app.name} />
        <h1 className="title">REPLAY LIBRARY</h1>
      </div>
      <span className="subtitle">COMING SOON</span>
    </div>
  </div>
);

const replaysWithoutTicket = (
  <div className="replays-page-no-ticket">
    <img className="replays-page-no-ticket__logo" src={logo} alt={app.name} />
    {/* <span className="replays-page-no-ticket__note">
      <span className="stronger" style={{ display: "block" }}>Instant Replay Access:</span>
      Visit the <Link to="/app/schedule?type=Schedule">Schedule</Link> and click&nbsp;
      <span className="stronger">“Watch Talk”</span> on a past speech
    </span> */}
    {/* <div className="replays-page-no-ticket__divider" /> */}
    <div className="replays-page-no-ticket__content">
      <h1 className="replays-page-no-ticket__content__title">UPGRADE TO GET <br /> 1-YEAR REPLAY ACCESS</h1>
      <span className="replays-page-no-ticket__content__subtitle">Access 300+ Ad World speeches on demand</span>
    </div>
    <div className="replays-page-no-ticket__countdown-box">
      <div className="replays-page-no-ticket__border-1" />
      <span className="countdown-title">FREE REPLAY ACCESS ENDS IN</span>
      <ReplaysCountdown time="2023-04-20T00:00:00.000Z" />
      <div className="replays-page-no-ticket__border-2" />
    </div>
    <a href={app.replayPassTitoURL} target="_blank" rel="noreferrer">
      <Button className="purchase-now-btn">PURCHASE NOW</Button>
    </a>
  </div>
);

const mapStateToProps = (state: RootState): AllTalksPageProps => ({
  user: state.userStore.byId["me"],
  tracks: Object.values(state.replayTrackStore.byId),
  stages: Object.values(state.replayStageStore.byId),
  talks: Object.values(state.replayTalkStore.byId).map((talk) => ({
    ...talk,
    stage: state.replayStageStore.byId[talk.stage_id],
    track: state.replayTrackStore.byId[talk.track_id],
  })),
});

export const ReplayPage = connect(mapStateToProps)(
  (props: AllTalksPageProps) => {
    const { apiService, dispatch } = useContext(DiContext);
    const stagesSrv = replayStageService({ apiService, dispatch });

    const { execute, pending, error } = useAsync(stagesSrv.getAgenda, false);

    useOnError(error);

    useEffect(() => {
      if (props.user?.replayAccess) {
        execute();
      }
    }, [props.user]);

    const [filter, setFilter] = useSessionState<string>("replays:search", "");
    const [selectedTracks, setSelectedTracks] = useSessionState<StringBooleanMap>("replays:tracks", {});
    const [selectedStages, setSelectedStages] = useSessionState<StringBooleanMap>("replays:stages", {});

    const selectedTracksArray = useMemo(() => {
      return Object.keys(selectedTracks).filter((key) => selectedTracks[key]);
    }, [selectedTracks]);

    const selectedStagesArray = useMemo(() => {
      return Object.keys(selectedStages).filter((key) => selectedStages[key]);
    }, [selectedStages]);

    const showClearAllFilters = useMemo(() => {
      return (
        filter.length > 0
        || selectedTracksArray.length > 0
        || selectedStagesArray.length > 0
      );
    }, [filter, selectedTracksArray, selectedStagesArray]);

    const clearFilters = (): void => {
      setFilter("");
      setSelectedTracks({});
      setSelectedStages({});
    };

    const tracks = useMemo(() => {
      return props.tracks.filter((track) => track.name !== "Break").reverse();
    }, [props.tracks]);

    const stages = useMemo(() => {
      return props.stages
        .filter((stage) => stage.name !== "Break")
        .sort((a, b) => (a.name < b.name ? 1 : -1));
    }, [props.stages]);

    const talks = useMemo(() => {
      let entries = props.talks;

      if (selectedTracksArray.length > 0) {
        entries = entries.filter((entry) =>
          selectedTracksArray.includes(entry.track_id.toString()));
      }

      if (selectedStagesArray.length > 0) {
        entries = entries.filter((entry) =>
          selectedStagesArray.includes(entry.stage_id.toString()));
      }

      if (filter !== "") {
        const strings = filter.toLowerCase().split(" ");
        entries = entries.filter((entry) => {
          const title = entry.title.toLowerCase();
          const description = entry.description.toLowerCase();

          return strings.reduce((acc: boolean, value: string) => {
            if (acc) return acc;
            return (
              value !== ""
              && (title.search(value) !== -1 || description.search(value) !== -1)
            );
          }, false);
        });
      }

      return entries;
    }, [props.talks, selectedTracksArray, selectedStagesArray, filter]);

    if (!props.user) {
      return (
        <Loading
          size={40}
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: 20,
            display: "block",
          }}
        />
      );
    }

    return (
      props.user.replayAccess ? (
        // replaysWithTicket
        // <div className="wrapper">
        //   <div className="agenda-page">
        //     <div className="checkbox-filters">
        //       <div className="search-input">
        //         <Input
        //           className="adw-white-input"
        //           size="large"
        //           placeholder="Search"
        //           prefix={<Search size={20} color="#A3A7B2" />}
        //           suffix={filter && <XCircle size={20} onClick={() => setFilter("")} style={{ cursor: "pointer" }} />}
        //           value={filter}
        //           onChange={(e) => setFilter(e.target.value)}
        //           style={{ maxWidth: "100%" }}
        //         />
        //       </div>

        //       <div className="filter-right">
        //         {showClearAllFilters && (
        //           <Button
        //             type="link"
        //             onClick={clearFilters}
        //             className="clear-filters-btn"
        //           >
        //             Clear Filters
        //           </Button>
        //         )}

        //         <CheckboxFilter
        //           items={tracks.map((element) => {
        //             return {
        //               id: element.id,
        //               name: element.name,
        //             };
        //           })}
        //           placeholder="Tracks"
        //           selectedItems={selectedTracks}
        //           setSelectedItems={setSelectedTracks}
        //         />

        //         <CheckboxFilter
        //           items={_.orderBy(stages, (stage) => stage.order, "desc").map((element) => {
        //             return {
        //               id: element.id,
        //               name: element.name,
        //             };
        //           })}
        //           placeholder="Events"
        //           selectedItems={selectedStages}
        //           setSelectedItems={setSelectedStages}
        //         />

        //       </div>
        //     </div>
        //     <div
        //       className="main-content"
        //       style={{
        //         padding: 0,
        //       }}
        //     >
        //       <Row className="replays-ant-row" gutter={[24, 24]}>
        //         {_.orderBy(talks, (talk) => [talk.stage?.order, talk.track?.name], "desc").map((talk) => (
        //           <Col key={`replay-ant-col-${talk.id}`} sm={24} md={12} lg={8} xl={6}>
        //             <div className="talk-item-container">
        //               <TalkItem talk={talk} />
        //             </div>
        //           </Col>
        //         ))}
        //       </Row>

        //       {talks.length === 0 && (
        //         pending ? (
        //           <Loading
        //             size={40}
        //             style={{
        //               marginLeft: "auto",
        //               marginRight: "auto",
        //               display: "block",
        //             }}
        //           />
        //         ) : (
        //           <div
        //             style={{
        //               display: "flex",
        //               justifyContent: "center",
        //               alignItems: "center",
        //               width: "100%",
        //             }}
        //           >
        //             <span
        //               style={{
        //                 fontWeight: 600,
        //                 color: "#181a55",
        //                 fontSize: 17,
        //               }}
        //             >
        //               No replay talks with that search criteria!
        //             </span>
        //           </div>
        //         )
        //       )}
        //     </div>
        //   </div>
        // </div>
        <div className="replay-page-presentation">
          {replaysWithoutTicket}
        </div>
      ) : (
        <div className="replay-page-presentation">
          {replaysWithoutTicket}
        </div>
      )
    );
  },
);
