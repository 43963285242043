import { createSlice } from "@reduxjs/toolkit";
import { addResources, updateResources, deleteResource, InitialState } from "app/common";

import { ReplayTrackEntity } from "./replayTrack.entity";

const initialState = { keyIds: {}, byId: {} } as InitialState<ReplayTrackEntity>;

export const replayTrackStore = createSlice({
  name: "replayTrackStore",
  initialState,
  reducers: {
    reset: () => initialState,
    addResources,
    deleteResource,
    updateResources,
  },
});
