import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Avatar, Button, Skeleton, Tag, Tooltip } from "antd";
import { Calendar, Clock, FastForward, PlayCircle, User, Video } from "react-feather";
import { RootState } from "services";
import { DiContext, useAsync } from "app/common/utils";
import { useOnError } from "hooks/useOnError";
import { scheduleService } from "app/infra/schedule";
import { StageEntity } from "app/infra/stage";
import { TalkEntity } from "app/infra/talk";
import { format } from "date-fns";
import { Loading } from "components/Loading/Loading";
import { TrackEntity } from "../../../infra/track";
import { UserAvatar } from "components/User/UserAvatar";
import RaffleList from "components/Raffle/RaffleList";
import { AppMode, mode } from "../../../../config/initializers";

interface TalkListProps {
  liveTalks: TalkEntityExtended[];
  pending: boolean;
  stages: StageEntity<string>[];
  myTalks: string[];
  streamingNow: boolean;
}

interface TalkItemProps {
  talk: TalkEntityExtended;
  stages: StageEntity<string>[];
  myTalks: string[];
  streamingNow: boolean;
}

interface TalkEntityExtended extends TalkEntity {
  track: TrackEntity;
}

const TalkItem = ({ talk, stages, myTalks, streamingNow }: TalkItemProps) => {
  const { apiService, dispatch } = useContext(DiContext);
  const scheduleSrv = scheduleService({ apiService, dispatch });

  const { pending, execute, error } = useAsync(
    (add: boolean) =>
      add
        ? scheduleSrv.addTalkToMySchedule(talk.id)
        : scheduleSrv.removeTalkFromMySchedule(talk.id),
    false
  );

  useOnError(error);

  const history = useHistory();

  const isStageBreak = (event: TalkEntity) => {
    return event.speakers?.map((speaker) => `${speaker.first_name} ${speaker.last_name}`).join(", ").toLocaleLowerCase().includes("stage break");
  };

  const isStageClosed = (event: TalkEntity) => {
    return event.title.toLowerCase().includes("stage closed");
  };

  return (
    <div className="live-talk">
      <div className="img-content-wrapper">
        {talk.speakers && (
          <UserAvatar user={talk.speakers[0]} size={60} style={{ alignSelf: "center", marginRight: "15px" }} />
        )}

        <div className="content">
          <Link to={`/app/talk/${talk.id}`} title={talk.title}>
            <h5>
              {talk.title}
            </h5>
          </Link>

          <div className="tag-wrapper">
            {
              talk.title !== "Stage Break" ? (
                <Tag
                  className="ant-tag-small"
                  style={{
                    background: talk.track?.color,
                    color: talk.track?.light ? "#000" : "#fff",
                  }}
                >
                  {talk.track?.name || ""}
                </Tag>
              ) : null
            }

            <span className="stage-time">
              <Clock size={18} style={{ marginRight: 2 }} />
              {format(new Date(talk.start_time), "kk:mm")}
            </span>
          </div>
        </div>
      </div>

      {!streamingNow && !isStageBreak(talk) && !isStageClosed(talk) && (
        <div
          role="none"
          onClick={() => {
            !pending && execute(!myTalks.includes(talk.id));
          }}
          className={`notification-wrapper ${myTalks.includes(talk.id) ? "active-notification" : ""}`}
        >
          {(pending && <Loading />) || (
            <Tooltip
              title={myTalks.includes(talk.id) ? "Remove from schedule" : "Add to schedule"}
            >
              {" "}
              <Calendar />{" "}
            </Tooltip>
          )}
        </div>
      )}

      {streamingNow && (
        <div
          role="none"
          onClick={() => {
            !pending && execute(!myTalks.includes(talk.id));
          }}
          className={`notification-wrapper ${
            (myTalks.includes(talk.id) && "active-notification") || ""
          }`}
        >

          {(pending && (
          <>
            <Skeleton avatar active title paragraph={{ rows: 2 }} />
            <Skeleton avatar active title paragraph={{ rows: 2 }} />
            <Skeleton avatar active title paragraph={{ rows: 2 }} />
          </>
          )) || (
            <Button
              type="link"
              size="small"
              onClick={() => history.push(`/app/talk/${talk.id}`)}
            >
              <PlayCircle size={28} color="white" fill="#EA6CA0" />
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export const NoTalkItem = () => (
  <div className="live-talk">
    <Avatar size={60} style={{ backgroundColor: "#000000", marginRight: "15px" }}>
      <User size={40} color="#FFFFFF" style={{ verticalAlign: "middle" }} />
    </Avatar>

    <div className="content">
      <div>
        <h5>Stay Tuned</h5>
      </div>
      <div className="tag-wrapper">
        <Tag className="ant-tag-small"
          style={{
            border: "1px solid #181a55",
            fontWeight: "bold",
          }}
        >
          Pending
        </Tag>
        <span
          style={{
            color: "#181a55",
            opacity: 0.5,
          }}
        >
          No Stage Yet
        </span>
      </div>
    </div>
  </div>
);

const TalkList = ({
  liveTalks,
  pending,
  stages,
  myTalks,
  streamingNow,
}: TalkListProps) => (
  <div className="live-talks">
    {(liveTalks.length === 0 && pending && (
      <>
        <Skeleton avatar active title paragraph={{ rows: 2 }} />
        <Skeleton avatar active title paragraph={{ rows: 2 }} />
        <Skeleton avatar active title paragraph={{ rows: 2 }} />
      </>
    )) || ""}
    {liveTalks.length === 0 && !pending && <NoTalkItem />}
    {liveTalks.map((talk) => (
      <TalkItem
        myTalks={myTalks}
        talk={talk}
        stages={stages}
        key={talk.id}
        streamingNow={streamingNow}
      />
    ))}
  </div>
);

interface WhatsOnSectionProps {
  talkList: TalkEntityExtended[];
  pending: boolean;
  stages: StageEntity<string>[];
  myTalks: string[];
  icon?: any;
  title: string;
  streamingNow: boolean;
  className?: string;
}

export const WhatsOnSection = ({
  talkList,
  pending,
  stages,
  myTalks,
  icon,
  title,
  streamingNow,
  className,
}: WhatsOnSectionProps) => (
  <>
    <div className={className ? `title-wrapper icon-title ${className}` : "title-wrapper icon-title"}>
      <Button shape="round" danger type="primary" className="title-button" style={{ cursor: "initial" }}>
        {icon}
      </Button>
      <h5>{title}</h5>
    </div>
    <TalkList
      liveTalks={talkList}
      pending={pending}
      stages={stages}
      myTalks={myTalks}
      streamingNow={streamingNow}
    />
  </>
);

interface WhatsOnProps {
  liveTalks: TalkEntityExtended[];
  nextTalks: TalkEntityExtended[];
  myTalks: string[];
  stages: StageEntity<string>[];
}

export const WhatsOn = connect((state: RootState) => {
  const talksById = state.talkStore.byId;

  const liveTalks = state.scheduleStore.currentTalks.map((key) => {
    const talk = talksById[key];
    return {
      ...talk,
      stage: state.stageStore.byId[talk.stage_id],
      track: state.trackStore.byId[talk.track_id],
    };
  });
  const nextTalks = state.scheduleStore.incomingTalks.map((key) => {
    const talk = talksById[key];
    return {
      ...talk,
      stage: state.stageStore.byId[talk.stage_id],
      track: state.trackStore.byId[talk.track_id],
    };
  });

  return {
    liveTalks,
    nextTalks,
    stages: Object.values(state.stageStore.byId),
    myTalks: state.scheduleStore.myTalks,
  };
})(({ liveTalks, nextTalks, stages, myTalks }: WhatsOnProps) => {
  // const { apiService, dispatch } = useContext(DiContext);
  // const scheduleSrv = scheduleService({ apiService, dispatch });
  // We don't do mySchedule here as the nextBanner requests that every time.
  // const { error, pending, execute } = useAsync(scheduleSrv.getIncomingTalks);

  // useOnMount(execute);
  // useOnError(error);

  return (
    <div className="whats-on">
      {mode !== AppMode.finished && (
        <section className="">
          <div className="title-wrapper">
            <h4>What’s on Today</h4>
            <Link to="/app/schedule?type=Schedule" className="view-more-button">
              <Calendar size={18} />
              View Schedule
            </Link>
          </div>

          <WhatsOnSection
            talkList={
              (liveTalks.length > 3 && [...liveTalks].slice(0, 3)) || liveTalks
            }
            pending={false}
            myTalks={myTalks}
            stages={stages}
            icon={<Video size={18} />}
            title="Streaming"
            streamingNow={true}
          />
        </section>
      )}

      <div className="whats-on-raffle">
        <RaffleList />
      </div>

      {mode !== AppMode.finished && (
        <section className="up-next">
          <WhatsOnSection
            talkList={
              (nextTalks.length > 3 && [...nextTalks].slice(0, 3)) || nextTalks
            }
            pending={false}
            myTalks={myTalks}
            stages={stages}
            icon={<FastForward size={18} />}
            title="Up Next"
            streamingNow={false}
            className="up-next"
          />
        </section>
      )}
    </div>
  );
});
