import { CommonServiceDeps, PaginatedResponse, PaginationRequest } from "app/common";
import { AppDispatch } from "services";
import { StageEntity } from "./stage.entity";
import type { TalkEntity } from "../talk";
import { stageStore } from "./stage.store";

export interface CreateStageDTO {
  name: string;
  description: string;
}

export interface UpdateStageDTO {
  name?: string;
  description?: string;
  order?: number;
}

const stagesWithEntityToIds = (stages: StageEntity<TalkEntity | string>[]) =>
  stages.map((stage) => ({
    ...stage,
    ...((stage.talks && {
      talks: stage.talks.map((talk: any) => ("id" in talk && talk.id) || talk),
    }) || {}),
  }));

export const addStages = (dispatch: AppDispatch) => (
  stages: StageEntity<TalkEntity | string>[],
) => {
  dispatch(
    stageStore.actions.updateResources({
      resources: stagesWithEntityToIds(stages),
      // @ts-ignore
      keys: ["talks"],
    }),
  );
};

export const stageService = ({ apiService, dispatch }: CommonServiceDeps) => ({
  getAll: (pagination: { params: PaginationRequest }) => {
    return apiService.get("stages/all", pagination).then((response: PaginatedResponse<StageEntity<string>>) => {
      addStages(dispatch)(response.data);
      return response;
    });
  },
  getOne: (id: string) => {
    return apiService.get(`stages/${id}`).then((response: StageEntity<string>) => {
      addStages(dispatch)([response]);
      return response;
    });
  },
  create: (data: CreateStageDTO) =>
    apiService
      .post<StageEntity<TalkEntity>>("stages", data)
      .then((response: StageEntity<TalkEntity>) => {
        addStages(dispatch)([response]);
        return response;
      }),
  delete: (id: string) =>
    apiService.delete(`stages/${id}`).then(() => {
      dispatch(stageStore.actions.deleteResource({ id }));
    }),
  update: (data: UpdateStageDTO, id: string) =>
    apiService
      .patch<StageEntity<TalkEntity>>(`stages/${id}`, data)
      .then((response: StageEntity<TalkEntity>) => {
        addStages(dispatch)([response]);
        return response;
      }),
});
