import React, { useContext, Fragment } from "react";
import {
  Form,
  Input,
  Select,
  Upload,
  Button,
  Checkbox,
} from "antd";
import { Trash2 } from "react-feather";
import { UserEntity } from "app/infra/user";
import { ReplayTalkEntity, replayTalkService } from "app/infra/replayTalk";
import { ReplayTrackEntity } from "app/infra/replayTrack";
import { FormInstance } from "antd/lib/form";
import { DiContext, useAsync } from "app/common/utils";
import { useOnError } from "hooks/useOnError";
import { FileFormFunctionProps, UploadPhoto } from "app/presentation/common";
import { connect } from "react-redux";
import { RootState } from "services";
import { ResourceEntity, resourceService } from "app/infra/resource";

interface OwnProps {
  talk?: ReplayTalkEntity;
  speakers: UserEntity[];
  tracks: ReplayTrackEntity[];
  form: FormInstance;
  onFinish: () => Promise<any>;
  loading: boolean;
}

interface ReplayStageFormProps extends OwnProps {
  resources: ResourceEntity[];
  cover: string;
}

export const ReplayTalkForm = connect((state: RootState, ownProps: OwnProps) => {
  const talk = (ownProps.talk?.id && state.replayTalkStore.byId[ownProps.talk?.id]) || undefined;

  if (talk) {
    return {
      resources: talk?.resources || [],
      cover: talk?.cover_url || "",
    };
  }
  return {
    resources: [],
    cover: "",
  };
})(
  ({
    talk,
    speakers,
    tracks,
    form,
    loading,
    onFinish,
    resources,
    cover,
  }: ReplayStageFormProps) => {
    const { dispatch, apiService } = useContext(DiContext);
    const resourceSrv = resourceService({ apiService, dispatch });
    const talkSrv = replayTalkService({ apiService, dispatch });

    const { execute, pending, error } = useAsync(
      (args: FileFormFunctionProps) =>
        resourceSrv.create({
          id: talk!.id,
          file: args.file,
          replayTalk: true,
        }),
      false
    );

    const { execute: executeDelete, error: errorDelete } = useAsync(
      (resourceId: string) => resourceSrv.delete(talk!.id, resourceId),
      false
    );

    const uploadPhoto = (props: FileFormFunctionProps) =>
      talkSrv.cover.create({
        id: talk?.id || "0",
        file: props.file,
      });

    useOnError(error);
    useOnError(errorDelete);

    return (
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Form.Item
          label="Name"
          name="title"
          rules={[{ required: true }]}
          initialValue={talk && talk.title}
        >
          <Input disabled={loading} />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          rules={[{ required: true }]}
          initialValue={talk && talk.description}
        >
          <Input.TextArea rows={4} disabled={loading} />
        </Form.Item>
        <Form.Item
          label="Dacast ID"
          name="video_url"
          rules={[{ required: true }]}
          initialValue={talk?.video_url}
        >
          <Input placeholder="Dacast ID" disabled={loading} />
        </Form.Item>
        <Form.Item
          label="VIMEO"
          name="video_url_2"
          rules={[{ required: true }]}
          initialValue={talk?.video_url_2}
        >
          <Input placeholder="Vimeo LINK" disabled={loading} />
        </Form.Item>
        <Form.Item
          label="Amazon"
          name="video_url_3"
          rules={[{ required: true }]}
          initialValue={talk?.video_url_3}
        >
          <Input placeholder="Amazon LINK" disabled={loading} />
        </Form.Item>
        <Form.Item
          label="JW"
          name="video_url_4"
          rules={[{ required: true }]}
          initialValue={talk?.video_url_4}
        >
          <Input placeholder="JW ID" disabled={loading} />
        </Form.Item>
        <Form.Item
          label="Track"
          name="track"
          initialValue={talk?.track_id}
          rules={[{ required: true }]}
        >
          <Select disabled={loading}>
            {tracks.map((track: ReplayTrackEntity) => (
              <Select.Option key={`${track.id}-${track.name}`} value={track.id}>{track.name}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Speakers"
          name="speakers"
          initialValue={talk?.speakers?.map((user) => user.id.toString())}
          rules={[{ required: true }]}
        >
          <Select mode="tags" id="speakers" disabled={loading}>
            {speakers.map((speaker) => (
              <Select.Option key={`${speaker.id}-${speaker.first_name}-${speaker.last_name}`} value={speaker.id.toString()}>
                {`${speaker.first_name} ${speaker.last_name}`}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        {talk && (
          <>
            <div>Cover photo</div>
            <UploadPhoto uploadPhoto={uploadPhoto} image_url={cover} />
          </>
        )}

        {talk && (
          <Upload
            name="file"
            customRequest={execute}
            onRemove={executeDelete}
            showUploadList={false}
          >
            <Button type="primary">Upload Resources</Button>
          </Upload>
        )}
        {!talk
        && "Image for talks can be uploaded only on edit, not on create!"}
        {talk
        && resources.map((resource) => (
          <div
            key={`${resource.id}-${resource.file_name}`}
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {resource.file_name}
            <Button type="link" size="small" onClick={() => executeDelete(resource.id)}>
              <Trash2 size={18} />
            </Button>
          </div>
          ))}
        {(pending && <div>Uploading...</div>) || ""}
      </Form>
    );
  },
);
