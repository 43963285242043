import React from "react";
import { Button, Input, Modal } from "antd";
import { useOnError, useAsync } from "hooks";
import { UserService } from "services/@admin/UserService";

interface PasswordRecoveryLinkProps {
  id: string;
  isVisible: boolean;
  onCancel: () => void;
}

export const PasswordRecoveryLink = (
  props: PasswordRecoveryLinkProps,
) => {
  const { execute, error, value, isPending } = useAsync(UserService().passwordRecoveries.createLink);

  useOnError(error);

  return (
    <Modal
      className="forgot-password-link-modal"
      visible={props.isVisible}
      onCancel={props.onCancel}
      title="Forgot Password Link"
      okText=""
      cancelText="Close"
      okButtonProps={{
        style: {
          display: "none",
        },
      }}
    >
      <Input
        value={value?.link || ""}
        placeholder="Reset password link."
      />
      <Button
        type="primary"
        style={{
          marginTop: 30,
        }}
        onClick={() => { execute(props.id); }}
        loading={isPending}
      >
        Get a password reset link
      </Button>
    </Modal>
  );
};
