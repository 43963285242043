import React, { useContext, useState } from "react";
import { app } from "config";

import moment, { Moment } from "moment";

import { DiContext } from "app/common";
import { useAsync } from "hooks/useAsync";
import { useOnError } from "hooks/useOnError";

import { CompanyEntity, companyService, TicketType } from "app/infra/company";
import { perkService } from "app/infra/perk";

import { Button, Modal, Radio, Space } from "antd";
import type { EventValue } from "rc-picker/lib/interface";
import fileDownload from "js-file-download";

import { AppContext } from "components/Layout/Layout";
import RangePicker from "components/RangePicker/RangePicker";

interface DataExportModalProps {
  company: CompanyEntity;
  visible: boolean;
  onClose: () => void;
}

export const DataExport = (props: DataExportModalProps) => {
  const [selectedRadio, setSelectedRadio] = useState("perk-leads");

  const { confOpenTime } = useContext(AppContext);

  const format = "YYYY-MM-DD";
  const [fromDate, setfromDate] = useState<EventValue<Moment>>(moment(confOpenTime));
  const [uptoDate, setuptoDate] = useState<EventValue<Moment>>(moment(Date.now()));

  const { apiService, dispatch } = useContext(DiContext);

  const perkSrv = perkService({ apiService, dispatch });
  const companySrv = companyService({ apiService, dispatch });

  const { execute: downloadBusinessCardLeads, error: businessCardLeadsError } = useAsync(() => {
    return companySrv.getBusinessCardStats({ ids: [props.company.id] }).then((response) => {
      fileDownload(response || "", `${props.company.name} - ${app.name} Business Card Leads.csv`);
    });
  });

  useOnError(businessCardLeadsError);

  const { execute: downloadPerkLeads, error: perkLeadsError } = useAsync(() => {
    return perkSrv.getPerkLeads({
        ids: [props.company.id],
        startDate: fromDate?.format(format),
        endDate: uptoDate?.format(format),
      })
      .then((response) => {
        fileDownload(response || "", `${props.company.name} - ${app.name} Perk Leads.csv`);
      });
  });

  useOnError(perkLeadsError);

  const download = () => {
    if (selectedRadio === "perk-leads") {
      downloadPerkLeads();
    } else {
      downloadBusinessCardLeads();
    }
  };

  const onSelectedDate = (from: EventValue<Moment>, upto: EventValue<Moment>) => {
    setfromDate(from);
    setuptoDate(upto);
  };

  return (
    <Modal
      title="Export Company Data"
      width={700}
      visible={props.visible}
      centered={true}
      onCancel={props.onClose}
      footer={(<Button type="primary" onClick={download}>Download</Button>)}
      bodyStyle={{ minHeight: 330 }}
      className="export-data"
    >
      <Radio.Group
        onChange={(e) => setSelectedRadio(e.target.value)}
        value={selectedRadio}
        style={{ textAlign: "left" }}
      >
        <Space direction="vertical">
          <Radio value="perk-leads" style={{ whiteSpace: "initial", fontSize: 16 }}>
            <span style={{ fontWeight: 600 }}>Perk Leads</span>

            <br />

            <span style={{ color: "rgb(196, 196, 196)" }}>
              Click the download button to export all the attendees that have
              claimed your company perks. You can also export based on the
              timeframe the perks have been claimed.
            </span>
          </Radio>

          {props.company.ticket_type === TicketType.CompanySuperBooth && (
            <Radio value="business-card-bowl-leads" style={{ marginTop: 10, whiteSpace: "initial", fontSize: 16 }}>
              <span style={{ fontWeight: 600 }}>Business Card Bowl Leads</span>

              <br />

              <span style={{ color: "rgb(196, 196, 196)" }}>
                Click the download button to export all the attendees that have
                shared their contact details with your company and want to be
                reached out to.
              </span>
            </Radio>
          )}
        </Space>
      </Radio.Group>

      <div className="time-filter" style={{ marginTop: 30 }}>
        {selectedRadio === "perk-leads" && (
          <RangePicker
            from={fromDate}
            upto={uptoDate}
            onSelected={onSelectedDate}
            style={{ width: 256 }}
            disabledDate={(d) => !d || d.isAfter(Date.now()) || d.isBefore(moment(confOpenTime))}
          />
        )}
      </div>
    </Modal>
  );
};
