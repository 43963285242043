import React from "react";

import { PerkEntity } from "app/infra/perk";
import { Button, Tooltip } from "antd";
import { ConditionalWrapper } from "components/ConditionalWrapper/ConditionalWrapper";

interface PerkProps {
  perk: PerkEntity;
  onButtonClick?: (id: string) => void;
}

export const PerkButton = (props: PerkProps) => {
  return props.perk.is_claimed ? (
    <ConditionalWrapper
      condition={
        props.perk.link?.length !== 0
      }
      wrapperFn={({ children }) => (
        <a
          href={props.perk.link}
          rel="noopener noreferrer"
          target="_blank"
        >
          {children}
        </a>
      )}
    >
      <Tooltip title="Perk Claimed. Sponsor will be in touch!">
        <Button
          type="primary"
          block
          className="ant-btn-success disabled perk-claimed-btn"
          disabled={
            props.perk.link?.length === 0
          }
        >
          <span>
            {props.perk.claimed_call_to_action || "Go to Perk"}
          </span>
        </Button>
      </Tooltip>
    </ConditionalWrapper>
  ) : (
    <Button
      type="primary"
      block
      onClick={() => props.onButtonClick && props.onButtonClick(props.perk.id)}
      data-label={props.perk.title}
      data-action="Perk"
      data-awtrack="Click"
      className="perk-claimed"
      style={
        !props.perk.note ? { width: "100%" } : {}
      }
    >
      {props.perk.default_call_to_action || "Claim Now!"}
    </Button>
  );
};

PerkButton.defaultProps = { onButtonClick: () => { } };
