import { Fragment } from "react";
import { TicketTypeEntity, TicketTypes } from "app/infra/ticketType";
import { Form, Input, Select } from "antd";

interface TicketTypeFormProps {
  ticketType: TicketTypeEntity;
  pending: boolean;
}

export interface FormProps {
  name: string;
  type: TicketTypes;
}

const ticketTypes = Object.keys(TicketTypes);

export const TicketTypeForm = (props: TicketTypeFormProps) => (
  <Fragment>
    <Form.Item label="Ticket Name">
      <Input value={props.ticketType.name} />
    </Form.Item>
    <Form.Item label="Ticket Type" initialValue={props.ticketType.type} name="type">
      <Select disabled={props.pending}>
        {ticketTypes.map((ticketType) => (
          <Select.Option value={ticketType}>{ticketType}</Select.Option>
        ))}
      </Select>
    </Form.Item>
  </Fragment>
);
