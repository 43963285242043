import React, { useContext } from "react";

import { DiContext } from "app/common";

import { Raffle, RaffleEntity, raffleService } from "app/infra/raffle";
import { FileFormFunctionProps, UploadPhoto } from "app/presentation/common";

import { pick } from "lodash";
import moment from "moment";

import { DatePicker, Input } from "antd";
import Form, { FormInstance } from "antd/lib/form";

interface Props {
  form: FormInstance;
  onFinish: (data: Raffle) => void;
  raffle?: RaffleEntity;
}

export const RaffleForm = (props: Props) => {
  const { apiService, dispatch } = useContext(DiContext);
  const raffleSrv = raffleService({ apiService, dispatch });

  const uploadPhoto = (propsFile: FileFormFunctionProps) => {
    return raffleSrv.updatePhoto({ id: props.raffle!.id, photo: propsFile.file });
  };

  return (
    <Form
      form={props.form}
      onFinish={props.onFinish}
      layout="vertical"
      scrollToFirstError={true}
      initialValues={pick(props.raffle, ["name", "price"])}
    >
      <Form.Item
        name="name"
        label="Name"
        rules={[
          {
            required: true,
            message: "Name is required",
          },
        ]}
      >
        <Input placeholder="Name" />
      </Form.Item>

      <Form.Item
        name="price"
        label="Price"
        rules={[
          {
            required: true,
            message: "Price is required",
          },
        ]}
      >
        <Input type="number" placeholder="Price" />
      </Form.Item>

      <Form.Item
        name="date"
        label="Date"
        initialValue={props.raffle?.date && moment(props.raffle.date)}
        rules={[
          {
            required: true,
            message: "Date is required",
          },
        ]}
      >
        <DatePicker showTime={true} />
      </Form.Item>

      {props.raffle ? (
        <UploadPhoto uploadPhoto={uploadPhoto} image_url={props.raffle.imageURL} />
      ) : (
        "Image for raffle can be uploaded only on edit, not on create!"
      )}
    </Form>
  );
};

RaffleForm.defaultProps = {
  raffle: undefined,
};
