import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { isMobile, isIOS } from "react-device-detect";
import { Status, TalkEntity } from "app/infra/talk";
import { StageEntity } from "app/infra/stage";
import { Input } from "antd";
import { Pause, Play, Volume2, VolumeX } from "react-feather";
import { connect } from "react-redux";
import { RootState } from "services";

import { FullScreen, useFullScreenHandle } from "react-full-screen";

import { ReplayStageEntity } from "app/infra/replayStage";

import { videoStreamStore } from "app/infra/videoStream";
import { DiContext } from "../../utils";
import { LoadingNextTalk } from "./loadingNextTalk";
import { TalkHasFinished } from "./talkHasFinished";
import { EnterFullScreenIcon, ExitFullScreenIcon } from "./fullScreenIcons";
import { PlayerService } from "./playerService";

interface PlayButtonProps {
  status: boolean;
  onClick: () => void;
}

const PlayButton = ({ status, onClick }: PlayButtonProps) => {
  return (
    <div role="none" className="play-button" onClick={onClick}>
      {
        status ? (
          <Play className="play-icon play" />
        ) : (
          <Pause className="play-icon pause" />
        )
      }
    </div>
  );
};

interface PlayerProps {
  player: PlayerService;
  videoId: string;
  offset: number;
  status: Status | null;
  identifier: string;
  cover?: string;
  stage?: StageEntity<string> | ReplayStageEntity<string>;
  nextTalk?: TalkEntity;
  onComplete: () => void;
  showPlay: boolean;
  volume: number;
  isMuted: boolean;
}

export const Player = connect((state: RootState) => ({
  volume: state.videoStreamStore.volume,
  isMuted: state.videoStreamStore.isMuted,
}))(
  ({
    videoId,
    offset,
    status: talkStatus,
    stage,
    cover,
    nextTalk,
    onComplete,
    player,
    showPlay,
    volume: volumeFromStore,
    isMuted: isMutedFromStore,
  }: PlayerProps) => {
    const [isFullScreenMobile, setIsFullScreenMobile] = useState(false);
    const fullScreenHandler = useFullScreenHandle();

    const { dispatch } = useContext(DiContext);

    const [status, setStatus] = useState(false);
    const [isMuted, setIsMuted] = useState(isMutedFromStore);
    const [volume, setVolume] = useState(isMobile ? 100 : volumeFromStore);

    const play = useCallback(() => {
      try {
        if (isMuted) {
          player?.play(offset, 0);
        } else {
          player?.play(offset, volume);
        }
      } catch (e) {
        console.error("Play threw exception", e);
      }
    }, [player, offset, volume, isMuted]);

    useEffect(() => {
      if (videoId && talkStatus === Status.streaming) {
        play();
        setStatus(true);
      } else {
        player.pause();
        setStatus(false);
      }
    }, [videoId, talkStatus, player]);

    useEffect(() => {}, [videoId, talkStatus, player]);

    const toggleMute = useCallback(() => {
      if (volume === 0) {
        setVolume(50);
        setIsMuted(false);
      } else if (isMuted) {
        setIsMuted(false);
      } else {
        setIsMuted(true);
      }
    }, [volume, isMuted]);

    useEffect(() => {
      if (isMuted) {
        // setPlayerVolume = 0;
        player.setVolume(0);
      } else {
        // setPlayerVolume = volume
        player.setVolume(volume);
      }
    }, [volume, isMuted]);

    useEffect(() => {
      dispatch(videoStreamStore.actions.update({ volume }));
    }, [volume]);

    useEffect(() => {
      dispatch(videoStreamStore.actions.update({ isMuted }));
    }, [isMuted]);

    const enterFullScreen = useCallback(() => {
      if (isIOS) {
        setIsFullScreenMobile(true);
      } else if (fullScreenHandler) {
        fullScreenHandler.enter();
      }
    }, [isIOS, fullScreenHandler]);

    const exitFullScreen = useCallback(() => {
      if (isIOS) {
        setIsFullScreenMobile(false);
      } else if (fullScreenHandler) {
        fullScreenHandler.exit();
      }
    }, [isIOS, fullScreenHandler]);

    const isFullScreen = useMemo(() => {
      if (isIOS) {
        return isFullScreenMobile;
      } else {
        return fullScreenHandler.active;
      }
    }, [isIOS, fullScreenHandler, isFullScreenMobile]);

    // console.log({ talkStatus, nextTalk })

    return (
      <div
        className={`vimeo-player-container ${status ? "play" : "pause"} ${
          (talkStatus !== Status.finished && "nocontrols") || ""
        } big ${(isFullScreen && "full-screen") || ""} ${
          isFullScreenMobile && "full-screen-mobile"
        }`}
      >
        {talkStatus && talkStatus !== Status.onehourfinished && stage?.name ? (
          <span className="talk-stage-name">{stage?.name}</span>
        ) : null}

        {talkStatus === Status.future && (
          <div
            className="video-overlay"
            style={{ backgroundImage: `url(${cover})` }}
          />
        )}

        {talkStatus === Status.streaming && (
          <>
            <span className="talk-status talk-status-streaming">Streaming</span>
            <div
              className="video-overlay"
              style={{ backgroundColor: "transparent" }}
            />
          </>
        )}

        {talkStatus === Status.onehourfinished && (
          <TalkHasFinished nextTalkIdentifier={nextTalk?.id} />
        )}

        {talkStatus === Status.loadingNextTalk && nextTalk && (
          <LoadingNextTalk text={nextTalk.title} onComplete={onComplete} />
        )}

        <FullScreen handle={fullScreenHandler}>
          <div
            key={videoId}
            className={`${(isFullScreen && "full-screen") || ""} `}
            id="stage-player"
            style={{
              height: (isFullScreen && "100vh !important") || 500,
            }}
          />

          {talkStatus === Status.streaming && (
            <EnterFullScreenIcon
              isFullScreen={isFullScreen}
              setFullScreen={enterFullScreen}
            />
          )}

          <ExitFullScreenIcon
            setFullScreen={exitFullScreen}
            isFullScreen={isFullScreen}
          />
          {talkStatus === Status.streaming && !isMobile && (
            <div className={`volume-wrapper ${(isMobile && "mobile") || ""}`}>
              <span
                role="none"
                className="volume-icon"
                onClick={toggleMute}
              >
                {((isMuted || volume === 0) && <VolumeX />) || <Volume2 />}
              </span>
              <Input
                type="range"
                className="volume-slider"
                value={(!isMuted && volume) || 0}
                onChange={(e) => {
                  const { value } = e.target;
                  const parsedVolume = parseInt(value);
                  if (parsedVolume > 0) {
                    setIsMuted(false);
                  }
                  setVolume(parsedVolume);
                }}
              />
            </div>
          )}
        </FullScreen>

        {talkStatus === Status.streaming && showPlay && (
          <PlayButton onClick={() => {}} status={false} />
        )}
      </div>
    );
  },
);
