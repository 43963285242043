export const companyExpertises = [
  "Other",
  "Analytics & Reporting",
  "Artificial Intelligence",
  "CMS",
  "CRM",
  "Hosting",
  "Marketing Automations",
  "Page Builder",
  "Payments",
  "Personalization",
  "Pricing / Sales",
  "Translation Services",
  "Website Optimization",
  "AR/VR Development",
  "Cloud Services",
  "Course & Membership Platform",
  "Cyber Security & Privacy",
  "Sales Automation",
  "Scheduling Software",
  "Store Creation",
  "Affiliate Program Management",
  "Customer Automation",
  "Customer Communications",
  "Customer Reviews",
  "Customer Support",
  "Email Marketing",
  "Intelligence Tool",
  "Lead Capture",
  "Loyalty & Referrals",
  "Video / Live Streaming",
  "Display Advertising",
  "Search Engine Optimization",
  "Email",
  "Marketplace",
  "Push Notifications",
  "Search",
  "SMS",
  "Voice",
  "Whatsapp",
  "Chatbots",
  "Search Engine Marketing",
  "Affiliate Network",
  "Ad Creatives",
  "Branding",
  "Copywriting",
  "Direct Response Marketing",
  "Influencer Marketing",
  "Paid Advertising",
  "Product Development",
  "Product Photography",
  "Store/Theme Design",
  "UGC",
  "Ads Management",
  "Growth Hacking",
  "Video Advertising",
  "Content Marketing",
  "Conversion Optimization",
  "Website Design & Development",
  "Ecommerce",
  "Lead Generation",
  "Native Ads",
  "Media Buying",
  "Social Media Management",
  "Dropshipping",
  "Manufacturing",
  "Print on Demand",
  "Private/White Label",
  "Product Sourcing",
  "Quality Assurance and Testing",
  "Influencers",
  "UCG",
  "Marketplace Store",
  "Global Shipping",
  "Inventory Tracking",
  "Order Tracking",
  "Packaging",
  "Shipping API",
  "Supply Chain Management",
  "Third-Party Logistics (3PL)",
  "Warehouse Storage",
  "Accounting",
  "Catalogs",
  "Legal Services",
  "Outsourcing",
  "Training & Development",
  "Business Audits",
  "Legal Services & Trademarks",
  "Media Licensing Platform",
  "Big Commerce",
  "Magento",
  "Opencart",
  "Push Up",
  "Shopify",
  "ShopifyPlus",
  "SquareSpace",
  "Wix",
  "Woocommerce",
  "Newsletter",
  "Podcast",
  "Social Media",
  "Acquisitions & Exits",
];

interface ICompanyExpertisesById {
  [key: string]: {
    title: string;
    values: string[];
  }
}

export const companyExpertisesById: ICompanyExpertisesById = {
  serviceproviderapps: {
    title: "Service Provider & Apps",
    values: [
      "Analytics & Reporting",
      "Artificial Intelligence",
      "CMS",
      "CRM",
      "Hosting",
      "Marketing Automations",
      "Page Builder",
      "Payments",
      "Personalization",
      "Pricing / Sales",
      "Translation Services",
      "Website Optimization",
      "AR/VR Development",
      "Cloud Services",
      "Accounting",
      "Course & Membership Platform",
      "Cyber Security & Privacy",
      "Sales Automation",
      "Scheduling Software",
      "Store Creation",
      "Affiliate Program Management",
    ],
  },
  customertechnology: {
    title: "Customer Technology",
    values: [
      "Customer Automation",
      "Customer Communications",
      "Customer Reviews",
      "Customer Support",
      "Other",
    ],
  },
  marketingtechnology: {
    title: "Marketing Technology",
    values: [
      "Paid Advertising",
      "Direct Response Marketing",
      "Display Advertising",
      "Search Engine Marketing",
      "Search Engine Optimization",
      "Email Marketing",
      "Social Media",
      "Chatbots",
      "Video / Live Streaming",
      "Loyalty & Referrals",
      "Lead Capture",
      "SMS",
      "Intelligence Tool",
    ],
  },
  saleschannel: {
    title: "Sales Channel",
    values: [
      "Email",
      "Marketplace",
      "Search",
      "Push Notifications",
      "SMS",
      "Social Media",
      "Voice",
      "Whatsapp",
      "Chatbots",
      "Affiliate Network",
    ],
  },
  agenciesmarketing: {
    title: "Agencies & Marketing",
    values: [
      "Media Buying",
      "Social Media Management",
      "Direct Response Marketing",
      "Ads Management",
      "Ecommerce",
      "Conversion Optimization",
      "Ad Creatives",
      "Branding",
      "Paid Advertising",
      "Influencer Marketing",
      "Lead Generation",
      "Website Design & Development",
      "Native Ads",
      "UGC",
      "Copywriting",
      "Video Advertising",
      "Content Marketing",
      "Growth Hacking",
      "Product Development",
      "Product Photography",
      "Store/Theme Design",
    ],
  },
  manufacturing: {
    title: "Manufacturing",
    values: [
      "Dropshipping",
      "Manufacturing",
      "Print on Demand",
      "Private/White Label",
      "Product Sourcing",
      "Quality Assurance and Testing",
      "Other",
    ],
  },
  marketplaces: {
    title: "Marketplaces",
    values: [
      "Influencers",
      "UCG",
      "Marketplace Store",
    ],
  },
  fulfilmentlogistics: {
    title: "Fulfilment & Logistics",
    values: [
      "Global Shipping",
      "Inventory Tracking",
      "Order Tracking",
      "Packaging",
      "Shipping API",
      "Supply Chain Management",
      "Third-Party Logistics (3PL)",
      "Warehouse Storage",
      "Other",
    ],
  },
  businessoperations: {
    title: "Business Operations",
    values: [
      "Accounting",
      "Catalogs",
      "Legal Services",
      "Outsourcing",
      "Training & Development",
      "Other",
      "Business Audits",
      "Legal Services & Trademarks",
      "Media Licensing Platform",
    ],
  },
  storeplatform: {
    title: "Store Platform",
    values: [
      "Shopify",
      "ShopifyPlus",
      "SquareSpace",
      "Wix",
      "Big Commerce",
      "Magento",
      "Opencart",
      "Push Up",
      "Woocommerce",
      "Other",
    ],
  },
  communities: {
    title: "Communities",
    values: [
      "Newsletter",
      "Podcast",
      "Social Media",
      "Other",
    ],
  },
  investor: {
    title: "Investor",
    values: [
      "Acquisitions & Exits",
      "Other",
    ],
  },
};

export const attendeeInterests = [
  "AI In Advertising",
  "The Future of Advertising",
  "Content Marketing",
  "Brand Building",
  "Ad Creatives",
  "Media Buying",
  "Conversion Optimization",
  "Email & SMS Marketing",
];
