/* eslint-disable @typescript-eslint/no-unsafe-call */
import firebase from "firebase";
import "firebase/messaging";

import { chatStore } from "app/infra/chat";
import { AppDispatch } from "./store";

firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY || "AIzaSyDWDJ67VG-6geFq6K1wLr5IJG8mzCCyiy8",
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || "adw5-chat-production",
  appId: process.env.REACT_APP_FIREBASE_APP_ID || "1:496599930601:web:215dbf37ef8853fd344278",
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID || "496599930601",
});

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const message = firebase.messaging.isSupported() ? firebase.messaging() : null;

const subscribeToChatPushNotifications = (dispatch: AppDispatch) => {
  if (message) {
    message.getToken({
      vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY
        || "BM1EqsGUieRHphNz3k6pYsAmw5DGEDytoWVWo82lGLf0njlb-3mzut4miTZK5Zxix9Tw7vObLYy8Pn31ZfBMzTk",
    })
      .then((fcmToken: string) => {
        if (fcmToken) {
          dispatch(chatStore.actions.update({ fcmToken }));
        }
      })
      .catch(console.error);
  }
};

const onMessageListener = (cb: (payload: any) => void) =>
  message?.onMessage((payload) => {
    cb(payload);
  });

export const Firebase = {
  subscribeToChatPushNotifications,
  onMessageListener,
};
