import { Button, Col, Input, Row } from "antd";
import { country_list } from "app/common/data";
import { attendeeInterests } from "app/core/user";
import CheckboxFilter, { StringBooleanMap } from "components/CheckboxFilter/CheckboxFilter";
import { CompanyShortList } from "components/Company/CompanyShortList";
import { useDebounce, useSessionState } from "hooks";
import React, { useMemo } from "react";
import { Briefcase, Mic, Search, Users, XCircle } from "react-feather";
import { Link } from "react-router-dom";
import UserList from "./UserList";

interface UserListSectionProps {
  role: string;
}

export const UserListSection = (props: UserListSectionProps) => {
  const [filter, setFilter] = useSessionState("directory:search", "");
  const [selectedInterests, setSelectedInterests] = useSessionState("directory:interests", {});
  const [selectedCountries, setSelectedCountries] = useSessionState("directory:countries", {});

  const debouncedFilter = useDebounce(filter, 300);

  const getSelectedOptions = (strBoolMap: StringBooleanMap) => {
    return Object.entries(strBoolMap).filter((element) => element[1] === true).map((element) => element[0]);
  };

  const showClearAllFilters = useMemo(() => {
    return (
      filter?.length > 0
      || getSelectedOptions(selectedInterests).length > 0
      || getSelectedOptions(selectedCountries).length > 0
    );
  }, [filter, selectedInterests, selectedCountries]);

  const onClearAllFilters = () => {
    setFilter("");
    setSelectedCountries({});
    setSelectedInterests({});
  };

  const interestList = useMemo(() => {
    return attendeeInterests.map((item) => ({ id: item, name: item }));
  }, [attendeeInterests]);

  const countryList = useMemo(() => {
    return country_list.map((country) => ({ id: country, name: country }));
  }, [country_list]);

  const keystamp = useMemo(() => {
    return props.role === "ROLE_ATTENDEE" ? "directory-page-attendees" : "directory-page-speakers";
  }, [props.role]);

  return (
    <div className="directory-page">
      <Button.Group className="networking-segmented-control">
        <Link to="/app/directory/companies">
          <Button>
            <Briefcase size={22} style={{ verticalAlign: "middle" }} />
            <span style={{ verticalAlign: "middle" }}>MARKETPLACE</span>
          </Button>
        </Link>

        {props.role === "ROLE_ATTENDEE" ? (
          <>
            <Link to="/app/directory/attendees" className="selected">
              <Button>
                <Users size={22} style={{ verticalAlign: "middle" }} />
                <span style={{ verticalAlign: "middle" }}>ATTENDEES</span>
              </Button>
            </Link>

            <Link to="/app/directory/speakers">
              <Button>
                <Mic size={22} style={{ verticalAlign: "middle" }} />
                <span style={{ verticalAlign: "middle" }}>SPEAKERS</span>
              </Button>
            </Link>
          </>
        ) : (
          <>
            <Link to="/app/directory/attendees">
              <Button>
                <Users size={22} style={{ verticalAlign: "middle" }} />
                <span style={{ verticalAlign: "middle" }}>ATTENDEES</span>
              </Button>
            </Link>

            <Link to="/app/directory/speakers" className="selected">
              <Button>
                <Mic size={22} style={{ verticalAlign: "middle" }} />
                <span style={{ verticalAlign: "middle" }}>SPEAKERS</span>
              </Button>
            </Link>
          </>
        )}
      </Button.Group>

      <div className="directory-page-main">
        <div className="checkbox-filters">
          <div className="search-input">
            <Input
              className="adw-white-input"
              size="large"
              placeholder="Search"
              prefix={<Search size={20} color="#A3A7B2" />}
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              suffix={filter && <XCircle size={20} onClick={() => setFilter("")} style={{ cursor: "pointer" }} />}
              style={{
                width: "100%",
                maxWidth: "100%",
              }}
            />
          </div>

          <div className="filter-right">
            {showClearAllFilters && (
              <Button className="clear-filters-btn" type="link" onClick={onClearAllFilters}>
                Clear Filters
              </Button>
            )}

            <CheckboxFilter
              placeholder="Filter by interests"
              selectedItems={selectedInterests}
              setSelectedItems={setSelectedInterests}
              items={interestList}
              searchable={true}
            />

            <CheckboxFilter
              placeholder="Filter by countries"
              selectedItems={selectedCountries}
              setSelectedItems={setSelectedCountries}
              items={countryList}
              searchable={true}
            />
          </div>
        </div>

        <Row gutter={20}>
          <Col xl={18}>
            <UserList
              role={props.role}
              keystamp={keystamp}
              filter={debouncedFilter}
              countries={getSelectedOptions(selectedCountries)}
              interests={getSelectedOptions(selectedInterests)}
            />
          </Col>

          <Col xl={6}>
            <div className="extra-information-wrapper">
              <div className="extra-infromation-header">
                <h2 className="extra-infromation-wrapper-title">
                  Recommended
                </h2>
              </div>

              <CompanyShortList />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
