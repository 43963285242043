import { CommonServiceDeps, PaginationRequest } from "app/common";
import { PaginationResponse } from "app/common/utils/PaginationResponse";

import { HighlightEntity } from "./highlight.entity";
import { highlightStore } from "./highlight.store";

interface UploadPhotoDTO {
  id: string;
  photo: string | Blob;
}

export type Highlight = Pick<HighlightEntity,
  "title"
  | "subtitle"
  | "video_url"
  | "start"
  | "end"
  >;

export const highlightService = (props: CommonServiceDeps) => ({
  getAll: () => {
    return props.apiService
      .get<HighlightEntity[]>("highlights")
      .then((response: HighlightEntity[]) => {
        props.dispatch(highlightStore.actions.addHighlights({ highlights: response }));
        props.dispatch(highlightStore.actions.addKeyedIds({
          keyedstamp: "lobby-highlights",
          ids: response.map((highlight) => highlight.id),
        }));
        return response;
      });
  },
  getOne: (id: string) => {
    return props.apiService
      .get<HighlightEntity>(`highlights/${id}`)
      .then((response: HighlightEntity) => {
        props.dispatch(highlightStore.actions.addHighlights({ highlights: [response] }));
        return response;
      })
  },
  create: (data: Highlight) => {
    return props.apiService
      .post<HighlightEntity>("highlights", data)
      .then((response) => {
        props.dispatch(highlightStore.actions.addHighlights({ highlights: [response] }));
        return response;
      });
  },
  update: (data: Highlight, id: string) => {
    return props.apiService
      .patch(`highlights/${id}`, data)
      .then((response: HighlightEntity) => {
        props.dispatch(highlightStore.actions.addHighlights({ highlights: [response] }));
        return response;
      });
  },
  delete: (id: string) => {
    return props.apiService
      .delete(`highlights/${id}`)
      .then((response) => {
        props.dispatch(highlightStore.actions.deleteHighlight({ id }));
        return response;
      });
  },
  getAllAdmin: (pagination: { params: PaginationRequest }) => {
    return props.apiService
      .get<HighlightEntity[]>("highlights/admin", pagination)
      .then((response: PaginationResponse<HighlightEntity>) => {
        props.dispatch(highlightStore.actions.addHighlights({ highlights: response.data }));
        props.dispatch(highlightStore.actions.addKeyedIds({
          keyedstamp: "lobby-highlights",
          ids: response.data.map((highlight) => highlight.id),
        }));
        return response;
      });
  },
  updatePhoto: ({ id, photo }: UploadPhotoDTO) => {
    const formData = new FormData();
    formData.append("picture", photo);
    return props.apiService
      .post(`highlights/thumbnail/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
  },
});
