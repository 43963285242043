import React from "react";

import { PushNotification, PushNotificationEntity } from "app/infra/pushNotification";

import moment from "moment";
import { pick } from "lodash";

import Antd, { DatePicker, Input } from "antd";
import Form, { FormInstance } from "antd/lib/form";

interface Props {
  form: FormInstance;
  onFinish: (data: PushNotification) => void;
  pushNotification?: PushNotificationEntity;
}

export const NotificationForm = (props: Props) => {
  return (
    <Form
      form={props.form}
      onFinish={props.onFinish}
      layout="vertical"
      initialValues={pick(props.pushNotification, "title", "body", "link")}
    >
      <Form.Item
        name="title"
        label="Title"
        rules={[
          {
            required: true,
            message: "Title is required",
          },
        ]}
      >
        <Input placeholder="Title" />
      </Form.Item>

      <Form.Item
        name="body"
        label="Body"
        rules={[
          {
            max: 30,
            message: "Body can't be longer than 30 characters",
          },
          {
            required: true,
            message: "Body is required",
          },
        ]}
      >
        <Input placeholder="Body" />
      </Form.Item>

      <Form.Item
        name="link"
        label="Link"
        rules={[
          {
            type: "url",
            message: "Link must be a valid URL",
          },
          {
            required: true,
            message: "Link is required",
          },
        ]}
      >
        <Input placeholder="Link" />
      </Form.Item>

      <Form.Item
        name="send_at"
        label={(
          <>
            Send at &nbsp;<b>(in your own timezone ({moment.tz.guess()}))</b>
          </>
        )}
        rules={[
          {
            required: true,
            message: "Send at is required",
          },
        ]}
        initialValue={props.pushNotification?.send_at && moment(props.pushNotification?.send_at)}
      >
        <DatePicker showTime={true} />
      </Form.Item>
    </Form>
  );
};

NotificationForm.defaultProps = { pushNotification: undefined };

// import React from "react";

// import moment from "moment-timezone";

// import { PushNotification, PushNotificationEntity } from "app/infra/pushNotification";

// import { DatePicker, Form, Input, notification } from "antd";
// import { FormInstance } from "antd/lib/form";

// interface PushNotificationFormProps {
//   form: FormInstance;
//   isPending: boolean;
//   onFinish: (data: PushNotification) => void;
//   pushNotification?: PushNotificationEntity;
// }

// export const PushNotificationForm = (props: PushNotificationFormProps) => {
//   const handleError = () => {
//     notification.error({
//       message: "Invalid form",
//       description: "Please fix all invalid fields to be able to save your changes",
//     });
//   };

//   return (
//     <Form
//       form={props.form}
//       layout="vertical"
//       onFinish={props.onFinish}
//       onFinishFailed={handleError}
//     >
//       <Form.Item
//         name="title"
//         label="Title"
//         initialValue={props.pushNotification?.title}
//         rules={[
//           {
//             required: true,
//             message: "Title is required",
//           },
//         ]}
//       >
//         <Input placeholder="Title" disabled={props.isPending} />
//       </Form.Item>

//       <Form.Item
//         name="body"
//         label="Body"
//         initialValue={props.pushNotification?.body}
//         rules={[
//           {
//             max: 30,
//             message: "Body can't be longer than 30 characters",
//           },
//           {
//             required: true,
//             message: "Body is required",
//           },
//         ]}
//       >
//         <Input placeholder="Body" disabled={props.isPending} />
//       </Form.Item>

//       <Form.Item
//         name="link"
//         label="Link"
//         initialValue={props.pushNotification?.link}
//         rules={[
//           {
//             type: "url",
//             message: "Link must be a valid URL",
//           },
//           {
//             required: true,
//             message: "Link is required",
//           },
//         ]}
//       >
//         <Input placeholder="Link" disabled={props.isPending} />
//       </Form.Item>

//       <Form.Item
//         name="send_at"
//         label={(
//           <>
//             Send at &nbsp;<b>(in your own timezone ({moment.tz.guess()}))</b>
//           </>
//         )}
//         rules={[
//           {
//             required: true,
//             message: "Send at is required",
//           },
//         ]}
//         initialValue={props.pushNotification?.send_at && moment(props.pushNotification?.send_at)}
//       >
//         <DatePicker showTime={true} disabled={props.isPending} />
//       </Form.Item>
//     </Form>
//   );
// };

// PushNotificationForm.defaultProps = { pushNotification: undefined };
