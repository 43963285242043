import React, { useState } from "react";
import { Modal, Form, Checkbox } from "antd";
import {EPubSubTable, Tracking} from "app/common";
import { useAsync } from "app/common/utils";
import { useOnError } from "hooks/useOnError";
import { app } from "config";

interface CreateRaffleModalProps {
  visible: boolean;
  close: () => void;
  onOk: () => Promise<unknown>;
  url: string;
  title: string;
}

export const AgreeShareInformationResource = ({
  onOk,
  visible,
  close,
  url,
  title,
}: CreateRaffleModalProps) => {
  const [form] = Form.useForm();
  const [checkbox, setCheckbox] = useState(false);

  const { execute, pending, error } = useAsync(
    () =>
      onOk().then((response) => {
        if (Tracking.User) {
          Tracking.PubSub([{
            table: EPubSubTable.Download,
            data: {
              datetime: Tracking.Date,
              user_id: Tracking.User.id,
              path: window.location.pathname,
              file_name: title,
            },
          }]);
        }

        close();
        const a = document.createElement("a");
        a.target = "_blank";
        a.href = url;
        a.download = url;
        a.click();
        return response;
      }),
    false
  );

  useOnError(error);

  // useEffect(() => {
  //   if (value) {
  //     close();
  //   }
  // }, [value]);

  return (
    <Modal
      className="chat-agreement-modal ghost-modal"
      visible={visible}
      okButtonProps={{
        loading: pending,
        disabled: !checkbox,
      }}
      onOk={() => {
        form.submit();
      }}
      onCancel={close}
    >
      <p>
        To download a resource, I give permission for {app.name} to share my
        contact information (name and email) with the companies associated with
        this specific Track and for them to contact me.
      </p>
      <Form form={form} onFinish={execute}>
        <Form.Item
          label="I Agree"
          name="agreement"
          initialValue={checkbox}
          rules={[{ required: true, message: "" }]}
        >
          <Checkbox
            onChange={(event) => {
              setCheckbox(event.target.checked);
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
