import { DiContext } from "app/common";
import React, { useContext } from "react";
import { companyService } from "app/infra/company";
import { AxiosRequestConfig } from "axios";
import { Entity, LeadComponent, LeadStatsDTO, ByIdDTO } from "./leadComponent";

export const BusinessCardLeads = ({
  calledFromProfile,
}: {
  calledFromProfile: boolean;
}) => {
  const { dispatch, apiService } = useContext(DiContext);
  const compService = companyService({ apiService, dispatch });
  return LeadComponent({
    calledFromProfile,
    getAll: compService.getAll as () => Promise<Entity[]>,
    getStats: (calledFromProfile ? compService.getBusinessCardStats : compService.getBusinessCardLeads) as
      (data?: LeadStatsDTO | ByIdDTO, config?: AxiosRequestConfig) =>
      Promise< string | ArrayBuffer | ArrayBufferView | Blob>,
    title: "company",
  });
};
