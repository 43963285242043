export const move = <T>(array: T[], sourceIndex: number, targetIndex: number) => {
  const item = array[sourceIndex];
  const diff = sourceIndex - targetIndex;
  const { length } = array;

  if (diff > 0) {
    // move left
    return [
      ...array.slice(0, targetIndex),
      item,
      ...array.slice(targetIndex, sourceIndex),
      ...array.slice(sourceIndex + 1, length),
    ];
  }

  if (diff < 0) {
    // move right
    const index = targetIndex + 1;

    return [
      ...array.slice(0, sourceIndex),
      ...array.slice(sourceIndex + 1, index),
      item,
      ...array.slice(index, length),
    ];
  }

  return array;
};
