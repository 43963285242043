import React from "react";

import Countdown, { CountdownTimeDelta, zeroPad } from "react-countdown";

interface CountdownBannerProps {
  time: string;
  callback?: () => void;
}

export const BannerCountdown = (props: CountdownBannerProps) => {
  const renderer = ({ days, hours, minutes, seconds, completed }: CountdownTimeDelta) => {
    if (completed && props.callback !== undefined) {
      props.callback();
    }

    return (
      <>
        {days !== 0 && (
          <>
            <div className="countdown-fraction-block">
              <span className="number">{zeroPad(days)}</span>
              <span className="label">{days === 1 ? "Day" : "Days"}</span>
              <span className="label-mobile">{days === 1 ? "Day" : "Days"}</span>
            </div>

            <strong>:</strong>
          </>
        )}

        <div className="countdown-fraction-block">
          <span className="number">{zeroPad(hours)}</span>
          <span className="label">Hours</span>
          <span className="label-mobile">hrs</span>
        </div>

        <strong>:</strong>

        <div className="countdown-fraction-block">
          <span className="number">{zeroPad(minutes)}</span>
          <span className="label">Minutes</span>
          <span className="label-mobile">Mins</span>
        </div>

        <strong>:</strong>

        <div className="countdown-fraction-block">
          <span className="number">{zeroPad(seconds)}</span>
          <span className="label">Seconds</span>
          <span className="label-mobile">Secs</span>
        </div>
      </>
    );
  };

  return (
    <div className="next-talk-countdown-wrapper">
      <Countdown
        date={Date.parse(props.time)}
        renderer={renderer}
        intervalDelay={0}
      />
    </div>
  );
};

BannerCountdown.defaultProps = { callback: () => {} };
