import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";

import { parse } from "qs";
import moment from "moment";

import { DiContext, OrderEnum, usePaginationAsync } from "app/common";
import { useAsync, useOnError, useOnMount } from "hooks";
import { TalkEntity, TalkEntityExtended, talkService } from "app/infra/talk";

import { ColumnsType } from "antd/lib/table";
import { Button, Card, Col, Input, notification, Popconfirm, Row, Table } from "antd";
import { Edit, Eye, Search, Trash2 } from "react-feather";

import { parseQueryPath } from "app/common/utils/Path";

interface TalkListParameters {
  page?: number;
  search?: string;
}

export const TalkList = () => {
  const { dispatch, apiService } = useContext(DiContext);
  const talkServ = talkService({ apiService, dispatch });

  const history = useHistory();
  const queryParams = parse(history.location.search, { ignoreQueryPrefix: true }) as TalkListParameters;

  const pagination = usePaginationAsync<TalkEntityExtended>(
    (page: number, pageSize: number, search: string, sortBy: string, sortDirection: OrderEnum) => {
      return talkServ.getAll({ params: {
        page,
        count: pageSize || 20,
        search: search || undefined,
        sortBy: sortBy || undefined,
        sortDirection: sortDirection || undefined,
      } });
    },
    {
      initialPage: Number(queryParams.page),
      initialFilter: { search: queryParams.search || "" },
    },
  );

  useOnMount(pagination.async.execute);

  const updateSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    history.push(parseQueryPath("/admin/schedule/talks", "page", "1", "search", event.currentTarget.value));
    pagination.onSetFilter({ search: event.currentTarget.value });
  };

  const { execute: onDeleteTalk, error: onDeleteTalkError } = useAsync((id: string) => {
    return talkServ.delete(id).then((response) => {
      notification.success({ message: "Talk deleted successfully" });
      pagination.async.execute();

      return response;
    });
  });
  useOnError(onDeleteTalkError);

  const columns: ColumnsType<TalkEntity> = [
    {
      title: "Title",
      dataIndex: "title",
      sorter: true,
      onHeaderCell: (_column) => ({
        onClick: () => pagination.onToggleOrderBy("title"),
      }),
    },
    {
      title: "Description",
      dataIndex: "description",
      ellipsis: true,
      sorter: true,
      onHeaderCell: (_column) => ({
        onClick: () => pagination.onToggleOrderBy("description"),
      }),
    },
    {
      title: "Stage",
      dataIndex: "stage_id",
      sorter: true,
      onHeaderCell: (_column) => ({
        onClick: () => pagination.onToggleOrderBy("stage.order"),
      }),
      render: (_value, record) => {
        const { stage } = record;
        if (!stage) {
          return null;
        }
        return (
          <div>
            {stage.name}
          </div>
        );
      },
    },
    {
      title: "Starts at",
      dataIndex: "start_time",
      sorter: true,
      onHeaderCell: (_column) => ({
        onClick: () => pagination.onToggleOrderBy("start_time"),
      }),
      render: (value: string) => {
        return moment(value).format("DD/MM/yyyy HH:mm:ss");
      },
    },
    {
      title: "Length",
      dataIndex: "length",
      sorter: true,
      onHeaderCell: (_column) => ({
        onClick: () => pagination.onToggleOrderBy("length"),
      }),
      render: (value: number) => {
        return `${value}min`;
      },
    },
    {
      title: "Speakers",
      render: (_value, record) => (
        record
          .speakers
          ?.map<React.ReactNode>((speaker) => <Link to={`/app/user-profile/${speaker.id}`} target="_blank">{[speaker.first_name, speaker.last_name].join(" ")}</Link>)
          .reduce((prev, curr) => [prev, ", ", curr])
      ),
    },
    {
      key: "actions",
      width: 116,
      render: (_value, record) => (
        <Button.Group>
          <Link
            to={`/admin/schedule/talks/${record.id}/edit`}
            title="Edit"
            className="ant-btn ant-btn-link ant-btn-sm"
          >
            <Edit size={20} />
          </Link>

          <Link
            to={`/admin/schedule/talks/${record.id}`}
            title="View"
            className="ant-btn ant-btn-link ant-btn-sm"
          >
            <Eye size={20} />
          </Link>

          <Popconfirm
            title="Are you certain that you want to delete this Talk?"
            onConfirm={() => onDeleteTalk(record.id)}
            okText="Confirm"
            cancelText="Cancel"
            placement="topRight"
          >
            <Button type="link" size="small" title="Delete" danger={true}>
              <Trash2 size={20} />
            </Button>
          </Popconfirm>
        </Button.Group>
      ),
    },
  ];

  return (
    <Card>
      <Row className="admin-page-header">
        <Col className="left-column">
          <h1 className="title">Talks</h1>
          <div className="elements">
            <Input
              placeholder="search"
              prefix={<Search size={20} color="#A3A7B2" />}
              value={pagination.filter.search}
              onChange={updateSearch}
            />
          </div>
        </Col>

        <Col>
          <Button type="primary" onClick={() => history.push("/admin/schedule/talks/new")}>Create Talk</Button>
        </Col>
      </Row>

      <Table
        size="middle"
        loading={pagination.async.isPending}
        bordered={true}
        rowKey="id"
        columns={columns}
        dataSource={pagination.async.value?.data}
        pagination={{
          size: "default",
          total: pagination.totalItems,
          current: pagination.page,
          pageSize: pagination.pageSize,
          onChange: (page: number, pageSize?: number) => {
            pagination.onSetPage(page);
            pagination.onSetPageSize(pageSize || 20);
            history.push(parseQueryPath(
              "/admin/schedule/talks",
              "page",
              page.toString(),
              "search",
              pagination.filter.search,
            ));
            window.scroll({ top: 0 });
          },
        }}
      />
    </Card>
  );
};
