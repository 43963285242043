import { Row } from "antd";
import { TicketsTable } from "./tickets.table";
import { useContext, useEffect, useState } from "react";
import { useOnError } from "hooks/useOnError";
import { DiContext, useAsync } from "app/common";
import { TicketTypeEntity } from "app/infra/ticketType";

export const TicketTypesSection = () => {
  const [page, setPage] = useState(1);

  const {
    entityServices: { TicketTypeService },
  } = useContext(DiContext);

  const { execute, pending, value, error } = useAsync<{
    data: TicketTypeEntity[];
    pagination: any;
  }>(TicketTypeService.all);

  useOnError(error);

  useEffect(() => {
    execute(page);
  }, [page]);

  return (
    <Row>
      <TicketsTable
        ticketTypes={value?.data || []}
        pagination={value?.pagination}
        pending={pending}
        refreshPage={() => execute(page)}
        changePage={setPage}
      />
    </Row>
  );
};
