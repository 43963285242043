import React, { Fragment, useEffect, useMemo, useState } from "react";
import { TicketTypeEntity } from "app/infra/ticketType";
import { Button } from "antd";
import { Edit } from "react-feather";
import { EditTicketTypeModal } from "./editTicketType.modal";
import { useModal } from "hooks/useModal";
import { Table } from "components/Table";

interface TicketsTableProps {
  ticketTypes: TicketTypeEntity[];
  refreshPage: () => void;
  pending: boolean;
  pagination: any;
  changePage: (page: number) => void;
}

export const TicketsTable = (props: TicketsTableProps) => {
  const editTicketTypesModal = useModal();
  const [
    selectedTicketType,
    setSelectedTicketType,
  ] = useState<TicketTypeEntity | null>(null);

  const columns = useMemo(() => {
    return [
      { title: "ID", dataIndex: "id" },
      { title: "Name", dataIndex: "name" },
      { title: "Type", dataIndex: "type" },
      {
        key: "actions",
        width: 40,
        render: (ticketTypes: TicketTypeEntity) => (
          <Button
            type="link"
            size="small"
            title="Edit"
            onClick={() => { setSelectedTicketType(ticketTypes); }}
          >
            <Edit size={20} />
          </Button>
        ),
      },
    ];
  }, []);

  useEffect(() => {
    if (selectedTicketType) {
      editTicketTypesModal.open();
    } else {
      editTicketTypesModal.shut();
    }
  }, [selectedTicketType]);

  return (
    <Fragment>
      <h1 className="title">Tickets</h1>
      {selectedTicketType && (
        <EditTicketTypeModal
          modal={{
            ...editTicketTypesModal,
            shut: () => setSelectedTicketType(null),
          }}
          ticketType={selectedTicketType}
          onSuccess={props.refreshPage}
        />
      )}
      <Table
        size="middle"
        style={{ width: "100%" }}
        loading={props.pending}
        bordered={true}
        rowKey="id"
        columns={columns}
        dataSource={props.ticketTypes}
        pagination={{
          size: "default",
          current: props.pagination?.page,
          total: props.pagination?.total,
          pageSize: 100,
          onChange: (page: number) => {
            props.changePage(page);
          },
          showSizeChanger: false,
        }}
      />
    </Fragment>
  );
};
