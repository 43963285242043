import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { app } from "config";

import { useAsync, useOnError } from "hooks";
import { AuthService } from "services/AuthService";

import { Button, Form, Input, notification } from "antd";
import { ChevronLeft, ChevronRight, Mail } from "react-feather";
import logo from "assets/images/adw/logo/logo-dark.svg";

export const SessionForm = () => {
  const { execute, value, isPending, error } = useAsync(AuthService().passwordless.requests.create);
  useOnError(error);

  const [successMessage, setSuccessMessage] = useState(false);

  useEffect(() => {
    if (value && value.ok) {
      notification.success({
        message: "Please check your email to login. Make sure to check your spam folder.",
      });

      setSuccessMessage(true);
    }
  }, [value]);

  return (
    <div className="auth-wrapper">
      <div className="auth-wrapper-form-container">
        <Form
          layout="vertical"
          onFinish={execute}
          className="auth-form"
        >
          <div className="logo">
            <img src={logo} alt={`${app.name} Logo`} />
            <h1 className="title">March 2023</h1>
          </div>

          {successMessage && (
            <div className="ant-row ant-form-item">
              <strong>Your login email is on the way!</strong>
              It can take a moment to arrive, please check your spam folder,
              before contacting support for additional assistance.
            </div>
          )}

          <Form.Item
            name="email"
            rules={[
              {
                type: "email",
                message: "Please enter a valid email!",
              },
              {
                required: true,
                message: "Please enter your email!",
              },
            ]}
          >
            <Input
              type="email"
              placeholder="Enter email"
              prefix={<Mail size={20} />}
            />
          </Form.Item>

          <Form.Item>
            <Button htmlType="submit" type="primary" block={true} loading={isPending}>
              Get login link
            </Button>
          </Form.Item>

          <Form.Item style={{ textAlign: "center" }}>
            <Link to="/auth/password-login">
              <Button type="default" block={true}>
                Login with email & password
              </Button>
            </Link>
          </Form.Item>

          <div style={{ textAlign: "center", marginTop: 20 }}>
            <Link to="/auth/register" className="didnt-receive-login">
              Didn't receive your login info?
            </Link>
          </div>
        </Form>
      </div>

      <div style={{ maxWidth: "360px", width: "100%", marginTop: "-20px", marginBottom: "60px", zIndex: 1 }}>
        <Button type="default" block={true} style={{ boxShadow: "0px 30px 50px rgba(19, 23, 76, 0.5)" }}>
          <a href="https://oct22.adworld.online">
            <ChevronRight style={{ verticalAlign: "middle", float: "left" }} />
            Ad World October 2022
            <ChevronLeft style={{ verticalAlign: "middle", float: "right" }} />
          </a>
        </Button>
      </div>

      <div
        style={{
          background: "rgba(0, 0, 0, 0.1)",
          boxShadow: "0 20px 20px 0 rgba(0, 0, 0, 0.1), 0 20px 20px 0 rgba(0, 0, 0, 0.1)",
          maxWidth: "420px",
          width: "100%",
          marginTop: "-20px",
          marginBottom: "60px",
          zIndex: 1,
          padding: "20px 10px",
        }}
      >
        <div className="replay-pass-container-expired">
          <h3>Ad World May 2022 Replay Pass expired.</h3>
          <p>Purchase Ad World Pro membership to<br /> access all replays and new masterclasses.</p>
          <Button type="primary" style={{ boxShadow: "0px 30px 50px rgba(19, 23, 76, 0.5)" }}>
            <a href="https://adworldconference.com/">
              Subscribe to Ad World Pro
            </a>
          </Button>
        </div>
      </div>
    </div>
  );
};
