import React from "react";
import { AppMode, mode } from "config/initializers";

import moment from "moment-timezone";

import { StageList } from "components/Stage/StageList";
import { AllTalksPage } from "./allTalks.page";
import { Info } from "react-feather";

export const StagePage = () => {
  return (
    <>
      {mode === AppMode.live ? (
        <div className="stage-page">
          <div className="stage-page-timezone-info">
            <Info size={18} style={{ marginBottom: 3.5 }} />
            The times shown are in your device's timezone ({moment.tz.guess()}).
          </div>

          <div className="wrapper">
            <StageList />
          </div>
        </div>
      ) : (
        <div className="wrapper">
          <AllTalksPage />
        </div>
      )}
    </>
  );
};
