import React, { useState } from "react";

import { app } from "config";
import { EPubSubTable, Tracking } from "app/common";
import { useAsync, useOnError } from "hooks";

import { UserEntity } from "app/infra/user";
import { PerkEntity } from "app/infra/perk";

import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { Checkbox, Form, Modal } from "antd";

interface Props {
  user: UserEntity;
  perk: PerkEntity;
  onOk: () => Promise<PerkEntity>;
  onCancel: () => void;
  isVisible: boolean;
}

export const Consent = (props: Props) => {
  const [form] = Form.useForm();

  const [isChecked, setIsChecked] = useState(false);

  const { execute, isPending, error } = useAsync(() => {
    return props.onOk().then((value: PerkEntity) => {
      if (value.link) {
        const win = window.open(value.link, "_blank");
        win?.focus();
      }

      Tracking.PubSub([{
        table: EPubSubTable.Perk,
        data: {
          datetime: Tracking.Date,
          user_id: props.user.old_id || props.user.id,
          perk: props.perk.title,
          company: props.perk.company?.name || "",
        },
      }]);
    });
  });
  useOnError(error);

  const onCheckBoxChange = (event: CheckboxChangeEvent) => {
    setIsChecked(event.target.checked);
  };

  return (
    <Modal
      title="Claim Perk"
      visible={props.isVisible}
      okButtonProps={{
        loading: isPending,
        disabled: !isChecked,
      }}
      onOk={form.submit}
      onCancel={props.onCancel}
      className="chat-agreement-modal ghost-modal"
    >
      <p>
        In exchange for this Perk, I give permission for {app.name} to share my
        profile information with the company associated with
        this perk and for the company to contact me.
      </p>

      <Form form={form} onFinish={execute}>
        <Form.Item
          label="I agree"
          name="agreement"
          initialValue={isChecked}
          rules={[
            {
              required: true,
              message: "",
            },
          ]}
        >
          <Checkbox onChange={onCheckBoxChange} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
