import React from "react";
import { app } from "config";

import { AppMode, mode } from "config/initializers";

import { Button, Card } from "antd";

export const Badge = () => {
  if (mode !== AppMode.finished) return null;

  return (
    <Card
      style={{ marginTop: "20px" }}
      bodyStyle={{ display: "flex", flexWrap: "wrap", alignItems: "center", alignContent: "center", width: "100%" }}
    >
      <h3 style={{ marginTop: "auto" }}>{app.name} Conference Badge</h3>

      <a href="/ADW6-Attendee-Badge.png" download={`${app.name} Attendee Badge.png`} style={{ marginLeft: "auto" }}>
        <Button
          style={{
            background: "rgb(240, 30, 106)",
            color: "#FFFFFF",
            border: "none",
            width: "230px",
            height: "45px",
            fontSize: "16px",
          }}
        >
          Download Badge
        </Button>
      </a>
    </Card>
  );
};
