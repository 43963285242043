import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";

import { DiContext, usePaginationAsync } from "app/common";
import { useAsync, useOnError, useOnMount } from "hooks";

import { TrackEntity, trackService } from "app/infra/track";
import { Sponsorship } from "app/infra/sponsorships";

import Table, { ColumnsType } from "antd/lib/table";
import { Button, Card, Col, Input, notification, Popconfirm, Row } from "antd";

import { parseQueryPath } from "app/common/utils/Path";
import { parse } from "qs";
import { Edit, Search, Trash2 } from "react-feather";

interface TrackListParams {
  page?: number;
  search?: string;
}

export const TrackList = () => {
  const { apiService, dispatch } = useContext(DiContext);
  const trackSrv = trackService({ apiService, dispatch });

  const history = useHistory();
  const queryParams = parse(history.location.search, { ignoreQueryPrefix: true }) as TrackListParams;

  const pagination = usePaginationAsync(
    (page, pageSize, search, sortBy, sortDirection) => {
      return trackSrv.getAllPaginated({
        params: {
          count: pageSize,
          page,
          search: search || undefined,
          sortBy: sortBy || undefined,
          sortDirection: sortDirection || undefined,
        },
      });
    },
    {
      initialFilter: { search: queryParams.search || "" },
      initialPage: queryParams.page,
    },
  );

  const { execute: onDelete, error: errorDelete } = useAsync((id: string) => {
    return trackSrv.delete(id).then((response) => {
      notification.success({ message: "Track was successfully deleted" });
      pagination.async.execute();
      return response;
    });
  });
  useOnError(errorDelete);

  useOnMount(pagination.async.execute);

  const updateSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    history.push(parseQueryPath(
      "/admin/schedule/tracks",
      "page",
      "1",
      "search",
      event.currentTarget.value,
    ));
    pagination.onSetFilter({ search: event.currentTarget.value });
  };

  const columns: ColumnsType<TrackEntity> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
      onHeaderCell: () => ({
        onClick: () => pagination.onToggleOrderBy("name"),
      }),
    },
    {
      title: "Color",
      dataIndex: "color",
      key: "color",
    },
    {
      title: "Sponsor",
      dataIndex: "sponsorship",
      sorter: true,
      onHeaderCell: () => ({
        onClick: () => pagination.onToggleOrderBy("sponsorship.title"),
      }),
      render: (value: Sponsorship) => {
        if (!value) return null;
        return (
          <span>{value.title}</span>
        );
      },
    },
    {
      key: "actions",
      width: 84,
      render: (_value, record) => (
        <Button.Group>
          <Link
            to={`/admin/schedule/tracks/${record.id}/edit`}
            title="Edit"
            className="ant-btn ant-btn-link ant-btn-sm"
          >
            <Edit size={20} />
          </Link>

          <Popconfirm
            title="Are you certain that you want to delete this Track?"
            onConfirm={() => onDelete(record.id)}
            okText="Confirm"
            cancelText="Cancel"
            placement="topRight"
          >
            <Button type="link" size="small" title="Delete" danger={true}>
              <Trash2 size={20} />
            </Button>
          </Popconfirm>
        </Button.Group>
      ),
    },
  ];

  return (
    <Card>
      <Row className="admin-page-header">
        <Col className="left-column">
          <h1 className="title">Tracks</h1>
          <div className="elements">
            <Input
              placeholder="search"
              prefix={<Search size={20} color="#A3A7B2" />}
              value={pagination.filter.search}
              onChange={updateSearch}
            />
          </div>
        </Col>

        <Col>
          <Button type="primary" onClick={() => history.push("/admin/schedule/tracks/new")} className="create-track">
            Create Track
          </Button>
        </Col>
      </Row>

      <Table
        size="middle"
        loading={pagination.async.isPending}
        bordered={true}
        rowKey="id"
        columns={columns}
        dataSource={pagination.async.value?.data}
        pagination={{
          size: "default",
          current: pagination.page,
          total: pagination.totalItems,
          pageSize: pagination.pageSize,
          onChange: (page: number, pageSize?: number) => {
            pagination.onSetPage(page);
            if (pageSize) {
              pagination.onSetPageSize(pageSize);
            }
            history.push(parseQueryPath(
              "/admin/schedule/tracks",
              "page",
              pagination.page.toString(),
              "search",
              pagination.filter.search,
            ));
            window.scrollTo({ top: 0 });
          },
        }}
      />
    </Card>
  );
};
