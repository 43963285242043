import { useAsync, useDebounce, useOnError } from "hooks";
import { useEffect, useState } from "react";
import type { PaginationResponse } from "./PaginationResponse";

interface UsePaginationProps {
  initialPageSize?: number;
  pagination?: {
    page: number;
    total: number;
  };
}

export interface FilterStructure {
  [key: string]: string;
}

export enum OrderEnum {
  DESC = "DESC",
  ASC = "ASC",
  NONE = "",
}

export const usePagination = ({
  initialPageSize = 20,
  pagination = { page: 0, total: 0 },
}: UsePaginationProps) => {
  const [filter, setFilter] = useState<FilterStructure>({});
  const [orderedBy, setOrderedBy] = useState("");
  const [order, setOrder] = useState<OrderEnum>(OrderEnum.NONE);

  const [totalItems, setTotalItems] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize] = useState(initialPageSize);

  const toggleOrderBy = (key: string) => {
    setOrderedBy((prevOrderedBy: string) => {
      if (prevOrderedBy !== key) {
        setOrder((prevOrder: string) => {
          return OrderEnum.ASC;
        });
      } else {
        setOrder((prevOrder: string) => {
          switch (prevOrder) {
            case OrderEnum.ASC:
              return OrderEnum.DESC;
            case OrderEnum.DESC:
              return OrderEnum.NONE;
            case OrderEnum.NONE:
            default:
              return OrderEnum.ASC;
          }
        });
      }

      return key;
    });
  };

  useEffect(() => {
    if (pagination) {
      setTotalItems(pagination.total);
      setPage(pagination.page);
    }
  }, [pagination]);

  return {
    page,
    setPage,
    pageSize,
    totalItems,
    setTotalItems,
    filter,
    setFilter,
    order,
    orderedBy,
    toggleOrderBy,
  };
};

interface PaginationProps {
  initialPageSize?: number;
  initialPage?: number;
  initialFilter?: FilterStructure;
}

export const usePaginationAsync = <T>(
  call: (
    page: number,
    pageSize: number,
    search: string,
    sortBy: string,
    sortDirection: OrderEnum
  ) => Promise<PaginationResponse<T>>,
  params?: PaginationProps,
) => {
  const [page, setPage] = useState(params?.initialPage || 1);
  const [pageSize, setPageSize] = useState(params?.initialPageSize || 20);
  const [totalItems, setTotalItems] = useState(0);

  const [filter, setFilter] = useState<FilterStructure>(params?.initialFilter || {});

  const [orderedBy, setOrderedBy] = useState("");
  const [order, setOrder] = useState<OrderEnum>(OrderEnum.NONE);

  const update = useAsync((_page?: number) => {
    return call(_page ? _page - 1 : page - 1, pageSize, filter.search, orderedBy, order).then((response) => {
      setTotalItems(response.pagination.total);
      return response;
    });
  });
  useOnError(update.error);

  const onToggleOrderBy = (key: string) => {
    if (orderedBy !== key) {
      setOrder(OrderEnum.ASC);
      setOrderedBy(key);
    } else {
      switch (order) {
        case OrderEnum.ASC:
          return setOrder(OrderEnum.DESC);
        case OrderEnum.DESC:
          setOrderedBy("");
          return setOrder(OrderEnum.NONE);
        case OrderEnum.NONE:
        default:
          return setOrder(OrderEnum.ASC);
      }
    }
    return key;
  };

  useEffect(() => {
    update.execute();
  }, [pageSize, order, orderedBy]);

  const onSetPage = (_page: number) => {
    setPage(_page);

    // Added _page variable to execute function so that page update can:
    // 1- be divided from useEffect main function
    // 2- execute function is not called when the update of the filter happens wich needs page to go again to 1
    update.execute(_page);
  };

  const onSetPageSize = (_pageSize: number) => {
    setPageSize(_pageSize);
  };

  const onSetFilter = (_filter: FilterStructure) => {
    setPage(1);
    setFilter(_filter);
  };

  const debouncedFilter = useDebounce(filter, 500);
  useEffect(() => {
    update.execute();
  }, [debouncedFilter]);

  return {
    async: update,
    page,
    onSetPage,
    totalItems,
    pageSize,
    onSetPageSize,
    filter,
    onSetFilter,
    onToggleOrderBy,
  };
};
