import React from "react";
import { isMobile } from "react-device-detect";

import { Maximize, Minimize } from "react-feather";

interface FullScreenIconsProps {
  isFullScreen: boolean;
  setFullScreen: (val: boolean) => void;
}

export const ExitFullScreenIcon = ({
  isFullScreen,
  setFullScreen,
}: FullScreenIconsProps) => {
  return (
    <Minimize
      style={{
        position: "absolute",
        bottom: isMobile ? 30 : 12,
        right: isMobile ? 30 : 12,
      }}
      className={`fullscreen-control ${isFullScreen && "show"}`}
      onClick={() => {
        setFullScreen(false);
      }}
    />
  );
};

export const EnterFullScreenIcon = ({
  isFullScreen,
  setFullScreen,
}: FullScreenIconsProps) => (
  <Maximize
    style={{
      position: "absolute",
      bottom: 12,
      right: 12,
    }}
    className={`fullscreen-control ${!isFullScreen && "show"}`}
    onClick={() => {
      setFullScreen(true);
    }}
  />
);
