import React from "react";

import { RaffleEntity } from "app/infra/raffle";

interface Props {
  raffle: RaffleEntity;
  onClickRaffle: (raffle: RaffleEntity) => void;
}

const Raffle = (props: Props) => {
  const raffleImg = (
    <img
      src={props.raffle.imageURL}
      alt="Raffle Ticket"
      data-label="Raffle Ticket"
      data-action="Raffle"
      data-awtrack="Click"
      style={{
        width: "100%",
        height: "100%",
      }}
    />
  );

  return (
    <div
      role="none"
      key={props.raffle.id}
      className={`ticket-section-wrapper ${(!props.raffle.isClaimed && "clickable") || ""}`}
      onClick={() => props.onClickRaffle(props.raffle)}
    >
      <div id="daily-raffle">
        <h3>
          {props.raffle.name}
        </h3>

        <div className="daily-raffle-item">
          <div className="lottery-item" key={props.raffle.id}>
            {
              props.raffle.isClaimed ? (
                <a href="https://adworldconference.com/pro" target="_blank" rel="noopener noreferrer">
                  {raffleImg}
                </a>
              ) : raffleImg
            }
          </div>

          {!props.raffle.isClaimed ? (
            <div className="daily-raffle-item-details">
              <button type="button">
                <span>Enter Raffle Now</span>
              </button>
            </div>
          ) : (
            <h4 style={{ marginTop: "5px" }}><small>(Already Entered)</small></h4>
          )}
        </div>
      </div>
    </div>
  );
};

export default Raffle;
