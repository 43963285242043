import React, { useContext, useEffect, useMemo } from "react";
import { Link, useHistory } from "react-router-dom";

import { connect } from "react-redux";
import { RootState } from "services";

import { uri } from "helpers/uri";

import { DiContext, LoadingPage } from "app/common";

import { useAsync } from "hooks/useAsync";
import { useModal } from "hooks/useModal";
import { useOnError } from "hooks/useOnError";
import { useOnMount } from "hooks/useOnMount";

import { UserConnectionService, UserEntity, userService } from "app/infra/user";
import { PerkEntity, perkService } from "app/infra/perk";

import { ClaimedPerks } from "app/presentation/dashboard/lobby/claimedPerks";

import { PerkButton } from "components/Perk/PerkButton";

import { Button, Card, Col, Row, Tabs, Tag } from "antd";
import { Check, DownloadCloud, Edit, MapPin, MessageCircle } from "react-feather";

import Icons from "components/Icons";
import { Table } from "components/Table";

import userBackgroundOverlay from "assets/images/user_profile_background.svg";
import speakerBackgroundOverlay from "assets/images/speaker-profile-background.svg";

import { CompanyShortList } from "components/Company/CompanyShortList";
import { preventCopy } from "helpers";
import UserShortList from "./UserShortList";
import { UserAvatar } from "./UserAvatar";

import * as Section from "./Section";
import * as Modal from "./Modal";
import * as CompanyModal from "../Company/Modal";

const { TabPane } = Tabs;

interface OwnProps {
  userId: string;
}

interface UserProps extends OwnProps {
  user: UserEntity;
  currentUser: UserEntity;
  perks: PerkEntity[];
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
  return {
    ...ownProps,

    user: state.userStore.byId[ownProps.userId],
    currentUser: state.userStore.byId.me,
    perks: state.perkStore.keyIds["profile-claimed-perks"]
      .map((key) => state.perkStore.byId[key])
      .sort((a, b) => (b.rank !== undefined ? b.rank : Infinity) - (a.rank !== undefined ? a.rank : Infinity)),
  };
};

export const User = connect(mapStateToProps)((props: UserProps) => {
  const { apiService, dispatch } = useContext(DiContext);
  const userSrv = userService({ apiService, dispatch });
  const perkSrv = perkService({ apiService, dispatch });
  const history = useHistory();

  const isCurrentUser = props.userId === "me";

  const { execute, isPending, error } = useAsync(() => {
    if (isCurrentUser) {
      return Promise.all([userSrv.me(), perkSrv.getAll()]).then((response) => {
        return perkSrv.getClaimedByUser(response[0].old_id);
      });
    }

    return Promise.all([userSrv.me(), userSrv.get(props.userId)]);
  });

  useOnMount(execute);
  useOnError(error);

  const profileWelcomeModal = useModal();
  const companyWelcomeModal = useModal();
  const companyDataExportModal = useModal();

  useEffect(() => {
    if (isCurrentUser && props.user) {
      if (!props.user.isComplete) {
        profileWelcomeModal.open();
      } else if (props.user.company && !props.user.company.isComplete) {
        companyWelcomeModal.open();
      }
    }
  }, [props.user]);

  const connectionSrv = UserConnectionService();

  const {
    execute: createConnection,
    isPending: isCreateConnectionPending,
    error: createConnectionError,
  } = useAsync(() => {
    return connectionSrv
      .create(props.user.id)
      .then((response) => {
        if (response.isAccepted) {
          history.push(`/app/chats/${response.chatRoomId}`);
        }

        return response;
      });
  });

  useOnError(createConnectionError);

  const company = useMemo(() => {
    if (props.user?.company) {
      return (
        <Link
          to={`/app/booth/${props.user.company.id}`}
          style={{ color: "inherit" }}
        >
          <span style={{ fontWeight: "bold", marginRight: "5px" }}>@</span>
          <span style={{ textDecoration: "underline" }}>
            {props.user.company.name}
          </span>
        </Link>
      );
    }

    return (
      <span>
        <span style={{ fontWeight: "bold", marginRight: "5px" }}>@</span>
        {props.user?.attendee_company}
      </span>
    );
  }, [props.user?.company, props.user?.attendee_company]);

  const location = useMemo(() => {
    return [props.user?.city, props.user?.country].filter(Boolean).join("/");
  }, [props.user?.country, props.user?.city]);

  const website = useMemo(() => {
    const url = uri.parse(props.user?.website);
    return typeof url === "string" ? url : url.hostname;
  }, [props.user?.website]);

  const questions = useMemo(() => {
    return [
      {
        question: "Currently working on: ",
        answer: props.user?.questionWorkingOn,
      },
      {
        question: "Looking to meet: ",
        answer: props.user?.questionLookingToMeet,
      },
      {
        question: "Biggest challenge: ",
        answer: props.user?.questionBiggestChallenge,
      },
    ].filter((pair) => pair.answer);
  }, [
    props.user?.questionWorkingOn,
    props.user?.questionLookingToMeet,
    props.user?.questionBiggestChallenge,
  ]);

  if (isPending || props.user === undefined) return <LoadingPage />;

  return (
    <div className="wrapper user-profile">
      {isCurrentUser && props.user.email !== "replays@adworld.online" && (
        <div style={{ marginTop: "40px", marginBottom: "-20px" }}>
          <Link
            to={`/app/user-profile/${props.user.old_id || props.user.id}/edit`}
          >
            <Button
              icon={<Edit size={18} style={{ verticalAlign: "middle", marginRight: 10 }} />}
              style={{
                marginRight: "20px",
                padding: "10px 25px",
                height: "auto",
                fontSize: "16px",
                boxShadow: "none",
                border: "none",
                marginBottom: "10px",
              }}
            >
              <span style={{ verticalAlign: "middle" }}>Edit Profile</span>
            </Button>
          </Link>

          {props.user.company && (
            <Button
              icon={<DownloadCloud size={18} style={{ verticalAlign: "middle", marginRight: 10 }} />}
              style={{
                marginRight: "20px",
                padding: "10px 25px",
                height: "auto",
                fontSize: "16px",
                boxShadow: "none",
                border: "none",
              }}
              onClick={companyDataExportModal.open}
            >
              <span style={{ verticalAlign: "middle" }}>
                Export Company Data
              </span>
            </Button>
          )}
        </div>
      )}

      <Row gutter={20} style={{ marginTop: "40px" }}>
        <Col xs={24} xl={isCurrentUser ? 24 : 16}>
          <Card
            style={{
              boxShadow: "0px 5px 16px rgba(42, 24, 60, 0.1)",
              borderRadius: "10px",
              marginBottom: "20px",
            }}
            bodyStyle={{ padding: 0 }}
          >
            {props.user.roles && props.user.roles.includes("ROLE_SPEAKER") ? (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    backgroundImage: `url(${speakerBackgroundOverlay})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "100% 0",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                    padding: "25px 20px",
                    flexWrap: "wrap",
                    gap: "10px",
                  }}
                  className="speaker-profile-banner"
                >
                  <img
                    src={speakerBackgroundOverlay}
                    alt=""
                    style={{
                      position: "absolute",
                      right: "0px",
                      bottom: "0px",
                    }}
                  />

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                      gap: "10px",
                    }}
                  >
                    <UserAvatar user={props.user} size={110} />

                    <div
                      style={{
                        verticalAlign: "middle",
                        height: "110px",
                        flex: "1 1",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <h2
                        style={{
                          fontSize: "24px",
                          color: "#262512",
                          marginBottom: "0px",
                        }}
                        onCopy={preventCopy}
                      >
                        <span style={{ verticalAlign: "middle" }}>
                          {props.user.first_name} {props.user.last_name}
                        </span>

                        <Check
                          size={20}
                          strokeWidth={4}
                          style={{
                            padding: 4,
                            background: "#0CC2FF",
                            borderRadius: "50%",
                            marginLeft: 5,
                            verticalAlign: "middle",
                          }}
                        />
                      </h2>

                      <div style={{ fontSize: "16px" }}>
                        {props.user.job_position} {company}
                        {location && (
                          <span style={{ marginLeft: "20px" }}>
                            <MapPin size={18} style={{ verticalAlign: "middle" }} />
                            <span
                              style={{
                                verticalAlign: "middle",
                                marginLeft: "5px",
                              }}
                            >
                              {location}
                            </span>
                          </span>
                        )}
                      </div>

                      <div className="speaker-profile-social-icons">
                        {props.user.linkedin && (
                          <a
                            href={props.user.linkedin}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ padding: "2.5px" }}
                          >
                            <Icons.LinkedIn />
                          </a>
                        )}

                        {props.user.twitter && (
                          <a
                            href={props.user.twitter}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ padding: "2.5px" }}
                          >
                            <Icons.Twitter />
                          </a>
                        )}

                        {props.user.facebook && (
                          <a
                            href={props.user.facebook}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ padding: "2.5px" }}
                          >
                            <Icons.FacebookSimple />
                          </a>
                        )}
                      </div>
                    </div>
                  </div>

                  <div style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 10,
                  }}
                  >
                    <Tag
                      style={{
                        color: "#4B4C53",
                        padding: "8px 40px",
                        display: "block",
                        float: "right",
                        background: "#FFFFFF",
                        fontSize: "16px",
                        fontWeight: "bold",
                        width: "100%",
                      }}
                    >
                      Speaker
                    </Tag>

                    {!isCurrentUser && props.currentUser.hasChatAccess && (
                      <Button
                        block={true}
                        style={{
                          borderRadius: "10rem",
                          height: "40px",
                          display: "block",
                          borderColor: "#fff",
                        }}
                        className="profile-chat-now-button"
                        icon={<MessageCircle size={18} style={{ verticalAlign: "middle", marginRight: 5 }} />}
                        disabled={isCreateConnectionPending}
                        onClick={createConnection}
                      >
                        <span style={{ verticalAlign: "middle" }}>
                          Chat now
                        </span>
                      </Button>
                    )}
                  </div>
                </div>

                <div style={{ marginTop: "20px" }} />
                <div style={{ padding: "0 24px 20px" }}>
                  {(props.user.bio || questions.length > 0) && (
                    <>
                      <h2
                        style={{ fontSize: "24px", color: "#4B4C53" }}
                        onCopy={preventCopy}
                      >
                        Bio
                      </h2>

                      {questions.length > 0 && (
                        <div
                          className="user-answers"
                          style={{ marginBottom: "10px" }}
                        >
                          {questions.map((pair) => (
                            <p
                              className="answer-item"
                              onCopy={preventCopy}
                            >
                              <span className="question-text">
                                {pair.question}
                              </span>
                              {pair.answer}
                            </p>
                          ))}
                        </div>
                      )}

                      {props.user.bio && (
                        <p
                          style={{ color: "#A3A7B3" }}
                          onCopy={preventCopy}
                        >
                          {props.user.bio}
                        </p>
                      )}
                    </>
                  )}

                  {props.user.interested.length > 0 && (
                    <>
                      <h2
                        style={{ fontSize: "24px", color: "#4B4C53" }}
                        onCopy={preventCopy}
                      >
                        Interested in
                      </h2>

                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          marginBottom: "5px",
                        }}
                      >
                        {props.user.interested.map((interest) => (
                          <Tag>
                            {interest}
                          </Tag>
                        ))}
                      </div>
                    </>
                  )}
                </div>
              </>
            ) : (
              <div style={{ display: "flex", padding: 24 }} className="attendee-profile">
                <div className="card-wrapper">
                  <Card
                    style={{
                      boxShadow: "0px 5px 16px rgba(42, 24, 60, 0.1)",
                      borderRadius: "10px",
                      textAlign: "center",
                      fontSize: "18px",
                      maxWidth: "435px",
                      marginRight: "auto",
                      marginLeft: "auto",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "0px",
                        left: "0px",
                        right: "0px",
                        height: "105px",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}
                      className="attendee-profile-banner"
                    >
                      <div style={{ overflow: "hidden" }}>
                        <img
                          src={userBackgroundOverlay}
                          alt=""
                          style={{ display: "block" }}
                        />
                      </div>

                      <UserAvatar
                        user={props.user}
                        size={110}
                        style={{ marginTop: "-55px" }}
                      />
                    </div>

                    <h2
                      style={{
                        marginTop: "140px",
                        marginBottom: "0px",
                        fontSize: "24px",
                      }}
                      onCopy={preventCopy}
                    >
                      <span
                        style={{
                          position: "relative",
                          display: "inline-block",
                          padding: "0px 10px",
                        }}
                      >
                        {props.user.first_name} {props.user.last_name}
                        {props.user.isPromoted && (
                          <Check
                            size={18}
                            color="white"
                            strokeWidth={4}
                            style={{
                              padding: 4,
                              background: "#0CC2FF",
                              borderRadius: "50%",
                              marginLeft: 5,
                            }}
                          />
                        )}
                      </span>
                    </h2>

                    <div style={{ color: "#8C8F98", fontWeight: 600 }}>
                      {props.user.job_position} {company}
                    </div>

                    {(props.user.attendee_company_type || location) && (
                      <div
                        style={{
                          marginTop: "5px",
                          marginBottom: "20px",
                          color: "#C4C4C4",
                          fontSize: "16px",
                          textTransform: "capitalize",
                        }}
                      >
                        {[props.user.attendee_company_type, location].join(" | ")}
                      </div>
                    )}

                    {props.user.lookingForWork && (
                      <Tag
                        style={{
                          marginBottom: "15px",
                        }}
                      >
                        Looking for work
                      </Tag>
                    )}

                    <div style={{ display: "flex", justifyContent: "center" }}>
                      {props.user.linkedin && (
                        <a
                          href={props.user.linkedin}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ padding: "2.5px" }}
                        >
                          <Icons.LinkedIn primaryColor="#000000" />
                        </a>
                      )}

                      {props.user.twitter && (
                        <a
                          href={props.user.twitter}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ padding: "2.5px" }}
                        >
                          <Icons.Twitter primaryColor="#000000" />
                        </a>
                      )}

                      {props.user.facebook && (
                        <a
                          href={props.user.facebook}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ padding: "2.5px" }}
                        >
                          <Icons.FacebookSimple primaryColor="#000000" />
                        </a>
                      )}
                    </div>

                    {props.user.website && (
                      <a
                        href={props.user.website}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "rgb(95, 188, 229)" }}
                      >
                        {website}
                      </a>
                    )}

                    {!isCurrentUser && props.currentUser.hasChatAccess && (
                      <Button
                        block={true}
                        style={{
                          borderRadius: "10px",
                          height: "60px",
                          margin: "20px 30px 0px 0px",
                        }}
                        className="profile-chat-now-button"
                        icon={<MessageCircle size={18} style={{ verticalAlign: "middle", marginRight: "5px" }} />}
                        disabled={isCreateConnectionPending}
                        onClick={createConnection}
                      >
                        <span style={{ verticalAlign: "middle" }}>
                          Chat now
                        </span>
                      </Button>
                    )}
                  </Card>
                </div>

                <div style={{ padding: "0px 20px", overflow: "hidden" }}>
                  {props.user.interested.length > 0 && (
                    <>
                      <h2
                        style={{ fontSize: "24px", color: "#4B4C53" }}
                        onCopy={preventCopy}
                      >
                        Interested in
                      </h2>

                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          marginBottom: "15px",
                        }}
                      >
                        {props.user.interested.map((interest) => (
                          <Tag
                            style={{
                              marginBottom: "5px",
                            }}
                          >
                            {interest}
                          </Tag>
                        ))}
                      </div>
                    </>
                  )}

                  {(props.user.bio || questions.length > 0) && (
                    <>
                      <h2
                        style={{ fontSize: "24px", color: "#4B4C53" }}
                        onCopy={preventCopy}
                      >
                        Bio
                      </h2>

                      {questions.length > 0 && (
                        <div
                          className="user-answers"
                          style={{ marginBottom: "10px" }}
                        >
                          {questions.map((pair) => (
                            <p
                              className="answer-item"
                              onCopy={preventCopy}
                            >
                              <span className="question-text">
                                {pair.question}
                              </span>
                              {pair.answer}
                            </p>
                          ))}
                        </div>
                      )}

                      {props.user.bio && (
                        <p
                          style={{ color: "#A3A7B3" }}
                          onCopy={preventCopy}
                        >
                          {props.user.bio}
                        </p>
                      )}
                    </>
                  )}
                </div>
              </div>
            )}
          </Card>

          {isCurrentUser && (
            <>
              <Section.Certificate />
              <Section.Badge />

              <Modal.Welcome
                visible={profileWelcomeModal.isVisible}
                onClose={() => {
                  profileWelcomeModal.shut();
                  history.push(`/app/user-profile/${props.user.old_id || props.user.id}/edit`);
                }}
              />

              {props.user.company && (
                <>
                  <CompanyModal.Welcome
                    visible={companyWelcomeModal.isVisible}
                    onClose={() => {
                      companyWelcomeModal.shut();
                      history.push(`/app/company-profile/${props.user.company!.id}/edit`);
                    }}
                  />

                  <CompanyModal.DataExport
                    company={props.user.company}
                    visible={companyDataExportModal.isVisible}
                    onClose={companyDataExportModal.shut}
                  />
                </>
              )}

              {props.perks.length > 0 && (
                <>
                  <Card style={{ marginTop: "20px" }}>
                    <h3
                      onCopy={preventCopy}
                    >
                      Perks Claimed
                    </h3>

                    <Table
                      pagination={false}
                      columns={[
                        {
                          title: "Name",
                          dataIndex: "title",
                          key: "title",
                        },
                        {
                          title: "Details",
                          dataIndex: "description",
                        },
                        {
                          title: "",
                          key: "action",
                          render: (_text, perk) => <PerkButton perk={perk} />,
                        },
                      ]}
                      dataSource={props.perks.map((perk) => ({
                        key: perk.id,
                        ...perk,
                      }))}
                    />
                  </Card>
                </>
              )}
            </>
          )}
        </Col>

        {!isCurrentUser && (
          <Col xs={24} xl={8}>
            <Card
              style={{
                boxShadow: "0px 5px 16px rgba(42, 24, 60, 0.1)",
                borderRadius: "10px",
              }}
              bodyStyle={{ padding: "0px" }}
            >
              <Tabs defaultActiveKey="1" className="tabbed">
                <TabPane key="1" tab="Attendees" style={{ padding: "10px 30px" }}>
                  <>
                    <UserShortList />
                    <ClaimedPerks />
                  </>
                </TabPane>

                <TabPane key="2" tab="Companies">
                  <CompanyShortList />
                </TabPane>
              </Tabs>
            </Card>
          </Col>
        )}
      </Row>
    </div>
  );
});
