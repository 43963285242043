import React, { useEffect } from "react";
import { PlayCircle } from "react-feather";

interface NextTalkProps {
  text: string;
  onComplete: () => void;
}

export const LoadingNextTalk = ({ text, onComplete }: NextTalkProps) => {
  useEffect(() => {
    const timeoutRef = setTimeout(() => {
      onComplete();
    }, 10000);

    return () => clearTimeout(timeoutRef);
  }, [text]);

  return (
    <div
      className="video-overlay loading-next-talk"
      style={{
        backgroundColor: "black",
      }}
    >
      <div>
        <h5>NEXT TALK</h5>
        <h4>{text}</h4>
      </div>
      <div className="play-button" onClick={onComplete}>
        <div className={`play-icon play`}>
          <PlayCircle style={{ width: "100%", height: "100%" }} />
        </div>
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 42 42"
          className="donut-icon"
        >
          <circle
            className="donut-segment animate"
            cx="21"
            cy="21"
            r="15.91549430918954"
            fill="transparent"
            stroke="#ce4b99"
            strokeWidth="3"
            strokeDasharray="100 100"
            strokeDashoffset="0"
          />
        </svg>
      </div>
    </div>
  );
};
