import React from "react";
import { Form, Input, notification } from "antd";
import { ReplayStageEntity } from "app/infra/replayStage";
import { FormInstance } from "antd/lib/form";

interface ReplayStageFormProps {
  stage?: ReplayStageEntity<String>;
  form: FormInstance;
  onFinish: () => Promise<any>;
  loading: boolean;
}

export const ReplayStageForm = ({
  stage,
  form,
  loading,
  onFinish,
}: ReplayStageFormProps) => (
  <Form 
    form={form} 
    onFinish={onFinish} 
    onFinishFailed={ () => notification.error({ message: "Invalid Form", description: "Please fix all invalid fields to be able to save your changes."}) }
    layout="vertical">
    <Form.Item
      label="Name"
      name="name"
      rules={[{ required: true }]}
      initialValue={stage && stage.name}
    >
      <Input placeholder="Name" disabled={loading} />
    </Form.Item>
    <Form.Item
      label="Description"
      name="description"
      rules={[{ required: true }]}
      initialValue={stage && stage.description}
    >
      <Input.TextArea placeholder="Description" rows={4} disabled={loading} />
    </Form.Item>
  </Form>
);
