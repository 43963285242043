import React, { useContext, useEffect } from "react";
import { Modal, notification } from "antd";
import { DiContext, useAsync } from "app/common";
import { useOnError } from "hooks/useOnError";
import { adminUserService } from "app/infra/user";

interface SyncUserModalProps {
  id: string;
  visible: boolean;
  close: () => void;
}

export const SyncUserModal = (
  props: SyncUserModalProps
) => {
  const { apiService, dispatch } = useContext(DiContext);
  const adminUserSrv = adminUserService({ apiService, dispatch });

  const { error, value, pending, execute } = useAsync<{ ok: boolean }>(
    (id: string) => adminUserSrv.syncTicket(id),
    false
  );

  useOnError(error);

  useEffect(() => {
    if (value && value.ok) {
      notification.success({
        message: "User queued successfully for syncing",
      });
      props.close();
    }
  }, [value]);

  return (
    <Modal
      className="sync-user-modal"
      visible={props.visible}
      onCancel={props.close}
      title="Sync User?"
      confirmLoading={pending}
      okText="Sync"
      cancelText="Close"
      onOk={() => {
        execute(props.id);
      }}
    />
  );
};
