export const companyType = [
  "Service Provider & Apps",
  "Customer Technology",
  "Marketing Technology",
  "Sales Channel",
  "Agencies & Marketing",
  "Manufacturing",
  "Marketplaces",
  "Fulfilment & Logistics",
  "Business Operations",
  "Store Platform",
  "Communities",
  "Investor",
];

export const attendeeCompanyType = [
  "Affiliate Marketer",
  "Agency",
  "Brand",
  "Brand - Private Label Seller",
  "Brand - Reseller",
  "Designer",
  "Digital Marketer",
  "Entrepreneur",
  "Freelancer",
  "Fulfilment & Logistics",
  "Influencer & Creator",
  "Investor",
  "Sourcing & Manufacturing",
  "Store Platform or Sales Channel",
  "Technology Provider or App",
  "Other",
];
