import React, { useContext } from "react";
import { app } from "config";

import { DiContext } from "app/common";

import { useAsync } from "hooks/useAsync";
import { useOnError } from "hooks/useOnError";

import { AppMode, mode } from "config/initializers";
import { userService } from "app/infra/user";

import { Button, Card, notification } from "antd";
import fileDownload from "js-file-download";
import axios from "axios";

export const Certificate = () => {
  const { apiService, dispatch } = useContext(DiContext);
  const userSrv = userService({ apiService, dispatch });

  const { execute, isPending, error } = useAsync(() => {
    return userSrv.getCertificate().then((response: { url: string }) => {
      axios
        .get(response.url, { responseType: "blob" }).then((res) => {
          fileDownload(res.data, `${app.name} Certificate of Attendence.pdf`);
        })
        .catch((e) => {
          notification.error({ message: "Could not download your Certificate. Please, try again later" });
          console.error(e);
        });
    });
  });

  useOnError(error);

  if (mode !== AppMode.finished) return null;

  return (
    <Card
      style={{ marginTop: "20px" }}
      bodyStyle={{ display: "flex", flexWrap: "wrap", alignItems: "center", alignContent: "center", width: "100%" }}
    >
      <h3 style={{ marginTop: "auto" }}>Download your IAB (Interactive Advertising Bureau) Certificate</h3>

      <Button
        size="large"
        onClick={execute}
        loading={isPending}
        disabled={isPending}
        style={{
          background: "rgb(240, 30, 106)",
          color: "#FFFFFF",
          border: "none",
          marginLeft: "auto",
          width: "230px",
          height: "45px",
        }}
      >
        Download Certificate
      </Button>
    </Card>
  );
};
