import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { CompanyEntity } from "app/infra/company";
import { Avatar } from "antd";
import { Briefcase } from "react-feather";
import _ from "lodash";

interface CompanyCardProps {
  company: CompanyEntity;
}

export const Premium = ({ company }: CompanyCardProps) => {
  const description = useMemo(() => {
    return _.truncate(company.description, { length: 100 });
  }, [company.description]);

  const subtitle = [company.company_type, company.country]
    .filter(Boolean)
    .join(" | ");

  return (
    <div className="premium-card">
      <div
        className="premium-card__header"
        style={{
          backgroundImage: `linear-gradient(#fff -500%, ${company.main_color})`,
          backgroundColor: company.main_color,
        }}
      >
        <div
          style={{
            position: "absolute",
            bottom: "-60px",
            backgroundColor: "white",
            borderStyle: "solid",
            borderRadius: "50%",
            borderWidth: "25px",
            borderColor: "white",
          }}
        >
          <Link to={`/app/booth/${company.id}`}>
            <Avatar
              className="premium-card__header__avatar"
              src={company.logo_url}
              alt={company.name}
              size={70}
              style={{ backgroundColor: "#000000" }}
            >
              <Briefcase size={35} color="#FFFFFF" style={{ verticalAlign: "middle" }} />
            </Avatar>
          </Link>
        </div>
      </div>

      <div className="premium-card__content">
        <Link className="premium-card__content__title" to={`/app/booth/${company.id}`}>{company.name}</Link>
        <p className="premium-card__content__subtitle">{subtitle}</p>
        <p className="premium-card__content__description">{description}</p>
      </div>

      <div className="premium-card__footer">
        <Link className="premium-card__footer__link" to={`/app/booth/${company.id}`}>View company</Link>
      </div>
    </div>
  );
};
