import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";

import { format } from "date-fns";

import { DiContext, usePaginationAsync } from "app/common";
import { useAsync, useOnError, useOnMount } from "hooks";

import { HighlightEntity, highlightService } from "app/infra/highlights";

import { ColumnsType } from "antd/lib/table";
import { Button, Card, Col, Input, notification, Popconfirm, Row, Table } from "antd";
import { Edit, Search, Trash2 } from "react-feather";

import { parseQueryPath } from "app/common/utils/Path";
import { parse } from "qs";

interface HighlightListParams {
  page?: number;
  search?: string;
}

export const HighlightList = () => {
  const { apiService, dispatch } = useContext(DiContext);
  const highlightSrv = highlightService({ apiService, dispatch });

  const history = useHistory();
  const queryParams = parse(history.location.search, { ignoreQueryPrefix: true }) as HighlightListParams;

  const pagination = usePaginationAsync<HighlightEntity>(
    (page, pageSize, search, sortBy, sortDirection) => {
      return highlightSrv.getAllAdmin({
        params: {
          count: pageSize,
          page,
          search: search || undefined,
          sortBy: sortBy || undefined,
          sortDirection: sortDirection || undefined,
        },
      });
    },
    {
      initialFilter: { search: queryParams.search || "" },
      initialPage: queryParams.page,
    },
  );

  useOnMount(pagination.async.execute);

  const { execute: onDelete, error: onDeleteError } = useAsync((id: string) => {
    return highlightSrv.delete(id).then((response) => {
      notification.success({ message: "Highlight was successfully deleted" });
      pagination.async.execute();

      return response;
    });
  });
  useOnError(onDeleteError);

  const updateSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    history.push(parseQueryPath(
      "/admin/highlights",
      "page",
      pagination.page.toString(),
      "search",
      event.currentTarget.value,
    ));
    pagination.onSetFilter({ search: event.currentTarget.value });
  };

  const columns: ColumnsType<HighlightEntity> = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      sorter: true,
      onHeaderCell: () => ({
        onClick: () => pagination.onToggleOrderBy("title"),
      }),
    },
    {
      title: "Video URL",
      dataIndex: "video_url",
      key: "video_url",
      sorter: true,
      onHeaderCell: () => ({
        onClick: () => pagination.onToggleOrderBy("video_url"),
      }),
    },
    {
      title: "Start (ISO)",
      dataIndex: "start",
      key: "start",
      sorter: true,
      onHeaderCell: () => ({
        onClick: () => pagination.onToggleOrderBy("start"),
      }),
      render: (value: string) => {
        return format(new Date(value), "do LLL hh:mm");
      },
    },
    {
      title: "End (ISO)",
      dataIndex: "end",
      key: "end",
      sorter: true,
      onHeaderCell: () => ({
        onClick: () => pagination.onToggleOrderBy("end"),
      }),
      render: (value: string) => {
        return format(new Date(value), "do LLL hh:mm");
      },
    },
    {
      key: "actions",
      width: 84,
      render: (_value, record) => (
        <Button.Group>
          <Link
            to={`/admin/highlights/${record.id}/edit`}
            title="Edit"
            className="ant-btn ant-btn-link ant-btn-sm"
          >
            <Edit size={20} />
          </Link>

          <Popconfirm
            title="Are you certain that you want to delete this Highlight?"
            onConfirm={() => onDelete(record.id)}
            okText="Confirm"
            cancelText="Cancel"
            placement="topRight"
          >
            <Button type="link" size="small" title="Delete" danger={true}>
              <Trash2 size={20} />
            </Button>
          </Popconfirm>
        </Button.Group>
      ),
    },
  ];

  return (
    <Card>
      <Row className="admin-page-header">
        <Col className="left-column">
          <h1 className="title">Highlights</h1>
          <div className="elements">
            <Input
              placeholder="search"
              prefix={<Search size={20} color="#A3A7B2" />}
              value={pagination.filter.search}
              onChange={updateSearch}
            />
          </div>
        </Col>

        <Col>
          <Button type="primary" onClick={() => history.push("/admin/highlights/new")}>
            Create Highlight
          </Button>
        </Col>
      </Row>

      <Table
        size="middle"
        loading={pagination.async.isPending}
        bordered={true}
        rowKey="id"
        columns={columns}
        dataSource={pagination.async.value?.data}
        pagination={{
          size: "default",
          current: pagination.page,
          total: pagination.totalItems,
          pageSize: pagination.pageSize,
          onChange: (page: number, pageSize?: number) => {
            pagination.onSetPage(page);
            if (pageSize) {
              pagination.onSetPageSize(pageSize);

              history.push(parseQueryPath(
                "app/admin/highlights",
                "page",
                page.toString(),
                "search",
                pagination.filter.search,
              ));
              window.scrollTo({ top: 0 });
            }
          },
        }}
      />
    </Card>
  );
};
