import React, { useContext } from "react";

import { DiContext } from "app/common";
import { Highlight, HighlightEntity, highlightService } from "app/infra/highlights";

import { UploadPhoto } from "app/presentation/common";

import { DatePicker, Input } from "antd";
import Form, { FormInstance } from "antd/lib/form";
import moment from "moment";

interface Props {
  form: FormInstance;
  onFinish: (data: Highlight) => void;
  highlight?: HighlightEntity;
}

export const HighlightForm = (props: Props) => {
  const { apiService, dispatch } = useContext(DiContext);
  const highlightSrv = highlightService({ apiService, dispatch });

  const uploadPhoto = ({ file }: { file: string | Blob }) => {
    return highlightSrv.updatePhoto({ id: props.highlight?.id || "", photo: file });
  };

  return (
    <Form
      form={props.form}
      onFinish={props.onFinish}
      scrollToFirstError={true}
      layout="vertical"
    >
      <Form.Item
        label="Title"
        name="title"
        initialValue={props.highlight?.title}
        rules={[
          {
            required: true,
            message: "Title is required",
          },
        ]}
      >
        <Input placeholder="Ttitle" />
      </Form.Item>

      <Form.Item
        label="URL"
        name="video_url"
        initialValue={props.highlight?.video_url}
        rules={[
          {
            required: true,
            message: "URL is required",
          },
          {
            type: "url",
            message: "Video URL must be valid",
          },
        ]}
      >
        <Input placeholder="Video URL" />
      </Form.Item>

      <Form.Item
        label={<span>Will be visible at&nbsp;&nbsp;<b>(In your own timezone)</b></span>}
        name="start"
        initialValue={props.highlight?.start && moment(props.highlight.start)}
        rules={[
          {
            required: true,
            message: "Date start is required",
          },
        ]}
      >
        <DatePicker showTime={true} />
      </Form.Item>

      <Form.Item
        label={<span>Will be hidden at&nbsp;&nbsp;<b>(In your own timezone)</b></span>}
        name="end"
        initialValue={props.highlight?.end && moment(props.highlight.end)}
        rules={[
          {
            required: true,
            message: "Date end is required",
          },
        ]}
      >
        <DatePicker showTime={true} />
      </Form.Item>

      {props.highlight ? (
        <UploadPhoto uploadPhoto={uploadPhoto} image_url={props.highlight?.thumbnail_url} />
      ) : (
        "Image for highlight can be uploaded only on edit, not on create!"
      )}
    </Form>
  );
};

HighlightForm.defaultProps = {
  highlight: undefined,
};
