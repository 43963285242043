import React, { useEffect, useMemo, useState } from "react";

import { Table as AntdTable } from "antd";
import { TableProps } from "antd/lib/table";

import { DndProvider } from "components/DndProvider/DndProvider";
import { ConditionalWrapper } from "components/ConditionalWrapper/ConditionalWrapper";

import { DraggableRow } from "./Row";

interface Props<T> extends TableProps<T> {
  isRowDraggable?: boolean;
  onMoveRow?: (dragIndex: number, dropIndex: number) => void;
}

export const Table = <T extends object = any>(props: Props<T>) => {
  const [tableProps, setTableProps] = useState(props);

  const moveRow = (dragIndex: number, dropIndex: number) => {
    if (props.onMoveRow) {
      props.onMoveRow(dragIndex, dropIndex);
    }
  };

  useEffect(() => {
    if (props.isRowDraggable) {
      setTableProps({
        ...props,
        components: {
          body: {
            row: DraggableRow,
          },
        },
        onRow: (_record, index) => {
          return {
            id: index?.toString(),
            index,
            moveRow,
          };
        },
      });
    } else {
      setTableProps(props);
    }
  }, [props]);

  const table = useMemo(() => {
    return (
      <AntdTable
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...tableProps}
      >
        {props.children}
      </AntdTable>
    );
  }, [tableProps]);

  return (
    <ConditionalWrapper
      condition={tableProps.isRowDraggable === true}
      wrapperFn={DndProvider}
    >
      {table}
    </ConditionalWrapper>
  );
};

Table.defaultProps = {
  isRowDraggable: false,
  onMoveRow: () => {},
};
