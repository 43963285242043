import { UserEntity } from "app/infra/user";
import { PerkEntity } from "app/infra/perk";

export enum TicketType {
  Company = "Company",
  CompanyBasicBooth = "CompanyBasicBooth",
  CompanyPremiumBooth = "CompanyPremiumBooth",
  CompanySuperBooth = "CompanySuperBooth",
}

export interface CompanyEntity<T = UserEntity> extends CompanyEntityWithoutUsers {
  users: T[];
}

export interface CompanyEntityWithoutUsers {
  country: string;
  description: string;
  id: string;
  logo_url: string;
  name: string;
  ticket_type: string;
  expertise: string[];
  interested: string[];
  url: string;
  company_type: string[];
  main_color: string;
  tags: string[];
  is_booth_cached: boolean;
  email?: string;
  promo_video?: string;
  perk?: PerkEntity;
  priority?: boolean;
  rank?: number;
  deactivate_sync?: boolean;
  jobHiringURL: string;
  isComplete: boolean;
}
