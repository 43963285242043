import React, { useContext } from "react";

import { useAsync, useOnError } from "hooks";
import { DiContext } from "app/common";

import { ResourceEntity, resourceService } from "app/infra/resource";
import { TalkEntity } from "app/infra/talk";

import { Button, Popconfirm, Upload } from "antd";
import { ColumnsType } from "antd/lib/table";
import { Trash2 } from "react-feather";

import { FileFormFunctionProps } from "app/presentation/common";

import { Table } from "components/Table";

interface ResourceListProps {
  talk: TalkEntity;
}

export const ResourceList = (props: ResourceListProps) => {
  const { dispatch, apiService } = useContext(DiContext);
  const resourceSrv = resourceService({ apiService, dispatch });

  const { execute, isPending, error } = useAsync((fileProps: FileFormFunctionProps) => {
    return resourceSrv.create({ file: fileProps.file, id: props.talk.id });
  });
  useOnError(error);

  const { execute: executeDelete, error: errorDelete } = useAsync((resourceId: string) => {
    return resourceSrv.delete(props.talk.id, resourceId);
  });
  useOnError(errorDelete);

  const resourceColumns: ColumnsType<ResourceEntity> = [
    {
      key: "file_name",
      title: "File name",
      dataIndex: "file_name",
    },
    {
      key: "size",
      title: "File size",
      dataIndex: "size",
    },
    {
      key: "actions",
      width: 40,
      render: (_value, record) => (
        <Popconfirm
          title="Are you certain that you want to delete this Resource?"
          onConfirm={() => executeDelete(record.id)}
          okText="Confirm"
          cancelText="Cancel"
        >
          <Button type="link" size="small" title="Delete" danger={true}>
            <Trash2 size={20} />
          </Button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          marginBottom: "24px",
          borderTop: "4px solid",
          marginTop: "20px",
          paddingTop: "20px",
          alignItems: "center",
        }}
      >
        <h4 style={{ marginBottom: "0px" }}>Talk resources</h4>

        <div style={{ marginLeft: "auto" }}>
          <Upload
            name="file"
            customRequest={execute}
            showUploadList={false}
          >
            <Button type="default">Upload Resource</Button>
          </Upload>
        </div>
      </div>

      <Table
        size="middle"
        bordered={true}
        rowKey="id"
        columns={resourceColumns}
        dataSource={props.talk?.resources}
        pagination={false}
      />

      {isPending && <div>Uploading...</div>}
    </div>
  );
};
