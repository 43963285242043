import { AxiosRequestConfig } from "axios";

import { CommonServiceDeps, PaginationRequest } from "app/common";

import { PaginationResponse } from "app/common/utils/PaginationResponse";

import { ByIdDTO } from "app/presentation/admin/stats/leadComponent";
import { UserEntity, userStore } from "../user";
import { PerkEntity } from "./perk.entity";
import { perkStore } from "./perk.store";

interface UploadPhotoDTO {
  id: string;
  photo: string | Blob;
}

interface ClaimedPerkEntity {
  perk: PerkEntity;
  user: UserEntity;
}

interface ClaimedPerkEntityByUser {
  perk: PerkEntity;
  userId: string;
}

export type Perk = Pick<PerkEntity,
  "title"
  | "description"
  | "link"
  | "price_new"
  | "price_old"
  | "note"
  | "rank"
  > & { company_id: string };

export const perkService = (props: CommonServiceDeps) => {
  return {
    getAll: () => {
      return props.apiService
        .get("/perks")
        .then((response: PerkEntity[]) => {
          props.dispatch(perkStore.actions.addPerks({ resources: response }));
          return response;
        });
    },
    getAllPaginated: (pagination: { params: PaginationRequest }) => {
      return props.apiService
        .get("/perks/paginated", pagination)
        .then((response: PaginationResponse<PerkEntity>) => {
          props.dispatch(perkStore.actions.addPerks({ resources: response.data }));
          return response;
        });
    },
    getOne: (id: string) => {
      return props.apiService
        .get(`/perks/${id}`)
        .then((response: PerkEntity) => {
          props.dispatch(perkStore.actions.addPerks({ resources: [response] }));
          return response;
        });
    },
    create: (data: Perk) => {
      return props.apiService
        .post<PerkEntity>("/perks", data)
        .then((response: PerkEntity) => {
          props.dispatch(perkStore.actions.addPerks({ resources: [response] }));
          return response;
        });
    },
    update: (data: Perk, id: string) => {
      return props.apiService
        .patch<Perk>(`/perks/${id}`, data)
        .then((response: PerkEntity) => {
          props.dispatch(perkStore.actions.updatePerks({ resources: [response] }));
          return response;
        });
    },
    delete: (id: string) => {
      return props.apiService
        .delete(`/perks/${id}`)
        .then((response) => {
          props.dispatch(perkStore.actions.deletePerk({ id }));
          return response;
        });
    },
    updatePhoto: ({ id, photo }: UploadPhotoDTO) => {
      const formData = new FormData();
      formData.append("picture", photo);
      return props.apiService
        .post(`/perks/image/${id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
    },
    claimPerk: (id: string) => (
      props.apiService
        .post<PerkEntity>(`/perks/claim/${id}`)
        .then((response) => {
          props.dispatch(perkStore.actions.updatePerks({ resources: [response] }));
          return response;
        })
    ),
    getClaimed: (data: { identifier?: string }) => {
      return props.apiService
        .get("/perks/claims")
        .then((response: ClaimedPerkEntity[]) => {
          const perks = response.map((entity) => entity.perk);
          const users = response.map((entity) => entity.user);

          props.dispatch(userStore.actions.updateUsers({ resources: users }));
          props.dispatch(perkStore.actions.updatePerks({ resources: perks }));

          props.dispatch(perkStore.actions.addClaims({
            resources: response.map((entity) => ({
              id: entity.perk.id,
              userIds: [entity.user.id],
            })),
          }));

          if (data.identifier) {
            props.dispatch(perkStore.actions.addKeyIds({
              ids: Array.from(new Set(perks.map((perk) => perk.id))),
              key: data.identifier,
            }));
          }

          return response;
        });
    },
    getClaimedByUser: (userId: string) => {
      return props.apiService
        .get(`/perks/claims/by-user/${userId}`)
        .then((response: ClaimedPerkEntityByUser[]) => {
          props.dispatch(perkStore.actions.addClaims({
            resources: response.map((entity) => ({
              id: entity.perk.id,
              userIds: [entity.userId],
            })),
          }));

          props.dispatch(perkStore.actions.addKeyIds({
            ids: Array.from(new Set(response.map((item) => item.perk.id))),
            key: "profile-claimed-perks",
          }));
          return response;
        });
    },
    getPerkStats: (data: ByIdDTO, config: AxiosRequestConfig = {}) => {
      return props.apiService.post<string | ArrayBuffer | ArrayBufferView | Blob>("/stats/perks", data, config);
    },
    getPerkLeads: (data: { ids: string[], startDate?: string, endDate?: string }, config: AxiosRequestConfig = {}) => {
      return props.apiService
        .post<string | ArrayBuffer | ArrayBufferView | Blob>("companies/perks/stats", data, config);
    },
  };
};
