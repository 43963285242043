import React, { useContext } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

import { DiContext } from "app/common";
import { useAsync, useOnError, useOnMount } from "hooks";

import { CreateStageDTO, StageEntity, stageService } from "app/infra/stage";

import { Button, Card, Col, notification, Row } from "antd";
import { useForm } from "antd/lib/form/Form";

import { StageForm } from "./StageForm";

export const StageUpdate = () => {
  const { apiService, dispatch } = useContext(DiContext);
  const stageServ = stageService({ apiService, dispatch });

  const [stageSelected, setStageSelected] = React.useState<StageEntity<string> | null>(null);

  const [form] = useForm();

  const history = useHistory();
  const routerParams = useRouteMatch().params as { id: string };

  const { execute: onMount, error } = useAsync(() => {
    return stageServ.getOne(routerParams.id).then((response) => {
      setStageSelected(response);
    });
  });
  useOnError(error);

  useOnMount(onMount);

  const { execute: onUpdate, error: onUpdateError } = useAsync((data: CreateStageDTO) => {
    return stageServ.update(data, routerParams.id).then((response) => {
      history.push("/admin/schedule/stages");

      notification.success({ message: "Stage was successfully updated" });

      return response;
    });
  });
  useOnError(onUpdateError);

  if (!stageSelected) {
    return null;
  }

  return (
    <Row>
      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
        <Card>
          <Row align="middle" style={{ marginBottom: "20px" }}>
            <Col>
              <h1>Update Stage</h1>
            </Col>
          </Row>

          <StageForm form={form} stage={stageSelected} onFinish={onUpdate} />

          <Row>
            <Col style={{ marginLeft: "auto" }}>
              <Button type="primary" onClick={form.submit}>Update</Button>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};
