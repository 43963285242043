import React, { useContext, useMemo, useState } from "react";
import { AppContext } from "components/Layout/Layout";
import { Card, Tabs } from "antd";
import type { EventValue } from "rc-picker/lib/interface";
import moment, { Moment } from "moment";
import { RangePicker } from "components";
import { PerkLeads } from "./perkLeads";
import { RaffleLeads } from "./raffleLeads";
import { ResourceLeads } from "./resourceLeads";
import { BusinessCardLeads } from "./businessCardLeads";
import { ProfileData } from "./profileData";

const { TabPane } = Tabs;

export const Leads = ({
  calledFromProfile,
}: {
  calledFromProfile: boolean;
}) => {
  const [selectedTab, setSelectedTab] = useState("perk-leads");

  const { confOpenTime } = useContext(AppContext);

  const format = "YYYY-MM-DD";
  const [from, setfrom] = useState<EventValue<Moment>>(moment(confOpenTime));
  const [upto, setUpto] = useState<EventValue<Moment>>(moment(Date.now()));

  const loading = false;

  const onSelectedDate = (fromDate: EventValue<Moment>, uptoDate: EventValue<Moment>) => {
    setfrom(fromDate);
    setUpto(uptoDate);
  };

  const showDatePicker = useMemo(() => {
    switch (selectedTab) {
      case "perk-leads":
      case "talk-resource-download-leads":
        return true;
      default:
        return false;
    }
  }, [selectedTab]);

  return (
    <Card
      loading={loading}
      bordered={false}
      bodyStyle={{ padding: "20px" }}
      className="leads"
    >
      <h1 className="title">Stats</h1>
      <Tabs
        size="large"
        tabBarStyle={{ marginBottom: 24 }}
        onChange={setSelectedTab}
        tabBarExtraContent={(
          <div className="time-filter" style={{ marginBottom: 5 }}>
            {showDatePicker && (
              <RangePicker
                from={from}
                upto={upto}
                onSelected={onSelectedDate}
              />
            )}
          </div>
        )}
      >
        <TabPane
          tab={<span>Perk Leads</span>}
          key="perk-leads"
        >
          <PerkLeads
            calledFromProfile={calledFromProfile}
            startDate={from?.format(format) as string}
            endDate={upto?.format(format) as string}
          />
        </TabPane>

        {!calledFromProfile && (
          <TabPane
            tab={<span>Talk Resource Leads</span>}
            key="talk-resource-download-leads"
          >
            <ResourceLeads
              startDate={from?.format(format) as string}
              endDate={upto?.format(format) as string}
            />
          </TabPane>
        )}

        <TabPane
          tab={<span>Business Card Bowl Leads</span>}
          key="business-card-bowl-leads"
        >
          <BusinessCardLeads calledFromProfile={calledFromProfile} />
        </TabPane>

        {!calledFromProfile && (
          <TabPane
            tab={<span>Raffle Leads</span>}
            key="raffle-leads"
          >
            <RaffleLeads />
          </TabPane>
        )}

        {!calledFromProfile && (
          <TabPane
            tab={<span>Profile Data</span>}
            key="profile-data"
          >
            <ProfileData />
          </TabPane>
        )}
      </Tabs>
    </Card>
  );
};
