import React, { useContext, useEffect } from "react";
import { Modal, notification, Form, Input } from "antd";
import { DiContext, useAsync } from "app/common";
import { useOnError } from "hooks/useOnError";
import { adminUserService, UserEntity } from "app/infra/user";

interface ConnectionsModalProps {
  user: UserEntity;
  visible: boolean;
  close: () => void;
  onSuccess: () => void;
}

interface FormProps {
  dailyLimit: number;
  connectionsLeft: number;
}

export const ConnectionsModal = (props: ConnectionsModalProps) => {
  const { apiService, dispatch } = useContext(DiContext);
  const adminUserSrv = adminUserService({ apiService, dispatch });
  const [form] = Form.useForm();

  const { error, value, pending, execute } = useAsync<{ message: string }>(
    (data: FormProps) => adminUserSrv.updateConnectionsData(data, props.user.id)
  );

  useOnError(error);

  useEffect(() => {
    if (value && value.message) {
      notification.success({
        message: value.message,
      });
      props.close();
      props.onSuccess();
    }
  }, [value]);

  return (
    <Modal
      visible={props.visible}
      onCancel={props.close}
      title="Update Connections Limit"
      confirmLoading={pending}
      okText="Update"
      cancelText="Close"
      onOk={() => {
        form.submit();
      }}
    >
      <Form layout="vertical" form={form} onFinish={execute}>
        <Form.Item label="Connections Left" name="connectionsLeft" initialValue={props.user.ticket?.connections_left} rules={[{ required: true }]}>
          <Input disabled={pending} />
        </Form.Item>
        <Form.Item label="Daily Limit" name="dailyLimit" initialValue={props.user.ticket?.daily_connection_limit} rules={[{ required: true }]}>
          <Input disabled={pending} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
