import { Form, Modal } from "antd";
import { DiContext, useAsync } from "app/common";
import { useOnError } from "hooks/useOnError";
import { TicketTypeEntity } from "app/infra/ticketType";
import { TicketTypeForm, FormProps } from "./ticketType.form";
import { useContext } from "react";
import { ModalState } from "hooks/useModal";

interface EditTicketTypeModalProps {
  modal: ModalState;
  ticketType: TicketTypeEntity;
  onSuccess: () => void;
}

export const EditTicketTypeModal = (props: EditTicketTypeModalProps) => {
  const [form] = Form.useForm();
  const {
    entityServices: { TicketTypeService },
  } = useContext(DiContext);
  const { pending, execute, error } = useAsync((data: FormProps) =>
    TicketTypeService.edit({ type: data.type }, props.ticketType.id).then((response) => {
      props.onSuccess();
      props.modal.shut();
      return response;
    })
  );

  useOnError(error);

  return (
    <Modal visible={props.modal.isVisible} onCancel={props.modal.shut} onOk={form.submit}>
      <Form layout="vertical" form={form} onFinish={execute}>
        <TicketTypeForm ticketType={props.ticketType} pending={pending} />
      </Form>
    </Modal>
  );
};
