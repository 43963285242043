import React, { useMemo } from "react";
import { Button, Dropdown, Menu } from "antd";
import { ChevronDown } from "react-feather";
import { UserEntity } from "app/infra/user";
import moment from 'moment'
import { Table } from "components/Table";

interface DirectoryTableProps {
  users: UserEntity[];
  pending: boolean;
  openModal: (user: UserEntity) => void;
  openUpdateConnectionLimits: (user: UserEntity) => void;
  openSendConfirmationModal: (user: UserEntity) => void;
  openResendForgotPasswordEmailModal: (user: UserEntity) => void;
  openForgotPasswordEmailModal: (user: UserEntity) => void;
  openSyncUserModal: (user: UserEntity) => void;
  page: number;
  setPage: (page: number) => void;
  toggleOrderBy: (key: string) => void;
  totalItems: number;
  pageSize: number;
}

const columns = [
  {
    title: "",
    dataIndex: "photo",
    width: 50,
  },
  {
    title: "Name",
    dataIndex: "name",
  },
  {
    title: "Bio",
    dataIndex: "bio",
  },
  {
    title: "Email",
    dataIndex: "email",
  },
  {
    title: "Ticket Reference",
    dataIndex: "ticket_reference",
  },
  {
    title: "Daily Connections Limit",
    dataIndex: "daily_connections_limit",
  },
  {
    title: "Connections Left",
    dataIndex: "connections_left",
  },
  {
    title: "Last Login",
    dataIndex: "lastLogin"
  },
  {
    title: "Active",
    render: ({ active }: { active: boolean }) => (active && "yes") || "no",
  },
  {
    key: "actions",
    width: 100,
    render: (data: any) => {
      return (
        <Dropdown
          overlay={
            <Menu style={{ background: "white" }}>
              <Menu.Item
                onClick={() => {
                  data.events.openModal(data.user);
                }}
              >
                Edit
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  data.events.openSendConfirmationModal(data.user);
                }}
              >
                Resend Confirmation Link
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  data.events.openResendForgotPasswordEmailModal(data.user);
                }}
              >
                Resend Forgot Password Link
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  data.events.openForgotPasswordEmailModal(data.user);
                }}
              >
                Get Forgot Password Link
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  data.events.openSyncUserModal({ id: data.user.ticket?.slug });
                }}
              >
                Sync user
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  data.events.openUpdateConnectionLimits(data.user);
                }}
              >
                Update connection limits
              </Menu.Item>
            </Menu>
          }
        >
          <Button type="link" size="small">
            Actions <ChevronDown size={18} />
          </Button>
        </Dropdown>
      );
    },
  },
];

export const DirectoryTable = ({
  users,
  pending,
  openModal,
  openUpdateConnectionLimits,
  page,
  totalItems,
  pageSize,
  setPage,
  toggleOrderBy,
  openSendConfirmationModal,
  openResendForgotPasswordEmailModal,
  openForgotPasswordEmailModal,
  openSyncUserModal,
}: DirectoryTableProps) => {
  const data = useMemo(() => {
    return users.map((user) => {
      return {
        key: user.id,
        name: `${user.first_name} ${user.last_name}`,
        photo: (
          <img
            src={user.profile_picture_url}
            width={30}
            alt={`${user.first_name} ${user.last_name}`}
          />
        ),
        ticket_reference: user.ticket?.reference,
        active: user.active,
        bio: user.bio,
        email: user.email,
        user,
        lastLogin: user.lastLogin && moment(user.lastLogin).format("DD/MM/YYYY hh:mm") || "",
        daily_connections_limit: user.ticket?.daily_connection_limit,
        connections_left: user.ticket?.connections_left,
        events: {
          openForgotPasswordEmailModal,
          openResendForgotPasswordEmailModal,
          openSendConfirmationModal,
          openModal,
          openUpdateConnectionLimits,
          openSyncUserModal,
        },
      };
    });
  }, [users, openModal]);

  return (
    <Table
      size="middle"
      loading={pending}
      bordered={true}
      columns={columns}
      dataSource={data}
      pagination={{
        size: "default",
        current: page + 1,
        total: totalItems,
        pageSize,
        onChange: (page: number) => setPage(page - 1),
      }}
      onChange={(pagination: any, filters: any) => {
        if (!filters.column && filters.field === "name") {
          toggleOrderBy("name");
        }
      }}
    />
  );
};
