import React, { useContext } from "react";
import { useHistory } from "react-router-dom";

import { DiContext } from "app/common";
import { useAsync, useOnError } from "hooks";
import { Post, postService } from "app/infra/posts";

import Antd, { Button, Card, Col, notification, Row } from "antd";
import { useForm } from "antd/lib/form/Form";

import { PostForm } from "./PostForm";

export const PostCreate = () => {
  const { apiService, dispatch } = useContext(DiContext);
  const postSrv = postService({ apiService, dispatch });

  const [form] = useForm();
  const history = useHistory();

  const { execute, error } = useAsync((data: Post) => {
    return postSrv.create(data).then((response) => {
      history.push("/admin/posts");
      notification.success({ message: "Post was successfully created" });

      return response;
    });
  });
  useOnError(error);

  return (
    <Row>
      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
        <Card>
          <Row>
            <Col>
              <h1>
                Create Post
              </h1>
            </Col>
          </Row>

          <PostForm form={form} onFinish={execute} />

          <Row justify="end" style={{ marginTop: "20px" }}>
            <Col>
              <Button type="primary" onClick={form.submit}>
                Create
              </Button>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};
