import React from "react";
import { app } from "config";

import { Button, Modal } from "antd";

interface WelcomeModalProps {
  visible: boolean;
  onClose: () => void;
}

export const Welcome = (props: WelcomeModalProps) => {
  return (
    <Modal
      footer={null}
      visible={props.visible}
      closable={false}
      centered={true}
    >
      <h4>Welcome to your {app.name} profile page!</h4>

      <p>
        Take 1 minute to fill out your essential details like your job title,
        interests and more to power-up our Smart Match recommendations for the show.

        <br />
        <br />

        This will allow you to find quality attendees and companies that will
        help you expand your business.

        <br />
        <br />

        <Button onClick={props.onClose} type="primary">Get Started!</Button>
      </p>
    </Modal>
  );
};
