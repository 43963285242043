export const app = {
  apiUrl: process.env.REACT_APP_API_ENDPOINT,
  chatUrl: process.env.REACT_APP_CHAT_URL,
  landingURL: process.env.REACT_APP_LANDING_URL || "",
  detailsURL: process.env.REACT_APP_DETAILS_URL || "https://adworldconference.com/faqs",
  confTimezone: process.env.REACT_APP_HOST_TIMEZONE || "America/New_York",
  name: process.env.REACT_APP_NAME || "AD WORLD",
  contactEmail: process.env.REACT_APP_CONTACT_EMAIL || "info@adworldconf.com",
  replayPassTitoURL: process.env.REPLAY_PASS_TITO_URL || "https://ti.to/strata-virtual-events/adworldoct2022/with/upgrade-replay-access",

  gcKey: process.env.REACT_APP_GC_KEY,
  gcUrl: process.env.REACT_APP_GC_URL,
};
