import React, { useContext } from "react";
import { connect } from "react-redux";
import { RootState } from "services";
import { AgendaEntity } from "models/entities";
import { ReplayStageEntity, replayStageService } from "app/infra/replayStage";
import { ReplayTalkEntity } from "app/infra/replayTalk";
import { ReplayTrackEntity } from "app/infra/replayTrack";
import { UserEntity, adminUserService } from "app/infra/user";
import { DiContext, useAsync } from "app/common/utils";
import { useOnError, useOnMount } from "hooks";
import { sponsorshipService } from "app/infra/sponsorships";
import * as Replay from "components/@admin/Replay";
import { EditReplayStagesTable } from "./editReplayStagesTable";

interface TalksById {
  [key: string]: ReplayTalkEntity;
}

interface ReplaySectionProps {
  stages: ReplayStageEntity<string>[];
  talks: TalksById;
  speakers: UserEntity[];
  tracks: ReplayTrackEntity[];
}

export const ReplaySection = connect((state: RootState) => ({
  stages: Object.values(state.replayStageStore.byId),
  talks: state.replayTalkStore.byId,
  tracks: Object.values(state.replayTrackStore.byId),
  speakers: Object.keys(state.userStore.byId)
    .map((key) => state.userStore.byId[key])
    .filter((user) => user.roles && user.roles.includes("ROLE_SPEAKER")),
}))(({ speakers, stages, talks, tracks }: ReplaySectionProps) => {
  const { apiService, dispatch } = useContext(DiContext);
  const sponsorSrv = sponsorshipService({ apiService, dispatch });
  const stagesSrv = replayStageService({ apiService, dispatch });
  const adminUserSrv = adminUserService({ apiService, dispatch });

  const { execute, error } = useAsync<AgendaEntity>(
    () =>
      Promise.all([
        stagesSrv.getAgenda(),
        adminUserSrv.getSpeakers(),
        sponsorSrv.getAll({}),
      ]),
    false,
  );

  useOnError(error);

  useOnMount(() => {
    execute();
  });

  return (
    <div>
      <div style={{ marginBottom: 64 }}>
        <Replay.TrackList />
      </div>
      <div style={{ marginBottom: 64 }}>
        <EditReplayStagesTable
          speakers={speakers}
          tracks={tracks}
          stages={stages}
          talksById={talks}
        />
      </div>
    </div>
  );
});
