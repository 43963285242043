/**
 * @param root root url path
 * @param args each even arg is a parameter, each odd arg is the value
 */
 export const parseQueryPath = (root: string, ...args: string[]) => {
  let retPath = `${root}?`;

  args.forEach((arg, index) => {
    if (index % 2 === 0 && args[index + 1]) {
      retPath = `${retPath}${arg}=`;
    } else if (index % 2 !== 0 && arg) {
      retPath = `${retPath}${arg}&`;
    }
  });

  if (retPath.endsWith("&")) {
    return retPath.substring(0, retPath.length - 1);
  }

  return retPath;
};
