import React, { useContext } from "react";
import { Redirect } from "react-router-dom";

import { connect } from "react-redux";
import { RootState } from "services";

import { DiContext, LoadingPage } from "app/common";
import { useAsync } from "hooks/useAsync";
import { useOnMount } from "hooks/useOnMount";
import { useOnError } from "hooks/useOnError";

import { UserEntity, userService } from "app/infra/user";

import { UserUpdateForm } from "./UserUpdateForm";

interface OwnProps {
  match: {
    params: {
      id: string;
    };
  };
}

interface UserUpdateProps extends OwnProps {
  user: UserEntity;
  currentUser: UserEntity;
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
  return {
    user: state.userStore.byId[ownProps.match.params.id],
    match: ownProps.match,
    currentUser: state.userStore.byId["me"],
  };
};

export const UserUpdate = connect(mapStateToProps)((props: UserUpdateProps) => {
  const { apiService, dispatch } = useContext(DiContext);
  const userSrv = userService({ apiService, dispatch });

  const { execute, isPending, error } = useAsync(() => {
    return Promise.all([
      userSrv.me(),
      userSrv.get(props.match.params.id),
    ]);
  });

  useOnMount(execute);
  useOnError(error);

  if (isPending || !props.user) return <LoadingPage />;
  if (props.user.id !== props.currentUser.old_id) {
    return <Redirect to={`/app/user-profile/${props.currentUser.old_id || props.currentUser.id}/edit`} />;
  }

  if (props.currentUser.email === "replays@adworld.online") return null;

  return (
    <UserUpdateForm user={props.currentUser} />
  );
});
