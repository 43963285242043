import React, { useEffect } from "react";
import { Modal, notification } from "antd";
import { useOnError, useAsync } from "hooks";
import { UserService } from "services/@admin/UserService";

interface PasswordRecoveryProps {
  id: string;
  isVisible: boolean;
  onCancel: () => void;
}

export const PasswordRecovery = (
  props: PasswordRecoveryProps,
) => {
  const { error, value, isPending, execute } = useAsync(UserService().passwordRecoveries.create);

  useOnError(error);

  useEffect(() => {
    if (value && value.message) {
      notification.success({ ...value });
      props.onCancel();
    }
  }, [value]);

  return (
    <Modal
      className="resend-forgot-password-email-modal"
      visible={props.isVisible}
      onCancel={props.onCancel}
      title="Resend Forgot Password Email Link"
      confirmLoading={isPending}
      okText="Send"
      cancelText="Close"
      onOk={() => { execute(props.id); }}
    />
  );
};
