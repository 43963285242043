import { DiContext } from "app/common";
import React, { useContext } from "react";
import { resourceService } from "app/infra/resource";
import { sponsorshipService } from "app/infra/sponsorships";
import { AxiosRequestConfig } from "axios";
import { Entity, LeadComponent, LeadStatsDTO, ByIdDTO } from "./leadComponent";

export const ResourceLeads = ({
  startDate,
  endDate,
}: {
  calledFromProfile? : boolean;
  startDate: string;
  endDate: string;
}) => {
  const { dispatch, apiService } = useContext(DiContext);
  const resourceSrv = resourceService({ apiService, dispatch });
  const sponsorSrv = sponsorshipService({ apiService, dispatch });

  return LeadComponent({
    startDate,
    endDate,
    getAll: (() => sponsorSrv.getAll({ identifier: "admin-sponsors" }) as Promise<Entity[]>),
    getStats: resourceSrv.getResourceStats as
      (data?: LeadStatsDTO | ByIdDTO, config?: AxiosRequestConfig) =>
      Promise< string | ArrayBuffer | ArrayBufferView | Blob>,
    title: "sponsor",
  });
};
