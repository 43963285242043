import { useCallback, useMemo, useState } from "react";

export const useSelected = ({ entityIds }: { entityIds: string[] }) => {
  const [selected, setSelected] = useState<{
    [key: string]: boolean;
  }>({});

  const selectedArray = useMemo(() => {
    return Object.keys(selected).reduce((acc: string[], val: string) => {
      if (selected[val]) {
        return [...acc, val];
      }
      return acc;
    }, []);
  }, [selected]);

  const isSelectAll = useMemo(() => {
    if (!entityIds.length) return false;
    const numberOfSelectedPerks = Object.values(selected).reduce(
      (acc, val) => (val ? (acc = acc + 1) : acc),
      0
    );
    return numberOfSelectedPerks === entityIds.length;
  }, [selected, entityIds]);

  const changeAll = useCallback(
    (value: boolean) => {
      return setSelected(() => {
        return entityIds.reduce((acc, val) => {
          return {
            ...acc,
            [val]: value,
          };
        }, {});
      });
    },
    [entityIds, selected]
  );

  return {
    selectedArray,
    selected,
    isSelectAll,
    changeAll,
    toggle: (id: string) =>
      setSelected((prev: any) => ({
        ...prev,
        [id]: prev[id] ? !prev[id] : true,
      })),
  };
};
