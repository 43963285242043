import React, { lazy, Suspense, useEffect, useMemo, useState } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { pusher } from "config";

import { useOnMount } from "hooks/useOnMount";

import { DiContext } from "app/common";
import { Layout } from "components/Layout/Layout";

import {
  LobbyPage,
  SchedulePage,
  StagePage,
  TrackPage,
  PrivacyPage,
  TermsPage,
  AdwProPage,
} from "app/presentation/dashboard";

import { ApiService, AppDispatch, PusherBeam, RootState, Firebase } from "services";
import { connect, DispatchProp, Matching } from "react-redux";

import { useDebounce } from "hooks/useDebounce";

import { AppMode, mode } from "config/initializers";
import { logout } from "services/AuthService";
import { UserEntity } from "app/infra/user";
import { TicketTypeService } from "app/infra/ticketType";
import { ChatService } from "app/infra/chat";
import { NotificationBareData, PushNotificationStore } from "app/infra/pushNotification/pushNotification.store";

import { BoothView } from "app/presentation/dashboard/booths/booth.view";
// import { ReplayPage } from "app/presentation/dashboard/replay/replay.page";
// import { ViewReplayPage } from "app/presentation/dashboard/replay/viewReplay.page";

import { PasswordRecoveryForm } from "components/User/Password/Recovery/PasswordRecoveryForm";
import { PasswordForm } from "components/User/Password/PasswordForm";
import * as Passwordless from "components/Auth/Passwordless";
import { RegistrationForm } from "components/User/Registration/RegistrationForm";
import { SessionForm } from "components/Auth/Session/SessionForm";
import { CompanyUpdate } from "components/Company/CompanyUpdate";
import { UserUpdate } from "components/User/UserUpdate";
import { User } from "components/User/User";

import { isMobile } from "react-device-detect";

import { notification } from "antd";

import { CurrentTalkUpdater } from "app/presentation/common/currentTalkUpdater";

import * as Admin from "components/@admin";

import ScrollToTop from "app/presentation/scrollToTop";
import { ChatPixel } from "components/Chat/ChatPixel";
import { Chat } from "components/Chat/Chat";
import { CompanyCardListSection } from "components/Company/CompanyCardListSection";
import { UserListSection } from "components/User/UserListSection";
import PerkList from "components/Perk/PerkList";
import { ReplaySection } from "app/presentation/admin/replay";
import { UserSection } from "app/presentation/admin/user";
import { TicketTypesSection } from "app/presentation/admin/ticketTypes";
import { StatsSection } from "app/presentation/admin/stats";
import { PublicRoute } from "./PublicRoute";
import { PrivateRoute } from "./PrivateRoute";

interface RouterProps extends Matching<{ token: null } & DispatchProp, unknown> {
  token: string | null;
  isLoggedIn: boolean;
  isAdmin: boolean;
  me: UserEntity | null;
  dispatch: AppDispatch;
}

const mapStateToProps = (state: RootState) => {
  return {
    token: state.authStore.token,
    isLoggedIn: state.authStore.isLoggedIn,
    isAdmin: state.authStore.isAdmin,
    me: state.userStore.byId["me"],
  };
};

// const AdminPage = lazy(() => import("app/presentation/admin/admin.page"));

export const Router = connect(mapStateToProps)((props: RouterProps) => {
  const [expired, setExpired] = useState(false);

  const { dispatch } = props;

  const isProfileNotComplete = useMemo(() => {
    return props.me ? !props.me.isComplete : false;
  }, [props.me]);

  const hasReplayAccess = useMemo(() => {
    return props.me?.replayAccess || props.me?.ticket_type === "Company";
  }, [props.me]);

  const debouncedExpired = useDebounce(expired, 150);
  useEffect(() => {
    if (debouncedExpired) {
      notification.error({ message: "You were logged out, because this login was used on another device or browser." });

      setTimeout(() => { setExpired(true); }, 100);
    }
  }, [debouncedExpired]);

  const [apiService] = useMemo(() => {
    return [
      new ApiService(
        props.token || "",
        () => { logout({ dispatch }); },
        () => { setExpired(true); },
      ),
    ];
  }, [props.token]);

  const entityServices = useMemo(() => {
    const commonDeps = { dispatch, apiService };

    return {
      TicketTypeService: TicketTypeService(commonDeps),
      ChatService: ChatService(commonDeps),
    };
  }, [apiService]);

  const pusherBeamService = useMemo(() => {
    return new PusherBeam(pusher.beam.instanceId, pusher.beam.tokenProviderURL);
  }, []);

  useEffect(() => {
    if (props.isLoggedIn) {
      pusherBeamService.subscribe(props.token, pusher.beam.interest);
      Firebase.subscribeToChatPushNotifications(dispatch);
      Firebase.onMessageListener((payload: any) => {
          notification
            .info({
              message:
                <a href={payload?.notification?.click_action || "/app/chats"}>
                  {payload?.notification?.body}
                </a>
            })
        });
    } else {
      pusherBeamService.unSubscribe();
    }
  }, [props.isLoggedIn]);

  useOnMount(() => {
    if (navigator.serviceWorker) {
      const receivedPushNotification = (data: MessageEvent<NotificationBareData>) => {
        if (!data.data.isFirebaseMessaging) {
          dispatch(PushNotificationStore.actions.unread.create(data.data));
        }
      };

      navigator.serviceWorker.addEventListener("message", receivedPushNotification);
    }
  });

  const adminOrDifferentFromOnboarding = useMemo(() => {
    return props.isAdmin || mode !== AppMode.onboarding;
  }, [props.isAdmin]);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <BrowserRouter>
        <DiContext.Provider value={{ apiService, dispatch, entityServices }}>
          {props.isAdmin && (
            <Route path="/admin">
              <Admin.Layout>
                <Route path="/admin/schedule/tracks" component={Admin.TrackList} exact={true} />
                <Route path="/admin/schedule/tracks/new" component={Admin.TrackCreate} exact={true} />
                <Route path="/admin/schedule/tracks/:id/edit" component={Admin.TrackUpdate} exact={true} />

                <Route path="/admin/schedule/stages" component={Admin.StageList} exact={true} />
                <Route path="/admin/schedule/stages/new" component={Admin.StageCreate} exact={true} />
                <Route path="/admin/schedule/stages/:id/edit" component={Admin.StageUpdate} exact={true} />

                <Route path="/admin/schedule/talks" component={Admin.TalkList} exact={true} />
                <Route
                  path="/admin/schedule/talks/:id"
                  exact={true}
                  render={(routerProps) => <Admin.TalkView id={routerProps.match.params.id} />}
                />

                <Route path="/admin/schedule/talks/new" component={Admin.TalkCreate} exact={true} />
                <Route path="/admin/schedule/talks/:id/edit" component={Admin.TalkUpdate} exact={true} />

                <Route path="/admin/replay/tracks" component={ReplaySection} exact={true} />
                <Route path="/admin/replay/stages" component={ReplaySection} exact={true} />
                <Route path="/admin/replay/talks" component={ReplaySection} exact={true} />

                <Route path="/admin/sponsorships" component={Admin.SponsorshipList} exact={true} />
                <Route path="/admin/sponsorships/new" component={Admin.SponsorshipCreate} exact={true} />
                <Route path="/admin/sponsorships/:id/edit" component={Admin.SponsorshipUpdate} exact={true} />

                <Route path="/admin/highlights" component={Admin.HighlightList} exact={true} />
                <Route path="/admin/highlights/new" component={Admin.HighlightCreate} exact={true} />
                <Route path="/admin/highlights/:id/edit" component={Admin.HighlightUpdate} exact={true} />

                <Route path="/admin/perks" component={Admin.PerkList} exact={true} />
                <Route path="/admin/perks/new" component={Admin.PerkCreate} exact={true} />
                <Route path="/admin/perks/:id/edit" component={Admin.PerkUpdate} exact={true} />

                <Route path="/admin/raffles" component={Admin.RaffleList} exact={true} />
                <Route path="/admin/raffles/new" component={Admin.RaffleCreate} exact={true} />
                <Route path="/admin/raffles/:id/edit" component={Admin.RaffleUpdate} exact={true} />

                <Route path="/admin/companies" component={Admin.CompanyList} exact={true} />
                <Route path="/admin/companies/new" component={Admin.CompanyCreate} exact={true} />
                <Route path="/admin/companies/:id/edit" component={Admin.CompanyUpdate} exact={true} />

                <Route path="/admin/users" component={UserSection} exact={true} />

                <Route path="/admin/notifications" component={Admin.NotificationList} exact={true} />
                <Route path="/admin/notifications/new" component={Admin.NotificationCreate} exact={true} />
                <Route path="/admin/notifications/:id/edit" component={Admin.NotificationUpdate} exact={true} />

                <Route path="/admin/posts" component={Admin.PostList} exact={true} />
                <Route path="/admin/posts/new" component={Admin.PostCreate} exact={true} />
                <Route path="/admin/posts/:id/edit" component={Admin.PostUpdate} exact={true} />

                <Route path="/admin/tickets" component={TicketTypesSection} exact={true} />
                <Route path="/admin/stats" component={StatsSection} exact={true} />
              </Admin.Layout>
            </Route>
          )}

          <>
            <ScrollToTop />
            <Switch>
              <Route path="/" exact={true}>
                <Redirect to="/auth/login" />
              </Route>

              <Route path="/privacy">
                <PrivacyPage />
              </Route>

              <Route path="/terms">
                <TermsPage />
              </Route>

              {adminOrDifferentFromOnboarding && (
                <Route
                  path="/api/share/:id"
                  render={(routerProps) => <Redirect to={`/app/talk/${routerProps.match.params.id}`} />}
                />
              )}

              <PublicRoute path="/auth" isLoggedIn={props.isLoggedIn}>
                <Layout>
                  <>
                    <Route path="/auth/register" component={RegistrationForm} />
                    <Route path="/auth/password-login" component={SessionForm} />

                    <Route path="/auth/login" component={Passwordless.SessionForm} />
                    <Route path="/auth/passwordless-login-verify" component={Passwordless.Session} />

                    <Route path="/auth/reset-password" component={PasswordRecoveryForm} />
                    <Route path="/auth/confirm-reset-password" component={PasswordForm} />
                  </>
                </Layout>
              </PublicRoute>

              <PrivateRoute path="/app" isLoggedin={props.isLoggedIn}>
                <Layout>
                  <>

                    <Route path="/app/me" render={() => <User userId="me" />} />
                    <Route
                      path="/app/user-profile/:id"
                      exact={true}
                      render={(routerProps) => {
                        const userId = routerProps.match.params.id;
                        return (
                          props.me?.id.toString() === userId || props.me?.old_id?.toString() === userId ? (
                            <Redirect to="/app/me" />
                          ) : (
                            <User userId={userId} />
                          )
                        );
                      }}
                    />
                    <Route path="/app/user-profile/:id/edit" component={UserUpdate} />

                    <Route
                      path="/app/company-profile/:id"
                      exact={true}
                      render={(routerProps) => <Redirect to={`/app/booth/${routerProps.match.params?.id}`} />}
                    />
                    <Route path="/app/company-profile/:id/edit" component={CompanyUpdate} />

                    {!hasReplayAccess && isProfileNotComplete && <Redirect to="/app/me" />}
                    {hasReplayAccess && <Redirect to="/app/stage" />}

                    <Route path="/app/schedule" component={SchedulePage} />
                    <Route path="/app/booth/:id" component={BoothView} />

                    {/* <>
                      {props.me?.replayAccess && (
                        <Route path="/app/replays" component={ReplayPage} />
                      )}
                      <Route path="/app/replay/:id" component={ViewReplayPage} />
                    </> */}

                    <Route path="/app/directory/speakers">
                      <UserListSection role="ROLE_SPEAKER" />
                    </Route>

                    <Route path="/app/directory/attendees">
                      <UserListSection role="ROLE_ATTENDEE" />
                    </Route>

                    <Route path="/app/directory/companies" component={CompanyCardListSection} />

                    {adminOrDifferentFromOnboarding && (
                      <>
                        {props.me?.hasChatAccess && (
                          <ChatPixel />
                        )}

                        <Route path="/app/stage" component={StagePage} />
                        <Route path="/app/talk/:id" component={TrackPage} />
                        <Route path="/app/perks" exact={true} component={PerkList} />
                        <Route path="/app/perks/:id" exact={true} component={PerkList} />
                        <Route path="/app/lobby" component={LobbyPage} />
                        <Route path="/app/lobby/post/:id" component={LobbyPage} />
                        <Route path="/app/pro" exact={true} component={AdwProPage} />

                        {!isMobile && props.me?.hasChatAccess && (
                          <Route path="/app/chats/:id?" component={Chat} />
                        )}
                      </>
                    )}
                    <CurrentTalkUpdater />
                  </>
                </Layout>
              </PrivateRoute>
            </Switch>
          </>
        </DiContext.Provider>
      </BrowserRouter>
    </Suspense>
  );
});
