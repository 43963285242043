import { DiContext } from "app/common";
import { perkService } from "app/infra/perk";
import { AxiosRequestConfig } from "axios";
import { useContext } from "react";
import { Entity, LeadComponent, LeadStatsDTO, ByIdDTO } from "./leadComponent";

export const PerkLeads = ({
  calledFromProfile,
  startDate,
  endDate,
}: {
  calledFromProfile: boolean;
  startDate: string;
  endDate: string;
}) => {
  const { dispatch, apiService } = useContext(DiContext);
  const perkSrv = perkService({ apiService, dispatch });

  return LeadComponent({
    calledFromProfile,
    startDate,
    endDate,
    getAll: perkSrv.getAll as () => Promise<Entity[]>,
    getStats: (calledFromProfile ? perkSrv.getPerkLeads : perkSrv.getPerkStats ) as 
      (data?: LeadStatsDTO | ByIdDTO, config?: AxiosRequestConfig) => 
      Promise< string | ArrayBuffer | ArrayBufferView | Blob>,
    title: "perks",
  });
};
