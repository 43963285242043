import React, { useContext, useEffect, useMemo, useState } from "react";
import _ from "lodash";

import { connect } from "react-redux";
import { RootState } from "services";

import { DiContext } from "app/common";

import { useAsync } from "hooks/useAsync";
import { useOnError } from "hooks/useOnError";
import { useOnMount } from "hooks/useOnMount";

import { CompanyEntity, companyService, companyStore } from "app/infra/company";
import { UserEntity } from "app/infra/user";

import InfiniteScroll from "react-infinite-scroll-component";
import { Col, Row, Skeleton } from "antd";

import { Loading } from "components/Loading/Loading";
import { Link } from "react-router-dom";
import { CompanyCard } from "./CompanyCard";

import * as Card from "./Card";

interface CompanyCardListProps {
  companies: CompanyEntity<UserEntity>[];
  filter: string;
  countries: string[];
  interests: string[];
  expertises: string[];
}

const mapStateToProps = (state: RootState) => {
  const companyIds = _.uniq((state.companyStore.keyIds["booth-list"] || [])
    .map((ref) => state.companyStore.keyIds[ref])
    .flat());

  return {
    companies: companyIds.map((id) => {
      const company = state.companyStore.companiesById[id];
      return {
        ...company,
        users: company.users.map((userId) => state.userStore.byId[userId]),
      };
    }),
  };
};

export const CompanyCardList = connect(mapStateToProps)((props: CompanyCardListProps) => {
  const { apiService, dispatch } = useContext(DiContext);
  const companySrv = companyService({ apiService, dispatch });

  const [page, setPage] = useState(0);
  const [showEmptyState, setShowEmptyState] = useState(false);

  const { execute, isPending, error } = useAsync((searchFilter: string, data?: { page: number }) => {
    return companySrv
      .getCompanies({
        page: data ? data.page : page,
        limit: 20,
        component: "booth-list",
        isLobby: true,
        onlybooths: true,
        isComplete: true,
        search: searchFilter || undefined,
        interest: props.interests,
        expertise: props.expertises,
        countries: props.countries,
      })
      .then((response) => {
        setPage((prev) => prev + 1);
        setShowEmptyState(response.data.length === 0 && response.pagination.page === 0);

        return response;
      });
  });

  useOnMount(() => execute(props.filter));
  useOnError(error);

  useEffect(() => {
    if (page !== 0) {
      dispatch(companyStore.actions.addKeyedIds({ ids: [], keyedstamp: "booth-list" }));
      setPage(0);

      execute(props.filter, { page: 0 });
    }
  }, [props.filter, props.interests, props.expertises, props.countries]);

  const getData = () => {
    if (!isPending) {
      execute(props.filter);
    }
  };

  const companies = useMemo(() => {
    return _.groupBy(props.companies, "ticket_type");
  }, [props.companies]);

  return (
    <>
      {showEmptyState && (
        <div className="directory-empty-state" style={{ textAlign: "center" }}>
          <div style={{ marginTop: 90 }}>
            No results found! Please check other {" "}
            <Link to="/app/directory?type=ATTENDEES" style={{ textDecoration: "underline" }}>Attendees</Link> / {" "}
            <Link to="/app/directory?type=SPEAKERS" style={{ textDecoration: "underline" }}>Speakers</Link> tabs.
          </div>
        </div>
      )}

      <div className="company-list">
        <InfiniteScroll
          next={getData}
          hasMore={true}
          loader={(isPending && (
            <Row key="companies-skeleton-row" gutter={[24, 24]}>
              <Col xs={24} sm={24} md={12} xl={8} xxl={6}>
                <Card.Skeleton />
              </Col>
              <Col xs={24} sm={24} md={12} xl={8} xxl={6}>
                <Card.Skeleton />
              </Col>
              <Col xs={24} sm={24} md={12} xl={8} xxl={6}>
                <Card.Skeleton />
              </Col>
              <Col xs={24} sm={24} md={12} xl={8} xxl={6}>
                <Card.Skeleton />
              </Col>
            </Row>
          ))}
          dataLength={props.companies.length}
          style={{ overflow: "hidden" }}
        >
          {Object.keys(companies).map((key) => (
            <Row key={key} gutter={[24, 24]}>
              {companies[key].map((company) => (
                <Col xs={24} sm={24} md={12} xl={8} xxl={6}>
                  <CompanyCard company={company} key={company.id} />
                </Col>
              ))}
            </Row>
          ))}
        </InfiniteScroll>
      </div>
    </>
  );
});
