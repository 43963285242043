import React, { useEffect } from "react";
import { UserEntity, ConnectionEntity, UserConnectionService } from "app/infra/user";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";

import { RootState } from "services";
import { mode, AppMode } from "config/initializers";
import { useAsync } from "app/common/utils";
import { useOnError } from "hooks/useOnError";

import { MessageCircle } from "react-feather";

import { Loading } from "components/Loading/Loading";
import { UserAvatar } from "components/User/UserAvatar";

interface PersonItemProps {
  user: UserEntity;
  isAdmin: boolean;
  me?: UserEntity;
}

export const PersonItem = connect((state: RootState) => ({
  isAdmin: state.authStore.isAdmin,
  me: state.userStore.byId["me"],
}))(({ user, me, isAdmin }: PersonItemProps) => {
  const history = useHistory();

  const { execute, error, pending, value } = useAsync<ConnectionEntity>(
    UserConnectionService().create,
  );
  useOnError(error);

  useEffect(() => {
    if (value && value.isAccepted) {
      history.push(`/app/chats/${value.chatRoomId}`);
    }
  }, [value]);

  return (
    <Link
      to={
        mode !== AppMode.onboarding || isAdmin
          ? `/app/user-profile/${user.id}`
          : "#"
      }
      className="contact-item"
    >
      <div className="left">
        <UserAvatar user={user} size={70} style={{ marginRight: "15px" }} />

        <div className="content">
          <h4>
            {user.first_name} {user.last_name}
          </h4>
          {user.job_position && <p className="status">{user.job_position}</p>}
        </div>
      </div>

      {(mode !== AppMode.onboarding || isAdmin) && me?.hasChatAccess && (
        <div className="right">
          <div
            onClick={(e) => {
              e.preventDefault();
              execute(user.id);
            }}
          >
            {pending && <Loading size={32} />}
            {!pending && (
              <MessageCircle
                size={20}
                style={{ verticalAlign: "middle" }}
              />
            )}
          </div>
        </div>
      )}
    </Link>
  );
});
