import React, { useContext, useEffect, useMemo, useState } from "react";

import { connect, ConnectedProps } from "react-redux";
import { RootState } from "services";

import { DiContext } from "app/common";
import { useAsync, useOnError, useOnMount } from "hooks";
import { userService, userStore } from "app/infra/user";

import { Row } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";

import { Loading } from "components/Loading/Loading";

import { PersonCard } from "app/presentation/dashboard/directory/personCard";

interface Props extends PropsRedux {
  keystamp: string;
  role: string;
  filter: string;
  countries: string[];
  interests: string[];
}

const UserList = (props: Props) => {
  const { apiService } = useContext(DiContext);
  const userSrv = userService({ apiService, dispatch: props.dispatch });

  const [page, setPage] = useState(0);
  const [showEmptyState, setShowEmptyState] = useState(false);

  const usersList = useMemo(() => {
    const hasComponentAPagination = props.users[props.keystamp];

    if (hasComponentAPagination) {
      return hasComponentAPagination.map((key) => props.users[key]).flat().map((userId) => props.usersById[userId]);
    }
    return [];
  }, [props.users]);

  const { execute, isPending, error } = useAsync((searchFilter: string, data?: { page: number }) => {
    return userSrv.getAll({
      limit: 20,
      page: data ? data.page : page,
      role: props.role,
      component: props.keystamp,
      search: searchFilter || undefined,
      interests: props.interests,
      countries: props.countries,
    }).then((response) => {
      setPage((prev) => prev + 1);
      setShowEmptyState(response.data.length === 0 && response.pagination.page === 0);

      return response;
    });
  });

  const getData = () => {
    if (!isPending) {
      execute(props.filter);
    }
  };

  useOnMount(getData);
  useOnError(error);

  useEffect(() => {
    if (page !== 0) {
      props.dispatch(userStore.actions.addKeyedIds({ keyedstamp: props.keystamp, ids: [] }));
      setPage(0);
      execute(props.filter, { page: 0 });
    }
  }, [props.filter, props.interests, props.countries]);

  const otherTabName = useMemo(() => {
    return props.role === "ROLE_ATTENDEE" ? "Speakers" : "Attendees";
  }, [props.role]);

  return (
    <>
      {showEmptyState && (
        <div className="directory-empty-state" style={{ textAlign: "center", marginTop: 90 }}>
          No results found. Try searching in the <u>{otherTabName}</u> tab.
        </div>
      )}

      <div className="user-list">
        <InfiniteScroll
          next={getData}
          hasMore={true}
          loader={isPending && (
            <div key={0} style={{ marginTop: 20, textAlign: "center" }}>
              <Loading size={40} />
            </div>
          )}
          dataLength={usersList.length}
          style={{ overflow: "none" }}
        >
          <Row gutter={[16, 16]}>
            {usersList.map((user) => (
              <PersonCard key={user.id} person={user} me={props.me} />
            ))}
          </Row>
        </InfiniteScroll>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  users: state.userStore.keyIds,
  usersById: state.userStore.byId,
  me: state.userStore.byId["me"],
});

const connector = connect(mapStateToProps);

type PropsRedux = ConnectedProps<typeof connector>;

export default connector(UserList);
