import React, { useContext } from "react";
import { useHistory } from "react-router-dom";

import { DiContext } from "app/common";
import { useAsync, useOnError } from "hooks";
import { CreateStageDTO, stageService } from "app/infra/stage";

import { Button, Card, Col, notification, Row } from "antd";
import { useForm } from "antd/lib/form/Form";

import { StageForm } from "./StageForm";

export const StageCreate = () => {
  const { apiService, dispatch } = useContext(DiContext);
  const stageServ = stageService({ apiService, dispatch });

  const history = useHistory();

  const [form] = useForm();

  const { execute: onCreate, error: onCreateError } = useAsync((data: CreateStageDTO) => {
    return stageServ.create(data).then((response) => {
      notification.success({ message: "Stage was successfully created" });

      history.push("/admin/schedule/stages");

      return response;
    });
  });
  useOnError(onCreateError);

  return (
    <Row>
      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
        <Card>
          <Row align="middle" style={{ marginBottom: "20px" }}>
            <Col>
              <h1>Create Stage</h1>
            </Col>
          </Row>

          <StageForm form={form} onFinish={onCreate} />

          <Row>
            <Col style={{ marginLeft: "auto" }}>
              <Button type="primary" onClick={form.submit}>Create</Button>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};
