import React, { useContext, useEffect } from "react";
import { Modal, Form, notification } from "antd";
import { DiContext, useAsync } from "app/common/utils";
import { useOnError } from "hooks/useOnError";
import { UserForm } from "./user.form";
import { AdminCreateUserDTO, adminUserService } from "app/infra/user";

interface CreateProfileModalProps {
  visible: boolean;
  close: () => void;
  onSuccess: () => void;
}

export const CreateProfileModal = ({
  visible,
  close,
  onSuccess,
}: CreateProfileModalProps) => {
  const [form] = Form.useForm();
  const { apiService, dispatch } = useContext(DiContext);
  const adminUserSrv = adminUserService({ apiService, dispatch });

  const { error, value, pending, execute } = useAsync(
    (data: AdminCreateUserDTO) => adminUserSrv.create(data),
    false
  );

  useOnError(error);

  const handleOnClose = () => {
    form.resetFields();
    close();
  }

  useEffect(() => {
    if (value) {
      notification.success({
        message: "User was created!",
      });
      onSuccess();
      close();
    }
  }, [value]);

  return (
    <Modal
      className="create-profile-modal"
      visible={visible}
      destroyOnClose={true}
      onCancel={handleOnClose}
      title="Create User"
      okText="Create"
      cancelText="Close"
      onOk={() => {
        form.submit();
      }}
    >
      <Form
        form={form}
        onFinish={execute}
        onFinishFailed={() => notification.error({ message: "Invalid Form", description: "Please fix all invalid fields to be able to save your changes."}) }
        name="create-user"
        layout="vertical">
        <UserForm pending={pending} create={true} />
      </Form>
    </Modal>
  );
};
