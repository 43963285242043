import React from "react";
import { Link, useLocation } from "react-router-dom";
import { parse } from "qs";
import { app } from "config";

import { useAsync, useOnError } from "hooks";
import { RegisterUser, UserService } from "services/UserService";

import { Button, Checkbox, Form, Input, notification, Tooltip } from "antd";
import { Info, Layers, Lock, Mail } from "react-feather";
import logo from "assets/images/adw/logo/logo-dark.svg";

export const RegistrationForm = () => {
  const location = useLocation();
  const userService = UserService();

  const { execute, isPending, error } = useAsync((data: RegisterUser) => {
    return userService
      .registrations
      .create(data)
      .then(() => notification.info({ message: "Successfully registered!" }));
  });

  useOnError(error);

  const queryParams = parse(location.search, { ignoreQueryPrefix: true });

  return (
    <div className="auth-wrapper">
      <div className="auth-wrapper-form-container">
        <Form
          layout="vertical"
          onFinish={execute}
        >
          <div className="logo">
            <img src={logo} alt={`${app.name} Logo`} />
            <h1 className="title">March 2023</h1>
          </div>

          <Form.Item
            name="email"
            initialValue={queryParams.email}
            label={(
              <Tooltip title="The email address you used to purchase your ticket">
                <span style={{ display: "flex", alignItems: "center" }}>
                  Registration email
                  <Info size={16} style={{ marginLeft: 5, verticalAlign: "middle" }} />
                </span>
              </Tooltip>
            )}
            rules={[
              {
                type: "email",
                message: "Not a valid email address",
              },
              {
                required: true,
                message: "This field is required",
              },
            ]}
          >
            <Input
              type="email"
              placeholder="info@example.com"
              prefix={<Mail size={20} />}
              disabled={!!queryParams.email}
            />
          </Form.Item>

          <Form.Item
            name="reference"
            initialValue={queryParams.reference}
            label={(
              <Tooltip
                title="Please enter your 5 digit ticket reference code from your ticket confirmation email e.g: 3ABC-1"
              >
                <span style={{ display: "flex", alignItems: "center" }}>
                  Ticket Code
                  <Info size={16} style={{ marginLeft: 5, verticalAlign: "middle" }} />
                </span>
              </Tooltip>
            )}
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Input
              placeholder="e.g ABBC-6"
              prefix={<Layers size={20} />}
              disabled={!!queryParams.reference}
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please input your password" }]}
          >
            <Input.Password
              placeholder="Setup password"
              prefix={<Lock size={20} />}
            />
          </Form.Item>

          <Form.Item
            name="passwordRepeat"
            rules={[
              {
                required: true,
                message: "Please confirm your password",
              },
              ({ getFieldValue }) => ({
                validator: (_rule, val, callback) => {
                  if (val !== getFieldValue("password")) {
                    callback("Should be the same as password");
                  } else {
                    callback();
                  }
                },
              }),
            ]}
          >
            <Input
              type="password"
              placeholder="Repeat password"
              prefix={<Lock size={20} />}
            />
          </Form.Item>

          <br />

          <Form.Item
            name="terms"
            label={(
              <Tooltip title="Click to view our terms and conditions">
                <Link to="/terms" target="_blank">
                  Terms and conditions
                  <Info size={16} style={{ marginLeft: 5, verticalAlign: "middle" }} />
                </Link>
              </Tooltip>
            )}
            rules={[
              {
                required: true,
                message: "Please review and accept our terms and conditions.",
              },
            ]}
            valuePropName="checked"
          >
            <Checkbox>
              I agree to the <Link to="/terms">terms and conditions</Link>.
            </Checkbox>
          </Form.Item>

          <Form.Item
            name="consented"
            label={(
              <Tooltip title="Click to view our privacy policy">
                <Link to="/privacy" target="_blank">
                  Data privacy
                  <Info size={16} style={{ marginLeft: 5, verticalAlign: "middle" }} />
                </Link>
              </Tooltip>
            )}
            valuePropName="checked"
          >
            <Checkbox>
              I agree to the <Link to="/privacy">privacy policy</Link>.
            </Checkbox>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isPending} block={true}>
              Register
            </Button>
          </Form.Item>

          <hr />

          <Form.Item style={{ textAlign: "center" }}>
            <Link to="/auth/login" className="didnt-receive-login">
              Already registered? Login here
            </Link>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
