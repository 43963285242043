import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { ConnectionEntity, UserConnectionService, UserEntity } from "app/infra/user";
import { useAsync } from "app/common";
import { useOnError } from "hooks/useOnError";
import { Spin, Col, Tag } from "antd";
import { Check, MapPin, MessageCircle } from "react-feather";
import { UserAvatar } from "components/User/UserAvatar";

interface PersonCardProps {
  person: UserEntity;
  simpleActionButton?: boolean;
  me?: UserEntity;
}

export const PersonCard = ({ person, me }: PersonCardProps) => {
  const history = useHistory();

  const { execute, error, pending, value } = useAsync<ConnectionEntity>(
    UserConnectionService().create,
  );
  useOnError(error);

  useEffect(() => {
    if (value && value.isAccepted) {
      history.push(`/app/chats/${value.chatRoomId}`);
    }
  }, [value]);

  return (
    <Col sm={24} md={12} style={{ width: "100%" }}>
      <Link to={`/app/user-profile/${person.id}`} className="person-card">
        <div className="content">
          {me?.old_id !== person.id && me?.hasChatAccess && (
            <div
              className="contact-attendee"
              onClick={(e) => {
                e.preventDefault();
                execute(person.id);
              }}
            >
              {pending && <Spin />}
              {!pending && <MessageCircle size={20} />}
            </div>
          )}
          <div className="card-header">
            <UserAvatar user={person} style={{ marginRight: "15px" }} />

            <div className="user-info-header">
              <div className="user-info-name">
                {person.first_name} {person.last_name}

                {(person.roles.includes("ROLE_SPEAKER") || person.isPromoted) && (
                  <Check
                    size={18}
                    color="white"
                    strokeWidth={4}
                    style={{
                      padding: 4,
                      background: "#0CC2FF",
                      borderRadius: "50%",
                      marginLeft: 5,
                    }}
                  />
                )}
              </div>
              <div className="subtitle">
                {person.job_position && (
                  <span className="user-info-job">
                    {person.job_position}
                    {(person.company && " @ " + person.company.name) ||
                      (person.attendee_company &&
                        " @ " + person.attendee_company) ||
                      ""}
                  </span>
                )}
                {person.country && (
                  <span className="user-info-country">
                    <MapPin size={18} style={{ marginRight: 5 }} />
                    {person.country}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="user-info">
            <div className="tag-container">
              {person.interested && person.interested.length > 0 && (
                <div className="tags tags-interested" style={{ flex: 1 }}>
                  <span className="tag-title">Interested in:</span>
                  <div className="tags-wrapper">
                    {person.interested.map((topic) => (
                      <Tag key={`interested-in-${topic}`} className={`ant-tag-small ${topic}`}>
                        {topic}
                      </Tag>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Link>
    </Col>
  );
};
