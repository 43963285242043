/* eslint-disable max-len */
import React, { useContext, useMemo, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { app } from "config";

import { DiContext } from "app/common";

import { useAsync } from "hooks/useAsync";
import { useOnError } from "hooks/useOnError";
import { useModal } from "hooks/useModal";

import { characterCountFormatter } from "helpers/characterCountFormatter";

import { UserEntity, userService } from "app/infra/user";

import { country_list } from "app/common/data";
import { attendeeCompanyType, attendeeInterests } from "app/core/user";

import { Loading } from "components/Loading/Loading";

import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Layout,
  Modal,
  notification,
  PageHeader,
  Row,
  Select,
  Switch,
  Tabs,
  Tooltip,
  Upload,
} from "antd";
import { RcFile } from "antd/lib/upload";
import { SelectValue } from "antd/lib/select";
import { AlertTriangle, Paperclip, RefreshCw } from "react-feather";
import ImgCrop from "antd-img-crop";

import pattern from "assets/images/booths/marketplace-company-header-icon.svg";

import { UserAvatar } from "./UserAvatar";
import { UserPasswordForm } from "./UserPasswordForm";
import _ from "lodash";

const { TabPane } = Tabs;

interface UserFormProps {
  user: UserEntity;
}

export const UserUpdateForm = (props: UserFormProps) => {
  const jobPositions = [
    "Account Manager",
    "Account Strategist",
    "CEO",
    "CMO",
    "Content Marketer",
    "Copywriter",
    "Creative Strategist",
    "CRO Expert",
    "Designer",
    "Digital Marketer",
    "Director",
    "Ecommerce Manager",
    "Email Marketer",
    "Founder",
    "Growth Manager",
    "Marketing Manager",
    "Media Buyer",
    "Social Media Manager",
    "Product Marketer",
    "SEO Specialist",
    "Other",
  ];

  const careerGoals = [
    "Promotion to C-level",
    "Promotion to Senior",
    "Start my own business",
    "Be a better Manager/Leader",
    "Become an industry expert",
    "Diversify my skillset",
    "Shift careers",
    "Build and grow my team",
    "Improve my companies marketing efficiency",
    "Close large clients",
    "Other",
  ];

  const [form] = Form.useForm();

  const beforeUpload = (file: RcFile) => {
    if (file.size > 5000000) {
      notification.error({ message: "Photo must be less than 5mb" });
      return false;
    }

    return true;
  };

  const { apiService, dispatch } = useContext(DiContext);
  const userSrv = userService({ apiService, dispatch });

  const {
    execute: upload,
    isPending: isUploadPending,
    error: uploadError,
  } = useAsync(({ file }: { file: string | Blob }) => {
    return userSrv.updateProfilePhoto({ photo: file }).then((response) => {
      return response;
    });
  });

  useOnError(uploadError);

  const [isError, setIsError] = useState(false);

  const history = useHistory();

  const { execute, isPending, error } = useAsync((data: any) => {
    setIsError(false);

    return userSrv.updateMe(data).then((response) => {
      history.push(`/app/${props.user.id}`);
      notification.success({ message: "Profile was successfully updated" });

      return response;
    });
  });

  useOnError(error);

  const handleError = (errorInfo: any) => {
    setIsError(true);
    console.log(errorInfo);

    notification.error({
      message: "Invalid form",
      description: "Please fix all invalid fields to be able to save your changes",
    });
  };

  const bioCharacterLimit = useMemo(() => {
    return props.user.roles.length > 0 && props.user.roles[0] === "ROLE_SPEAKER" ? 1200 : 250;
  }, [props.user.roles]);

  const tag = useMemo(() => {
    const role = props.user.company?.ticket_type || props.user.roles.length > 0 ? props.user.roles[0] : "ROLE_ATTENDEE";

    switch (role) {
      case "ROLE_ATTENDEE":
        return "Attendee";
      case "ROLE_SPEAKER":
        return "Speaker";
      case "ROLE_ADMIN":
        return "Admin";
      default:
        return "Company";
    }
  }, [props.user.company, props.user.roles]);

  const handleTabClick = (key: string) => {
    if (key === "2") {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      history.push(`/app/company-profile/${props.user.company!.id}/edit`);
    }
  };

  const passwordModal = useModal();
  const [passwordForm] = Form.useForm();

  const [isJobPositionOther, setIsJobPositionOther] = useState(
    props.user.job_position && (
      props.user.job_position.toLowerCase() === "other"
      || jobPositions.findIndex((el) => el === props.user.job_position) === -1
    ),
  );

  const handleOptionalSelect = (value: SelectValue) => {
    if (value.toString().toLowerCase() === "other") {
      form.setFieldsValue({ job_position: "" });
      setIsJobPositionOther(true);
      setTimeout(() => { form.getFieldInstance("job_position").input.focus(); }, 250);
    } else {
      form.setFieldsValue({ job_position: value.toString() });
      setIsJobPositionOther(false);
    }
  };

  const [isCareerGoalOther, setIsCareerGoalOther] = useState(
    props.user.careerGoal && (
      props.user.careerGoal.toLowerCase() === "other"
      || careerGoals.findIndex((el) => el === props.user.careerGoal) === -1
    ),
  );

  const handleCareerGoalOptionalSelect = (value: SelectValue) => {
    if (value.toString().toLowerCase() === "other") {
      form.setFieldsValue({ careerGoal: "" });
      setIsCareerGoalOther(true);
      setTimeout(() => { form.getFieldInstance("careerGoal").input.focus(); }, 250);
    } else {
      form.setFieldsValue({ careerGoal: value.toString() });
      setIsCareerGoalOther(false);
    }
  };

  const passwordActionText = useMemo(() => {
    return props.user.hasPassword ? "Change Password" : "Setup Password";
  }, [props.user.hasPassword]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={execute}
      onFinishFailed={handleError}
    >
      <PageHeader
        ghost={false}
        extra={[
          <Button
            key="update-user-form-reset-info-btn"
            type="link"
            icon={(
              <RefreshCw size={18} style={{ verticalAlign: "middle", marginRight: 10 }} />
            )}
            onClick={() => { form.resetFields(); }}
          >
            Reset Info
          </Button>,
          <Button key="update-user-form-save-changes-btn" type="primary" htmlType="submit">Save changes</Button>,
        ]}
        style={{ position: "sticky", top: "57px", width: "100%", zIndex: 999 }}
      />

      <Layout className="wrapper" style={{ marginTop: "40px" }}>
        <Tabs
          defaultActiveKey="1"
          tabBarGutter={40}
          tabBarStyle={{ borderBottom: 0 }}
          onTabClick={handleTabClick}
        >
          <TabPane
            key="1"
            tab="My Profile"
          />

          {props.user.company && (
            <TabPane
              key="2"
              tab="Company Profile"
            />
          )}
        </Tabs>

        <Row gutter={{ md: 20, lg: 40, xxl: 60 }}>
          <Col xs={24} lg={12}>
            <Card style={{ marginBottom: "20px" }}>
              <div
                style={{
                  position: "absolute",
                  top: "0px",
                  left: "0px",
                  right: "0px",
                  backgroundColor: "#1D0D43",
                  height: "125px",
                  borderTopLeftRadius: "5px",
                  borderTopRightRadius: "5px",
                }}
              >
                <img src={pattern} alt="" style={{ position: "absolute" }} />

                <ImgCrop>
                  <Upload
                    name="avatar"
                    accept=".png, .jpg, .jpeg"
                    listType="picture-card"
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                    customRequest={upload}
                    className="ant-upload-picture-card-wrapper-circle"
                    disabled={isPending || isUploadPending}
                  >
                    <span>
                      <UserAvatar user={props.user} size={108} style={{ zIndex: 0 }} />

                      {isUploadPending && (
                        <div
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            background: "#FFFFFF",
                            width: "40px",
                            height: "40px",
                            borderRadius: "50%",
                            zIndex: 4,
                          }}
                        >
                          <Loading
                            style={{ marginTop: "5px" }}
                            size={30}
                          />
                        </div>
                      )}

                      <Tooltip
                        title={(
                          <b>
                            Upload image (.png/.jpg/.jpeg) <br /> recommended size is 150x150
                          </b>
                        )}
                        placement="top"
                        color="#000000"
                        overlayInnerStyle={{ textAlign: "center" }}
                      >
                        <Button
                          size="small"
                          shape="circle"
                          style={{ background: "#FFFFFF", zIndex: 5 }}
                          disabled={isPending || isUploadPending}
                        >
                          <Paperclip size={14} />
                        </Button>
                      </Tooltip>
                    </span>
                  </Upload>
                </ImgCrop>

                <b style={{ position: "absolute", top: "20px", right: "20px", color: "#ffffff" }}>{tag}</b>
              </div>

              <h2 style={{ marginTop: "140px" }}>
                About You

                <Button
                  type="link"
                  style={{ float: "right", marginTop: "-5px", padding: "0px", color: "#32BEEA" }}
                  onClick={passwordModal.open}
                >
                  {passwordActionText.toLowerCase()}
                </Button>
              </h2>

              <Modal
                visible={passwordModal.isVisible}
                onOk={passwordForm.submit}
                okText={passwordActionText}
                onCancel={() => {
                  passwordModal.shut();
                  passwordForm.resetFields();
                }}
              >
                <UserPasswordForm form={passwordForm} onFinish={passwordModal.shut} user={props.user} />
              </Modal>

              <Row gutter={20}>
                <Col span={12}>
                  <Form.Item
                    name="first_name"
                    label="First Name"
                    initialValue={props.user.first_name}
                    rules={[
                      {
                        required: true,
                        message: "First Name is required",
                      },
                      {
                        min: 2,
                        max: 20,
                        message: "First Name must be within 2 - 20 characters",
                      },
                    ]}
                  >
                    <Input placeholder="First Name" disabled={isPending} />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    name="last_name"
                    label="Last Name"
                    initialValue={props.user.last_name}
                    rules={[
                      {
                        required: true,
                        message: "Last Name is required",
                      },
                      {
                        min: 2,
                        max: 20,
                        message: "Last Name must be within 2 - 20 characters",
                      },
                    ]}
                  >
                    <Input placeholder="Last Name" disabled={isPending} />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                name="gender"
                label="Gender"
                initialValue={props.user.gender}
              >
                <Select placeholder="Gender" disabled={isPending}>
                  <Select.Option value="female">Female</Select.Option>
                  <Select.Option value="male">Male</Select.Option>
                  <Select.Option value="other">Other</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                name="age"
                label="Age"
                initialValue={props.user.age}
              >
                <Select placeholder="Age" disabled={isPending}>
                  <Select.Option value="18-24">18-24</Select.Option>
                  <Select.Option value="25-34">25-34</Select.Option>
                  <Select.Option value="35-44">35-44</Select.Option>
                  <Select.Option value="45-54">45-54</Select.Option>
                  <Select.Option value="55-64">55-64</Select.Option>
                  <Select.Option value="65+">65+</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="Job Title"
                required={true}
              >
                <Select
                  placeholder="Job Title e.g: CEO"
                  showSearch={true}
                  disabled={isPending}
                  onChange={handleOptionalSelect}
                  defaultValue={isJobPositionOther ? "Other" : String(_.toString(props.user.job_position)) || undefined}
                >
                  {jobPositions.map((position) => (
                    <Select.Option key={`job-title-${position}`} value={position}>{position}</Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="job_position"
                initialValue={props.user.job_position}
                shouldUpdate={(prevValues, currentValues) => prevValues.job_position !== currentValues.job_position}
                rules={[
                  {
                    required: true,
                    message: "Job Title is required",
                  },
                  {
                    min: 2,
                    max: 30,
                    message: "Job Title must be withing 2 - 30 characters",
                  },
                ]}
                style={{ display: isJobPositionOther ? "block" : "none" }}
              >
                <Input
                  placeholder="Job Title"
                  disabled={isPending}
                />
              </Form.Item>

              {!props.user.company && (
                <Form.Item
                  name="attendee_company"
                  label="Company Name"
                  initialValue={props.user.attendee_company}
                  rules={[
                    {
                      required: true,
                      message: "Company Name is required",
                    },
                    {
                      min: 2,
                      max: 50,
                      message: "Company Name must be within 2 - 50 characters",
                    },
                  ]}
                >
                  <Input placeholder="Company Name" disabled={isPending} />
                </Form.Item>
              )}

              <Form.Item
                name="attendee_company_type"
                label="Attendee Type"
                rules={[
                  {
                    required: true,
                    message: "Attendee Type is required",
                  },
                ]}
                initialValue={props.user.attendee_company_type}
              >
                <Select placeholder="Attendee Type" disabled={isPending}>
                  {attendeeCompanyType.map((type) => (
                    <Select.Option key={`atendee-type-${type}`} value={type}>
                      {type}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="country"
                label="Country"
                rules={[
                  {
                    required: true,
                    message: "Country is required",
                  },
                ]}
                initialValue={props.user.country}
              >
                <Select placeholder="Country" showSearch={true} disabled={isPending}>
                  {country_list.map((country) => (
                    <Select.Option key={`user-update-form-country${country}`} value={country}>
                      {country}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="city"
                label="City"
                rules={[
                  {
                    required: true,
                    message: "City is required",
                  },
                  {
                    min: 2,
                    max: 50,
                    message: "City name must be within 2 - 50 characters",
                  },
                ]}
                initialValue={props.user.city}
              >
                <Input placeholder="City" disabled={isPending} />
              </Form.Item>

              <Form.Item
                name="website"
                label="Website"
                rules={[
                  {
                    type: "url",
                    message: "Not a valid URL",
                  },
                ]}
                initialValue={props.user.website}
              >
                <Input placeholder="Website URL e.g: https://example.com" disabled={isPending} />
              </Form.Item>

              <Form.Item
                name="attendee_company_size"
                label="What is your Company size?"
                initialValue={props.user.attendee_company_size}
              >
                <Select placeholder="Company Size" disabled={isPending}>
                  <Select.Option value="less-5">Small &lt;5 Employees</Select.Option>
                  <Select.Option value="10-75">Small to Medium 10 - 75 Employees</Select.Option>
                  <Select.Option value="75-500">Medium 75 - 500 Employees</Select.Option>
                  <Select.Option value="500-5000">Medium to Large 500 - 5000 Employees</Select.Option>
                  <Select.Option value="5000+">Large 5000&gt; Employees</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                name="monthly_revenue"
                label="How much monthly revenue do you generate? (Not Public)"
                initialValue={props.user.monthly_revenue && props.user.monthly_revenue.toString()}
              >
                <Select placeholder="Monthly Revenue" disabled={isPending}>
                  <Select.Option value="1">$0 - $50k per month</Select.Option>
                  <Select.Option value="2">$50k - $200k per month</Select.Option>
                  <Select.Option value="3">$200k - $500k per month</Select.Option>
                  <Select.Option value="4">$500k - $1M per month</Select.Option>
                  <Select.Option value="5">$1M - $10M per month</Select.Option>
                  <Select.Option value="6">$10M+ per month</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item
                name="monthly_adspend"
                label="How much monthly ad spend do you manage? (Not Public)"
                initialValue={props.user.monthly_adspend && props.user.monthly_adspend.toString()}
              >
                <Select placeholder="Monthly Advertisement Spending" disabled={isPending}>
                  <Select.Option value="1">$0 - $20k per month</Select.Option>
                  <Select.Option value="2">$20k - $50k per month</Select.Option>
                  <Select.Option value="3">$50k - $100k per month</Select.Option>
                  <Select.Option value="4">$100k - $500k per month</Select.Option>
                  <Select.Option value="5">$500k - $2M per month</Select.Option>
                  <Select.Option value="6">$2M per month</Select.Option>
                </Select>
              </Form.Item>
            </Card>
          </Col>

          <Col xs={24} lg={12}>
            <Card style={{ marginBottom: "20px" }}>
              {!props.user.isComplete && (
                <>
                  <div
                    style={{
                      position: "absolute",
                      top: "0px",
                      left: "0px",
                      right: "0px",
                      backgroundColor: isError ? "#F35F5C" : "rgb(29, 13, 67)",
                      borderTopLeftRadius: "5px",
                      borderTopRightRadius: "5px",
                      padding: "25px 20px",
                      color: "#FFFFFF",
                      display: "flex",
                      height: "125px",
                      alignItems: "center",
                    }}
                  >
                    {isError ? (
                      <>
                        <AlertTriangle size={84} color={isError ? "#F35F5C" : "rgb(29, 13, 67)"} fill="#FFC700" />

                        <p style={{ marginBottom: "0px", paddingTop: "10px", paddingLeft: "20px" }}>
                          You haven't finished your profile yet! Please complete to be an <b>{app.name} recommended Attendee</b> and be 100% ready for the Networking Mixers.

                          <br />

                          Bonus: All completed profiles get double entries into the $500 Daily Raffles
                        </p>
                      </>
                    ) : (
                      <p style={{ marginBottom: "0px", paddingTop: "10px", paddingLeft: "20px" }}>
                        Please complete to be an <b>{app.name} recommended Attendee</b> and be 100% ready for the Networking Mixers.
                      </p>
                    )}
                  </div>

                  <div style={{ marginBottom: "140px" }} />
                </>
              )}

              <Form.Item
                label="Your Current Career Goal:"
              >
                <Select
                  placeholder="Current Career Goal e.g: Promotion to CEO"
                  showSearch={true}
                  disabled={isPending}
                  onChange={handleCareerGoalOptionalSelect}
                  defaultValue={isCareerGoalOther ? "Other" : String(_.toString(props.user.careerGoal)) || undefined}
                >
                  {careerGoals.map((careerGoal, i) => (
                    <Select.Option key={`career-goal-${i}`} value={careerGoal}>{careerGoal}</Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="careerGoal"
                initialValue={props.user.careerGoal}
                shouldUpdate={(prevValues, currentValues) => prevValues.careerGoal !== currentValues.careerGoal}
                style={{ display: isCareerGoalOther ? "block" : "none" }}
              >
                <Input
                  placeholder="Career Goal"
                  disabled={isPending}
                />
              </Form.Item>

              <Form.Item
                name="interested"
                label={(
                  <span>
                    Pick 3 focuses for the event:
                  </span>
                )}
                rules={[
                  {
                    type: "array",
                    max: 3,
                    message: "You cannot select more than 3 focuses",
                  },
                ]}
                initialValue={props.user.interested}
              >
                <Select
                  mode="multiple"
                  options={attendeeInterests.map((item) => ({ value: item }))}
                  showArrow={true}
                  showSearch={true}
                  disabled={isPending}
                  placeholder="Focuses"
                />
              </Form.Item>

              <h2>Networking Questions</h2>

              <Form.Item
                name="questionWorkingOn"
                label={(
                  <>
                    <span role="presentation" aria-hidden="true">🖥</span>&nbsp; What I'm Currently Working On:
                  </>
                )}
                rules={[
                  {
                    min: 2,
                    max: 50,
                    message: "Answer must be within 2 - 50 characters",
                  },
                ]}
                initialValue={props.user.questionWorkingOn}
              >
                <Input placeholder="e.g: Lead generation for an upcoming sale" disabled={isPending} />
              </Form.Item>

              <Form.Item
                name="questionBiggestChallenge"
                label={(
                  <>
                    <AlertTriangle size={14} fill="#FFC700" style={{ verticalAlign: "middle" }} />&nbsp; Biggest Challenge Right Now:
                  </>
                )}
                rules={[
                  {
                    min: 2,
                    max: 50,
                    message: "Answer must be within 2 - 50 characters",
                  },
                ]}
                initialValue={props.user.questionBiggestChallenge}
              >
                <Input placeholder="e.g: iOS14 not attributing leads to our campaigns" disabled={isPending} />
              </Form.Item>

              <Form.Item
                name="questionLookingToMeet"
                label={(
                  <>
                    <span role="presentation" aria-hidden="true">🤝</span>&nbsp; Looking To Meet:
                  </>
                )}
                rules={[
                  {
                    min: 2,
                    max: 50,
                    message: "Answer must be within 2 - 50 characters",
                  },
                ]}
                initialValue={props.user.questionLookingToMeet}
              >
                <Input placeholder="e.g: Media buyers specializing in Facebook Ads" disabled={isPending} />
              </Form.Item>

              <small style={{ float: "right", marginBottom: "20px", color: "#CCCCCC" }}>
                (Max 50 characters per answer)
              </small>
              <div style={{ clear: "both" }} />

              <h2><span role="presentation" aria-hidden="true">👋</span>&nbsp; Connect With Me</h2>

              <Form.Item
                name="linkedin"
                label="LinkedIn"
                rules={[
                  {
                    type: "url",
                    message: "Not a valid URL",
                  },
                  {
                    validator: (_, value: string) => {
                      if (!value || /^https:\/\/(www.|[a-z]{2}[.])?linkedin.com\/(in\/[\w\d&=-]*|profile\/view\?id=[\w\d&=-]*|pub\/[\w\d&=-]*|[\w\d&=/-]*)/.test(value)) {
                        return Promise.resolve();
                      }

                      return Promise.reject();
                    },
                    message: "Not a valid LinkedIn URL",
                  },
                ]}
                validateFirst={true}
                initialValue={props.user.linkedin}
              >
                <Input placeholder="Linkedin Profile" disabled={isPending} />
              </Form.Item>

              <Form.Item
                name="twitter"
                label="Twitter"
                rules={[
                  {
                    type: "url",
                    message: "Not a valid URL",
                  },
                  {
                    validator: (_, value: string) => {
                      if (!value || /^https:\/\/(www.|mobile.|m.)?twitter.com\/(@?[\w]{1,15})\/?/.test(value)) {
                        return Promise.resolve();
                      }

                      return Promise.reject();
                    },
                    message: "Not a valid Twitter URL",
                  },
                ]}
                validateFirst={true}
                initialValue={props.user.twitter}
              >
                <Input placeholder="Twitter Profile" disabled={isPending} />
              </Form.Item>

              <Form.Item
                name="facebook"
                label="Facebook"
                rules={[
                  {
                    type: "url",
                    message: "Not a valid URL",
                  },
                  {
                    validator: (_, value: string) => {
                      if (!value || /^https:\/\/(www.|mobile.|m.|touch.|mbasic.)?(facebook.com|fb.me)\/([\w#!?=/.%-]*)/.test(value)) {
                        return Promise.resolve();
                      }

                      return Promise.reject();
                    },
                    message: "Not a valid Facebook URL",
                  },
                ]}
                validateFirst={true}
                initialValue={props.user.facebook}
              >
                <Input placeholder="Facebook Profile" disabled={isPending} />
              </Form.Item>

              <Form.Item
                name="bio"
                label="Bio"
                rules={[
                  {
                    max: bioCharacterLimit,
                    message: `Bio cannot be longer than ${bioCharacterLimit} characters}`,
                  },
                ]}
                initialValue={props.user.bio}
              >
                <Input.TextArea
                  rows={6}
                  maxLength={bioCharacterLimit}
                  showCount={{ formatter: characterCountFormatter }}
                  placeholder={`Write a short bio (max ${bioCharacterLimit} characters)`}
                  disabled={isPending}
                />
              </Form.Item>

              <Form.Item
                name="lookingForWork"
                label="Are you looking for work?"
                initialValue={props.user.lookingForWork}
                valuePropName="checked"
              >
                <Checkbox>
                  <Tooltip title="Displays on your profile">
                    I am looking for work
                  </Tooltip>
                </Checkbox>
              </Form.Item>

              <h2>Your Privacy Settings</h2>

              <Tooltip title="Click to view our policy">
                <Link to="/privacy" target="_blank">
                  Data Privacy
                </Link>
              </Tooltip>

              <Form.Item
                name="consented"
                initialValue={props.user.consentedAt !== null && props.user.consentedAt !== undefined}
                valuePropName="checked"
              >
                <Checkbox>
                  I agree to the {" "}
                  <Link to="/privacy" target="_blank" style={{ textDecoration: "underline" }}>privacy policy</Link>
                </Checkbox>
              </Form.Item>
            </Card>
          </Col>
        </Row>
      </Layout>
    </Form>
  );
};
