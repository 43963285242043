import React, { useContext } from "react";
import { connect, ConnectedProps } from "react-redux";

import { RootState } from "services";
import { DiContext } from "app/common";
import { useAsync, useOnError, useOnMount } from "hooks";

import { Track, TrackEntity } from "app/infra/track";
import { sponsorshipService } from "app/infra/sponsorships";

import { Form, Input, Select } from "antd";
import { FormInstance } from "antd/lib/form";

const mapStateToProps = (state: RootState) => ({
  sponsorships: Object.values(state.sponsorshipStore.sponsorshipsById),
});

const connector = connect(mapStateToProps);
type PropsRedux = ConnectedProps<typeof connector>;

interface Props extends PropsRedux {
  form: FormInstance,
  onFinish: (data: Track) => void,
  track?: TrackEntity,
}

export const TrackForm = connector((props: Props) => {
  const { apiService, dispatch } = useContext(DiContext);
  const sponsorshipSrv = sponsorshipService({ apiService, dispatch });

  const { execute, error, isPending } = useAsync(() => {
    return sponsorshipSrv.getAll({});
  });
  useOnError(error);

  useOnMount(execute);

  return (
    <Form
      form={props.form}
      onFinish={props.onFinish}
      layout="vertical"
      initialValues={props.track}
    >
      <Form.Item
        name="name"
        label="name"
        rules={[
          {
            required: true,
            message: "Name is required",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="color"
        label="Background color"
        rules={[
          {
            required: true,
            message: "Background color is required",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="sponsorshipId"
        label="Sponsorship"
        rules={[
          {
            required: true,
            message: "Sponsorship is required",
          },
        ]}
        initialValue={props.track?.sponsorship?.id}
      >
        <Select
          showSearch={true}
          optionFilterProp="children"
          filterOption={(input, option) => {
            return (option?.children as string).toLowerCase().includes(input.toLowerCase());
          }}
          disabled={isPending}
        >
          {props.sponsorships.map((sponsorship) => (
            <Select.Option key={sponsorship.id} value={sponsorship.id}>
              {sponsorship.title}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Form>
  );
});
