import React from "react";

interface ConditionalWrapperProps {
  wrapperFn: (obj: { children: React.ReactNode }) => React.ReactElement;
  condition: boolean;
  children: React.ReactNode;
}

export const ConditionalWrapper = (props: ConditionalWrapperProps) => {
  return props.condition ? props.wrapperFn({ children: props.children }) : props.children as React.ReactElement;
};
