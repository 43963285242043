import React, { useState } from "react";

import { app } from "config";
import { useAsync, useOnError } from "hooks";

import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { Checkbox, Form, Modal } from "antd";

interface Props {
  onOk: () => Promise<unknown>;
  onCancel: () => void;
  isVisible: boolean;
}

export const Consent = (props: Props) => {
  const [form] = Form.useForm();

  const { execute, isPending, error } = useAsync(props.onOk);
  useOnError(error);

  const [isChecked, setIsChecked] = useState(false);

  const onCheckBoxChange = (event: CheckboxChangeEvent) => {
    setIsChecked(event.target.checked);
  };

  return (
    <Modal
      visible={props.isVisible}
      okButtonProps={{
        loading: isPending,
        disabled: !isChecked,
      }}
      onOk={form.submit}
      onCancel={props.onCancel}
      className="chat-agreement-modal ghost-modal"
    >
      <p>
        To enter the Raffle, I give permission for {app.name} to share my profile
        information with the company associated with the Raffle and for the company to
        contact me.
      </p>

      <Form form={form} onFinish={execute}>
        <Form.Item
          label="I Agree"
          name="agreement"
          initialValue={isChecked}
          rules={[
            {
              required: true,
              message: "",
            },
          ]}
        >
          <Checkbox onChange={onCheckBoxChange} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
