import React, { useMemo, useContext, useState, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { app } from "config";

import { connect } from "react-redux";
import { RootState } from "services";

import moment from "moment-timezone";

import { isMobile } from "react-device-detect";

import { DiContext } from "app/common";
import { useAsync } from "hooks/useAsync";
import { useOnMount } from "hooks/useOnMount";

import { AppMode, mode } from "config/initializers";
import { logout } from "services/AuthService";
import { UserEntity, userService } from "app/infra/user";
import { PushNotificationEntity, pushNotificationService } from "app/infra/pushNotification";
import { NotificationBareData } from "app/infra/pushNotification/pushNotification.store";
import { scheduleService } from "app/infra/schedule";

import { Badge, Button, Drawer, Dropdown, Layout, Menu } from "antd";
import {
  Archive,
  Bell,
  Bookmark,
  BookOpen,
  Calendar,
  Gift,
  HelpCircle,
  List,
  LogOut,
  Menu as MenuToggle,
  MessageCircle,
  PieChart,
  PlayCircle,
  Settings,
  Shield,
  Users,
} from "react-feather";
import logo from "assets/images/adw/logo/logo.svg";
// import logo from `assets/images/${app.nameAbr}/logo/logo.svg`;

import { UserAvatar } from "components/User/UserAvatar";
import { NotificationDropdown } from "./NotificationDropdown";

const { SubMenu } = Menu;

interface HeaderProps {
  me: UserEntity | null;
  isAdmin: boolean;
  unreadMessages: number;
  daysUserTime: string[];

  notifications: {
    latest: PushNotificationEntity | null;
    unread: NotificationBareData[];
  };
}

const mapStateToProps = (state: RootState): HeaderProps => {
  return {
    me: state.userStore.byId["me"],
    isAdmin: state.authStore.isAdmin,
    daysUserTime: state.scheduleStore.daysUserTime,
    unreadMessages: state.chatStore.unreadMessages,

    notifications: {
      latest: state.pushNotificationStore.latest,
      unread: state.pushNotificationStore.unread,
    },
  };
};

export const Header = connect(mapStateToProps)((props: HeaderProps) => {
  const { dispatch, apiService } = useContext(DiContext);

  const userSrv = userService({ apiService, dispatch });
  const scheduleSrv = scheduleService({ apiService, dispatch });
  const pushNotificationSrv = pushNotificationService({ apiService, dispatch });

  const [drawerVisible, setDrawerVisible] = useState(false);

  // const isProfileComplete = props.me?.isComplete;
  const hasReplayAccess = props.me?.replayAccess || props.me?.ticket_type === "Company";

  const { execute, isPending } = useAsync(() => {
    return Promise.all([
      userSrv.me(),
      scheduleSrv.getAgenda(),
    ]);
  });

  useOnMount(execute);

  const day = useMemo(() => {
    const index = props.daysUserTime.findIndex((date: string) => date === moment().format("D MMMM"));
    return index + 1;
  }, [props.daysUserTime]);

  const mainLink = useMemo(() => {
    if (isMobile) {
      return "/app/stage";
    }

    return mode === AppMode.onboarding && !props.isAdmin ? "/app/me" : "/app/lobby";
  }, [props.isAdmin]);

  const location = useLocation();

  useEffect(() => {
    if (
      window.navigator.serviceWorker === undefined
      || window.Notification === undefined
      || window.Notification.permission !== "granted"
    ) {
      if (props.notifications.latest) {
        pushNotificationSrv.unread.getAll({ after: props.notifications.latest.send_at }).catch(console.error);
      } else {
        pushNotificationSrv.getAll({ page: 0, count: 20 }).catch(console.error);
      }
    }
  }, [location.pathname]);

  const notificationMenuItemRender = useMemo(() => {
    return (
      <Menu.Item
        className="notification-bell"
        icon={(
          <Dropdown
            overlay={<NotificationDropdown unreadNotifications={props.notifications.unread} />}
            trigger={["click"]}
            overlayStyle={{ position: "fixed" }}
            openClassName="fixed-notifications"
          >
            <a
              href="/"
              className="ant-dropdown-link notification-indicator"
              onClick={(e) => { e.preventDefault(); }}
            >
              <Bell size={18} />
              <Badge
                count={props.notifications.unread.length}
                style={{ fontWeight: "bold", boxShadow: "none", top: "-14px", right: "-25px" }}
              />
            </a>
          </Dropdown>
        )}
      />
    );
  }, [props.notifications.unread]);

  const chatMenuItemRender = useMemo(() => {
    return (
      <Menu.Item>
        <NavLink to="/app/chats" onClick={() => setDrawerVisible(false)}>
          <Badge
            count={props.unreadMessages}
            overflowCount={99}
            offset={[17, 0]}
            style={{ background: "#F0136A", fontWeight: "bold", boxShadow: "none" }}
          >
            <MessageCircle size={18} />
            <span style={{ verticalAlign: "middle" }}>
              Chat
            </span>
          </Badge>
        </NavLink>
      </Menu.Item>
    );
  }, [props.unreadMessages]);

  if (isPending) return null;

  const getLogo = () => {
    return (
      <Menu.Item className="logo">
        {hasReplayAccess
          ? (
            <NavLink to={mainLink} onClick={() => window.scroll({ top: 0, behavior: "smooth" })}>
              <img src={logo} alt={app.name} height={40} />
            </NavLink>
          )
          : (
            <NavLink to="/app/pro">
              <img src={logo} alt={app.name} height={40} />
            </NavLink>
          )}

        {day ? (<span className="day-pill">{`Day ${day}`}</span>) : null}
      </Menu.Item>
    );
  };

  const getMenuItems = () => {
    return (
      <>
        {/* {(mode !== AppMode.onboarding || props.isAdmin) && isProfileComplete && hasReplayAccess && ( */}
        {(mode !== AppMode.onboarding || props.isAdmin) && hasReplayAccess && (
          <Menu.Item>
            <NavLink to="/app/lobby" onClick={() => setDrawerVisible(false)}>
              <Bookmark size={18} />
              <span style={{ verticalAlign: "middle" }}>
                Lobby
              </span>
            </NavLink>
          </Menu.Item>
        )}

        {/* {(mode !== AppMode.onboarding || props.isAdmin) && isProfileComplete && hasReplayAccess && ( */}
        {(mode !== AppMode.onboarding || props.isAdmin) && hasReplayAccess && (
          <Menu.Item>
            <NavLink to="/app/stage" onClick={() => setDrawerVisible(false)}>
              <PlayCircle size={18} />
              <span style={{ verticalAlign: "middle" }}>
                Stages
              </span>
            </NavLink>
          </Menu.Item>
        )}

        {/* {isProfileComplete && hasReplayAccess && (
          <Menu.Item>
            <NavLink to="/app/replays" onClick={() => setDrawerVisible(false)}>
              <Archive size={18} />
              <span style={{ verticalAlign: "middle" }}>
                Past Replays
              </span>
            </NavLink>
          </Menu.Item>
        )} */}

        {/* {isProfileComplete && hasReplayAccess && ( */}
        {hasReplayAccess && (
          <Menu.Item>
            <NavLink to="/app/schedule?type=Schedule" onClick={() => setDrawerVisible(false)}>
              <Calendar size={18} />
              <span style={{ verticalAlign: "middle" }}>
                Schedule
              </span>
            </NavLink>
          </Menu.Item>
        )}

        {/* {(mode !== AppMode.onboarding || props.isAdmin) && isProfileComplete && hasReplayAccess && ( */}
        {(mode !== AppMode.onboarding || props.isAdmin) && hasReplayAccess && (
          <>
            <Menu.Item>
              <NavLink to="/app/directory/companies" onClick={() => setDrawerVisible(false)}>
                <Users size={18} />
                <span style={{ verticalAlign: "middle" }}>
                  Networking
                </span>
              </NavLink>
            </Menu.Item>

            {!isMobile && props.me?.hasChatAccess && (chatMenuItemRender)}

            <Menu.Item>
              <NavLink to="/app/perks" onClick={() => setDrawerVisible(false)}>
                <Gift size={18} />
                <span style={{ verticalAlign: "middle" }}>
                  Perks
                </span>
              </NavLink>
            </Menu.Item>
          </>
        )}

        <Menu.Item>
          <NavLink to="/app/pro" onClick={() => setDrawerVisible(false)}>
            <List size={18} />
            <span style={{ verticalAlign: "middle" }}>
              Ad World Pro
            </span>
            <span
              style={{
                borderRadius: 20,
                background: "linear-gradient(223.32deg, #FF9900 0%, #F75E2E 97.23%)",
                color: "#fff",
                fontWeight: 800,
                fontSize: 12,
                padding: "4px 8px",
                marginTop: 5,
                marginLeft: 5,
              }}
            >
              NEW!
            </span>
          </NavLink>
        </Menu.Item>

        {props.isAdmin && (
          <Menu.Item>
            <NavLink to="/admin/schedule/tracks" onClick={() => setDrawerVisible(false)}>
              <PieChart size={18} />
              <span style={{ verticalAlign: "middle" }}>
                Admin
              </span>
            </NavLink>
          </Menu.Item>
        )}

        {!isMobile
          && (mode !== AppMode.onboarding || props.isAdmin)
          // && isProfileComplete
          && hasReplayAccess
          && (notificationMenuItemRender)}

        <SubMenu
          style={{ marginLeft: "0", border: "none" }}
          key="settings"
          popupOffset={[0, 0]}
          title={(
            <NavLink
              to="#"
              style={{ position: "relative" }}
              onClick={() => setDrawerVisible(false)}
            >
              {props.me && (
                <UserAvatar user={props.me} size={32} />
              )}
            </NavLink>
          )}
        >
          <Menu.Item
            key="setting:1"
            className="profile"
          >
            <NavLink to="/app/me" onClick={() => setDrawerVisible(false)}>
              <Settings size={18} />
              Profile
            </NavLink>
          </Menu.Item>

          <Menu.Item
            key="setting:2"
            className="profile"
          >
            <a
              href={app.detailsURL}
              target="_blank"
              rel="noreferrer"
              onClick={() => setDrawerVisible(false)}
            >
              <HelpCircle size={18} />
              FAQ
            </a>
          </Menu.Item>

          <Menu.Item
            key="privacy"
            className="profile"
          >
            <NavLink to="/privacy" target="_blank" onClick={() => setDrawerVisible(false)}>
              <Shield size={18} />
              Privacy Policy
            </NavLink>
          </Menu.Item>

          <Menu.Item
            key="terms"
            className="profile"
          >
            <NavLink to="/terms" target="_blank" onClick={() => setDrawerVisible(false)}>
              <BookOpen size={18} />
              Terms and Conditions
            </NavLink>
          </Menu.Item>

          <Menu.Item
            key="setting:3"
            className="logout"
            onClick={() => { logout({ dispatch }); }}
          >
            <LogOut size={18} />
            Logout
          </Menu.Item>
        </SubMenu>
      </>
    );
  };

  return (
    <Layout.Header>
      <Menu
        mode="horizontal"
        className="header-full"
        selectedKeys={[location.pathname]}
      >
        {getLogo()}
        {getMenuItems()}
      </Menu>

      <Menu mode="horizontal" className="header-menu-right">
        {getLogo()}
        <Menu.Item>
          <Button
            type="text"
            icon={<MenuToggle color="#ffffff80" />}
            onClick={() => setDrawerVisible(true)}
          />
        </Menu.Item>
      </Menu>

      <Drawer
        className="header-menu-right-drawer"
        placement="right"
        onClose={() => setDrawerVisible(false)}
        visible={drawerVisible}
        closeIcon={() => {}}
      >
        <Menu mode="inline">
          {getMenuItems()}
        </Menu>
      </Drawer>
    </Layout.Header>
  );
});
