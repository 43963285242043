import { useOnError } from "hooks/useOnError";
import React, { useState, useMemo } from "react";
import fileDownload from "js-file-download";
import { Button, Progress, Spin, Input, Checkbox, Row, Col, Card } from "antd";
import { Search } from "react-feather";
import { AxiosRequestConfig } from "axios";
import { useOnMount } from "hooks/useOnMount";
import { useAsync } from "hooks/useAsync";
import { PerkEntity } from "app/infra/perk";
import { SponsorshipEntity } from "app/infra/sponsorships";
import { CompanyEntity } from "app/infra/company";
import { RaffleEntity } from "app/infra/raffle";
import { useSelected } from "../../../common/utils/selected";

export interface Entity extends PerkEntity, SponsorshipEntity, CompanyEntity, RaffleEntity {}
export interface LeadStatsDTO {
  ids?: string[];
  startDate: string;
  endDate: string;
  profile?: boolean;
}

export interface ByIdDTO {
  ids: string[];
}

export interface LeadComponentProps {
  calledFromProfile?: boolean;
  select?: string[];
  startDate?: string;
  endDate?: string;
  getAll: () => Promise<Entity[]>;
  getStats: (data?: LeadStatsDTO | ByIdDTO, config?: AxiosRequestConfig) =>
    Promise<string | ArrayBuffer | ArrayBufferView | Blob>;
  title: string;
}

export interface DownloadProps {
  statsCall: (data?: LeadStatsDTO | ByIdDTO, config?: AxiosRequestConfig) =>
    Promise<string | ArrayBuffer | ArrayBufferView | Blob>;
  select?: string[];
  calledFromProfile?: boolean;
  startDate?: string;
  endDate?: string;
  checkboxes?: JSX.Element;
  title: string;
}

const LoadEntity = ({
  serviceCall,
  title,
}: {
  serviceCall: () => Promise<Entity[]>;
  title: string;
}) => {
  const { execute, error, isPending, value } = useAsync(serviceCall);
  useOnError(error);
  useOnMount(execute);

  const titleCase = (e: Entity) => {
    switch (title) {
      case "perks":
        return `${e.company?.name || "N/A"}-${e.title}`;
      case "company":
        return e.name || "N/A";
      case "sponsor":
        return e.title;
      default:
        return "";
    }
  };

  const [searchTerm, setSearchTerm] = useState<string>();
  const [searchResult, setSearchResult] = useState<Entity[]>();
  const updateSearch = (text: string) => {
    setSearchTerm(text);
    const result = value?.filter((e) => {
      return titleCase(e).toLowerCase().startsWith(text.toLowerCase());
    });
    setSearchResult(result);
  };

  const { entity, ids } = useMemo<{ entity: Entity[]; ids: string[] }>(() => {
    if (value) {
      return { entity: value, ids: value?.map((e) => e.id) };
    }
    return { entity: [], ids: [] };
  }, [value]);

  const {
    selectedArray,
    selected,
    changeAll,
    isSelectAll,
    toggle,
  } = useSelected({ entityIds: ids });

  return {
    selectedCheckBoxes: selectedArray,
    renderElement: (
      <>
        {isPending && <Spin />}
        <div
          style={{ display: "flex" }}
          className="search-container"
        >
          <Input
            type={searchTerm}
            value={searchTerm}
            prefix={<Search size={20} color="#A3A7B2" />}
            onChange={(e) => updateSearch(e.target.value)}
            placeholder="Search"
            style={{
              width: 200,
            }}
          />
        </div>

        <Card style={{ margin: "20px 0", border: 0 }}>
          <div className="select-all-container">
            <Checkbox
              checked={isSelectAll}
              onChange={() => {
              changeAll(!isSelectAll);
            }}
            >
              Select All
            </Checkbox>
          </div>

          <div className="perks-checkboxes">
            <Row>
              {(searchResult || entity).map((e) => (
                <Col key={`${e.id}-${e.name}`} md={6}>
                  <Checkbox
                    key={e.id}
                    checked={selected[e.id]}
                    onChange={() => toggle(e.id)}
                  >
                    {titleCase(e)}
                  </Checkbox>
                </Col>
            ))}
            </Row>
          </div>
        </Card>
      </>
    ),
  };
};

export const DownloadStats = ({
  statsCall,
  select = [],
  calledFromProfile = false,
  startDate = "",
  endDate = "",
  checkboxes = <></>,
  title,
}: DownloadProps) => {
  const params = startDate !== "" ?
    { ids: select, startDate, endDate } :
    { ids: select };

  const {
    execute: executeGetStats,
    isPending: pendingGetStats,
    error: errorGetStats,
  } = useAsync(() =>
    (statsCall(params).then((response: string | ArrayBuffer | ArrayBufferView | Blob) => {
        fileDownload(
          response || "",
          `${title}-${startDate}-${endDate}.csv`,
        );
      })
    ));

  const [progress] = useState(0);
  useOnError(errorGetStats);

  return (
    <div className="perk-leads">
      {!calledFromProfile && checkboxes}
      <div className="download-button-wrapper">
        <Button
          type="ghost"
          style={{
            ...(pendingGetStats ? { width: 200, paddingRight: 55 } : {}),
          }}
          onClick={executeGetStats}
          disabled={pendingGetStats}
        >
          <span style={{ ...(pendingGetStats ? { paddingRight: 10 } : {}) }}>
            Download
          </span>
          {pendingGetStats && <Progress percent={progress} size="small" />}
        </Button>
      </div>
    </div>
  );
};

export const LeadComponent = ({
  calledFromProfile,
  select = [],
  startDate = "",
  endDate = "",
  getAll,
  getStats,
  title,
}: LeadComponentProps) => {
  let checkboxes: JSX.Element = <></>;

  if (!calledFromProfile) {
    const { selectedCheckBoxes, renderElement } = LoadEntity({
      serviceCall: getAll,
      title,
    });
    select = selectedCheckBoxes;
    checkboxes = renderElement;
  }

  return DownloadStats({
    statsCall: getStats,
    checkboxes,
    select,
    calledFromProfile,
    startDate,
    endDate,
    title,
  });
};
