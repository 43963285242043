import React from "react";
import { Link } from "react-router-dom";
import { CompanyEntity } from "app/infra/company";

interface CompanyCardProps {
  company: CompanyEntity;
}

export const Basic = ({ company }: CompanyCardProps) => {
  const subtitle = [company.company_type, company.country]
    .filter(Boolean)
    .join(" | ");

  return (
    <div className="basic-card">
      <div className="basic-card__header">
        <Link className="basic-card__header__title" to={`/app/booth/${company.id}`}>{company.name}</Link>
        <p className="basic-card__header__subtitle">{subtitle}</p>
      </div>

      <div className="basic-card__footer">
        <Link className="basic-card__footer__link" to={`/app/booth/${company.id}`}>View company</Link>
      </div>
    </div>
  );
};
