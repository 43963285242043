import React, { useContext } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";

import { RootState } from "services";
import { DiContext } from "app/common";
import { useAsync, useOnError, useOnMount } from "hooks";

import { Sponsorship, sponsorshipService } from "app/infra/sponsorships";

import { useForm } from "antd/lib/form/Form";
import { Button, Card, Col, notification, Row } from "antd";

import { SponsorshipForm } from "./SponsorshipForm";

const mapStateToProps = (state: RootState) => ({
  sponsorships: state.sponsorshipStore.sponsorshipsById,
});

const connector = connect(mapStateToProps);
type PropsRedux = ConnectedProps<typeof connector>;

export const SponsorshipUpdate = connector((props: PropsRedux) => {
  const { apiService, dispatch } = useContext(DiContext);
  const sponsorshipSrv = sponsorshipService({ apiService, dispatch });

  const [form] = useForm();
  const history = useHistory();
  const params = useRouteMatch().params as { id: string };

  const { execute, error } = useAsync(() => {
    return sponsorshipSrv.getOne(params.id);
  });
  useOnError(error);

  useOnMount(execute);

  const { execute: onUpdate, error: onUpdateError } = useAsync((data: Sponsorship) => {
    return sponsorshipSrv.update(data, params.id).then((response) => {
      history.push("/admin/sponsorships");

      notification.success({ message: "Sponsorship was successfully updated" });
      return response;
    });
  });
  useOnError(error);

  if (!props.sponsorships[params.id]) return null;

  return (
    <Row>
      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
        <Card>
          <Row style={{ marginBottom: "20px" }}>
            <Col>
              <h1>Update Sponsorship</h1>
            </Col>
          </Row>

          <SponsorshipForm
            form={form}
            onFinish={onUpdate}
            sponsorship={props.sponsorships[params.id]}
          />

          <Row style={{ marginTop: "20px" }}>
            <Col style={{ marginLeft: "auto" }}>
              <Button type="primary" onClick={form.submit}>Update</Button>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
});
