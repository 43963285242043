import React from "react";

import { PerkEntity } from "app/infra/perk";
import { PerkButton } from "./PerkButton";

interface PerkProps {
  perk: PerkEntity;
  onButtonClick?: (id: string) => void;
}

export const Perk = (props: PerkProps) => {
  return (
    <div className="perk-card">
      <div className="perk-card-cover">
        <div
          className="image-cover"
          style={{ backgroundImage: `url(${props.perk.image_url as string})` }}
        />

        <div className="image-cover-gradient" />
      </div>

      <div className="perk-card-content">
        <h3 className="perk-card-content-title">{props.perk.title}</h3>
        <p className="perk-card-content-description">
          {props.perk.description}
        </p>
        <div className="perk-card-content-divider" />
        <div className="perk-card-content-footer">
          <PerkButton perk={props.perk} onButtonClick={props.onButtonClick} />
        </div>
      </div>
    </div>
  );
};

Perk.defaultProps = { onButtonClick: () => {} };
