import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";

import { useAsync, useOnError, useOnMount } from "hooks";
import { PostEntity, postService } from "app/infra/posts";
import { DiContext, usePaginationAsync } from "app/common";

import { parse } from "qs";
import { parseQueryPath } from "app/common/utils/Path";

import Table, { ColumnsType } from "antd/lib/table";
import Antd, { Button, Card, Col, Input, notification, Popconfirm, Row } from "antd";
import { Edit, Search, Trash2 } from "react-feather";

interface PostListParams {
  page?: string;
  search?: string;
}

export const PostList = () => {
  const { apiService, dispatch } = useContext(DiContext);
  const postSrv = postService({ apiService, dispatch });

  const history = useHistory();
  const queryParams = parse(history.location.search, { ignoreQueryPrefix: true }) as PostListParams;

  const pagination = usePaginationAsync(
    (page, pageSize, search, sortBy, sortDirection) => {
      return postSrv.getAllPaginated({
        count: pageSize,
        page,
        search: search || undefined,
        sortBy: sortBy || undefined,
        sortDirection: sortDirection || undefined,
      });
    },
    {
      initialFilter: { search: queryParams.search || "" },
      initialPage: Number(queryParams.page),
    },
  );

  useOnMount(pagination.async.execute);

  const { execute, error } = useAsync((id: string) => {
    return postSrv.delete(id).then((response) => {
      notification.success({ message: "Post was successfully deleted" });
      pagination.async.execute();
      return response;
    });
  });
  useOnError(error);

  const updateSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    history.push(parseQueryPath("/admin/posts", "page", "1", "search", event.currentTarget.value));
    pagination.onSetFilter({ search: event.currentTarget.value });
  };

  const columns: ColumnsType<PostEntity> = [
    {
      title: "ID",
      dataIndex: "id",
      sorter: true,
      onHeaderCell: () => ({
        onClick: () => pagination.onToggleOrderBy("id"),
      }),
    },
    {
      title: "Content",
      dataIndex: "body",
      render: (value: string) => <div dangerouslySetInnerHTML={{ __html: value }} />,
    },
    {
      key: "actions",
      width: 84,
      render: (_value, record) => (
        <Button.Group>
          <Link
            to={`/admin/posts/${record.id}/edit`}
            title="Edit"
            className="ant-btn ant-btn-link ant-btn-sm"
          >
            <Edit size={20} />
          </Link>

          <Popconfirm
            title="Are you certain that you want to delete this Post?"
            onConfirm={() => execute(record.id)}
            okText="Confirm"
            cancelText="Cancel"
            placement="topRight"
          >
            <Button type="link" size="small" title="Delete" danger={true}>
              <Trash2 size={20} />
            </Button>
          </Popconfirm>
        </Button.Group>
      ),
    },
  ];

  return (
    <Card>

      <Row className="admin-page-header">
        <Col className="left-column">
          <h1 className="title">Posts</h1>
          <div className="elements">
            <Input
              placeholder="Search profile"
              prefix={<Search size={20} color="#A3A7B2" />}
              value={pagination.filter.search}
              onChange={updateSearch}
            />
          </div>
        </Col>

        <Col>
          <Button type="primary" onClick={() => history.push("/admin/posts/new")}>
            Create Post
          </Button>
        </Col>
      </Row>

      <Table
        size="middle"
        loading={pagination.async.isPending}
        bordered={true}
        rowKey="id"
        columns={columns}
        dataSource={pagination.async.value?.data}
        pagination={{
          size: "default",
          total: pagination.totalItems,
          current: pagination.page,
          pageSize: pagination.pageSize,
          onChange: (page: number, pageSize?: number) => {
            pagination.onSetPage(page);
            if (pageSize) {
              pagination.onSetPageSize(pageSize);
            }

            history.push(parseQueryPath(
              "/admin/posts",
              "page",
              page.toString(),
              "search",
              pagination.filter.search,
            ));
            window.scrollTo({ top: 0 });
          },
        }}
      />
    </Card>
  );
};
