import React, { CSSProperties, useRef } from "react";
import { DragObjectWithType, useDrag, useDrop } from "react-dnd";

interface DraggableRowProps {
  index: number;
  moveRow: (index: number, to: number) => void;
  className: string;
  style: CSSProperties;
}

interface DropProps {
  isOver?: boolean;
  dropClassName?: string;
}

interface DragItem extends DragObjectWithType {
  id: string;
  index: number;
  className: string;
}

const type = "DraggableRow";

export const DraggableRow = (props: DraggableRowProps) => {
  const ref = useRef(null);

  const [dropProps, drop] = useDrop<DragItem, undefined, DropProps>({
    accept: type,
    collect: (monitor) => {
      const item = monitor.getItem() as DragItem;
      if (!item || item.index === props.index) {
        return {};
      }

      const isDownward = props.index > item.index;
      return {
        isOver: monitor.isOver(),
        dropClassName: isDownward ? "ant-table-row--drop-over-downward" : "ant-table-row--drop-over-upward",
      };
    },
    drop: (item) => {
      props.moveRow(item.index, props.index);
      return undefined;
    },
  });

  const [, drag] = useDrag<DragItem, DropProps, object>({
    item: {
      id: type,
      type,
      index: props.index,
      className: props.className,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  const dropClassName = (dropProps.isOver && dropProps.dropClassName) ? dropProps.dropClassName : "";
  return (
    <tr
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      ref={ref}
      className={["ant-table-row--draggable", props.className, dropClassName].join(" ")}
      style={{ ...props.style }}
    />
  );
};
