import React, { useContext, useEffect } from "react";
import { Modal, Form } from "antd";
import { UserEntity } from "app/infra/user";
import { useAsync, DiContext } from "app/common";
import { useOnError } from "hooks/useOnError";
import { ReplayTalkForm } from "./replayTalk.form";
import { ReplayTrackEntity } from "app/infra/replayTrack";
import { replayTalkService } from "app/infra/replayTalk";

interface EditReplayTalkModalProps {
  stageId: string;
  speakers: UserEntity[];
  tracks: ReplayTrackEntity[];
  visible: boolean;
  close: () => void;
}

interface FormProps {
  title: string;
  description: string;
  start_time: string;
  length: number;
  speakers: string[];
  track: string;
}

export const CreateReplayTalkModal = ({
  visible,
  close,
  stageId,
  speakers,
  tracks,
}: EditReplayTalkModalProps) => {
  const [form] = Form.useForm();
  const { apiService, dispatch } = useContext(DiContext);
  const talkSrv = replayTalkService({ apiService, dispatch });

  const { value, execute, pending, error } = useAsync(
    (data: FormProps) => talkSrv.create(data, stageId),
    false
  );

  useOnError(error);

  useEffect(() => {
    if (value) {
      form.resetFields();
      close();
    }
  }, [value]);

  return (
    <Modal
      title="Create talk"
      className="create-talk-modal"
      visible={visible}
      onOk={() => {
        form.submit();
      }}
      onCancel={close}
    >
      <ReplayTalkForm
        form={form}
        speakers={speakers}
        tracks={tracks}
        onFinish={execute}
        loading={pending}
      />
    </Modal>
  );
};
