import React, { useContext, useEffect, useMemo, useState } from "react";
import { connect, ConnectedProps } from "react-redux";

import _ from "lodash";
import moment, { Moment } from "moment";

import { useOnError, useOnMount } from "hooks";
import { RootState } from "services";
import { DiContext, useAsync } from "app/common";
import { UserEntity, userService } from "app/infra/user";
import { Talk, TalkEntity, talkService } from "app/infra/talk";

import { ColumnsType } from "antd/lib/table";
import Form, { FormInstance } from "antd/lib/form";
import Select, { SelectValue } from "antd/lib/select";
import { Button, Checkbox, Col, DatePicker, Input, InputNumber, Row } from "antd";
import { Delete, UserPlus } from "react-feather";

import { Table } from "components/Table";
import { UserAvatar } from "components/User/UserAvatar";
import { FileFormFunctionProps, UploadPhoto } from "app/presentation/common";

const mapStateToProps = (state: RootState) => ({
  tracks: Object.values(state.trackStore.byId),
  stages: Object.values(state.stageStore.byId),
  speakers: Object.keys(state.userStore.byId)
    .map((key) => state.userStore.byId[key])
    .filter((user) => user.roles && user.roles.includes("ROLE_SPEAKER")),
});

const connector = connect(mapStateToProps);
type PropsRedux = ConnectedProps<typeof connector>;

interface Props extends PropsRedux {
  form: FormInstance;
  onFinish: (data: Talk) => void;
  loading: boolean;
  talk?: TalkEntity;
}

export const TalkForm = connector((props: Props) => {
  const { dispatch, apiService } = useContext(DiContext);
  const talkSrv = talkService({ apiService, dispatch });
  const userSrv = userService({ apiService, dispatch });

  const [hasDiscussion, setHasDiscussion] = useState(props.talk?.has_discussion);
  const [hasMixer, setHasMixer] = useState(props.talk?.hasNetworkingMixer);

  const [selectValue, setSelectValue] = useState<SelectValue | undefined>(undefined);
  const [selectedSpeakers, setSelectedSpeakers] = useState<UserEntity[]>(props.talk?.speakers || []);
  const [selectableSpeakers, setSelectableSpeakers] = useState<UserEntity[]>([]);

  useEffect(() => {
    props.form.setFieldsValue({ speakers: selectedSpeakers.map((speaker) => speaker.id) });
    setSelectableSpeakers(props.speakers.filter((speaker) => {
      return !selectedSpeakers.find((selectSpeaker) => selectSpeaker.id === speaker.id);
    }));
  }, [selectedSpeakers, props.speakers]);

  const onAddSpeaker = () => {
    if (!selectValue) {
      return;
    }

    setSelectedSpeakers([
      ...selectedSpeakers,
      props.speakers.find((speaker) => speaker.id.toString() === selectValue)!,
    ]);

    setSelectValue(undefined);
  };

  const onRemoveSpeaker = (id: string) => {
    setSelectedSpeakers(selectedSpeakers.filter((speaker) => speaker.id.toString() !== id.toString()));
  };

  const uploadPhoto = (fileProps: FileFormFunctionProps) => {
    return talkSrv.cover.create({ id: props.talk?.id || "0", file: fileProps.file });
  };

  const [endsAt, setEndsAt] = useState("");

  const recalculateEndTime = () => {
    const startTime = props.form.getFieldValue("start_time") as Moment;
    const length = props.form.getFieldValue("length") as number;
    const ret = startTime && length ? _.cloneDeep(startTime) : null;
    if (ret) {
      ret.add(length, "minutes");
      setEndsAt(ret.format("HH:mm:ss"));
      return;
    }
    setEndsAt("");
  };

  useOnMount(recalculateEndTime);

  useEffect(() => {
    props.form.resetFields();

    if (props.talk && props.talk.speakers) {
      setSelectedSpeakers(props.talk.speakers);
      props.form.setFieldsValue({ speakers: props.talk.speakers.map((speaker) => speaker.id) });
    }

    setHasDiscussion(props.talk?.has_discussion);
    setHasMixer(props.talk?.hasNetworkingMixer);
  }, [props.talk]);

  const { execute: getAllUsers, error: getAllUsersError } = useAsync(() => {
    return userSrv.getAll({
      limit: 100,
      page: 0,
      role: "ROLE_SPEAKER",
    });
  });
  useOnError(getAllUsersError);

  useEffect(() => {
    getAllUsers();
  }, []);

  const speakerColumns: ColumnsType<UserEntity> = [
    {
      key: "profile_picture_url",
      dataIndex: "profile_picture_url",
      render: (_value, record) => {
        return <UserAvatar user={record} />;
      },
      width: "100px",
    },
    {
      key: "first_name",
      dataIndex: "first_name",
      render: (_value, record) => {
        return `${record.first_name} ${record.last_name}`;
      },
      align: "left",
    },
    {
      key: "actions",
      width: 40,
      render: (_value, record) => (
        <Button
          type="link"
          size="small"
          title="Remove"
          danger={true}
          onClick={() => onRemoveSpeaker(record.id)}
        >
          <Delete size={20} />
        </Button>
      ),
    },
  ];

  return (
    <Form
      form={props.form}
      onFinish={props.onFinish}
      layout="vertical"
    >

      <Row gutter={{ lg: 10, xl: 10, xxl: 10 }}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <Form.Item
            label="Stage"
            name="stage_id"
            rules={[
              {
                required: true,
                message: "Stage is required",
              },
            ]}
            initialValue={props.talk?.stage_id}
          >
            <Select disabled={props.loading}>
              {props.stages.map((stage) => {
                return <Select.Option key={stage.id} value={stage.id}>{stage.name}</Select.Option>;
              })}
            </Select>
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <Form.Item
            label="Track"
            name="track"
            rules={[
              {
                required: true,
                message: "Track is required",
              },
            ]}
            initialValue={props.talk?.track_id}
          >
            <Select disabled={props.loading}>
              {props.tracks.map((track) => {
                return <Select.Option key={track.id} value={track.id}>{track.name}</Select.Option>;
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        label="Name"
        name="title"
        rules={[
          {
            required: true,
            message: "Name is required",
          },
        ]}
        initialValue={props.talk?.title}
      >
        <Input disabled={props.loading} />
      </Form.Item>

      <Form.Item
        label="Description"
        name="description"
        rules={[
          {
            required: true,
            message: "Description is required",
          },
        ]}
        initialValue={props.talk?.description}
      >
        <Input.TextArea rows={4} disabled={props.loading} />
      </Form.Item>

      <Row gutter={{ lg: 10, xl: 10, xxl: 10 }}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <Form.Item
            label={<span>Starts at <b>(your timezone)</b></span>}
            name="start_time"
            rules={[
              {
                required: true,
                message: "Start time is required",
              },
            ]}
            initialValue={props.talk && moment(props.talk.start_time)}
          >
            <DatePicker
              showTime={true}
              disabled={props.loading}
              onChange={recalculateEndTime}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
          <Form.Item label="Ends at">
            <Input disabled={true} value={endsAt} />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
          <Form.Item
            label="Video length"
            name="length"
            rules={[
              {
                required: true,
                message: "Video length is required",
              },
            ]}
            initialValue={props.talk?.length}
          >
            <InputNumber
              min={1}
              max={200}
              placeholder="Length"
              disabled={props.loading}
              onChange={recalculateEndTime}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        label="Dacast ID"
        name="video_url"
        rules={[
          {
            required: true,
            message: "Dacast ID is required",
          },
        ]}
        initialValue={props.talk?.video_url}
      >
        <Input placeholder="Dacast ID" disabled={props.loading} />
      </Form.Item>

      <Form.Item
        label="VIMEO"
        name="video_url_2"
        rules={[
          {
            required: true,
            message: "VIMEO Link is required",
          },
          {
            message: "VIMEO Link is not a valid URL",
            type: "url",
          },
        ]}
        initialValue={props.talk?.video_url_2}
      >
        <Input placeholder="Vimeo Link" disabled={props.loading} />
      </Form.Item>

      <Form.Item
        label="Amazon"
        name="video_url_3"
        rules={[
          {
            required: true,
            message: "Amazon Link is required",
          },
          {
            message: "Amazon Link is not a valid URL",
            type: "url",
          },
        ]}
        initialValue={props.talk?.video_url_3}
      >
        <Input placeholder="Amazon Link" disabled={props.loading} />
      </Form.Item>

      <Form.Item
        label="JW"
        name="video_url_4"
        rules={[
          {
            required: true,
            message: "JW ID is required",
          },
          {
            message: "JW ID is not a valid URL",
            type: "url",
          },
        ]}
        initialValue={props.talk?.video_url_4}
      >
        <Input placeholder="JW ID" disabled={props.loading} />
      </Form.Item>

      <Form.Item
        label="Sponsor Q&A Ad Text"
        name="sponsor_qa_ad_text"
        initialValue={props.talk?.sponsor_qa_ad_text}
      >
        <Input placeholder="Sponsor Q&A Ad Text" disabled={props.loading} />
      </Form.Item>

      <Row>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <Form.Item
            label="Has Discussion"
            name="has_discussion"
            initialValue={hasDiscussion}
            valuePropName="checked"
          >
            <Checkbox checked={hasDiscussion} disabled={hasMixer} onChange={() => setHasDiscussion(!hasDiscussion)} />
          </Form.Item>
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <Form.Item
            label="Has Networking Mixer"
            name="hasNetworkingMixer"
            initialValue={hasMixer}
            valuePropName="checked"
          >
            <Checkbox checked={hasMixer} disabled={hasDiscussion} onChange={() => setHasMixer(!hasMixer)} />
          </Form.Item>
        </Col>
      </Row>

      {props.talk ? (
        <Form.Item label="Cover photo">
          <UploadPhoto uploadPhoto={uploadPhoto} image_url={props.talk?.cover_url || ""} />
        </Form.Item>
      ) : "Image for talks can be uploaded only on edit, not on create!"}

      <Form.List
        name="speakers"
        rules={[
          {
            validator: (_rule, value: number[], callback) => {
              if (!value || value.length === 0) {
                callback("At least one speaker must be added");
              } else {
                callback();
              }
            },
          },
        ]}
      >
        {(_fields, _operations, { errors }) => {
          return (
            <>
              <Form.Item
                label="Speakers"
                required={true}
              >
                <Input.Group compact={true} style={{ display: "flex" }}>
                  <Select
                    onChange={setSelectValue}
                    value={selectValue}
                    showSearch={true}
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      return (option?.children as string).toLowerCase().includes(input.toLowerCase());
                    }}
                    style={{ flex: "1" }}
                  >
                    {selectableSpeakers.map((speaker) => {
                      return (
                        <Select.Option key={speaker.id} value={speaker.id.toString()}>
                          {`${speaker.first_name} ${speaker.last_name}`}
                        </Select.Option>
                      );
                    })}
                  </Select>

                  <Button
                    icon={<UserPlus style={{ verticalAlign: "middle" }} />}
                    onClick={onAddSpeaker}
                    style={{
                      width: "50px",
                      height: "50px",
                      backgroundColor: "#9a6aff",
                      color: "white",
                    }}
                  />
                </Input.Group>
              </Form.Item>

              <Table
                size="middle"
                bordered={true}
                showHeader={false}
                rowKey="id"
                columns={speakerColumns}
                dataSource={selectedSpeakers}
                pagination={false}
              />

              <Form.ErrorList errors={errors} />
            </>
          );
        }}
      </Form.List>
    </Form>
  );
});
