import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import { DiContext, usePaginationAsync } from "app/common";
import { CompanyEntity, companyService } from "app/infra/company";
import { useOnMount } from "hooks";

import { ColumnsType } from "antd/lib/table";
import { Avatar, Button, Card, Checkbox, Col, Input, Row, Table } from "antd";
import { Edit, Search } from "react-feather";

import { parseQueryPath } from "app/common/utils/Path";
import { parse } from "qs";

interface CompanyListParams {
  page?: string;
  search?: string;
}

export const CompanyList = () => {
  const { apiService, dispatch } = useContext(DiContext);
  const companySrv = companyService({ apiService, dispatch });

  const history = useHistory();
  const queryParams = parse(history.location.search, { ignoreQueryPrefix: true }) as CompanyListParams;

  const [onlyBooths, setOnlyBooths] = useState(false);

  const pagination = usePaginationAsync(
    (page, pageSize, search, sortBy, sortDirection) => {
      return companySrv.getCompaniesPaginated({
        count: pageSize,
        onlybooths: onlyBooths,
        page,
        search: search || undefined,
        sortBy: sortBy || undefined,
        sortDirection: sortDirection || undefined,
        component: "admin-company",
      });
    },
    {
      initialFilter: { search: queryParams.search || "" },
      initialPage: Number(queryParams.page),
    },
  );

  useOnMount(pagination.async.execute);

  useEffect(pagination.async.execute, [onlyBooths]);

  const updateSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    history.push(parseQueryPath("/admin/companies", "page", "1", "search", event.currentTarget.value));
    pagination.onSetFilter({ search: event.currentTarget.value });
  };

  const columns: ColumnsType<CompanyEntity> = [
    {
      title: "",
      dataIndex: "logo_url",
      render: (value: string) => {
        return <Avatar src={value} alt={value} size="large" />;
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      sorter: true,
      onHeaderCell: () => ({
        onClick: () => pagination.onToggleOrderBy("name"),
      }),
    },
    {
      title: "Description",
      dataIndex: "description",
      ellipsis: true,
      sorter: true,
      onHeaderCell: () => ({
        onClick: () => pagination.onToggleOrderBy("description"),
      }),
    },
    {
      title: "Ticket Type",
      dataIndex: "ticket_type",
    },
    {
      title: "Country",
      dataIndex: "country",
      sorter: true,
      onHeaderCell: () => ({
        onClick: () => pagination.onToggleOrderBy("country"),
      }),
    },
    {
      title: "Url",
      dataIndex: "url",
      sorter: true,
      onHeaderCell: () => ({
        onClick: () => pagination.onToggleOrderBy("url"),
      }),
    },
    {
      title: "Expertise",
      dataIndex: "expertise",
      render: (value: string[]) => {
        return value.map((val) => `${val} `);
      },
    },
    {
      key: "actions",
      width: 40,
      render: (_value, record) => (
        <Link
          to={`/admin/companies/${record.id}/edit`}
          title="Edit"
          className="ant-btn ant-btn-link ant-btn-sm"
        >
          <Edit size={20} />
        </Link>
      ),
    },
  ];

  return (
    <Card>
      <Row className="admin-page-header">
        <Col className="left-column">
          <h1 className="title">Companies</h1>
          <div className="elements">
            <Input
              placeholder="Search profile"
              prefix={<Search size={20} color="#A3A7B2" />}
              value={pagination.filter.search}
              onChange={updateSearch}
            />
            <Checkbox
              checked={onlyBooths}
              onChange={() => setOnlyBooths(!onlyBooths)}
              style={{ flexDirection: "row-reverse", width: 190 }}
            >
              Only Booths:
            </Checkbox>
          </div>
        </Col>

        <Col style={{ marginLeft: "auto" }}>
          <Button type="primary" onClick={() => history.push("/admin/companies/new")}>
            Create Company
          </Button>
        </Col>
      </Row>

      <Table
        size="middle"
        loading={pagination.async.isPending}
        bordered={true}
        rowKey="id"
        columns={columns}
        dataSource={pagination.async.value?.data}
        pagination={{
          size: "default",
          total: pagination.totalItems,
          current: pagination.page,
          pageSize: pagination.pageSize,
          onChange: (page: number, pageSize: number | undefined) => {
            pagination.onSetPage(page);
            if (pageSize) {
              pagination.onSetPageSize(pageSize);
            }

            history.push(parseQueryPath(
              "/admin/companies",
              "page",
              page.toString(),
              "search",
              pagination.filter.search,
            ));
            window.scrollTo({ top: 0 });
          },
        }}
      />
    </Card>
  );
};
