import React, { useEffect, useState, useContext, useMemo } from "react";
import qs from "qs";

import { useLocation } from "react-router-dom";
import { Row, Input, Button, Col } from "antd";
import { Search } from "react-feather";
import {
  useAsync,
  DiContext,
  usePagination,
} from "app/common/utils";
import { useOnError } from "hooks/useOnError";
import { UserEntity, adminUserService } from "app/infra/user";
import { useModal, useDebounce } from "hooks";
import * as Modal from "components/@admin/User/Modal";
import { EditProfileModal } from "./editProfile.modal";
import { DirectoryTable } from "./directory.table";
import { CreateProfileModal } from "./createProfile.modal";
import { ConnectionsModal } from "./connections.modal";
import { SyncUserModal } from "./syncUser.modal";

export const UserSection = () => {
  const { dispatch, apiService } = useContext(DiContext);
  const adminUserSrv = useMemo(
    () => adminUserService({ apiService, dispatch }),
    [apiService, dispatch],
  );
  const [selectedUser, setSelectedUser] = useState<UserEntity | null>(null);

  const editUserModal = useModal();
  const createUserModal = useModal();
  const confirmationLinkModal = useModal();
  const resendForgotPasswordEmailLinkModal = useModal();
  const forgotPasswordEmailLinkModal = useModal();
  const updateConnectionLimitsModal = useModal();
  const syncUserModal = useModal();

  const { execute, error, value, pending } = useAsync<{
    data: UserEntity[];
    pagination: { page: number; total: number };
  }>(adminUserSrv.getAll);

  const {
    page,
    pageSize,
    totalItems,
    filter,
    setFilter,
    setPage,
    toggleOrderBy,
  } = usePagination({
    initialPageSize: 20,

    // @ts-ignore
    pagination: (value && value.pagination) || null,
  });

  const location = useLocation();

  useEffect(() => {
    const queryParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    }) as { search?: string };
    queryParams.search && updateSearch(queryParams.search);
  }, []);

  const updateSearch = (text: string) => {
    setFilter({
      ...filter,
      search: text,
    });
  };

  const search = (filter && filter["search"]) || "";

  const debouncedSearch = useDebounce(search, 500);

  useEffect(() => {
    if (debouncedSearch !== "") {
      execute({
        search: debouncedSearch,
        page: 0,
        pageSize,
      });
    } else {
      execute({
        search: debouncedSearch,
        page,
        pageSize,
      });
    }
  }, [page, pageSize, debouncedSearch]);

  useOnError(error);

  const users = useMemo(() => {
    return (value && value.data) || [];
  }, [value]);

  return (
    <div>
      {/* Edit has to be unmounted as it keeps the previous fields prefilled */}
      {selectedUser && editUserModal.isVisible && (
        <EditProfileModal
          user={selectedUser}
          visible={editUserModal.isVisible}
          close={() => {
            editUserModal.shut();
            setSelectedUser(null);
          }}
          onSuccess={() => {
            execute({
              search,
              page,
              pageSize,
            });
            editUserModal.shut();
            setSelectedUser(null);
          }}
        />
      )}

      {selectedUser && (
        <ConnectionsModal
          user={selectedUser}
          visible={updateConnectionLimitsModal.isVisible}
          close={() => {
            updateConnectionLimitsModal.shut();
            setSelectedUser(null);
          }}
          onSuccess={() => {
            execute({
              search,
              page,
              pageSize,
            });
            updateConnectionLimitsModal.shut();
            setSelectedUser(null);
          }}
        />
      )}

      {selectedUser && (
        <Modal.Invitation
          id={selectedUser.id}
          isVisible={confirmationLinkModal.isVisible}
          onCancel={() => {
            confirmationLinkModal.shut();
            setSelectedUser(null);
          }}
        />
      )}

      {selectedUser && (
        <Modal.PasswordRecovery
          id={selectedUser.id}
          isVisible={resendForgotPasswordEmailLinkModal.isVisible}
          onCancel={() => {
            resendForgotPasswordEmailLinkModal.shut();
            setSelectedUser(null);
          }}
        />
      )}
      {selectedUser && (
        <Modal.PasswordRecoveryLink
          id={selectedUser.id}
          isVisible={forgotPasswordEmailLinkModal.isVisible}
          onCancel={() => {
            forgotPasswordEmailLinkModal.shut();
            setSelectedUser(null);
          }}
        />
      )}

      {selectedUser && (
        <SyncUserModal
          id={selectedUser.id}
          visible={syncUserModal.isVisible}
          close={() => {
            syncUserModal.shut();
            setSelectedUser(null);
          }}
        />
      )}

      <CreateProfileModal
        visible={createUserModal.isVisible}
        close={() => {
          createUserModal.shut();
          setSelectedUser(null);
        }}
        onSuccess={() => {
          execute({
            search,
            page,
            pageSize,
          });
          createUserModal.shut();
          setSelectedUser(null);
        }}
      />

      <Row className="admin-page-header">
        <Col className="left-column" flex={1}>
          <h1 className="title">Users</h1>
          <div className="elements">
            <Input
              type={search}
              value={search}
              prefix={<Search size={20} color="#A3A7B2" />}
              onChange={(e) => updateSearch(e.target.value)}
              placeholder="Search profile (email, name, ticket reference)"
              style={{
                maxWidth: 400,
              }}
            />
          </div>
        </Col>

        <Col>
          <Button
            type="primary"
            onClick={createUserModal.open}
            style={{ marginLeft: 16 }}
          >
            Create User
          </Button>
        </Col>
      </Row>

      <DirectoryTable
        users={users}
        pending={pending}
        openModal={(user) => {
          setSelectedUser(user);
          editUserModal.open();
        }}
        openSendConfirmationModal={(user) => {
          setSelectedUser(user);
          confirmationLinkModal.open();
        }}
        openResendForgotPasswordEmailModal={(user) => {
          setSelectedUser(user);
          resendForgotPasswordEmailLinkModal.open();
        }}
        openForgotPasswordEmailModal={(user) => {
          setSelectedUser(user);
          forgotPasswordEmailLinkModal.open();
        }}
        openUpdateConnectionLimits={(user) => {
          setSelectedUser(user);
          updateConnectionLimitsModal.open();
        }}
        openSyncUserModal={(user) => {
          setSelectedUser(user);
          syncUserModal.open();
        }}
        page={page}
        totalItems={totalItems}
        toggleOrderBy={toggleOrderBy}
        pageSize={pageSize}
        setPage={setPage}
      />
    </div>
  );
};
