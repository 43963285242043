import React from "react";

import moment, { Moment } from "moment";

import { DatePicker } from "antd";
import type { RangePickerDateProps } from "antd/lib/date-picker/generatePicker";
import type { EventValue, RangeValue } from "rc-picker/lib/interface";

interface Props extends RangePickerDateProps<Moment> {
  onSelected: (from: EventValue<Moment>, upto: EventValue<Moment>) => void;
  from: EventValue<Moment>;
  upto: EventValue<Moment>;
}

const RangePicker = (props: Props) => {
  const onDateChanged = (dates: RangeValue<Moment>) => {
    if (dates) {
      props.onSelected(dates[0], dates[1]);
    } else {
      props.onSelected(moment(undefined), moment(undefined));
    }
  };

  return (
    <DatePicker.RangePicker
      value={[props.from, props.upto]}
      onChange={(values, _) => onDateChanged(values)}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

export default RangePicker;
