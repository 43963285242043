import React, { useMemo } from "react";

import { connect } from "react-redux";
import { RootState } from "services";

import _ from "lodash";

import { useSessionState } from "hooks";

import { StageEntity } from "app/infra/stage";
import { TrackEntity } from "app/infra/track";
import { TalkEntityExtended } from "app/infra/talk";

import { Button, Col, Input, Row } from "antd";
import { Search, XCircle } from "react-feather";

import CheckboxFilter from "components/CheckboxFilter/CheckboxFilter";
import { TalkItem } from "./talk.item";

interface StringBooleanMap {
  [key: string]: boolean;
}

interface AllTalksPageProps {
  stages: StageEntity<string>[];
  tracks: TrackEntity[];
  talks: TalkEntityExtended[];
}

const mapStateToProps = (state: RootState): AllTalksPageProps => ({
  tracks: Object.values(state.trackStore.byId),
  stages: Object.values(state.stageStore.byId),
  talks: Object.values(state.talkStore.byId).map((talk) => ({
    ...talk,
    stage: state.stageStore.byId[talk.stage_id],
    track: state.trackStore.byId[talk.track_id],
  })),
});

export const AllTalksPage = connect(mapStateToProps)((props: AllTalksPageProps) => {
  const [filter, setFilter] = useSessionState<string>("stages:search", "");
  const [selectedTracks, setSelectedTracks] = useSessionState<StringBooleanMap>("stages:tracks", {});
  const [selectedStages, setSelectedStages] = useSessionState<StringBooleanMap>("stages:stages", {});

  const selectedTracksArray = useMemo(() => {
    return Object.keys(selectedTracks).filter((key) => selectedTracks[key]);
  }, [selectedTracks]);

  const selectedStagesArray = useMemo(() => {
    return Object.keys(selectedStages).filter((key) => selectedStages[key]);
  }, [selectedStages]);

  const showClearAllFilters = useMemo(() => {
    return (
      filter.length > 0
      || selectedTracksArray.length > 0
      || selectedStagesArray.length > 0
    );
  }, [filter, selectedTracksArray, selectedStagesArray]);

  const clearFilters = (): void => {
    setFilter("");
    setSelectedTracks({});
    setSelectedStages({});
  };

  const tracks = useMemo(() => {
    return props.tracks
      .filter((track) => track.name !== "Stage Break")
      .sort((a, b) => (a.name < b.name ? 1 : -1));
  }, [props.tracks]);

  const stages = useMemo(() => {
    return props.stages
      .filter((stage) => stage.name !== "Break")
      .sort((a, b) => (a.name < b.name ? 1 : -1));
  }, [props.stages]);

  const talks = useMemo(() => {
    let entries = props.talks.filter((talk) => {
      const title = talk.title.toLowerCase().trim();
      const isStageClosed = talk.description.toLowerCase().trim().includes("stage closed")
        || title === ""
        || title.includes("stage closed");
      return !isStageClosed && !title.includes("stage break") && !talk.hasNetworkingMixer;
    });

    if (selectedTracksArray.length > 0) {
      entries = entries.filter((entry) => selectedTracksArray.includes(entry.track_id.toString()));
    }

    if (selectedStagesArray.length > 0) {
      entries = entries.filter((entry) => selectedStagesArray.includes(entry.stage_id.toString()));
    }

    if (filter !== "") {
      const strings = filter.toLowerCase().split(" ");
      entries = entries.filter((entry) => {
        const title = entry.title.toLowerCase();
        const description = entry.description.toLowerCase();

        return strings.reduce((acc: boolean, value: string) => {
          if (acc) return acc;
          return (
            value !== ""
            && (title.search(value) !== -1 || description.search(value) !== -1)
          );
        }, false);
      });
    }

    return _.orderBy(entries, (talk) => [talk.stage?.order, talk.track?.name], "desc");
  }, [props.talks, selectedTracksArray, selectedStagesArray, filter]);

  return (
    <div className="agenda-page">
      <div className="checkbox-filters">
        <div className="search-input">
          <Input
            className="adw-white-input"
            size="large"
            placeholder="Search"
            prefix={<Search size={20} color="#A3A7B2" />}
            suffix={filter && <XCircle size={20} onClick={() => setFilter("")} style={{ cursor: "pointer" }} />}
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            style={{ maxWidth: "100%" }}
          />
        </div>

        <div className="filter-right">
          {showClearAllFilters && (
            <Button
              type="link"
              onClick={clearFilters}
              className="clear-filters-btn"
            >
              Clear Filters
            </Button>
          )}

          <CheckboxFilter
            items={tracks.map((element) => {
              return {
                id: element.id,
                name: element.name,
              };
            })}
            placeholder="Tracks"
            selectedItems={selectedTracks}
            setSelectedItems={setSelectedTracks}
          />

          <CheckboxFilter
            items={stages.reverse().map((element) => {
              return {
                id: element.id,
                name: element.name,
              };
            })}
            placeholder="Stages"
            selectedItems={selectedStages}
            setSelectedItems={setSelectedStages}
          />

        </div>
      </div>

      <div
        className="main-content"
        style={{
          padding: 0,
        }}
      >
        <Row className="replays-ant-row" gutter={[24, 24]}>
          {talks.map((talk) => (
            <Col sm={24} md={12} lg={8} xl={6}>
              <div className="talk-item-container">
                <TalkItem talk={talk} />
              </div>
            </Col>
          ))}
        </Row>

        {talks.length === 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <span
              style={{
                fontWeight: 600,
                color: "#181a55",
                fontSize: 17,
              }}
            >
              No talks with that search criteria!
            </span>
          </div>
        )}
      </div>
    </div>
  );
});
