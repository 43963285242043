import React, { useContext, useEffect, useMemo, useState } from "react";
import { Form, Input, Select, Upload, Switch, Checkbox, Spin, Button } from "antd";
import { Paperclip, Trash2 } from "react-feather";
import { attendeeInterests } from "app/core/user/data";
import { uniqBy } from "lodash";
import { UserEntity } from "app/infra/user";
import { DiContext, useAsync } from "app/common";
import { useOnError } from "hooks/useOnError";
import { CompanyEntity, companyService } from "app/infra/company";
import { attendeeCompanyType } from "app/core/user/data/companyType";
import { Loading } from "components/Loading/Loading";
import { useDebounce } from "hooks/useDebounce";

const { Option } = Select;

interface UserFormProps {
  user?: UserEntity;
  pending?: boolean;
  photo?: {
    deletePhoto: () => void;
    upload: () => void;
    pendingDelete: boolean;
    pendingUpload: boolean;
  };
  create?: boolean;
}

const Roles = [
  { value: "ROLE_SPEAKER", title: "speaker" },
  { value: "ROLE_ATTENDEE", title: "attendee" },
  { value: "ROLE_ADMIN", title: "admin" },
];

export const UserForm = ({ user, pending, photo, create }: UserFormProps) => {
  const { apiService, dispatch } = useContext(DiContext);
  const companySrv = companyService({ apiService, dispatch });

  const [deactivateSync, setDeactivateSync] = useState(
    user?.ticket?.deactivate_sync ?? true
  );

  const [company, setCompany] = useState<string>(user?.company?.id || "");
  const [search, setSearch] = useState<string>("");

  const disableFieldEditing = useMemo(() => pending || !deactivateSync, [
    pending,
    deactivateSync,
  ]);

  const { execute, pending: getCompaniesPending, error, value } = useAsync<{
    data: CompanyEntity[];
  }>((search: string) => {
    return companySrv.getCompanies({
      search: search || undefined,
      component: "user-company-search",
      limit: 10,
      page: 0,
    });
  }, false);

  useOnError(error);

  const debouncedSearch = useDebounce(search, 500);

  useEffect(() => {
    execute(debouncedSearch);
  }, [debouncedSearch]);

  const companies = useMemo(() => {
    return uniqBy(
      [...(value?.data || []), ...((user?.company && [user.company]) || [])],
      "id"
    );
  }, [value]);

  return (
    <>
      {user && (
        <Form.Item
          label="Disable Tito Synchronisation?"
          name="deactivate_sync"
          initialValue={deactivateSync}
        >
          <Switch checked={deactivateSync} onChange={setDeactivateSync} />
        </Form.Item>
      )}
      <Form.Item
        label="Active?"
        name="active"
        valuePropName="checked"
        initialValue={user?.active || false}
      >
        <Checkbox />
      </Form.Item>
      <Form.Item
        label="Chat Admin?"
        name="isChatAdmin"
        valuePropName="checked"
        initialValue={user?.isChatAdmin}
      >
        <Checkbox />
      </Form.Item>

      <Form.Item
        label="Has Chat Access?"
        name="hasChatAccess"
        valuePropName="checked"
        initialValue={user?.hasChatAccess}
      >
        <Checkbox />
      </Form.Item>

      <Form.Item
        label="Replay Access?"
        name="replayAccess"
        valuePropName="checked"
        initialValue={user?.replayAccess}
      >
        <Checkbox />
      </Form.Item>
      <Form.Item
        label="Promoted?"
        name="isPromoted"
        valuePropName="checked"
        initialValue={user?.isPromoted}
      >
        <Checkbox />
      </Form.Item>
      {user && (
        <Form.Item
          label="ID"
          name="id"
          initialValue={user?.id}
          style={{ display: "none" }}
        >
          <Input disabled={disableFieldEditing} />
        </Form.Item>
      )}
      <Form.Item
        rules={[{ required: true }]}
        label="First name"
        name="first_name"
        initialValue={user?.first_name}
      >
        <Input disabled={disableFieldEditing} />
      </Form.Item>
      <Form.Item
        rules={[{ required: true }]}
        label="Last name"
        name="last_name"
        initialValue={user?.last_name}
      >
        <Input disabled={disableFieldEditing} />
      </Form.Item>

      <Form.Item
        rules={[{ required: true }]}
        label="Email"
        name="email"
        initialValue={user?.email}
      >
        <Input disabled={disableFieldEditing} />
      </Form.Item>
      {!user && (
        <Form.Item
          rules={[{ required: true }]}
          label="Password"
          name="password"
        >
          <Input disabled={disableFieldEditing} />
        </Form.Item>
      )}
      {photo && (
        <Form.Item
          label="Photo"
          name="profile_picture_url"
          style={{ position: "relative" }}
        >
          {user?.profile_picture_url && (
            <Button
              size="small"
              shape="circle"
              style={{
                position: "absolute",
                top: -10,
                left: -10,
                zIndex: 10,
                background: "#000",
              }}
              onClick={photo.deletePhoto}
            >
              <Trash2 size={14} color="white" />
            </Button>
          )}

          <Upload
            name="avatar"
            listType="picture-card"
            showUploadList={false}
            customRequest={photo.upload}
            accept=".png,.jpg,.jpeg"
          >
            {user?.profile_picture_url ? (
              <div
                style={{
                  position: "relative",
                }}
              >
                {(photo.pendingDelete || photo.pendingUpload) && (
                  <Loading
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  />
                )}
                <img
                  src={user?.profile_picture_url}
                  alt="avatar"
                  style={{ width: "100%" }}
                />
              </div>
            ) : (
              <div>
                {photo.pendingUpload ? <Loading /> : <Paperclip size={18} />}
                <div className="ant-upload-text">Upload</div>
              </div>
            )}
          </Upload>
        </Form.Item>
      )}

      {!create
        ? (
          <>
            <Form.Item
              label="Job Position"
              name="job_position"
              initialValue={user?.job_position}
            >
              <Input disabled={disableFieldEditing} />
            </Form.Item>

            <Form.Item
              label="Bio"
              name="bio"
              initialValue={user?.bio}
            >
              <Input.TextArea rows={6} disabled={disableFieldEditing} />
            </Form.Item>
          </>
        )
      : null}

      <Form.Item
        rules={[{ max: 3, min: 0, type: "array" }]}
        label="Interests"
        name="interested"
        initialValue={user?.interested}
      >
        <Select
          mode="multiple"
          options={attendeeInterests.map((item) => ({ value: item }))}
          showArrow={true}
          showSearch={true}
          disabled={disableFieldEditing}
          placeholder="Interests"
        />
      </Form.Item>

      <Form.Item
        label="Role"
        name="role"
        initialValue={user?.roles[0] || "ROLE_ATTENDEE"}
        rules={[{ required: true }]}
      >
        <Select style={{ width: "100%" }} disabled={disableFieldEditing}>
          {Roles.map((role) => (
            <Select.Option key={`${role.title}-${role.value}`} value={role.value}>
              {role.title.toUpperCase()}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="gender"
        label="Gender"
        initialValue={user?.gender || null}
      >
        <Select disabled={disableFieldEditing}>
          <Select.Option value="male">Male</Select.Option>
          <Select.Option value="female">Female</Select.Option>
          <Select.Option value="other">Other</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item name="age" label="Age" initialValue={user?.age || null}>
        <Select disabled={disableFieldEditing}>
          <Select.Option value="18-24">18-24</Select.Option>
          <Select.Option value="25-34">25-34</Select.Option>
          <Select.Option value="35-44">35-44</Select.Option>
          <Select.Option value="45-54">45-54</Select.Option>
          <Select.Option value="55-64">55-64</Select.Option>
          <Select.Option value="65+">65+</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item
        name="attendee_company_type"
        label="Attendee Type"
        initialValue={user?.attendee_company_type}
      >
        <Select
          // placeholder="Company Type"
          placeholder="Attendee Type"
          allowClear={true}
          // options={companyType.map((value) => Object.create({ value }))}
          options={attendeeCompanyType.map((value) => Object.create({ value }))}
        />
      </Form.Item>

      {!company && (
        <>
          <Form.Item
            name="attendee_company"
            label="Company Name"
            rules={[
              {
                max: 50,
                min: 2,
                message: "Company Name must be between 2 and 50 characters",
              },
            ]}
            initialValue={user?.attendee_company}
          >
            <Input placeholder="Company Name" />
          </Form.Item>
        </>
      )}

      <Form.Item
        label="Company"
        name="company_id"
        initialValue={company || null}
      >
        <Select
          allowClear={true}
          disabled={disableFieldEditing}
          showSearch
          // labelInValue
          value={company}
          placeholder="Select company"
          notFoundContent={getCompaniesPending ? <Spin size="small" /> : null}
          filterOption={false}
          onSearch={(value) => {
            setSearch(value);
          }}
          onChange={(value) => {
            if (value) {
              // @ts-ignore
              setCompany(value);
            } else {
              setCompany("");
            }
          }}
          style={{ width: "100%" }}
        >
          {companies.map((comp) => (
            <Option key={`${comp.id}-${comp.name}`} value={comp.id}>
              {comp.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </>
  );
};
